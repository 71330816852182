import { Injectable } from "@angular/core";
// import { Http, Headers, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/map";
// import { Logger } from "angular2-logger/core";
// import { environment } from "environments/environment";
import { ConstantService } from "../constants/constant.service";
import { CommonService } from "../services/common.service";
import { DMConstantService } from "../constants/dm.constant.service";

import * as moment from "moment-timezone";
import { MyCurrencyPipe } from "../components/formatter/currency/my-currency.pipe";
import { MyPercentagePipe } from "../components/formatter/percentage/my-percentage.pipe";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
@Injectable()
export class ProjectionReportPdfService {
  public projectionDataList: any[] = [];
  public storeGroup: any = [];
  public snapShotVersion!: any[];
  public snapShotData: any;
  public projectionDataRow: any[] = [];
  constructor(
    // private http: Http,
    // private _logger: Logger,
    private constantService: ConstantService,
    private commonService: CommonService,
    private mycurpipe: MyCurrencyPipe,
    private myperpipe: MyPercentagePipe,
    private DMConstantService: DMConstantService
  ) {
    // (pdfFonts.pdfMake.vfs = pdfMake.vfs;
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
  }
  init() {
    //
  }
  downloadProjectionPdfReport(
    projectionColumnsList: any[],
    data: any[],
    storeGroupData: any[],
    totalDataRow: {
      unadjustedApparentUplift: string;
      armatusOptimizedUplift: string;
      factoryAdjustedUplift: string;
      netUpliftYear1: string;
      rOIInMonths: null;
    },
    imgData: any,
    type: string,
    snapShotVersion: any,
    snapShotData: string
  ) {
    console.log(projectionColumnsList);
    console.log(data);
    this.projectionDataRow = [
      true,
      true,
      true,
      true,
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ];
    /** ----------End Section---------- */

    let fileName_ws = "";
    let snapShotCreatedTime = null;
    this.storeGroup = storeGroupData;
    this.projectionDataList = data;
    this.snapShotVersion = snapShotVersion;
    this.snapShotData = snapShotData;
    if (type === "cp") {
      let snapShotName = this.snapShotVersion[0].description;
      if (this.snapShotVersion[0].id < 0) {
        snapShotName = this.snapShotData;
      }
      snapShotName = snapShotName + "_";
      fileName_ws = "Projection_" + moment().format("YYYYMMDDhhmmss");
      //snapShotCreatedTime = this.commonService.randomVersionNumberGenerator(this.DMConstantService.REPORT_VERSION_FORMAT);
    } else {
      fileName_ws = "Projection_" + moment().format("YYYYMMDDhhmmss");
    }
    const cellWidths = [
      33, 20, 17, 36, 21, 27, 27, 24, 35, 35, 24, 24, 24, 27, 26, 24, 24, 24, 27, 24, 22, 23, 23,
      26,
    ];
    let footerText = this.constantService.EXCEL_FOOTER_DYNAMIC;
    footerText = footerText.replace(
      /{{footerName}}/i,
      this.storeGroup[0].salesPerson ? this.storeGroup[0].salesPerson : ""
    );
    footerText = footerText.replace(
      /{{footerEmail}}/i,
      this.storeGroup[0].spEmail ? this.storeGroup[0].spEmail : ""
    );
    footerText = footerText.replace(
      /{{footerPhone}}/i,
      this.storeGroup[0].spPhone ? this.storeGroup[0].spPhone : ""
    );
    let objFooter;
    if (snapShotCreatedTime) {
      objFooter = [
        {
          text: footerText,
          alignment: "center",
          border: [true, false, false, true],
        },
        {
          text: this.DMConstantService.REPORT_VERSION_LABEL + snapShotCreatedTime,
          alignment: "right",
          border: [false, false, true, true],
        },
      ];
    } else {
      objFooter = [
        {
          text: footerText,
          alignment: "center",
          border: [true, false, true, true],
        },
      ];
    }
    const loopData = [];
    for (const i in this.projectionDataList) {
      if (this.projectionDataList) {
        const loopData1 = [
          {
            text: this.projectionDataList[i]["stName"]
              ? this.projectionDataList[i]["stName"]
              : null,
            style: "hederSection",
          },
          {
            text: this.projectionDataList[i]["brand"] ? this.projectionDataList[i]["brand"] : null,
            style: "hederSection",
          },
          {
            text: this.projectionDataList[i]["state"] ? this.projectionDataList[i]["state"] : null,
            style: "hederSection",
          },
          {
            text: this.projectionDataList[i]["annualWarrPartsCost"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["annualWarrPartsCost"], 0)
              : null,
            style: "hederSection",
          },
          {
            text: this.projectionDataList[i]["cpPartsGrossProfit"]
              ? this.myperpipe.transform(this.projectionDataList[i]["cpPartsGrossProfit"], 2)
              : null,
            style: "hederSection",
          },
          {
            text: this.projectionDataList[i]["effWpGrossProfit"]
              ? this.myperpipe.transform(this.projectionDataList[i]["effWpGrossProfit"], 2)
              : null,
            style: "hederSection",
          },
          {
            text: this.projectionDataList[i]["cpGpMarkup"]
              ? this.myperpipe.transform(this.projectionDataList[i]["cpGpMarkup"], 2)
              : null,
            style: "hederSection",
          },
          {
            text: this.projectionDataList[i]["effWpGrossProfitMarkup"]
              ? this.myperpipe.transform(this.projectionDataList[i]["effWpGrossProfitMarkup"], 2)
              : null,
            style: "hederSection",
          },
          {
            text: this.projectionDataList[i]["cpWarrMarkupDelta"]
              ? this.myperpipe.transform(this.projectionDataList[i]["cpWarrMarkupDelta"], 2)
              : null,
            style: "hederSectionGray",
          },
          {
            text: this.projectionDataList[i]["addlAnnualWpProfit"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["addlAnnualWpProfit"], 0)
              : null,
            style: "hederSectionGray",
          },
          {
            text: this.projectionDataList[i]["stStatuteAif"]
              ? this.myperpipe.transform(this.projectionDataList[i]["stStatuteAif"], 2)
              : null,
            style: "hederSectionB",
          },
          {
            text: this.projectionDataList[i]["projectedWpMarkup"]
              ? this.myperpipe.transform(this.projectionDataList[i]["projectedWpMarkup"], 2)
              : null,
            style: "hederSectionB",
          },
          {
            text: this.projectionDataList[i]["alBrandedApprAvg"]
              ? this.myperpipe.transform(this.projectionDataList[i]["alBrandedApprAvg"], 2)
              : null,
            style: "hederSectionB",
          },
          {
            text: this.projectionDataList[i]["addlPtsAbvCurrEffWm"]
              ? this.myperpipe.transform(this.projectionDataList[i]["addlPtsAbvCurrEffWm"], 2)
              : null,
            style: "hederSectionB",
          },
          {
            text: this.projectionDataList[i]["optAddlOgAwpProfit"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["optAddlOgAwpProfit"], 0)
              : null,
            style: "hederSectionB",
          },
          {
            text: this.projectionDataList[i]["currLikelyFactBehaviorImpact"]
              ? this.myperpipe.transform(
                  this.projectionDataList[i]["currLikelyFactBehaviorImpact"],
                  2
                )
              : "0.00 %",
            style:
              this.projectionDataList[i]["currLikelyFactBehaviorImpact"] > 0
                ? "hederSectionWhite"
                : "dataSectionRed",
          },
          {
            text: this.projectionDataList[i]["currentEffWarrMUPostFactBehavior"]
              ? this.myperpipe.transform(
                  this.projectionDataList[i]["currentEffWarrMUPostFactBehavior"],
                  2
                )
              : null,
            style: "hederSectionB",
          },
          {
            text: this.projectionDataList[i]["ogWpMarkupAdjForFactory"]
              ? this.myperpipe.transform(this.projectionDataList[i]["ogWpMarkupAdjForFactory"], 2)
              : null,
            style: "hederSectionB",
          },
          {
            text: this.projectionDataList[i]["addlOgAwpProfitAdjForFactory"]
              ? this.mycurpipe.transform(
                  this.projectionDataList[i]["addlOgAwpProfitAdjForFactory"],
                  0
                )
              : null,
            style: "hederSectionB",
          },
          {
            text: this.projectionDataList[i]["postApprSubmissionFee"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["postApprSubmissionFee"], 0)
              : null,
            style: "headerSectionG",
          },
          {
            text: this.projectionDataList[i]["monthlyUpliftFee"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["monthlyUpliftFee"], 0)
              : null,
            style: "headerSectionG",
          },
          {
            text: this.projectionDataList[i]["totalProfitAcqCost"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["totalProfitAcqCost"], 0)
              : null,
            style: "headerSectionG",
          },
          {
            text: this.projectionDataList[i]["netWpAddlPuYr1"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["netWpAddlPuYr1"], 0)
              : null,
            style: "headerSectionG",
          },
          {
            text: this.projectionDataList[i]["nomToReturnFullProfitAcqCost"]
              ? this.projectionDataList[i]["nomToReturnFullProfitAcqCost"]
              : null,
            style: "headerSectionG",
          },
        ];
        loopData.push(loopData1);
      }
    }
    console.log(loopData);
    const projectioReport: any = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [4, 4, 4, 4],
      content: [
        {
          style: "topHeaderTable",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: this.constantService.EXCEL_HEADING1,
                  style: "reportHeader",
                },
              ],
            ],
          },
          layout: { defaultBorder: false },
        },
        {
          style: "topHeaderTable",
          table: {
            widths: [150, 650],
            body: [
              [
                {
                  image: imgData,
                  width: 150,
                  height: 100,
                },
                {
                  border: [false, false, false, false],
                  text: this.constantService.EXCEL_HEADING2,
                  italics: true,
                  style: "subhead2",
                },
              ],
            ],
          },
          layout: { defaultBorder: false },
        },
        {
          alignment: "left",
          style: "groupNameList",
          columns: [
            { text: "Group Name:", style: "header" },
            { text: this.storeGroup[0].itemName, style: "header1" },
          ],
        },
        {
          style: "tableExample",
          table: {
            border: [true, true, true, false],
            widths: [171, 79, 296, "*"],
            body: [
              [
                {
                  text: this.constantService.EXCEL_HEADING3,
                  style: "topHeader",
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.SUB_HEAD_COLUMN2,
                  style: "topHeader1",
                },
                {
                  text: this.constantService.EXCEL_HEADING4,
                  style: "topHeader2",
                },
                {
                  text: this.constantService.EXCEL_HEADING5,
                  style: "topHeader3",
                },
              ],
            ],
          },
          layout: {},
        },
        {
          style: "peojectionTableData",
          table: {
            heights: [70],
            widths: cellWidths,
            border: [false, false, false, true],
            body: [
              [
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_1,
                  style: "hederSection",
                  alignment: "middle",
                  border: [true, true, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_2,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, true, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_3,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, true, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_4,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_5,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_6,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_7,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_8,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_9,
                  style: "hederSectionGray",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_10,
                  style: "hederSectionGray",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_11,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_12,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_13,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_14,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_15,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_16,
                  style: "hederSectionRed",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_17,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_18,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_19,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_20,
                  style: "headerSectionG",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_21,
                  style: "headerSectionG",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_22,
                  style: "headerSectionG",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_23,
                  style: "headerSectionG",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.COLUMN_24,
                  style: "headerSectionG",
                  alignment: "center",
                  border: [true, false, true, false],
                },
              ],
            ],
          },
          layout: { defaultBorder: true },
        },
        {
          style: "peojectionTableData",
          table: {
            heights: [20],
            widths: cellWidths,
            body: loopData,
          },
          layout: {},
        },
        {
          style: "peojectionTableData",
          table: {
            widths: [199, 148, 161, 125, 88, "*"],
            heights: function (row: number) {
              return (row + 1) * 15;
            },
            body: [
              [
                {
                  rowSpan: 2,
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_6,
                  alignment: "center",
                  fillColor: "#FFFFFF",
                  style: "footerRegion",
                  margin: [0, 20, 0, 0],
                  border: [true, false, true, true],
                },
                {
                  text: totalDataRow.unadjustedApparentUplift
                    ? this.mycurpipe.transform(totalDataRow.unadjustedApparentUplift, 0)
                    : "$ 0",
                  alignment: "center",
                  fillColor: "#D9D9D9",
                  style: "footerRegion",
                  border: [true, false, true, true],
                },
                {
                  text: totalDataRow.armatusOptimizedUplift
                    ? this.mycurpipe.transform(totalDataRow.armatusOptimizedUplift, 0)
                    : "$ 0",
                  alignment: "center",
                  fillColor: "#D0E3F2",
                  style: "footerRegion",
                  border: [true, false, true, true],
                },
                {
                  text: totalDataRow.factoryAdjustedUplift
                    ? this.mycurpipe.transform(totalDataRow.factoryAdjustedUplift, 0)
                    : "$ 0",
                  alignment: "center",
                  fillColor: "#D0E3F2",
                  style: "footerRegion",
                  border: [true, false, true, true],
                },
                {
                  text: totalDataRow.netUpliftYear1
                    ? this.mycurpipe.transform(totalDataRow.netUpliftYear1, 0)
                    : "$ 0",
                  alignment: "center",
                  fillColor: "#DAEAD4",
                  style: "footerRegion",
                  border: [true, false, true, true],
                },
                {
                  text: totalDataRow.rOIInMonths != null ? totalDataRow.rOIInMonths : "",
                  alignment: "center",
                  fillColor: "#DAEAD4",
                  style: "footerRegion",
                  border: [true, false, true, true],
                },
              ],
              [
                "",
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_1,
                  alignment: "center",
                  fillColor: "#D9D9D9",
                  style: "footerRegion",
                  margin: [0, 10, 0, 0],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_2,
                  alignment: "center",
                  fillColor: "#D0E3F2",
                  style: "footerRegion",
                  margin: [0, 10, 0, 0],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_3,
                  alignment: "center",
                  fillColor: "#D0E3F2",
                  style: "footerRegion",
                  margin: [0, 10, 0, 0],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_4,
                  alignment: "center",
                  fillColor: "#DAEAD4",
                  style: "footerRegion",
                  margin: [0, 10, 0, 0],
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_5,
                  alignment: "center",
                  fillColor: "#DAEAD4",
                  style: "footerRegion",
                  margin: [0, 10, 0, 0],
                },
              ],
            ],
          },
          layout: {},
        },
        // {
        //   style: 'peojectionTableDataFooter',
        //   table: {
        //     widths: snapShotCreatedTime ? [600, '*'] : ['*'],
        //     body: [
        //       objFooter,
        //     ]
        //   },
        //   layout: {
        //     hLineWidth: function (i, node) {
        //       return 1;
        //     },
        //     vLineWidth: function (i, node) {
        //       return 1;
        //     },
        //     hLineColor: function (i, node) {
        //       return 'black';
        //     },
        //     vLineColor: function (i, node) {
        //       return 'black';
        //     },
        //   }
        // }
      ],
      styles: {
        row1Style: {
          fontSize: 18,
          bold: true,
          alignment: "center",
          margin: [10, 1, 40, 2],
          color: "#175595",
        },
        subhead2: {
          fontSize: 10,
          bold: false,
          alignment: "center",
          margin: [0, 30, 0, 0],
          color: "#175595",
        },
        reportHeader: {
          fontSize: 18,
          bold: true,
          alignment: "center",
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          color: "#175595",
        },
        topHeaderTable: {
          border: [false, false, false, false],
        },
        topHeader: {
          fillColor: "#FFFFFF",
          fontSize: 9,
          alignment: "center",
          margin: [0, 15, 0, 0],
          border: [true, true, true, false],
        },
        topHeader1: {
          fillColor: "#D9D9D9",
          fontSize: 9,
          alignment: "center",
          margin: [0, 10, 0, 0],
          border: [true, true, true, false],
        },
        topHeader2: {
          fillColor: "#D0E3F2",
          fontSize: 9,
          alignment: "center",
          margin: [0, 5, 0, 0],
          border: [true, true, true, false],
        },
        topHeader3: {
          fillColor: "#DAEAD4",
          fontSize: 9,
          alignment: "center",
          margin: [0, 5, 0, 0],
          border: [true, true, true, false],
        },
        hederSection: {
          fillColor: "#FFFFFF",
          fontSize: 6,
        },
        hederSectionGray: {
          fillColor: "#DFDCDC",
          fontSize: 6,
        },
        hederSectionB: {
          fillColor: "#CDE2F4",
          fontSize: 6,
        },
        headerSectionG: {
          fillColor: "#D0E0BF",
          fontSize: 6,
        },
        hederSectionWhite: {
          fillColor: "#FFFFFF",
          fontSize: 6,
        },
        dataSectionRed: {
          fillColor: "#f7c1be",
          fontSize: 6,
        },
        hederSectionRed: {
          fillColor: "#F4C0BF",
          fontSize: 6,
        },
        header: {
          fontSize: 16,
          bold: true,
          alignment: "left",
          margin: [40, 12, 40, 1],
          color: "#175595",
        },
        header1: {
          fontSize: 16,
          bold: true,
          alignment: "left",
          margin: [10, 10, 0, 0],
          color: "#175595",
        },
        subhead: {
          fontSize: 12,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          backgroundColor: "#175595",
          border: 1,
          widths: 200,
        },
        subheadR1: {
          fontSize: 3,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          border: [true, true, true, true],
          fillColor: "red",
        },
        subheadR2: {
          fontSize: 12,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          widths: 300,
        },
        subheadR3: {
          fontSize: 12,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          widths: 400,
        },
        tableHeader: {
          alignment: "center",
          fontSize: 13,
          bold: true,
          color: "black",
          margin: [0, 10, 0, 10],
        },
        tableExample: {
          margin: [97, 10, 0, 0],
        },
        groupNameList: {
          margin: [60, 10, 0, 0],
        },
        footerRegion: {
          fontSize: 9,
          alignment: "center",
          bold: true,
          color: "black",
        },
        peojectionTableDataFooter: {
          fontSize: 10,
          alignment: "center",
          bold: true,
          color: "black",
        },
      },
    };
    pdfMake.createPdf(projectioReport).download(fileName_ws + ".pdf");
  }
}
