import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DuedateReportComponent } from "../duedate-report/duedate-report/duedate-report.component";

const routes: Routes = [
  {
    path: "home",
    component: DuedateReportComponent,
    data: { title: "Home", breadcrumb: [{ label: "Home", url: "" }] },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
