import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedRoutingModule } from "./shared-routing.module";
import { MyCurrencyPipe } from "src/app/structure/components/formatter/currency/my-currency.pipe";
import { FlashMessagesModule } from "angular2-flash-messages";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Daterangepicker } from "ng2-daterangepicker";
import { SafeHtmlPipe } from "src/app/structure/components/formatter/safeHtml/safe-html-render.pipe";
import { FieldErrorDisplayComponent } from "src/app/structure/components/field-error-display/field-error-display.component";
import { AgGridModule } from "ag-grid-angular";
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { ReceiptingStartedPipe } from "src/app/structure/components/formatter/date/receipting-started.pipe";
import { MyPercentagePipe } from "src/app/structure/components/formatter/percentage/my-percentage.pipe";
import { ModalModule } from "ngx-modialog";
import { BootstrapModalModule } from "ngx-modialog/plugins/bootstrap";
import { SearchPipe } from "src/app/structure/components/formatter/search/search.pipe";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { ChartModule } from "angular2-chartjs";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { OnlyNumberDirective } from "src/app/structure/components/formatter/number-only/only-number.directive";
import { RatePerhourFormatterDirective } from "src/app/structure/components/formatter/rate-perhour/rate-perhour-formatter.directive";
import { MyCurrencyFormatterDirective } from "src/app/structure/components/formatter/currency/my-currency-formatter.directive";
import { MyPercentageFormatterDirective } from "src/app/structure/components/formatter/percentage/my-percentage-formatter.directive";
import { RatePerhourPipe } from "src/app/structure/components/formatter/rate-perhour/rate-perhour.pipe";
import { FocusOnClickDirective } from "src/app/structure/components/formatter/focus-on-directive/focus-on-click.directive";


@NgModule({
  declarations: [
    FieldErrorDisplayComponent,
    RatePerhourFormatterDirective,
    MyCurrencyFormatterDirective,
    MyPercentageFormatterDirective,
    FocusOnClickDirective,
    MyCurrencyPipe,
    MyPercentagePipe,
    RatePerhourPipe,
    SafeHtmlPipe,
    ReceiptingStartedPipe,
    SearchPipe,
    OnlyNumberDirective,
  ],
  exports: [
    FieldErrorDisplayComponent,
    Daterangepicker,
    FormsModule,
    ReactiveFormsModule,
    SafeHtmlPipe,
    AgGridModule,
    ReceiptingStartedPipe,
    NgDynamicBreadcrumbModule,
    NgMultiSelectDropDownModule,
    FlashMessagesModule,
    SearchPipe,
    ProgressbarModule,
    ChartModule,
    CKEditorModule,
    RatePerhourFormatterDirective,
    MyCurrencyFormatterDirective,
    MyPercentageFormatterDirective,
    FocusOnClickDirective,
    MyCurrencyPipe,
    MyPercentagePipe,
    RatePerhourPipe,
    OnlyNumberDirective,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    FlashMessagesModule.forRoot(),
    ChartModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }), //Updated due to attr depreciation.
    CKEditorModule,
    // ModalModule.forRoot(),
    // BootstrapModalModule,
    // ModalModule.forRoot(),
    // BootstrapModalModule,
  ],
  providers: [],
})
export class SharedModule {}
