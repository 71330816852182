/**
 * This pipe is used to show receipt date in MM-YYYY format which the receipt is ggenerated from the mage sheet.
 */

import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment-timezone";
import { ConstantService } from "../../../../structure/constants/constant.service";

@Pipe({ name: "receiptingStarted" })
export class ReceiptingStartedPipe implements PipeTransform {
  constructor(public constantService: ConstantService) {
    // TODO comes from configuration settings
  }
  transform(value: string, format: string): any {
    let dateFormatted = value;
    if (
      value &&
      moment(value, "MM-DD-YYYY")
        .isBefore
        //this.constantService.RECEIPT_START_DATE
        ()
    ) {
      dateFormatted = moment(value, "MM-DD-YYYY").format("MM-YYYY");
    }
    return dateFormatted;
  }
}
