import { Injectable } from "@angular/core";
// import { Http, Headers, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/map";
// import { Logger } from "angular2-logger/core";
// import { environment } from "environments/environment";
import { ConstantService } from "../constants/constant.service";
import { CommonService } from "../services/common.service";
import * as moment from "moment-timezone";
import { DMConstantService } from "../constants/dm.constant.service";

declare var $: any;
declare var jQuery: any;
declare var XLSX: any;

@Injectable()
export class PartPssReportServiceV2 {
  public pssDataListCp: any = [];
  public storeGroup: any = [];
  public pushFlag = false;
  public snapShotVersion!: any[];
  public snapShotData: any;
  constructor(
    // private http: Http,
    // private _logger: Logger,
    private constantService: ConstantService,
    private commonService: CommonService,
    private DMConstantService: DMConstantService
  ) {}
  init() {
    //
  }
  downloadPartsPssReport(
    partsPssColumnsList: string | any[],
    data: any[],
    storeGroupData: any[],
    totalDataRow: any,
    imgData: any,
    type: string,
    snapShotVersion: any,
    snapShotData: string
  ) {
    const footerFlag = false;
    this.pssDataListCp = [];
    this.storeGroup = [];
    this.pssDataListCp = data;
    this.storeGroup = storeGroupData;
    this.snapShotVersion = snapShotVersion;
    this.snapShotData = snapShotData;
    console.log(totalDataRow);
    const csvData: any = [{}, {}, {}, {}, {}, {}];
    console.log(this.pssDataListCp);
    for (const i in this.pssDataListCp) {
      if (this.pssDataListCp) {
        let postAuditOemApprovalDeadline = null;
        if (this.pssDataListCp[i]["postAuditOemApprovalDeadline"]) {
          postAuditOemApprovalDeadline = this.pssDataListCp[i][
            "postAuditOemApprovalDeadline"
          ].replace(this.constantService.REG_EXP_FOR_DATE_FORMAT, "$2-$3-$1");
        }
        const stateName = this.pssDataListCp[i]["state"] ? this.pssDataListCp[i]["state"] : null;
        const brandName = this.pssDataListCp[i]["brand"] ? this.pssDataListCp[i]["brand"] : null;
        const brandAndStname = brandName + "      " + stateName + "    ";
        csvData.push({
          0: brandAndStname,
          1: this.pssDataListCp[i]["stName"] ? this.pssDataListCp[i]["stName"] : null,
          2: this.pssDataListCp[i]["avgMonthlyWarrCogs"]
            ? this.pssDataListCp[i]["avgMonthlyWarrCogs"]
            : null,
          3: this.pssDataListCp[i]["currWpMu"] ? this.pssDataListCp[i]["currWpMu"] : null,
          4: this.pssDataListCp[i]["projOptMarkup"] ? this.pssDataListCp[i]["projOptMarkup"] : null,
          5: this.pssDataListCp[i]["projAddlPtsOvrCurr"]
            ? this.pssDataListCp[i]["projAddlPtsOvrCurr"]
            : null,
          6: this.pssDataListCp[i]["upAddlMonthlyProfit"]
            ? this.pssDataListCp[i]["upAddlMonthlyProfit"]
            : null,
          7: this.pssDataListCp[i]["projAnnualProfitUplift"]
            ? this.pssDataListCp[i]["projAnnualProfitUplift"]
            : null,
          8: this.pssDataListCp[i]["preAuditMu"] ? this.pssDataListCp[i]["preAuditMu"] : null,
          9: this.pssDataListCp[i]["preAuditAddlPtsOvrCurr"]
            ? this.pssDataListCp[i]["preAuditAddlPtsOvrCurr"]
            : null,
          10: this.pssDataListCp[i]["preAuditMonthlyProfitUplift"]
            ? this.pssDataListCp[i]["preAuditMonthlyProfitUplift"]
            : null,
          11: this.pssDataListCp[i]["preAuditAnnualProfitUplift"]
            ? this.pssDataListCp[i]["preAuditAnnualProfitUplift"]
            : null,
          12: this.pssDataListCp[i]["preDiscMu"] ? this.pssDataListCp[i]["preDiscMu"] : null,
          13: this.pssDataListCp[i]["preDiscAddlPtsOvrCurr"]
            ? this.pssDataListCp[i]["preDiscAddlPtsOvrCurr"]
            : null,
          14: this.pssDataListCp[i]["preDiscAddlMonthlyProfit"]
            ? this.pssDataListCp[i]["preDiscAddlMonthlyProfit"]
            : null,
          15: this.pssDataListCp[i]["preDiscAnnualProfitUplift"]
            ? this.pssDataListCp[i]["preDiscAnnualProfitUplift"]
            : null,
          16: this.pssDataListCp[i]["postAuditMu"] ? this.pssDataListCp[i]["postAuditMu"] : null,
          17: this.pssDataListCp[i]["postAuditAddlPtsOvrCurr"]
            ? this.pssDataListCp[i]["postAuditAddlPtsOvrCurr"]
            : null,
          18: this.pssDataListCp[i]["postDiscAddlMonthlyProfit"]
            ? this.pssDataListCp[i]["postDiscAddlMonthlyProfit"]
            : null,
          19: this.pssDataListCp[i]["postAuditAnnualProfitUplift"]
            ? this.pssDataListCp[i]["postAuditAnnualProfitUplift"]
            : null,
          20: this.pssDataListCp[i]["isSecondaryResolved"]
            ? this.DMConstantService.IS_SECONDARY_RESOLVED
            : this.pssDataListCp[i]["isRetracted"] === true
              ? this.DMConstantService.NOT_VIABLE
              : postAuditOemApprovalDeadline,
          21: this.pssDataListCp[i]["apprMarkup"] ? this.pssDataListCp[i]["apprMarkup"] : null,
          22: this.pssDataListCp[i]["apprFaPointImpact"]
            ? this.pssDataListCp[i]["apprFaPointImpact"]
            : null,
          23: this.pssDataListCp[i]["apprFaAnnualImpact"]
            ? this.pssDataListCp[i]["apprFaAnnualImpact"]
            : null,
          24: this.pssDataListCp[i]["apprAddlPtsAbvCurrEffWpMu"]
            ? this.pssDataListCp[i]["apprAddlPtsAbvCurrEffWpMu"]
            : null,
          25: this.pssDataListCp[i]["apprAdjAddlMonthlyProfit"]
            ? this.pssDataListCp[i]["apprAdjAddlMonthlyProfit"]
            : null,
          26: this.pssDataListCp[i]["apprAdjAddlAnnualProfit"]
            ? this.pssDataListCp[i]["apprAdjAddlAnnualProfit"]
            : null,
          27: this.pssDataListCp[i]["finalAcqSubmissionFee"]
            ? this.pssDataListCp[i]["finalAcqSubmissionFee"]
            : null,
          28: this.pssDataListCp[i]["finalAcqMonthlyUplift"]
            ? this.pssDataListCp[i]["finalAcqMonthlyUplift"]
            : null,
          29: this.pssDataListCp[i]["finalAcqAnnualUpliftFee"]
            ? this.pssDataListCp[i]["finalAcqAnnualUpliftFee"]
            : null,
          30: this.pssDataListCp[i]["finalAcqTotal"]
            ? this.pssDataListCp[i]["finalAcqTotal"]
            : null,
          // 31: this.pssDataListCp[i]['finalImpactNetUplift'] ? this.pssDataListCp[i]['finalImpactNetUplift'] : null,
          // 32: this.pssDataListCp[i]['finalImpactPayback'] ? this.pssDataListCp[i]['finalImpactPayback'] : null
          31: this.pssDataListCp[i]["finalImpactPayback"]
            ? this.pssDataListCp[i]["finalImpactPayback"]
            : null,
          32: this.pssDataListCp[i]["finalImpactPayback"]
            ? this.pssDataListCp[i]["finalImpactPayback"]
            : null,
        });
      }
    }

    let fileName_ws = "";
    let fileName = "";
    let snapShotCreatedTime = null;
    if (type === "cp") {
      let snapShotName = this.snapShotVersion[0].description;
      if (this.snapShotVersion[0].id < 0) {
        snapShotName = this.snapShotData;
      }
      snapShotName = snapShotName + "_";
      fileName = "PartsPSSV2_" + snapShotName + moment().format("YYYYMMDDhhmmss") + ".xlsx";
      fileName_ws = "PartsPSSV2_" + moment().format("YYYYMMDDhhmmss");
      snapShotCreatedTime = moment(this.snapShotVersion[0].createdAt).format(
        "MM-DD-YYYY hh:mm:ss A"
      );
    } else {
      fileName = "PartsPSSV2_" + moment().format("YYYYMMDDhhmmss") + ".xlsx";
      fileName_ws = "PartsPSSV2_" + moment().format("YYYYMMDDhhmmss");
    }
    const ws_name = fileName_ws;
    const wb = XLSX.utils.book_new();
    wb.Sheets = {};
    wb.SheetNames = [];
    const ws: any = XLSX.utils.json_to_sheet(csvData);
    const wscols = [
      { wch: 14 }, // "characters"
      { wch: 30 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 12 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 28 }
    ];
    const wsrows = [
      { hpx: 50, level: 1 },
      { hpx: 70, level: 2 },
      { hpx: 45, level: 3 },
      { hpx: 50, level: 4 },
      { hpx: 50, level: 5 },
      { hpx: 50, level: 6 },
      { hpx: 50, level: 7 },
    ];
    for (let i = 0; i < this.pssDataListCp.length + 8; i++) {
      const obj = { hpx: 28, level: i + 8 };
      wsrows.push(obj);
    }

    ws["!cols"] = wscols;
    for (let i = 0; i < partsPssColumnsList.length; i++) {
      if (!partsPssColumnsList[i]) {
        ws["!cols"][i] = { hidden: true };
      }
    }
    ws["!pageSetup"] = { orientation: "landscape", scale: "100" };
    /**
     * Set worksheet sheet to "narrow".
     */
    ws["!margins"] = {
      left: 0.25,
      right: 0.25,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 0, c: 32 } },

      { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
      { s: { r: 1, c: 2 }, e: { r: 1, c: 32 } },

      { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
      { s: { r: 2, c: 2 }, e: { r: 2, c: 32 } },

      { s: { r: 3, c: 0 }, e: { r: 3, c: 1 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: 1 } },

      { s: { r: 6, c: 0 }, e: { r: 6, c: 1 } },

      /** Heading Section */
      { s: { r: 3, c: 2 }, e: { r: 3, c: 3 } },
      { s: { r: 3, c: 4 }, e: { r: 3, c: 7 } },

      { s: { r: 3, c: 8 }, e: { r: 3, c: 11 } },

      { s: { r: 3, c: 12 }, e: { r: 3, c: 20 } },
      { s: { r: 4, c: 12 }, e: { r: 4, c: 15 } },
      { s: { r: 4, c: 16 }, e: { r: 4, c: 20 } },

      { s: { r: 3, c: 21 }, e: { r: 4, c: 26 } },
      { s: { r: 3, c: 27 }, e: { r: 4, c: 30 } },
      { s: { r: 3, c: 31 }, e: { r: 4, c: 31 } },
      { s: { r: 3, c: 32 }, e: { r: 4, c: 32 } },

      /** Sub headings section */

      { s: { r: 4, c: 2 }, e: { r: 6, c: 2 } },
      { s: { r: 4, c: 3 }, e: { r: 6, c: 3 } },
      { s: { r: 4, c: 4 }, e: { r: 6, c: 4 } },
      { s: { r: 4, c: 5 }, e: { r: 6, c: 5 } },
      { s: { r: 4, c: 6 }, e: { r: 6, c: 6 } },
      { s: { r: 4, c: 7 }, e: { r: 6, c: 7 } },

      { s: { r: 4, c: 8 }, e: { r: 6, c: 8 } },
      { s: { r: 4, c: 9 }, e: { r: 6, c: 9 } },
      { s: { r: 4, c: 10 }, e: { r: 6, c: 10 } },
      { s: { r: 4, c: 11 }, e: { r: 6, c: 11 } },

      { s: { r: 5, c: 12 }, e: { r: 6, c: 12 } },
      { s: { r: 5, c: 13 }, e: { r: 6, c: 13 } },
      { s: { r: 5, c: 14 }, e: { r: 6, c: 14 } },
      { s: { r: 5, c: 15 }, e: { r: 6, c: 15 } },
      { s: { r: 5, c: 16 }, e: { r: 6, c: 16 } },
      { s: { r: 5, c: 17 }, e: { r: 6, c: 17 } },
      { s: { r: 5, c: 18 }, e: { r: 6, c: 18 } },
      { s: { r: 5, c: 19 }, e: { r: 6, c: 19 } },
      { s: { r: 5, c: 20 }, e: { r: 6, c: 20 } },
      { s: { r: 5, c: 21 }, e: { r: 6, c: 21 } },
      { s: { r: 5, c: 22 }, e: { r: 5, c: 23 } },
      { s: { r: 5, c: 24 }, e: { r: 6, c: 24 } },
      { s: { r: 5, c: 25 }, e: { r: 6, c: 25 } },
      { s: { r: 5, c: 26 }, e: { r: 6, c: 26 } },
      { s: { r: 5, c: 27 }, e: { r: 6, c: 27 } },
      { s: { r: 5, c: 28 }, e: { r: 6, c: 28 } },
      { s: { r: 5, c: 29 }, e: { r: 6, c: 29 } },
      { s: { r: 5, c: 30 }, e: { r: 6, c: 30 } },
      { s: { r: 5, c: 31 }, e: { r: 6, c: 31 } },
      { s: { r: 5, c: 32 }, e: { r: 6, c: 32 } },

      // { s: { r: 5, c: 32 }, e: { r: 6, c: 32 } },
      // /** Footer display */
      {
        s: { r: this.pssDataListCp.length + 7, c: 0 },
        e: { r: this.pssDataListCp.length + 7, c: 1 },
      },
      /** Total display section */
      {
        s: { r: this.pssDataListCp.length + 7, c: 2 },
        e: { r: this.pssDataListCp.length + 7, c: 3 },
      },
      {
        s: { r: this.pssDataListCp.length + 7, c: 4 },
        e: { r: this.pssDataListCp.length + 7, c: 7 },
      },
      {
        s: { r: this.pssDataListCp.length + 7, c: 8 },
        e: { r: this.pssDataListCp.length + 7, c: 11 },
      },
      {
        s: { r: this.pssDataListCp.length + 7, c: 12 },
        e: { r: this.pssDataListCp.length + 7, c: 15 },
      },
      {
        s: { r: this.pssDataListCp.length + 7, c: 16 },
        e: { r: this.pssDataListCp.length + 7, c: 20 },
      },
      {
        s: { r: this.pssDataListCp.length + 7, c: 21 },
        e: { r: this.pssDataListCp.length + 7, c: 22 },
      },
      {
        s: { r: this.pssDataListCp.length + 7, c: 23 },
        e: { r: this.pssDataListCp.length + 7, c: 26 },
      },
      {
        s: { r: this.pssDataListCp.length + 7, c: 27 },
        e: { r: this.pssDataListCp.length + 7, c: 31 },
      },
      {
        s: { r: this.pssDataListCp.length + 7, c: 32 },
        e: { r: this.pssDataListCp.length + 7, c: 32 },
      },
      // { s: { r: (this.pssDataListCp.length + 7), c: 31 }, e: { r: (this.pssDataListCp.length + 7), c: 32 } },
      {
        s: { r: this.pssDataListCp.length + 8, c: 0 },
        e: { r: this.pssDataListCp.length + 8, c: 1 },
      },
      {
        s: { r: this.pssDataListCp.length + 8, c: 2 },
        e: { r: this.pssDataListCp.length + 8, c: 3 },
      },
      {
        s: { r: this.pssDataListCp.length + 8, c: 4 },
        e: { r: this.pssDataListCp.length + 8, c: 7 },
      },
      {
        s: { r: this.pssDataListCp.length + 8, c: 8 },
        e: { r: this.pssDataListCp.length + 8, c: 11 },
      },
      {
        s: { r: this.pssDataListCp.length + 8, c: 12 },
        e: { r: this.pssDataListCp.length + 8, c: 12 },
      },
      {
        s: { r: this.pssDataListCp.length + 8, c: 13 },
        e: { r: this.pssDataListCp.length + 8, c: 15 },
      },
      {
        s: { r: this.pssDataListCp.length + 8, c: 16 },
        e: { r: this.pssDataListCp.length + 8, c: 20 },
      },
      {
        s: { r: this.pssDataListCp.length + 8, c: 21 },
        e: { r: this.pssDataListCp.length + 8, c: 26 },
      },
      {
        s: { r: this.pssDataListCp.length + 8, c: 27 },
        e: { r: this.pssDataListCp.length + 8, c: 31 },
      },
      {
        s: { r: this.pssDataListCp.length + 8, c: 32 },
        e: { r: this.pssDataListCp.length + 8, c: 32 },
      },
      // { s: { r: (this.pssDataListCp.length + 8), c: 31 }, e: { r: (this.pssDataListCp.length + 8), c: 32 } },
    ];
    let footerRow: any = "";
    let footerTimestampRow: any = "";
    ws["!rows"] = wsrows;
    ws["!ref"] = "A1:AG" + (this.pssDataListCp.length + 9);
    let border = {
      top: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    let alignment = {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    };
    let font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: false,
      italic: false,
      underline: false,
    };
    let borderRight = { right: { style: "thin", color: { auto: 1 } } };

    ws["A1"] = { t: "s", v: " " };
    ws["A2"] = { t: "s", v: " " };
    ws["A3"] = { t: "s", v: " " };
    ws["B1"] = { t: "s", v: " " };
    let objProperty: { [k: string]: any } = {};
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "0b5394",
      borderRight,
      alignment,
      "",
      ""
    );
    ws["A1"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["A2"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["A3"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["C1"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["C2"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["C3"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    let objPropertyR = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "0b5394",
      borderRight,
      alignment,
      "",
      ""
    );
    ws["AG1"] = {
      t: "s",
      v: "",
      s: objPropertyR,
    };
    ws["AG2"] = {
      t: "s",
      v: "",
      s: objPropertyR,
    };
    ws["AG3"] = {
      t: "s",
      v: "",
      s: objPropertyR,
    };

    let inlineFontProperty = { sz: 18, bold: true };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "0b5394",
      borderRight,
      alignment,
      "",
      inlineFontProperty
    );
    ws["A4"] = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.EXCEL_PSSHEADING,
      s: objProperty,
    };
    let inlineFontPropertyDesc = { sz: 10, bold: false };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "000",
      borderRight,
      alignment,
      "",
      inlineFontPropertyDesc
    );
    ws["A5"] = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.EXCEL_DESCRIPTION,
      s: objProperty,
    };
    ws["B1"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["B2"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["B3"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["B4"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["B5"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["B6"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["B7"] = {
      t: "s",
      v: "",
      s: objProperty,
    };

    let alignmentE3 = {
      wrapText: true,
      vertical: "center",
      horizontal: "left",
    };
    const alignmentE3_1 = {
      wrapText: true,
      vertical: "center",
      horizontal: "right",
    };
    inlineFontProperty = { sz: 11, bold: true };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "0b5394",
      "",
      alignmentE3_1,
      "",
      inlineFontProperty
    );
    ws["A6"] = {
      t: "s",
      v: "Group:",
      s: objProperty,
    };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "0b5394",
      borderRight,
      alignmentE3,
      "",
      inlineFontProperty
    );
    ws["B6"] = {
      t: "s",
      v: " " + this.storeGroup[0].itemName,
      s: objProperty,
    };

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "0b5394",
      "",
      alignment,
      "",
      inlineFontProperty
    );
    ws["A7"] = {
      t: "s",
      v: "Store",
      s: objProperty,
    };

    /**
     * Headings Starts Here
     */
    let inlineFontPropertyHead = { sz: 12, bold: true };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      "",
      inlineFontPropertyHead
    );

    let head1 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.HEAD_1,
      s: objProperty,
    };
    ws["C4"] = head1;
    ws["D4"] = head1;

    let head2 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.HEAD_2,
      s: objProperty,
    };
    ws["E4"] = head2;
    ws["F4"] = head2;
    ws["G4"] = head2;
    ws["H4"] = head2;

    let head5 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.HEAD_5,
      s: objProperty,
    };
    ws["I4"] = head5;
    ws["J4"] = head5;
    ws["K4"] = head5;
    ws["L4"] = head5;

    let head8 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.HEAD_8,
      s: objProperty,
    };
    ws["M4"] = head8;
    ws["N4"] = head8;
    ws["O4"] = head8;
    ws["P4"] = head8;
    ws["Q4"] = head8;
    ws["R4"] = head8;
    ws["S4"] = head8;
    ws["T4"] = head8;
    ws["U4"] = head8;

    let head9 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.HEAD_9,
      s: objProperty,
    };
    ws["M5"] = head9;
    ws["N5"] = head9;
    ws["O5"] = head9;
    ws["P5"] = head9;

    let head10 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.HEAD_10,
      s: objProperty,
    };
    ws["Q5"] = head10;
    ws["R5"] = head10;
    ws["S5"] = head10;
    ws["T5"] = head10;
    ws["U5"] = head10;

    let head13 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.HEAD_13,
      s: objProperty,
    };
    ws["V4"] = head13;
    ws["W4"] = head13;
    ws["X4"] = head13;
    ws["Y4"] = head13;
    ws["Z4"] = head13;
    ws["AA4"] = head13;

    ws["V5"] = head13;
    ws["W5"] = head13;
    ws["X5"] = head13;
    ws["Y5"] = head13;
    ws["Z5"] = head13;
    ws["AA5"] = head13;
    let head14 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.HEAD_14,
      s: objProperty,
    };
    ws["AB4"] = head14;
    ws["AC4"] = head14;
    ws["AD4"] = head14;
    ws["AE4"] = head14;
    ws["AB5"] = head14;
    ws["AC5"] = head14;
    ws["AD5"] = head14;
    ws["AE5"] = head14;

    let head15 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.HEAD_15,
      s: objProperty,
    };
    let head155 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.HEAD_155,
      s: objProperty,
    };
    ws["AF4"] = head15;
    ws['AG4'] = head155;
    ws["AF5"] = head15;
    ws['AG5'] = head155;

    /** Inner Sub Heads */

    const inlineFontPropertySubHead = { sz: 9, bold: false };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      "",
      inlineFontPropertySubHead
    );

    const subHead1 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_1,
      s: objProperty,
    };
    ws["C5"] = subHead1;
    ws["C6"] = subHead1;
    ws["C7"] = subHead1;

    const subHead2 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_2,
      s: objProperty,
    };
    ws["D5"] = subHead2;
    ws["D6"] = subHead2;
    ws["D7"] = subHead2;

    const subHead3 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_3,
      s: objProperty,
    };
    ws["E5"] = subHead3;
    ws["E6"] = subHead3;
    ws["E7"] = subHead3;

    const subHead4 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_4,
      s: objProperty,
    };
    ws["F5"] = subHead4;
    ws["F6"] = subHead4;
    ws["F7"] = subHead4;

    const subHead4_1 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_46,
      s: objProperty,
    };
    ws["G5"] = subHead4_1;
    ws["G6"] = subHead4_1;
    ws["G7"] = subHead4_1;

    const subHead5 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_5,
      s: objProperty,
    };
    ws["H5"] = subHead5;
    ws["H6"] = subHead5;
    ws["H7"] = subHead5;

    const subHead12 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_12,
      s: objProperty,
    };
    ws["I5"] = subHead12;
    ws["I6"] = subHead12;
    ws["I7"] = subHead12;

    const subHead13 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_13,
      s: objProperty,
    };
    ws["J5"] = subHead13;
    ws["J6"] = subHead13;
    ws["J7"] = subHead13;

    const subHead13_2 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_46,
      s: objProperty,
    };
    ws["K5"] = subHead13_2;
    ws["K6"] = subHead13_2;
    ws["K7"] = subHead13_2;

    const subHead14 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_14,
      s: objProperty,
    };
    ws["L5"] = subHead14;
    ws["L6"] = subHead14;
    ws["L7"] = subHead14;

    const subHead21 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_21,
      s: objProperty,
    };
    ws["M6"] = subHead21;
    ws["M7"] = subHead21;

    const subHead22 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_22,
      s: objProperty,
    };
    ws["N6"] = subHead22;
    ws["N7"] = subHead22;

    const subHead22_3 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_46,
      s: objProperty,
    };
    ws["O6"] = subHead22_3;
    ws["O7"] = subHead22_3;

    const subHead23 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_23,
      s: objProperty,
    };
    ws["P6"] = subHead23;
    ws["P7"] = subHead23;

    const subHead24 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_24,
      s: objProperty,
    };
    ws["Q6"] = subHead24;
    ws["Q7"] = subHead24;

    const subHead25 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_25,
      s: objProperty,
    };
    ws["R6"] = subHead25;
    ws["R7"] = subHead25;

    const subHead25_1 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_46,
      s: objProperty,
    };
    ws["S6"] = subHead25_1;
    ws["S7"] = subHead25_1;

    const subHead26 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_26,
      s: objProperty,
    };
    ws["T6"] = subHead26;
    ws["T7"] = subHead26;

    const subHead27 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_27,
      s: objProperty,
    };
    ws["U6"] = subHead27;
    ws["U7"] = subHead27;

    const subHead34 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_34,
      s: objProperty,
    };
    ws["V6"] = subHead34;
    ws["V7"] = subHead34;

    const subHead35 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_35,
      s: objProperty,
    };
    ws["W6"] = subHead35;
    ws["X6"] = subHead35;

    const subHead36 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_36,
      s: objProperty,
    };
    ws["W7"] = subHead36;

    const subHead37 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_37,
      s: objProperty,
    };
    ws["X7"] = subHead37;

    const subHead47 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_47,
      s: objProperty,
    };
    ws["Y6"] = subHead47;
    ws["Y7"] = subHead47;

    const subHead38 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_38,
      s: objProperty,
    };
    ws["Z6"] = subHead38;
    ws["Z7"] = subHead38;

    const subHead39 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_39,
      s: objProperty,
    };
    ws["AA6"] = subHead39;
    ws["AA7"] = subHead39;

    const subHead40 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_40,
      s: objProperty,
    };
    ws["AB6"] = subHead40;
    ws["AB7"] = subHead40;

    const subHead41 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_41,
      s: objProperty,
    };
    ws["AC6"] = subHead41;
    ws["AC7"] = subHead41;

    const subHead42 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_42,
      s: objProperty,
    };
    ws["AD6"] = subHead42;
    ws["AD7"] = subHead42;

    const subHead43 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_43,
      s: objProperty,
    };
    ws["AE6"] = subHead43;
    ws["AE7"] = subHead43;

    const subHead44 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_44,
      s: objProperty,
    };
    // ws['AF6'] = subHead44;
    // ws['AF7'] = subHead44;

    const subHead45 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_45,
      s: objProperty,
    };
    ws["AF6"] = subHead45;
    ws["AF7"] = subHead45;
    const subHead455 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.SUB_HEAD_455,
      s: objProperty,
    };
    ws['AG6'] = subHead455;
    ws['AG7'] = subHead455;

    /**
     * Footer display
     */
    if (footerFlag) {
      let footerTextPss = this.constantService.EXCEL_FOOTER_DYNAMIC;
      footerTextPss = footerTextPss.replace(
        /{{footerName}}/i,
        this.storeGroup[0].salesPerson ? this.storeGroup[0].salesPerson : ""
      );
      footerTextPss = footerTextPss.replace(
        /{{footerEmail}}/i,
        this.storeGroup[0].spEmail ? this.storeGroup[0].spEmail : ""
      );
      footerTextPss = footerTextPss.replace(
        /{{footerPhone}}/i,
        this.storeGroup[0].spPhone ? this.storeGroup[0].spPhone : ""
      );

      /*let alignmentVersionNumber = {};
      if (type === 'cp') {
        alignmentVersionNumber = { wrapText: true, vertical: 'center', horizontal: 'left' };
        const versionNumber = this.DMConstantService.REPORT_VERSION_LABEL +
          this.commonService.randomVersionNumberGenerator(this.DMConstantService.REPORT_VERSION_FORMAT);
        footerTextPss = footerTextPss + '    ' + versionNumber;
      }*/

      const inlineFontPropertyFooter = { sz: 9, bold: false };
      /*objProperty = this.commonService.getCellPropertyObj('FFFFFF', 'FFFFFF', '0b5394',
        borderRight, type === 'cp' ? alignmentVersionNumber : alignment, '', inlineFontPropertyFooter);*/
      objProperty = this.commonService.getCellPropertyObj(
        "FFFFFF",
        "FFFFFF",
        "0b5394",
        borderRight,
        alignment,
        "",
        inlineFontPropertyFooter
      );

      ws["A" + (this.pssDataListCp.length + 8)] = footerTextPss;

      const subHead46 = {
        t: "s",
        v: footerTextPss,
        s: objProperty,
      };
      ws["A" + (this.pssDataListCp.length + 8)] = subHead46;
      ws["B" + (this.pssDataListCp.length + 8)] = subHead46;

      /**
       *  End footer display
       */
    }
    /** Total Section */
    font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: true,
      italic: false,
      underline: false,
    };

    const totalDisplayRowsStart = {
      top: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
    };
    const totalDisplayRowsPosRemoveTopborderRow9 = {
      left: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    const totalDisplayRowsPosRemoveTopborderLeftNoneRow9 = {
      right: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    const totalDisplayRowsEnd = {
      bottom: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
    };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsStart,
      alignment,
      font,
      ""
    );
    const totalLabelObj1 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.TOTAL_LABEL_1,
      s: objProperty,
    };

    ws["C" + (this.pssDataListCp.length + 8)] = totalLabelObj1;
    ws["D" + (this.pssDataListCp.length + 8)] = totalLabelObj1;

    font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 9,
      color: { rgb: "#FF0000" },
      bold: true,
      italic: false,
      underline: false,
    };

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsEnd,
      alignment,
      font,
      ""
    );
    const totalLabelObj0 = {
      t: "s",
      v: " ",
      s: objProperty,
    };
    ws["C" + (this.pssDataListCp.length + 9)] = totalLabelObj0;
    ws["D" + (this.pssDataListCp.length + 9)] = totalLabelObj0;

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsPosRemoveTopborderLeftNoneRow9,
      alignment,
      font,
      ""
    );
    const totalLabelObj2 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.TOTAL_LABEL_2,
      s: objProperty,
    };
    const objPropertyH = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsPosRemoveTopborderRow9,
      alignment,
      font,
      ""
    );
    const totalLabelObjH = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.TOTAL_LABEL_2,
      s: objPropertyH,
    };
    ws["E" + (this.pssDataListCp.length + 9)] = totalLabelObj2;
    ws["F" + (this.pssDataListCp.length + 9)] = totalLabelObj2;
    ws["G" + (this.pssDataListCp.length + 9)] = totalLabelObj2;
    ws["H" + (this.pssDataListCp.length + 9)] = totalLabelObjH;

    const totalLabelObj5 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.TOTAL_LABEL_5,
      s: objProperty,
    };
    const totalLabelObj5I = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.TOTAL_LABEL_5,
      s: objPropertyH,
    };
    ws["I" + (this.pssDataListCp.length + 9)] = totalLabelObj5I;
    ws["J" + (this.pssDataListCp.length + 9)] = totalLabelObj5;
    ws["K" + (this.pssDataListCp.length + 9)] = totalLabelObj5;
    ws["L" + (this.pssDataListCp.length + 9)] = totalLabelObj5;

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );

    const totalLabelObj8 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.TOTAL_LABEL_8,
      s: objProperty,
    };
    ws["M" + (this.pssDataListCp.length + 9)] = totalLabelObj8;

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsPosRemoveTopborderRow9,
      alignment,
      font,
      ""
    );

    const totalLabelObj9 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.TOTAL_LABEL_9,
      s: objProperty,
    };
    ws["Q" + (this.pssDataListCp.length + 9)] = totalLabelObj9;
    ws["R" + (this.pssDataListCp.length + 9)] = totalLabelObj9;
    ws["S" + (this.pssDataListCp.length + 9)] = totalLabelObj9;
    ws["T" + (this.pssDataListCp.length + 9)] = totalLabelObj9;
    ws["U" + (this.pssDataListCp.length + 9)] = totalLabelObj9;

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );

    const totalLabelObj12 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.TOTAL_LABEL_12,
      s: objProperty,
    };
    ws["V" + (this.pssDataListCp.length + 8)] = totalLabelObj12;
    ws["W" + (this.pssDataListCp.length + 8)] = totalLabelObj12;

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsPosRemoveTopborderRow9,
      alignment,
      font,
      ""
    );

    const totalLabelObj13 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.TOTAL_LABEL_13,
      s: objProperty,
    };
    // ws['AB' + (this.pssDataListCp.length + 9)] = totalLabelObj13;
    // ws['AC' + (this.pssDataListCp.length + 9)] = totalLabelObj13;
    // ws['AD' + (this.pssDataListCp.length + 9)] = totalLabelObj13;
    // ws['AE' + (this.pssDataListCp.length + 9)] = totalLabelObj13;

    const totalLabelObj14 = {
      t: "s",
      v: this.DMConstantService.PARTS_PSS_REPORT_V2.TOTAL_LABEL_14,
      s: objProperty,
    };

    const totalLabelObj144 = {
      t: "s",
      v: " ",
      s: objProperty,
    };
    // ws['AF' + (this.pssDataListCp.length + 9)] = totalLabelObj14;
    // ws['AG' + (this.pssDataListCp.length + 9)] = totalLabelObj14;

    ws["AB" + (this.pssDataListCp.length + 9)] = totalLabelObj14;
    ws["AC" + (this.pssDataListCp.length + 9)] = totalLabelObj14;
    ws["AD" + (this.pssDataListCp.length + 9)] = totalLabelObj14;
    ws["AE" + (this.pssDataListCp.length + 9)] = totalLabelObj14;
    ws["AF" + (this.pssDataListCp.length + 9)] = totalLabelObj14;
    // ws["AG" + (this.pssDataListCp.length + 9)] = totalLabelObj144;
    // ws["AG" + (this.pssDataListCp.length + 8)] = totalLabelObj144;

    font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: true,
      italic: false,
      underline: false,
    };

    const totalDisplayRowsPosRemoveTopborderR8 = {
      left: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
    };

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );
    const objPropertyRemoveBorderBottom = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsPosRemoveTopborderR8,
      alignment,
      font,
      ""
    );

    const projObjClear = {
      t: "s",
      v: "",
      s: objProperty,
    };
    const projObjClearBorderBottom = {
      t: "s",
      v: "",
      s: objPropertyRemoveBorderBottom,
    };

    ws["AD" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["AE" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["AF" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    
    ws["Z" + (this.pssDataListCp.length + 8)] = projObjClear;
    ws["AA" + (this.pssDataListCp.length + 8)] = projObjClear;
    ws["AB" + (this.pssDataListCp.length + 8)] = projObjClear;
    ws["AA" + (this.pssDataListCp.length + 9)] = projObjClear;
    ws["AC" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["W" + (this.pssDataListCp.length + 9)] = projObjClear;
    ws["X" + (this.pssDataListCp.length + 9)] = projObjClear;
    ws["Y" + (this.pssDataListCp.length + 9)] = projObjClear;
    ws["Z" + (this.pssDataListCp.length + 9)] = projObjClear;
    ws["X" + (this.pssDataListCp.length + 8)] = projObjClear;
    ws["Y" + (this.pssDataListCp.length + 8)] = projObjClear;
    ws["U" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["Q" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["R" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["S" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["T" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["E" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["F" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["G" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["H" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["I" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["J" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["K" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["L" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["M" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["N" + (this.pssDataListCp.length + 8)] = projObjClearBorderBottom;
    ws["O" + (this.pssDataListCp.length + 8)] = projObjClear;
    ws["P" + (this.pssDataListCp.length + 8)] = projObjClear;
    ws["O" + (this.pssDataListCp.length + 9)] = projObjClear;
    ws["P" + (this.pssDataListCp.length + 9)] = projObjClear;

    ws['AG' + (this.pssDataListCp.length + 7)] = projObjClear;
    ws["AG" + (this.pssDataListCp.length + 8)] = projObjClear;
    ws["AG" + (this.pssDataListCp.length + 9)] = projObjClear;

    const totalDisplayRowsPos2 = {
      top: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
    };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsPos2,
      alignment,
      font,
      ""
    );
    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.totalProjectionFormdataProvided
    );
    ws["E" + (this.pssDataListCp.length + 8)] = {
      t: totalDataRow.totalProjectionFormdataProvided !== null ? "n" : "s",
      v:
        totalDataRow.totalProjectionFormdataProvided !== null
          ? totalDataRow.totalProjectionFormdataProvided
          : "",
      s: objProperty,
    };

    const totalDisplayRowsPosRemoveTopborderRow8 = {
      left: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
    };

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsPosRemoveTopborderRow8,
      alignment,
      font,
      ""
    );
    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.totalOPRFDMSDataOngoingAnualUplift
    );
    ws["I" + (this.pssDataListCp.length + 8)] = {
      t: totalDataRow.totalOPRFDMSDataOngoingAnualUplift !== null ? "n" : "s",
      v:
        totalDataRow.totalOPRFDMSDataOngoingAnualUplift !== null
          ? totalDataRow.totalOPRFDMSDataOngoingAnualUplift
          : "",
      s: objProperty,
    };

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );
    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.totalPreDiscAnnualProfitUplift
    );
    ws["M" + (this.pssDataListCp.length + 8)] = {
      t: totalDataRow.totalPreDiscAnnualProfitUplift !== null ? "n" : "s",
      v:
        totalDataRow.totalPreDiscAnnualProfitUplift !== null
          ? totalDataRow.totalPreDiscAnnualProfitUplift
          : "",
      s: objProperty,
    };

    if (totalDataRow.anualDiscountImpact !== null && totalDataRow.anualDiscountImpact < -1) {
      let inlineFontPropertyTotal = { sz: 12, bold: true };
      objProperty = this.commonService.getCellPropertyObj(
        "A20F0A",
        "A20F0A",
        "FFFFFF",
        border,
        alignment,
        "",
        inlineFontPropertyTotal
      );
    } else {
      objProperty = this.commonService.getCellPropertyObj(
        "FFFFFF",
        "FFFFFF",
        "",
        border,
        alignment,
        font,
        ""
      );
    }
    if (totalDataRow.anualDiscountImpact === null) {
      totalDataRow.anualDiscountImpact = 0;
    }
    objProperty["numFmt"] = this.commonService.getDollarFormat(totalDataRow.anualDiscountImpact);
    ws["N" + (this.pssDataListCp.length + 9)] = {
      t: totalDataRow.anualDiscountImpact !== null ? "n" : "s",
      v: totalDataRow.anualDiscountImpact !== null ? totalDataRow.anualDiscountImpact : "",
      s: objProperty,
    };

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsPosRemoveTopborderRow8,
      alignment,
      font,
      ""
    );
    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.totalPostAuditAnnualProfitUplift
    );
    ws["Q" + (this.pssDataListCp.length + 8)] = {
      t: totalDataRow.totalPostAuditAnnualProfitUplift !== null ? "n" : "s",
      v:
        totalDataRow.totalPostAuditAnnualProfitUplift !== null
          ? totalDataRow.totalPostAuditAnnualProfitUplift
          : "",
      s: objProperty,
    };

    if (totalDataRow.anualFactoryAdjustment !== null && totalDataRow.anualFactoryAdjustment < -1) {
      let inlineFontPropertyTotal = { sz: 12, bold: true };
      objProperty = this.commonService.getCellPropertyObj(
        "A20F0A",
        "A20F0A",
        "FFFFFF",
        border,
        alignment,
        "",
        inlineFontPropertyTotal
      );
    } else {
      objProperty = this.commonService.getCellPropertyObj(
        "FFFFFF",
        "FFFFFF",
        "",
        border,
        alignment,
        font,
        ""
      );
    }
    objProperty["numFmt"] = this.commonService.getDollarFormat(totalDataRow.anualFactoryAdjustment);
    ws["X" + (this.pssDataListCp.length + 8)] = {
      t: totalDataRow.anualFactoryAdjustment !== null ? "n" : "s",
      v: totalDataRow.anualFactoryAdjustment !== null ? totalDataRow.anualFactoryAdjustment : "",
      s: objProperty,
    };

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );
    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.totalApprAdjAddlAnnualProfit
    );
    ws["V" + (this.pssDataListCp.length + 9)] = {
      t: totalDataRow.totalApprAdjAddlAnnualProfit !== null ? "n" : "s",
      v:
        totalDataRow.totalApprAdjAddlAnnualProfit !== null
          ? totalDataRow.totalApprAdjAddlAnnualProfit
          : "",
      s: objProperty,
    };

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsPosRemoveTopborderRow8,
      alignment,
      font,
      ""
    );
    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.totalFinalImpactNetUplift
    );
    // ws['AB' + (this.pssDataListCp.length + 8)] = {
    //   t: totalDataRow.totalFinalImpactNetUplift !== null ? 'n' : 's',
    //   v: totalDataRow.totalFinalImpactNetUplift !== null ? totalDataRow.totalFinalImpactNetUplift : '',
    //   s: objProperty
    // };

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      totalDisplayRowsPosRemoveTopborderRow8,
      alignment,
      font,
      ""
    );
    if (totalDataRow.initialI12MIPaybackPeriodFinal !== null) {
      objProperty["numFmt"] = this.commonService.get2DecimalPointFormat(
        totalDataRow.initialI12MIPaybackPeriodFinal
      );
    } else {
      objProperty["numFmt"] = this.commonService.get2DecimalPointFormat(0);
    }
    ws["AB" + (this.pssDataListCp.length + 8)] = {
      t: "n",
      v:
        totalDataRow.initialI12MIPaybackPeriodFinal != null
          ? totalDataRow.initialI12MIPaybackPeriodFinal
          : "0.00",
      s: objProperty,
    };

    /**
     * Data looping section
     */
    const dollarArray = [2, 6, 7, 10, 11, 14, 15, 18, 19, 23, 25, 26, 27, 28, 29, 30];
    const display4Digit = [3, 4, 8, 12, 16, 21];
    const display2Digit = [5, 9, 13, 17, 22, 24, 31];
    const stringDisplay = [0, 1, 32];

    const cellBgColorConditionGroup1 = [5, 6, 7, 9, 10, 11, 13, 14, 15, 17, 18, 19, 24, 25, 26, 31];
    const cellBgColorConditionGroup2: number[] = [];
    const cellBgColorConditionGroup3 = [22, 23];

    inlineFontProperty = { sz: 10, bold: false };
    const headeRange = {
      s: { c: 0, r: 7 },
      e: { c: 31, r: this.pssDataListCp.length + 6 },
    };
    let excelCell;
    for (let R = headeRange.s.r; R <= headeRange.e.r; ++R) {
      for (let C = headeRange.s.c; C <= headeRange.e.c; ++C) {
        const cell_address = {
          c: C,
          r: R,
        };
        const cell_ref = XLSX.utils.encode_cell(cell_address);

        if (stringDisplay.includes(C)) {
          if (cellBgColorConditionGroup1.includes(C)) {
            const respObjProperty = this.getCellProperty(
              1,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else if (cellBgColorConditionGroup2.includes(C)) {
            const respObjProperty = this.getCellProperty(
              2,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else if (cellBgColorConditionGroup3.includes(C)) {
            const respObjProperty = this.getCellProperty(
              3,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else {
            if (C === 0 || C === 1) {
              const borderFirst3 = {
                top: { style: "thin", color: { auto: 1 } },
                bottom: { style: "thin", color: { auto: 1 } },
              };
              const alignmentFirst3 = {
                wrapText: true,
                vertical: "center",
                horizontal: "left",
              };
              objProperty = this.commonService.getCellPropertyObj(
                "FFFFFF",
                "FFFFFF",
                "#FFFFFF",
                borderFirst3,
                alignmentFirst3,
                "",
                inlineFontProperty
              );
            } else {
              objProperty = this.commonService.getCellPropertyObj(
                "FFFFFF",
                "FFFFFF",
                "#FFFFFF",
                border,
                alignment,
                "",
                inlineFontProperty
              );
            }
          }

          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: "s",
            s: objProperty,
          };
        } else if (dollarArray.includes(C)) {
          if (cellBgColorConditionGroup1.includes(C)) {
            const respObjProperty = this.getCellProperty(
              1,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else if (cellBgColorConditionGroup2.includes(C)) {
            const respObjProperty = this.getCellProperty(
              2,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else if (cellBgColorConditionGroup3.includes(C)) {
            const respObjProperty = this.getCellProperty(
              3,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else {
            objProperty = this.commonService.getCellPropertyObj(
              "FFFFFF",
              "FFFFFF",
              "#FFFFFF",
              border,
              alignment,
              "",
              inlineFontProperty
            );
          }
          if (csvData[R - 1][C] !== null) {
            objProperty["numFmt"] = this.commonService.getDollarFormat(csvData[R - 1][C]);
          }
          excelCell = {
            v:
              csvData[R - 1][C] !== undefined && csvData[R - 1][C] !== null
                ? csvData[R - 1][C]
                : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        } else if (display4Digit.includes(C)) {
          if (cellBgColorConditionGroup1.includes(C)) {
            const respObjProperty = this.getCellProperty(
              1,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else if (cellBgColorConditionGroup2.includes(C)) {
            const respObjProperty = this.getCellProperty(
              2,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else if (cellBgColorConditionGroup3.includes(C)) {
            const respObjProperty = this.getCellProperty(
              3,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else {
            objProperty = this.commonService.getCellPropertyObj(
              "FFFFFF",
              "FFFFFF",
              "#FFFFFF",
              border,
              alignment,
              "",
              inlineFontProperty
            );
          }
          objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          excelCell = {
            v:
              csvData[R - 1][C] !== undefined &&
              csvData[R - 1][C] !== null &&
              typeof csvData[R - 1][C] !== undefined
                ? csvData[R - 1][C]
                : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        } else if (display2Digit.includes(C)) {
          if (cellBgColorConditionGroup1.includes(C)) {
            const respObjProperty = this.getCellProperty(
              1,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else if (cellBgColorConditionGroup2.includes(C)) {
            const respObjProperty = this.getCellProperty(
              2,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else if (cellBgColorConditionGroup3.includes(C)) {
            const respObjProperty = this.getCellProperty(
              3,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else {
            objProperty = this.commonService.getCellPropertyObj(
              "FFFFFF",
              "FFFFFF",
              "#FFFFFF",
              border,
              alignment,
              "",
              inlineFontProperty
            );
          }
          objProperty["numFmt"] = this.commonService.get2DecimalPointFormat(csvData[R - 1][C]);
          excelCell = {
            v:
              csvData[R - 1][C] !== undefined && csvData[R - 1][C] !== null
                ? csvData[R - 1][C]
                : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        } else {
          let dateString = "";
          if (csvData[R - 1][C] !== null) {
            dateString = " " + csvData[R - 1][C].toString();
          }
          if (cellBgColorConditionGroup1.includes(C)) {
            const respObjProperty = this.getCellProperty(
              1,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else if (cellBgColorConditionGroup2.includes(C)) {
            const respObjProperty = this.getCellProperty(
              2,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else if (cellBgColorConditionGroup3.includes(C)) {
            const respObjProperty = this.getCellProperty(
              3,
              csvData[R - 1][C],
              border,
              alignment,
              inlineFontProperty
            );
            objProperty = respObjProperty;
          } else {
            objProperty = this.commonService.getCellPropertyObj(
              "FFFFFF",
              "FFFFFF",
              "#FFFFFF",
              border,
              alignment,
              "",
              inlineFontProperty
            );
          }
          excelCell = {
            v: csvData[R - 1][C] !== null ? dateString : "",
            t: "s",
            s: objProperty,
          };
        }

        ws[cell_ref] = excelCell;
      }
    }

    ws["!images"] = [];
    ws["!images"].push({
      name: "Armatus.jpeg",
      data: this.imgToDataUrl($("#armatusLogo")[0]),
      opts: { base64: true },
      type: "jpeg",
      position: {
        type: "twoCellAnchor",
        attrs: { editAs: "oneCell" },
        from: { col: 0, row: 0 },
        to: { col: 2, row: 3 },
      },
    });

    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    const res = { wb: wb, fileName: fileName };
    return res;
  }

  imgToDataUrl(img: CanvasImageSource) {
    // const canvas = document.createElement("canvas");
    // canvas.width = img.naturalWidth; // or 'width' if you want a special/scaled size
    // canvas.height = img.naturalHeight; // or 'height' if you want a special/scaled size
    // canvas.getContext("2d").drawImage(img, 0, 0);
    // return canvas
    //   .toDataURL("image/png")
    //   .replace(/^data:image\/(png|jpg);base64,/, "");
    const canvas: any = document.createElement("canvas");

    if (img instanceof HTMLImageElement) {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      canvas.getContext("2d").drawImage(img, 0, 0);
    }

    // Make sure img is not null before calling toDataURL
    if (img) {
      return canvas.toDataURL("image/png").replace(/^data:image\/(png|jpg);base64,/, "");
    }
  }

  getCellProperty(
    group: number,
    value: number | null,
    border: {
      top: { style: string; color: { auto: number } };
      right: { style: string; color: { auto: number } };
      left: { style: string; color: { auto: number } };
      bottom: { style: string; color: { auto: number } };
    },
    alignment: { wrapText: boolean; vertical: string; horizontal: string },
    inlineFontProperty: { sz: number; bold: boolean }
  ) {
    let fgColor = "FFFFFF";
    let bgColor = "FFFFFF";
    let rgb = "#FFFFFF";
    if (group === 1) {
      if (value != null) {
        if (value < 0) {
          fgColor = "A61C00";
          bgColor = "A61C00";
          rgb = "FFFFFF";
        } else if (value > 0) {
          fgColor = "DAEAD4";
          bgColor = "DAEAD4";
          rgb = "#DAEAD4";
        } else {
          fgColor = "FFFFFF";
          bgColor = "FFFFFF";
          rgb = "#FFFFFF";
        }
      }
    } else if (group === 2) {
      if (value != null) {
        if (value <= 0) {
          fgColor = "A61C00";
          bgColor = "A61C00";
          rgb = "FFFFFF";
        } else {
          fgColor = "FFFFFF";
          bgColor = "FFFFFF";
          rgb = "#FFFFFF";
        }
      }
    } else {
      if (value != null) {
        if (value < -1) {
          fgColor = "A61C00";
          bgColor = "A61C00";
          rgb = "FFFFFF";
        } else if (value >= -1 && value <= 1) {
          fgColor = "FFFFFF";
          bgColor = "FFFFFF";
          rgb = "#FFFFFF";
        } else {
          fgColor = "DAEAD4";
          bgColor = "DAEAD4";
          rgb = "#DAEAD4";
        }
      }
    }
    return this.commonService.getCellPropertyObj(
      fgColor,
      bgColor,
      rgb,
      border,
      alignment,
      "",
      inlineFontProperty
    );
  }
}
