import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "search",
})
// export class SearchPipe implements PipeTransform {
//   public transform(value: any, keys: string, term: string) {
//     // tslint:disable-next-line:curly
//     if (!term) return value;
//     return (value || []).filter((item: any) =>
//       keys
//         .split(",")
//         .some((key) => item.hasOwnProperty(key) && new RegExp(term, "gi").test(item[key]))
//     );
//   }
// }
export class SearchPipe implements PipeTransform {
  public transform(value: any[], keys: string, term: string): any[] {
    console.log("term", term);
    if (!term) return value;

    const escapedTerm = this.escapeRegExp(term);
    console.log("escapedTerm", escapedTerm);
    const regex = new RegExp(escapedTerm, "gi");
    console.log("regex", regex);
    console.log("value", value, keys);

    return (value || []).filter((item: any) =>
      keys.split(",").some((key) => item.hasOwnProperty(key) && regex.test(String(item[key])))
    );
  }

  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
}
