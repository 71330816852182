import { Injectable } from "@angular/core";
import { CommonService } from "../services/common.service";
import * as moment from "moment-timezone";
import { DMConstantService } from "../constants/dm.constant.service";

declare var $: any;
declare var jQuery: any;
declare var XLSX: any;
declare var saveAs: any;

@Injectable()
export class PartsVerifiedReportService {
  public pssDataListCp = [];
  public storeGroup: any = [];
  public pushFlag = false;
  public snapShotVersion: any;
  public snapShotData: any;
  constructor(
    private commonService: CommonService,
    private DMConstantService: DMConstantService
  ) {}
  init() {}
  downloadPartsVerifiedReport(data: any, verifiedType: any, extraParams: any) {
    const csvData = [{}];
    let fileName_ws = "PartsVerified" + moment().format("YYYYMMDDhhmmss");
    const ws_name = fileName_ws;
    const wb = XLSX.utils.book_new();
    wb.Sheets = {};
    wb.SheetNames = [];
    const ws: any = XLSX.utils.json_to_sheet(csvData);
    const wsrows = [];
    for (let i = 0; i < data.length + 12; i++) {
      const obj = { hpx: 15, level: i };
      wsrows.push(obj);
    }

    ws["!pageSetup"] = { orientation: "portrait", scale: "70" };
    ws["!margins"] = {
      left: 0.2,
      right: 0.2,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };
    ws["!rows"] = wsrows;
    let border = {
      top: { style: "thin", color: { rgb: "BBBBBB" } },
      right: { style: "thin", color: { rgb: "BBBBBB" } },
      left: { style: "thin", color: { rgb: "BBBBBB" } },
      bottom: { style: "thin", color: { rgb: "BBBBBB" } },
    };
    let alignment = {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    };
    let inlineFontPropertyHead = { sz: 10, bold: true };
    let objPropertyBold = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      "",
      inlineFontPropertyHead
    );
    let objPropertyBoldNormal = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      "",
      { sz: 10, bold: true }
    );
    let inlineFontProperty = { sz: 10, bold: false };

    let objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      "",
      inlineFontProperty
    );
    let alignmentString = {
      wrapText: true,
      vertical: "center",
      horizontal: "left",
    };
    let alignmentNumber = {
      wrapText: true,
      vertical: "center",
      horizontal: "right",
    };
    let objPropertyString = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignmentString,
      "",
      inlineFontProperty
    );
    let objPropertyNumber = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignmentNumber,
      "",
      inlineFontProperty
    );
    let excelHeader = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"];
    let startRow = 12;

    ws["!merges"] = [
      { s: { r: 2, c: 1 }, e: { r: 2, c: 6 } },
      { s: { r: 10, c: 0 }, e: { r: 10, c: 2 } },
    ];
    let wscols = [
      { wch: 5 },
      { wch: 9 },
      { wch: 9 },
      { wch: 12 },
      { wch: 11 },
      { wch: 11 },
      { wch: 16 },
      { wch: 18 },
      { wch: 28 },
      { wch: 23 },
    ];
    let borderHead = {
      top: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    let objPropertyBoldNormalHead = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      borderHead,
      alignment,
      {
        sz: 10,
        bold: true,
        italic: false,
        underline: true,
        name: "Arial, Helvetica, sans-serif",
      },
      { sz: 10, bold: true }
    );
    let title = {
      t: "s",
      v: this.DMConstantService.VERIFIED_REPORT_LABELS.partsTitle,
      s: objPropertyBoldNormalHead,
    };
    ws["B3"] = title;
    ws["C3"] = title;
    ws["D3"] = title;
    ws["E3"] = title;
    ws["F3"] = title;
    ws["G3"] = title;
    let repairOrder = {
      t: "s",
      v: this.DMConstantService.VERIFIED_REPORT_LABELS.repairOrder,
      s: objPropertyBoldNormal,
    };
    ws["A11"] = repairOrder;
    ws["B11"] = repairOrder;
    ws["C11"] = repairOrder;
    ws["E11"] = {
      t: "s",
      v: this.DMConstantService.VERIFIED_REPORT_LABELS.netCustomer,
      s: objPropertyBoldNormal,
    };
    ws["F11"] = {
      t: "s",
      v: this.DMConstantService.VERIFIED_REPORT_LABELS.markup,
      s: objPropertyBoldNormal,
    };

    extraParams.dealerCode =
      extraParams.dealerCode && extraParams.dealerCode != null
        ? extraParams.dealerCode.replace(/^[0][0-9]*$/g, "_$&")
        : extraParams.dealerCode;
    let alignmentLeft = {
      wrapText: false,
      vertical: "center",
      horizontal: "left",
    };
    let objPropertyBoldAllignLeft = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignmentLeft,
      "",
      inlineFontPropertyHead
    );
    ws["D5"] = {
      t: "s",
      v: this.DMConstantService.VERIFIED_REPORT_LABELS.dealerName,
      s: objPropertyBoldNormal,
    };
    ws["E5"] = {
      t:
        extraParams.dealerName &&
        !isNaN(extraParams.dealerName) &&
        extraParams.dealerName != null &&
        extraParams.dealerName !== undefined
          ? "n"
          : "s",
      v: extraParams.dealerName ? extraParams.dealerName : "",
      s: objPropertyBoldAllignLeft,
    };
    ws["D6"] = {
      t: "s",
      v: this.DMConstantService.VERIFIED_REPORT_LABELS.dealerCode,
      s: objPropertyBoldNormal,
    };
    ws["E6"] = {
      t:
        extraParams.dealerCode &&
        !isNaN(extraParams.dealerCode) &&
        extraParams.dealerCode != null &&
        extraParams.dealerCode !== undefined
          ? "n"
          : "s",
      v: extraParams.dealerCode ? extraParams.dealerCode : "",
      s: objPropertyBoldAllignLeft,
    };

    let endRowNum = parseInt(data.length) + startRow;
    ws["!ref"] = "A1:J" + endRowNum;
    ws["!cols"] = wscols;
    let partsCostTotal = 0;
    let paidPriceTotal = 0;
    for (let i = 0; i < data.length; i++) {
      let j = 0;
      let paramTwo = i + startRow;
      if (i === 0) {
        for (let indexKey in data[i]) {
          let paramOne = excelHeader[j];
          let label = { t: "s", v: indexKey, s: objPropertyBold };
          ws[paramOne + "" + paramTwo] = label;
          j++;
        }
      }
      j = 0;
      paramTwo = i + (startRow + 1);
      for (let key in data[i]) {
        let paramOne = excelHeader[j];
        let labelString = {
          t:
            data[i][key] &&
            !isNaN(data[i][key]) &&
            data[i][key] != null &&
            data[i][key] !== undefined
              ? "n"
              : "s",
          v: data[i][key] ? data[i][key] : "",
          s:
            data[i][key] &&
            !isNaN(data[i][key]) &&
            data[i][key] != null &&
            data[i][key] !== undefined
              ? objPropertyNumber
              : objPropertyString,
        };
        if (paramOne === "A") {
          ws[paramOne + paramTwo] = {
            t: "n",
            v: data[i][key] ? data[i][key] : 0,
            s: objPropertyNumber,
          };
        } else if (paramOne === "C") {
          ws[paramOne + paramTwo] = {
            t: "s",
            v: this.commonService.transformDateFormat(data[i][key], "MM/DD/YYYY"),
            s: objPropertyNumber,
          };
        } else if (paramOne === "D") {
          let objPropertyColD: any = this.commonService.getCellPropertyObj(
            "FFFFFF",
            "FFFFFF",
            "",
            border,
            alignmentNumber,
            "",
            { sz: 10, bold: false }
          );
          objPropertyColD.numFmt = "0.00";
          ws[paramOne + paramTwo] = {
            t:
              data[i][key] &&
              !isNaN(data[i][key]) &&
              data[i][key] != null &&
              data[i][key] !== undefined
                ? "n"
                : "s",
            v: data[i][key] ? data[i][key] : "",
            s: objPropertyColD,
          };
        } else if (paramOne === "E") {
          let objPropertyColE: any = this.commonService.getCellPropertyObj(
            "FFFFFF",
            "FFFFFF",
            "",
            border,
            alignmentNumber,
            "",
            { sz: 10, bold: false }
          );
          objPropertyColE.numFmt = "0.00";
          ws[paramOne + paramTwo] = {
            t:
              data[i][key] &&
              !isNaN(data[i][key]) &&
              data[i][key] != null &&
              data[i][key] !== undefined
                ? "n"
                : "s",
            v: data[i][key] ? data[i][key] : "",
            s: objPropertyColE,
          };
        } else if (paramOne === "F") {
          let objPropertyNumFormatPercentParts: any = this.commonService.getCellPropertyObj(
            "FFFFFF",
            "FFFFFF",
            "",
            border,
            alignmentNumber,
            "",
            { sz: 10, bold: false }
          );
          objPropertyNumFormatPercentParts.numFmt = "0.00%";
          ws[paramOne + paramTwo] = {
            t: "n",
            v: "",
            s: objPropertyNumFormatPercentParts,
            f: "(E" + paramTwo + "/D" + paramTwo + ")-1",
          };
        } else if (paramOne === "H") {
          ws[paramOne + paramTwo] = {
            t:
              data[i][key] &&
              !isNaN(data[i][key]) &&
              data[i][key] != null &&
              data[i][key] !== undefined
                ? "n"
                : "s",
            v: data[i][key] ? data[i][key] : "",
            s: objPropertyString,
          };
        } else if (paramOne === "I") {
          ws[paramOne + paramTwo] = {
            t:
              data[i][key] &&
              !isNaN(data[i][key]) &&
              data[i][key] != null &&
              data[i][key] !== undefined
                ? "n"
                : "s",
            v: data[i][key] ? data[i][key] : "",
            s: objPropertyString,
          };
        } else {
          ws[paramOne + paramTwo] = labelString;
        }
        j++;
      }
    }
    let bufferRow = endRowNum + 10;
    let objPropertyNumFormatPC: any = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignmentNumber,
      "",
      { sz: 10, bold: true }
    );
    objPropertyNumFormatPC.numFmt = this.commonService.getDollarFormat2DecimalPoint(partsCostTotal);
    ws["D10"] = {
      t: "n",
      s: objPropertyNumFormatPC,
      f: "SUM(D12:D" + bufferRow + ")",
    };

    let objPropertyNumFormatPT: any = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignmentNumber,
      "",
      { sz: 10, bold: true }
    );
    objPropertyNumFormatPT.numFmt = this.commonService.getDollarFormat2DecimalPoint(paidPriceTotal);
    ws["E10"] = {
      t: "n",
      s: objPropertyNumFormatPT,
      f: "SUM(E12:E" + bufferRow + ")",
    };
    let objPropertyNumFormatPercent: any = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignmentNumber,
      "",
      { sz: 10, bold: true }
    );
    objPropertyNumFormatPercent.numFmt = "0.00%";
    ws["F10"] = { t: "n", s: objPropertyNumFormatPercent, f: "(E10/D10)-1" };

    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    const wbout = XLSX.write(wb, {
      type: "binary",
      bookSST: true,
      bookType: "xlsx",
    });
    let blob = new Blob([this.commonService.s2ab(wbout) as BlobPart], {
      type: "application/octet-stream",
    });
    return blob;
  }
}
