import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TopBarComponent } from "./structure/components/top-bar/top-bar.component";
import { MenuLeftComponent } from "./structure/components/menu-left/menu-left.component";
import { FooterComponent } from "./structure/components/footer/footer.component";
import { CommonBarComponent } from "./structure/components/common-bar/common-bar.component";

import { ConstantService } from "./structure/constants/constant.service";
import { CommonService } from "./structure/services/common.service";
import { HomeModule } from "./modules/home/home.module";
import { ValidationService } from "./structure/services/validation.service";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from "@azure/msal-browser";
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import { environment } from "./../environments/environment";
import { SharedModule } from "./modules/shared/shared.module";
import { GraphQLModule } from "./graphql.module";
import { MyCurrencyPipe } from "./structure/components/formatter/currency/my-currency.pipe";
import { MyPercentagePipe } from "./structure/components/formatter/percentage/my-percentage.pipe";
import { EventEmitterService } from "./structure/services/event.emitter.services";
import { SubscriptionConstantService } from "./structure/constants/subscription.constant.service";
import { ModalModule } from "ngx-modialog";
import { Modal, BootstrapModalModule } from "ngx-modialog/plugins/bootstrap";
import { DMConstantService } from "./structure/constants/dm.constant.service";
import { DMQueryService } from "./structure/services/dm.query.services";
import { DmFormGroupService } from "./structure/services/dm.formgroup.services";
import { RatePerhourPipe } from "./structure/components/formatter/rate-perhour/rate-perhour.pipe";
import { ProjectionReportService } from "./structure/services/projection.report.services";
import { PartPssReportService } from "./structure/services/partpss.report.services";
import { InternalPssReportService } from "./structure/services/internalpss.report.services";
import { ProjectionReportPdfService } from "./structure/services/projection.report.pdf.services";
import { LaborPssReportService } from "./structure/services/laborpss.report.services";
import { PreAuditLaborReportService } from "./structure/services/preaudit.labor.report.services";
import { ProjectionReportServiceV2 } from "./structure/services/projection.reportV2.services";
import { ProjectionReportPdfServiceV2 } from "./structure/services/projection.reportV2.pdf.services";
import { PartPssReportServiceV2 } from "./structure/services/partpss.reportV2.services";
import { LaborPssReportServiceV2 } from "./structure/services/laborpss.reportV2.services";
import { ImpactReportPdfService } from "./structure/services/impact.report.pdf.services";
import { ImpactReportLaborPdfService } from "./structure/services/impact.report.labor.pdf.services";
import { ProjectionReportPdfServiceV2NoBehavior } from "./structure/services/projection.report.pdfservice.v2.noBehavior";
import { ProjectionReportPdfServiceV2WithBehavior } from "./structure/services/projection.report.pdfservice.v2_withBehavior";
import { billingTemplatePdfServiceParts } from "./structure/services/billing.template.report.pdfService.parts";
import { billingTemplatePdfServiceLabor } from "./structure/services/billing.template.report.pdfService.labor";
import { ImpactReportBetaPdfService } from "./structure/services/impact.report.beta.pdf.services";
import { ImpactReportBetaLaborPdfService } from "./structure/services/impact.report.beta.labor.pdf.services";
import { PartsVerifiedReportService } from "./structure/services/partsVerified.report.services";
import { LaborVerifiedReportService } from "./structure/services/laborVerified.report.services";
import { UploadFileService } from "./structure/services/upload-file.service";
import { AuthGuard } from "./authGuard";
import { LoginComponent } from "./modules/login/login.component";
import { MyCurrencyFormatterDirective } from "./structure/components/formatter/currency/my-currency-formatter.directive";
import { MyPercentageFormatterDirective } from "./structure/components/formatter/percentage/my-percentage-formatter.directive";
import { RatePerhourFormatterDirective } from "./structure/components/formatter/rate-perhour/rate-perhour-formatter.directive";
import { SafeInputPipe } from "./structure/components/formatter/safeInput/safe-input.pipe";
import { NumericEditor } from "./modules/swat/swat/numeric-editor.component";
import { FocusOnClickDirective } from "./structure/components/formatter/focus-on-directive/focus-on-click.directive";
import { GlobalErrorHandler } from "./structure/services/global.error.handler.service";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      // clientId: '3fba556e-5d4a-48e3-8e1a-fd57c12cb82e', // PPE testing environment
      authority: "https://login.microsoftonline.com/7663244e-06dc-4b2c-adba-cc0980585782",
      // authority: 'https://login.windows-ppe.net/common', // PPE testing environment
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.redirectUri,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      // storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Trace,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);
  // protectedResourceMap.set('/backend/*', [environment.authScope]);
  // protectedResourceMap.set(environment.apiUrl+'/portal', [environment.authScope]);
  // protectedResourceMap.set(environment.apiUrl+'/duJobs', [environment.authScope]);

  // protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']); // PPE testing environment

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["user.read", "openid", "profile"],
    },
    // loginFailedRoute: "/login",
  };
}
@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    MenuLeftComponent,
    FooterComponent,
    LoginComponent,
    CommonBarComponent,
    SafeInputPipe,
    NumericEditor
  ],
  entryComponents: [NumericEditor],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    HomeModule,
    MsalModule,
    GraphQLModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
  ],
  providers: [
    Title,
    ConstantService,
    CommonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthGuard,
    MyCurrencyFormatterDirective,
    MyPercentageFormatterDirective,
    RatePerhourFormatterDirective,
    MyCurrencyPipe,
    MyPercentagePipe,
    RatePerhourPipe,
    EventEmitterService,
    SubscriptionConstantService,
    DMConstantService,
    DMQueryService,
    DmFormGroupService,
    ProjectionReportService,
    PartPssReportService,
    InternalPssReportService,
    LaborPssReportService,
    PreAuditLaborReportService,
    ProjectionReportPdfService,
    ProjectionReportServiceV2,
    ProjectionReportPdfServiceV2,
    PartPssReportServiceV2,
    LaborPssReportServiceV2,
    ImpactReportPdfService,
    ImpactReportLaborPdfService,
    ProjectionReportPdfServiceV2NoBehavior,
    ProjectionReportPdfServiceV2WithBehavior,
    billingTemplatePdfServiceParts,
    billingTemplatePdfServiceLabor,
    ImpactReportBetaPdfService,
    ImpactReportBetaLaborPdfService,
    PartsVerifiedReportService,
    LaborVerifiedReportService,
    UploadFileService,
    ValidationService,
    SafeInputPipe,
    FocusOnClickDirective,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {
  constructor(private commonService: CommonService) {}
}
