import { ErrorHandler, Injectable } from "@angular/core";
import { CommonService } from "./common.service";
import { Router } from "@angular/router";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private commonService: CommonService, private router: Router) {}

  handleError(error: any): void {
    // Log the error to the console
    console.error("Error", error);
    const activityData = {
      activityName: "Handle Console Error",
      activityDescription: "Current Page: " + this.router.url,
      activityData: {
        error: error.message,
        stack: error.stack,
      },
    };
    this.commonService.saveActivity("Console Error", activityData);
  }
}
