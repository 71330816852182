import { Injectable } from "@angular/core";
// import { Http, Headers, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/map";
// import { Logger } from "angular2-logger/core";
// import { environment } from "environments/environment";
import { ConstantService } from "../constants/constant.service";
import { CommonService } from "../services/common.service";
import * as moment from "moment-timezone";
import { DMConstantService } from "../constants/dm.constant.service";

declare var $: any;
declare var jQuery: any;
declare var XLSX: any;

@Injectable()
export class InternalPssReportService {
  public internalPssDataListCp: any[] = [];
  public storeGroup: any = [];
  public pushFlag = false;
  public pushFlagPss = false;
  public snapShotVersion!: any[];
  public snapShotData: any;
  constructor(
    // private http: Http,
    // private _logger: Logger,
    private constantService: ConstantService,
    private commonService: CommonService,
    private DMConstantService: DMConstantService
  ) {}
  init() {
    //
  }
  downloadInternalPssReport(
    internalPssColumnsList: string | any[],
    data: any[],
    storeGroupData: any[],
    totalDataRow: {
      intPssSumUpAddlAnnualProfit: any;
      intPssSumOptPreRoAuditUplift: any;
      intPssSumOptPreDiscountUplift: any;
      intPssSumOptPostDiscountUplift: any;
      intPssSumFactoryAprovedUplift: any;
    },
    imgData: any,
    type: string,
    snapShotVersion: any,
    snapShotData: string
  ) {
    const footerFlag = false;
    this.internalPssDataListCp = [];
    this.storeGroup = [];
    this.storeGroup = storeGroupData;
    this.internalPssDataListCp = data;
    this.snapShotVersion = snapShotVersion;
    this.snapShotData = snapShotData;
    const csvData: any = [{}, {}, {}];
    const emptyObj = {
      0: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
      10: null,
      11: null,
      12: null,
      13: null,
      14: null,
      15: null,
      16: null,
      17: null,
      18: null,
      19: null,
      20: null,
      21: null,
      22: null,
    };
    csvData.push(emptyObj);
    let subTitle = emptyObj;

    if (type === "cp") {
      if (!this.pushFlagPss) {
        this.internalPssDataListCp.unshift(subTitle);
      }
      this.pushFlagPss = true;
    } else {
      if (!this.pushFlag) {
        this.internalPssDataListCp.unshift(subTitle);
      }
      this.pushFlag = true;
    }

    for (const i in this.internalPssDataListCp) {
      if (this.internalPssDataListCp) {
        let postDiscDueDate = null;
        if (this.internalPssDataListCp[i]["postDiscDueDate"]) {
          postDiscDueDate = this.internalPssDataListCp[i]["postDiscDueDate"].replace(
            this.constantService.REG_EXP_FOR_DATE_FORMAT,
            "$2-$3-$1"
          );
        }
        csvData.push({
          0: this.internalPssDataListCp[i]["stName"]
            ? this.internalPssDataListCp[i]["stName"]
            : null,
          1: this.internalPssDataListCp[i]["brand"] ? this.internalPssDataListCp[i]["brand"] : null,
          2: this.internalPssDataListCp[i]["state"] ? this.internalPssDataListCp[i]["state"] : null,
          3:
            this.internalPssDataListCp[i]["upAvgMonthlyWarrCogs"] >= 0
              ? this.internalPssDataListCp[i]["upAvgMonthlyWarrCogs"]
              : null,
          4: this.internalPssDataListCp[i]["upCurrWpMu"],
          5: this.internalPssDataListCp[i]["upNormProjectionMu"],
          6: this.internalPssDataListCp[i]["upIncrOvrCurr"],
          7:
            this.internalPssDataListCp[i]["upAddlAnnualProfit"] >= 0
              ? this.internalPssDataListCp[i]["upAddlAnnualProfit"]
              : null,
          8: this.internalPssDataListCp[i]["preAudMu"],
          9: this.internalPssDataListCp[i]["preAudIncrOvrCurr"],
          10:
            this.internalPssDataListCp[i]["preAudAnnualUplift"] >= 0
              ? this.internalPssDataListCp[i]["preAudAnnualUplift"]
              : null,
          11: this.internalPssDataListCp[i]["preDiscInitMu"],
          12: this.internalPssDataListCp[i]["preDiscIncrOvrCurr"],
          13:
            this.internalPssDataListCp[i]["preDiscAddlAnnualProfit"] >= 0
              ? this.internalPssDataListCp[i]["preDiscAddlAnnualProfit"]
              : null,
          14: this.internalPssDataListCp[i]["postDiscMu"],
          15: this.internalPssDataListCp[i]["postDiscIncrOvrCurr"],
          16:
            this.internalPssDataListCp[i]["postDiscAddlAnnualProfit"] >= 0
              ? this.internalPssDataListCp[i]["postDiscAddlAnnualProfit"]
              : null,
          17: postDiscDueDate,
          18: this.internalPssDataListCp[i]["approvedWpMu"],
          19: this.internalPssDataListCp[i]["approvedImpact"],
          20: this.internalPssDataListCp[i]["approvedAnnualImpact"]
            ? this.internalPssDataListCp[i]["approvedAnnualImpact"]
            : null,
          21: this.internalPssDataListCp[i]["approvedAdjAddlMonthlyProfit"]
            ? this.internalPssDataListCp[i]["approvedAdjAddlMonthlyProfit"]
            : null,
          22: this.internalPssDataListCp[i]["approvedAdjAddlAnnualProfit"]
            ? this.internalPssDataListCp[i]["approvedAdjAddlAnnualProfit"]
            : null,
        });
      }
    }
    csvData[4]["19"] = "Point  \nImpact";
    csvData[4]["20"] = "Annual \n $ Impact";
    let fileName_ws = "";
    let fileName = "";
    let snapShotCreatedTime = null;
    if (type === "cp") {
      let snapShotName = this.snapShotVersion[0].description;
      if (this.snapShotVersion[0].id < 0) {
        snapShotName = this.snapShotData;
      }
      snapShotName = snapShotName + "_";
      fileName = "InternalPSS_" + snapShotName + moment().format("YYYYMMDDhhmmss") + ".xlsx";
      fileName_ws = "InternalPSS_" + moment().format("YYYYMMDDhhmmss");
      snapShotCreatedTime = moment(this.snapShotVersion[0].createdAt).format(
        "MM-DD-YYYY hh:mm:ss A"
      );
    } else {
      fileName = "InternalPSS_" + moment().format("YYYYMMDDhhmmss") + ".xlsx";
      fileName_ws = "InternalPSS_" + moment().format("YYYYMMDDhhmmss");
    }
    const ws_name = fileName_ws;
    const wb = XLSX.utils.book_new();
    wb.Sheets = {};
    wb.SheetNames = [];
    const ws: any = XLSX.utils.json_to_sheet(csvData);
    const wscols = [
      { wch: 25 }, // "characters"
      { wch: 7 },
      { wch: 7 },
      { wch: 11 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 11 },
      { wch: 8 },
      { wch: 8 },
      { wch: 11 },
      { wch: 8 },
      { wch: 8 },
      { wch: 11 },
      { wch: 8 },
      { wch: 8 },
      { wch: 11 },
      { wch: 11 },
      { wch: 8 },
      { wch: 8 },
      { wch: 11 },
      { wch: 11 },
      { wch: 11 },
    ];
    const wsrows = [
      { hpx: 50, level: 1 },
      { hpx: 70, level: 2 },
      { hpx: 25, level: 3 },
      { hpx: 50, level: 4 },
      { hpx: 30, level: 5 },
      { hpx: 110, level: 6 },
    ];
    for (let i = 0; i < this.internalPssDataListCp.length + 6; i++) {
      const obj = { hpx: 28, level: i + 6 };
      wsrows.push(obj);
    }
    ws["!cols"] = wscols;
    for (let i = 0; i < internalPssColumnsList.length; i++) {
      if (!internalPssColumnsList[i]) {
        ws["!cols"][i] = { hidden: true };
      }
    }
    ws["!pageSetup"] = { orientation: "landscape", scale: "100" };
    /**
     * Set worksheet sheet to "narrow".
     */
    ws["!margins"] = {
      left: 0.25,
      right: 0.25,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 22 } },
      { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
      { s: { r: 1, c: 1 }, e: { r: 1, c: 22 } },
      { s: { r: 2, c: 1 }, e: { r: 2, c: 2 } },
      { s: { r: 2, c: 3 }, e: { r: 2, c: 5 } },
      { s: { r: 2, c: 6 }, e: { r: 2, c: 22 } },

      { s: { r: 3, c: 0 }, e: { r: 3, c: 2 } },
      { s: { r: 3, c: 3 }, e: { r: 3, c: 7 } },
      { s: { r: 3, c: 8 }, e: { r: 3, c: 10 } },
      { s: { r: 3, c: 11 }, e: { r: 3, c: 13 } },
      { s: { r: 3, c: 14 }, e: { r: 3, c: 17 } },
      { s: { r: 3, c: 18 }, e: { r: 3, c: 22 } },

      {
        s: { r: this.internalPssDataListCp.length + 5, c: 0 },
        e: { r: this.internalPssDataListCp.length + 5, c: 4 },
      },
      {
        s: { r: this.internalPssDataListCp.length + 5, c: 5 },
        e: { r: this.internalPssDataListCp.length + 5, c: 7 },
      },
      {
        s: { r: this.internalPssDataListCp.length + 5, c: 8 },
        e: { r: this.internalPssDataListCp.length + 5, c: 10 },
      },
      {
        s: { r: this.internalPssDataListCp.length + 5, c: 11 },
        e: { r: this.internalPssDataListCp.length + 5, c: 13 },
      },
      {
        s: { r: this.internalPssDataListCp.length + 5, c: 14 },
        e: { r: this.internalPssDataListCp.length + 5, c: 17 },
      },
      {
        s: { r: this.internalPssDataListCp.length + 5, c: 18 },
        e: { r: this.internalPssDataListCp.length + 5, c: 22 },
      },

      { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
      { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
      { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },
      { s: { r: 4, c: 3 }, e: { r: 5, c: 3 } },
      { s: { r: 4, c: 4 }, e: { r: 5, c: 4 } },
      { s: { r: 4, c: 5 }, e: { r: 5, c: 5 } },

      { s: { r: 4, c: 6 }, e: { r: 5, c: 6 } },
      { s: { r: 4, c: 7 }, e: { r: 5, c: 7 } },
      { s: { r: 4, c: 8 }, e: { r: 5, c: 8 } },

      { s: { r: 4, c: 9 }, e: { r: 5, c: 9 } },
      { s: { r: 4, c: 10 }, e: { r: 5, c: 10 } },
      { s: { r: 4, c: 11 }, e: { r: 5, c: 11 } },

      { s: { r: 4, c: 12 }, e: { r: 5, c: 12 } },
      { s: { r: 4, c: 13 }, e: { r: 5, c: 13 } },
      { s: { r: 4, c: 14 }, e: { r: 5, c: 14 } },
      { s: { r: 4, c: 15 }, e: { r: 5, c: 15 } },

      { s: { r: 4, c: 16 }, e: { r: 5, c: 16 } },

      { s: { r: 4, c: 17 }, e: { r: 5, c: 17 } },
      { s: { r: 4, c: 18 }, e: { r: 5, c: 18 } },

      { s: { r: 4, c: 19 }, e: { r: 4, c: 20 } },

      { s: { r: 4, c: 21 }, e: { r: 5, c: 21 } },
      { s: { r: 4, c: 22 }, e: { r: 5, c: 22 } },
    ];
    if (footerFlag) {
      let footerRow: any = "";
      /*let footerTimestampRow:any = '';
      if(type === 'cp') {
        footerRow = { s: { r: (this.internalPssDataListCp.length + 6), c: 0 }, e: { r: (this.internalPssDataListCp.length + 6), c: 18 } };
        footerTimestampRow = { s: { r: (this.internalPssDataListCp.length + 6), c: 19 }, e: { r: (this.internalPssDataListCp.length + 6), c: 22 } };
        ws['!merges'].push(footerRow);
        ws['!merges'].push(footerTimestampRow);
      } else {
        footerRow = { s: { r: (this.internalPssDataListCp.length + 6), c: 0 }, e: { r: (this.internalPssDataListCp.length + 6), c: 22 } };
        ws['!merges'].push(footerRow);
      }*/
      footerRow = {
        s: { r: this.internalPssDataListCp.length + 6, c: 0 },
        e: { r: this.internalPssDataListCp.length + 6, c: 22 },
      };
      ws["!merges"].push(footerRow);
    }
    ws["!rows"] = wsrows;
    ws["!ref"] = "A1:W" + (this.internalPssDataListCp.length + 8);
    let border = {
      top: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    let alignment = {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    };
    let font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: false,
      italic: false,
      underline: false,
    };
    let borderRight = { right: { style: "thin", color: { auto: 1 } } };

    ws["A1"] = { t: "s", v: " " };
    ws["A2"] = { t: "s", v: " " };
    ws["A3"] = { t: "s", v: " " };
    ws["B3"] = { t: "s", v: " " };
    ws["C3"] = { t: "s", v: " " };
    ws["B1"] = { t: "s", v: " " };
    let objProperty: { [k: string]: any } = {};
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "0b5394",
      borderRight,
      alignment,
      "",
      ""
    );
    ws["W1"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["W2"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    ws["W3"] = {
      t: "s",
      v: "",
      s: objProperty,
    };
    let inlineFontProperty = { sz: 18, bold: true };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "0b5394",
      borderRight,
      alignment,
      "",
      inlineFontProperty
    );
    ws["B2"] = {
      t: "s",
      v: this.constantService.EXCEL_INTERNAL_PSSHEADING,
      s: objProperty,
    };
    inlineFontProperty = { sz: 14, bold: true };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "0b5394",
      borderRight,
      alignment,
      "",
      inlineFontProperty
    );
    ws["D3"] = {
      t: "s",
      v: "Group Name:",
      s: objProperty,
    };
    let alignmentE3 = {
      wrapText: true,
      vertical: "center",
      horizontal: "left",
    };
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "0b5394",
      "",
      alignmentE3,
      "",
      inlineFontProperty
    );
    ws["G3"] = {
      t: "s",
      v: this.storeGroup[0].itemName,
      s: objProperty,
    };
    /**
     * Headings Starts Here
     */
    objProperty = this.commonService.getCellPropertyObj(
      "EFEFEF",
      "EFEFEF",
      "",
      border,
      alignment,
      font,
      ""
    );

    ws["A4"] = { t: "s", v: " " };
    ws["B4"] = { t: "s", v: " " };
    ws["C4"] = { t: "s", v: " " };
    let head1 = {
      t: "s",
      v: this.constantService.EXCEL_INTERNAL_PSSHEADING_SUB1,
      s: objProperty,
    };
    ws["D4"] = head1;
    ws["E4"] = head1;
    ws["F4"] = head1;
    ws["G4"] = head1;
    ws["H4"] = head1;

    objProperty = this.commonService.getCellPropertyObj(
      "D9D9D9",
      "D9D9D9",
      "",
      border,
      alignment,
      font,
      ""
    );

    let head2 = {
      t: "s",
      v: this.constantService.EXCEL_INTERNAL_PSSHEADING_SUB2,
      s: objProperty,
    };
    ws["I4"] = head2;
    ws["J4"] = head2;
    ws["K4"] = head2;

    objProperty = this.commonService.getCellPropertyObj(
      "CCCCCC",
      "CCCCCC",
      "",
      border,
      alignment,
      font,
      ""
    );

    let head3 = {
      t: "s",
      v: this.constantService.EXCEL_INTERNAL_PSSHEADING_SUB3,
      s: objProperty,
    };
    ws["L4"] = head3;
    ws["M4"] = head3;
    ws["N4"] = head3;
    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );
    let head4 = {
      t: "s",
      v: this.constantService.EXCEL_INTERNAL_PSSHEADING_SUB4,
      s: objProperty,
    };
    ws["O4"] = head4;
    ws["P4"] = head4;
    ws["Q4"] = head4;
    ws["R4"] = head4;

    objProperty = this.commonService.getCellPropertyObj(
      "DADADA",
      "DADADA",
      "",
      border,
      alignment,
      font,
      ""
    );
    let head5 = {
      t: "s",
      v: this.constantService.EXCEL_INTERNAL_PSSHEADING_SUB5,
      s: objProperty,
    };
    ws["S4"] = head5;
    ws["T4"] = head5;
    ws["U4"] = head5;
    ws["V4"] = head5;
    ws["W4"] = head5;

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead1 = {
      t: "s",
      v: "Store DBA Name",
      s: objProperty,
    };
    ws["A5"] = subHead1;
    ws["A6"] = subHead1;

    let subHeadB = {
      t: "s",
      v: "Brand",
      s: objProperty,
    };
    ws["B5"] = subHeadB;
    ws["B6"] = subHeadB;

    let subHeadS = {
      t: "s",
      v: "State",
      s: objProperty,
    };
    ws["C5"] = subHeadS;
    ws["C6"] = subHeadS;

    objProperty = this.commonService.getCellPropertyObj(
      "EFEFEF",
      "EFEFEF",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead2 = {
      t: "s",
      v: "Avg. \n Monthly \n Warranty \n COGS",
      s: objProperty,
    };
    ws["D5"] = subHead2;
    ws["D6"] = subHead2;

    let subHead3 = {
      t: "s",
      v: "Current \n Warranty \n  Parts Markup",
      s: objProperty,
    };
    ws["E5"] = subHead3;
    ws["E6"] = subHead3;

    let subHead4 = {
      t: "s",
      v: "Normalized \n Projection Markup",
      s: objProperty,
    };
    ws["F5"] = subHead4;
    ws["F6"] = subHead4;

    objProperty = this.commonService.getCellPropertyObj(
      "DCEAD6",
      "DCEAD6",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead5 = {
      t: "s",
      v: "Increase \n  Over Current",
      s: objProperty,
    };
    ws["G5"] = subHead5;
    ws["G6"] = subHead5;

    let subHead6 = {
      t: "s",
      v: "Additional \n Annual Profit",
      s: objProperty,
    };
    ws["H5"] = subHead6;
    ws["H6"] = subHead6;

    objProperty = this.commonService.getCellPropertyObj(
      "D9D9D9",
      "D9D9D9",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead7 = {
      t: "s",
      v: "Markup",
      s: objProperty,
    };
    ws["I5"] = subHead7;
    ws["I6"] = subHead7;
    objProperty = this.commonService.getCellPropertyObj(
      "DCEAD6",
      "DCEAD6",
      "",
      border,
      alignment,
      font,
      ""
    );
    let subHead8 = {
      t: "s",
      v: "Increase \n  over \n Current",
      s: objProperty,
    };
    ws["J5"] = subHead8;
    ws["J6"] = subHead8;

    let subHead9 = {
      t: "s",
      v: "Additional \n Annual  \n Profit",
      s: objProperty,
    };
    ws["K5"] = subHead9;
    ws["K6"] = subHead9;

    objProperty = this.commonService.getCellPropertyObj(
      "CCCCCC",
      "CCCCCC",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead10 = {
      t: "s",
      v: "Initial \n Markup From \n Data Pull",
      s: objProperty,
    };
    ws["L5"] = subHead10;
    ws["L6"] = subHead10;

    objProperty = this.commonService.getCellPropertyObj(
      "DCEAD6",
      "DCEAD6",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead11 = {
      t: "s",
      v: "Increase \n Over Current",
      s: objProperty,
    };
    ws["M5"] = subHead11;
    ws["M6"] = subHead11;

    let subHead12 = {
      t: "s",
      v: "Additional \n Annual \n Profit",
      s: objProperty,
    };
    ws["N5"] = subHead12;
    ws["N6"] = subHead12;

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );
    let subHead13 = {
      t: "s",
      v: "Final \n Markup \n to Submit",
      s: objProperty,
    };
    ws["O5"] = subHead13;
    ws["O6"] = subHead13;

    objProperty = this.commonService.getCellPropertyObj(
      "DCEAD6",
      "DCEAD6",
      "",
      border,
      alignment,
      font,
      ""
    );
    let subHead14 = {
      t: "s",
      v: "Increase \n Over Current",
      s: objProperty,
    };
    ws["P5"] = subHead14;
    ws["P6"] = subHead14;

    objProperty = this.commonService.getCellPropertyObj(
      "F2CBCC",
      "F2CBCC",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead15 = {
      t: "s",
      v: "Additional \n Annual Profit",
      s: objProperty,
    };
    ws["Q5"] = subHead15;
    ws["Q6"] = subHead15;

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead18 = {
      t: "s",
      v: "Due Date",
      s: objProperty,
    };
    ws["R5"] = subHead18;
    ws["R6"] = subHead18;

    objProperty = this.commonService.getCellPropertyObj(
      "EFEFEF",
      "EFEFEF",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead19 = {
      t: "s",
      v: "Approved \n Warranty \n Parts Markup",
      s: objProperty,
    };
    ws["S5"] = subHead19;
    ws["S6"] = subHead19;

    objProperty = this.commonService.getCellPropertyObj(
      "F0CACC",
      "F0CACC",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead20 = {
      t: "s",
      v: "Factory \n Adjustment",
      s: objProperty,
    };
    ws["T5"] = subHead20;
    ws["U5"] = subHead20;

    let subHead16 = {
      t: "s",
      v: "Point \nImpact",
      s: objProperty,
    };
    ws["S6"] = subHead16;

    let subHead17 = {
      t: "s",
      v: "Annual \n $ \n Impact",
      s: objProperty,
    };
    ws["T6"] = subHead17;

    objProperty = this.commonService.getCellPropertyObj(
      "DCEAD6",
      "DCEAD6",
      "",
      border,
      alignment,
      font,
      ""
    );

    let subHead21 = {
      t: "s",
      v: "Adjusted \n Additional \n Monthly Profit",
      s: objProperty,
    };
    ws["V5"] = subHead21;
    ws["V6"] = subHead21;

    objProperty = this.commonService.getCellPropertyObj(
      "427628",
      "427628",
      "",
      border,
      alignment,
      font,
      ""
    );
    let subHead22 = {
      t: "s",
      v: "Adjusted \n Additional \n Annual Profit",
      s: objProperty,
    };
    ws["W5"] = subHead22;
    ws["W6"] = subHead22;
    /**
     * Each headings Style and cell color
     */

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );

    let obj = objProperty;
    ws["A5"].s = obj;
    ws["B5"].s = obj;
    ws["C5"].s = obj;
    font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 9,
      color: { rgb: "#FF0000" },
      bold: false,
      italic: false,
      underline: false,
    };
    objProperty = this.commonService.getCellPropertyObj(
      "EFEFEF",
      "EFEFEF",
      "",
      border,
      alignment,
      font,
      ""
    );
    obj = objProperty;
    ws["D5"].s = obj;
    ws["E5"].s = obj;
    ws["F5"].s = obj;
    ws["S5"].s = obj;

    objProperty = this.commonService.getCellPropertyObj(
      "D9D9D9",
      "D9D9D9",
      "",
      border,
      alignment,
      font,
      ""
    );
    obj = objProperty;
    ws["I5"].s = obj;

    objProperty = this.commonService.getCellPropertyObj(
      "CCCCCC",
      "CCCCCC",
      "",
      border,
      alignment,
      font,
      ""
    );
    obj = objProperty;
    ws["L5"].s = obj;

    objProperty = this.commonService.getCellPropertyObj(
      "DCEAD6",
      "DCEAD6",
      "",
      border,
      alignment,
      font,
      ""
    );
    obj = objProperty;
    ws["V5"].s = obj;

    objProperty = this.commonService.getCellPropertyObj(
      "DCEAD6",
      "DCEAD6",
      "",
      border,
      alignment,
      font,
      ""
    );
    obj = objProperty;
    ws["G5"].s = obj;
    ws["H5"].s = obj;
    ws["J5"].s = obj;
    ws["K5"].s = obj;
    ws["M5"].s = obj;
    ws["N5"].s = obj;
    ws["P5"].s = obj;
    ws["Q5"].s = obj;

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );
    obj = objProperty;
    ws["O5"].s = obj;
    ws["R5"].s = obj;

    objProperty = this.commonService.getCellPropertyObj(
      "F0CACC",
      "F0CACC",
      "",
      border,
      alignment,
      font,
      ""
    );
    obj = objProperty;
    ws["T5"].s = obj;
    ws["U5"].s = obj;
    ws["T6"].s = obj;
    ws["U6"].s = obj;

    objProperty = this.commonService.getCellPropertyObj(
      "46762D",
      "46762D",
      "",
      border,
      alignment,
      font,
      ""
    );
    obj = objProperty;
    ws["W5"].s = obj;

    /**
     * Total Value Display Row
     */
    font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: true,
      italic: false,
      underline: false,
    };

    objProperty = this.commonService.getCellPropertyObj(
      "FFFFFF",
      "FFFFFF",
      "",
      border,
      alignment,
      font,
      ""
    );

    let projObj = {
      t: "s",
      v: "Totals:",
      s: objProperty,
    };

    ws["A" + (this.internalPssDataListCp.length + 6)] = projObj;
    ws["B" + (this.internalPssDataListCp.length + 6)] = projObj;
    ws["C" + (this.internalPssDataListCp.length + 6)] = projObj;
    ws["D" + (this.internalPssDataListCp.length + 6)] = projObj;
    ws["E" + (this.internalPssDataListCp.length + 6)] = projObj;

    objProperty = this.commonService.getCellPropertyObj(
      "DCEAD6",
      "DCEAD6",
      "",
      border,
      alignment,
      font,
      ""
    );
    let projObjClear = {
      t: "s",
      v: "",
      s: objProperty,
    };

    ws["D" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["E" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["F" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["G" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["H" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["I" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["J" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["K" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["L" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["M" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["N" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["O" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["P" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["Q" + (this.internalPssDataListCp.length + 6)] = projObjClear;
    ws["R" + (this.internalPssDataListCp.length + 6)] = projObjClear;

    objProperty = this.commonService.getCellPropertyObj(
      "46762D",
      "46762D",
      "",
      border,
      alignment,
      font,
      ""
    );

    let projObjB = {
      t: "s",
      v: "",
      s: objProperty,
    };

    ws["S" + (this.internalPssDataListCp.length + 6)] = projObjB;
    ws["T" + (this.internalPssDataListCp.length + 6)] = projObjB;
    ws["U" + (this.internalPssDataListCp.length + 6)] = projObjB;
    ws["V" + (this.internalPssDataListCp.length + 6)] = projObjB;
    ws["W" + (this.internalPssDataListCp.length + 6)] = projObjB;

    objProperty = this.commonService.getCellPropertyObj(
      "DCEAD6",
      "DCEAD6",
      "",
      border,
      alignment,
      font,
      ""
    );
    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.intPssSumUpAddlAnnualProfit
    );
    ws["F" + (this.internalPssDataListCp.length + 6)] = {
      t: "n",
      v: totalDataRow.intPssSumUpAddlAnnualProfit,
      s: objProperty,
    };
    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.intPssSumOptPreRoAuditUplift
    );
    ws["I" + (this.internalPssDataListCp.length + 6)] = {
      t: "n",
      v: totalDataRow.intPssSumOptPreRoAuditUplift,
      s: objProperty,
    };
    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.intPssSumOptPreDiscountUplift
    );
    ws["L" + (this.internalPssDataListCp.length + 6)] = {
      t: "n",
      v: totalDataRow.intPssSumOptPreDiscountUplift,
      s: objProperty,
    };

    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.intPssSumOptPostDiscountUplift
    );

    ws["O" + (this.internalPssDataListCp.length + 6)] = {
      t: "n",
      v: totalDataRow.intPssSumOptPostDiscountUplift,
      s: objProperty,
    };

    objProperty = this.commonService.getCellPropertyObj(
      "46762D",
      "46762D",
      "",
      border,
      alignment,
      font,
      ""
    );
    objProperty["numFmt"] = this.commonService.getDollarFormat(
      totalDataRow.intPssSumFactoryAprovedUplift
    );

    ws["S" + (this.internalPssDataListCp.length + 6)] = {
      t: "n",
      v: totalDataRow.intPssSumFactoryAprovedUplift,
      s: objProperty,
    };
    if (footerFlag) {
      /**
       * Footer Section
       */
      let footerTextPss = this.constantService.EXCEL_FOOTER_DYNAMIC;
      footerTextPss = footerTextPss.replace(
        /{{footerName}}/i,
        this.storeGroup[0].salesPerson ? this.storeGroup[0].salesPerson : ""
      );
      footerTextPss = footerTextPss.replace(
        /{{footerEmail}}/i,
        this.storeGroup[0].spEmail ? this.storeGroup[0].spEmail : ""
      );
      footerTextPss = footerTextPss.replace(
        /{{footerPhone}}/i,
        this.storeGroup[0].spPhone ? this.storeGroup[0].spPhone : ""
      );

      inlineFontProperty = { sz: 12, bold: false };

      const borderFooter: any = {
        top: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
      };
      const borderFooterTimeStamp = {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
      };
      //let borderF =  type === 'cp' ? borderFooter : border;
      let borderF = border;
      objProperty = this.commonService.getCellPropertyObj(
        "FFFFFF",
        "FFFFFF",
        "0b5394",
        borderF,
        alignment,
        "",
        inlineFontProperty
      );

      let projObjFooter = {
        t: "s",
        v: footerTextPss,
        s: objProperty,
      };
      let footerPosition = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
      ];
      let pssDataLength = this.internalPssDataListCp.length + 7;
      for (let footerLoop = 0; footerLoop < footerPosition.length; footerLoop++) {
        ws[footerPosition[footerLoop] + pssDataLength] = projObjFooter;
      }

      /*const alignmentVersionNumber = { wrapText: true, vertical: 'center', horizontal: 'right' };
      let projObjTimestamp = {
        t: 's', v: snapShotCreatedTime ? this.DMConstantService.REPORT_VERSION_LABEL
          + this.commonService.randomVersionNumberGenerator(this.DMConstantService.REPORT_VERSION_FORMAT) : '',
        s: {
          fill: {
            patternType: 'solid',
            fgColor: { rgb: 'FFFFFF' },
            bgColor: { rgb: 'FFFFFF' }
          },
          font: {
            name: 'Arial, Helvetica, sans-serif',
            sz: 12,
            color: { rgb: '0b5394' },
            bold: false,
            italic: false,
            underline: false
          },
          border: borderFooterTimeStamp,
          alignment: alignmentVersionNumber
        }
      };

      ws['T' + (pssDataLength)] = type === 'cp' ? projObjTimestamp : projObjFooter;
      ws['U' + (pssDataLength)] = type === 'cp' ? projObjTimestamp : projObjFooter;
      ws['V' + (pssDataLength)] = type === 'cp' ? projObjTimestamp : projObjFooter;
      ws['W' + (pssDataLength)] = type === 'cp' ? projObjTimestamp : projObjFooter;*/
    }
    /**
     * Data looping section
     */
    inlineFontProperty = { sz: 10, bold: false };
    const headeRange = {
      s: { c: 0, r: 5 },
      e: { c: 22, r: this.internalPssDataListCp.length + 4 },
    };
    let excelCell;
    for (let R = headeRange.s.r; R <= headeRange.e.r; ++R) {
      for (let C = headeRange.s.c; C <= headeRange.e.c; ++C) {
        const cell_address = {
          c: C,
          r: R,
        };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (C < 3) {
          objProperty = this.commonService.getCellPropertyObj(
            "FFFFFF",
            "FFFFFF",
            "#FFFFFF",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: "s",
            s: objProperty,
          };
        } else if (C > 2 && C < 6) {
          objProperty = this.commonService.getCellPropertyObj(
            "EFEFEF",
            "EFEFEF",
            "#EFEFEF",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          if (csvData[R - 1][C] !== null && C === 3) {
            objProperty["numFmt"] = this.commonService.getDollarFormat(csvData[R - 1][C]);
          }
          if (csvData[R - 1][C] !== null && C !== 3) {
            objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          }
          excelCell = {
            v:
              C > 3
                ? csvData[R - 1][C] !== undefined &&
                  csvData[R - 1][C] !== null &&
                  typeof csvData[R - 1][C] !== undefined
                  ? csvData[R - 1][C]
                  : ""
                : csvData[R - 1][C] !== null
                  ? csvData[R - 1][C]
                  : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        } else if (C >= 6 && C < 8) {
          objProperty = this.commonService.getCellPropertyObj(
            "DCEAD6",
            "DCEAD6",
            "#DCEAD6",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          if (csvData[R - 1][C] !== null && C > 6) {
            objProperty["numFmt"] = this.commonService.getDollarFormat(csvData[R - 1][C]);
          }
          if (csvData[R - 1][C] !== null && C === 6) {
            objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          }

          excelCell = {
            v:
              C < 7
                ? csvData[R - 1][C] !== undefined &&
                  csvData[R - 1][C] !== null &&
                  typeof csvData[R - 1][C] !== undefined
                  ? csvData[R - 1][C]
                  : ""
                : csvData[R - 1][C] !== null
                  ? csvData[R - 1][C]
                  : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        } else if (C === 8) {
          objProperty = this.commonService.getCellPropertyObj(
            "D9D9D9",
            "D9D9D9",
            "#D9D9D9",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          excelCell = {
            v:
              csvData[R - 1][C] !== undefined &&
              csvData[R - 1][C] !== null &&
              typeof csvData[R - 1][C] !== undefined
                ? csvData[R - 1][C]
                : "",
            t: "n",
            s: objProperty,
          };
        } else if (C > 8 && C < 11) {
          objProperty = this.commonService.getCellPropertyObj(
            "DCEAD6",
            "DCEAD6",
            "#DCEAD6",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          if (csvData[R - 1][C] !== null && C === 10) {
            objProperty["numFmt"] = this.commonService.getDollarFormat(csvData[R - 1][C]);
          }
          if (csvData[R - 1][C] !== null && C === 9) {
            objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          }
          excelCell = {
            v:
              C < 10
                ? csvData[R - 1][C] !== undefined &&
                  csvData[R - 1][C] !== null &&
                  typeof csvData[R - 1][C] !== undefined
                  ? csvData[R - 1][C]
                  : ""
                : csvData[R - 1][C] !== null
                  ? csvData[R - 1][C]
                  : "",
            t: C < 10 ? "n" : csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        } else if (C === 11) {
          objProperty = this.commonService.getCellPropertyObj(
            "CCCCCC",
            "CCCCCC",
            "#CCCCCC",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          excelCell = {
            v:
              csvData[R - 1][C] !== undefined &&
              csvData[R - 1][C] !== null &&
              typeof csvData[R - 1][C] !== undefined
                ? csvData[R - 1][C]
                : "",
            t: "n",
            s: objProperty,
          };
        } else if (C > 11 && C < 14) {
          objProperty = this.commonService.getCellPropertyObj(
            "DCEAD6",
            "DCEAD6",
            "#DCEAD6",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          if (csvData[R - 1][C] !== null && C === 13) {
            objProperty["numFmt"] = this.commonService.getDollarFormat(csvData[R - 1][C]);
          }
          if (csvData[R - 1][C] !== null && C === 12) {
            objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          }
          excelCell = {
            v:
              C <= 12
                ? csvData[R - 1][C] !== undefined &&
                  csvData[R - 1][C] !== null &&
                  typeof csvData[R - 1][C] !== undefined
                  ? csvData[R - 1][C]
                  : ""
                : csvData[R - 1][C] !== null
                  ? csvData[R - 1][C]
                  : "",
            t: C <= 12 ? "n" : csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        } else if (C === 14) {
          objProperty = this.commonService.getCellPropertyObj(
            "FFFFFF",
            "FFFFFF",
            "#FF0000",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          excelCell = {
            v:
              csvData[R - 1][C] !== undefined &&
              csvData[R - 1][C] !== null &&
              typeof csvData[R - 1][C] !== undefined
                ? csvData[R - 1][C]
                : "",
            t: "n",
            s: objProperty,
          };
        } else if (C >= 15 && C < 17) {
          objProperty = this.commonService.getCellPropertyObj(
            "DCEAD6",
            "DCEAD6",
            "#DCEAD6",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          if (csvData[R - 1][C] !== null && C === 16) {
            objProperty["numFmt"] = this.commonService.getDollarFormat(csvData[R - 1][C]);
          }
          if (csvData[R - 1][C] !== null && C === 15) {
            objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          }
          excelCell = {
            v:
              C <= 16
                ? csvData[R - 1][C] !== null && csvData[R - 1][C] !== "NaN"
                  ? csvData[R - 1][C]
                  : ""
                : csvData[R - 1][C] !== null
                  ? csvData[R - 1][C]
                  : "",
            t: C <= 16 ? "n" : csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        } else if (C === 17) {
          let dateString = "";
          if (csvData[R - 1][C] !== null) {
            dateString = " " + csvData[R - 1][C].toString();
          }
          objProperty = this.commonService.getCellPropertyObj(
            "FFFFFF",
            "FFFFFF",
            "#FFFFFF",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          excelCell = {
            v: csvData[R - 1][C] !== null ? dateString : "",
            t: "s",
            s: objProperty,
          };
        } else if (C === 18) {
          objProperty = this.commonService.getCellPropertyObj(
            "EFEFEF",
            "EFEFEF",
            "#EFEFEF",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          if (csvData[R - 1][C] !== null) {
            objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          }
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        } else if (C === 19) {
          objProperty = this.commonService.getCellPropertyObj(
            R == 5 ? "F2CBCC" : "EFEFEF",
            R == 5 ? "F2CBCC" : "EFEFEF",
            R == 5 ? "#F2CBCC" : "#EFEFEF",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          if (csvData[R - 1][C] !== null && R !== 5) {
            objProperty["numFmt"] = this.commonService.get4DecimalPointFormat(csvData[R - 1][C]);
          }
          excelCell = {
            v: csvData[R - 1][C] !== null && csvData[R - 1][C] !== "NaN" ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] !== null && R !== 5 ? "n" : "s",
            s: objProperty,
          };
        } else if (C === 20) {
          objProperty = this.commonService.getCellPropertyObj(
            "F2CBCC",
            "F2CBCC",
            "#F2CBCC",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          console.log(csvData[R - 1][C], R);
          if (csvData[R - 1][C] !== null && R !== 5) {
            objProperty["numFmt"] = this.commonService.getDollarFormat(csvData[R - 1][C]);
          }
          excelCell = {
            v: csvData[R - 1][C] !== null && csvData[R - 1][C] !== "NaN" ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] !== null && R !== 5 ? "n" : "s",
            s: objProperty,
          };
        } else if (C === 21) {
          objProperty = this.commonService.getCellPropertyObj(
            "DCEAD6",
            "DCEAD6",
            "#DCEAD6",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          objProperty["numFmt"] = this.commonService.getDollarFormat(csvData[R - 1][C]);
          excelCell = {
            v: csvData[R - 1][C] !== null && csvData[R - 1][C] !== "NaN" ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        } else if (C === 22) {
          objProperty = this.commonService.getCellPropertyObj(
            "46762D",
            "46762D",
            "#46762D",
            border,
            alignment,
            "",
            inlineFontProperty
          );
          objProperty["numFmt"] = this.commonService.getDollarFormat(csvData[R - 1][C]);
          excelCell = {
            v: csvData[R - 1][C] !== null && csvData[R - 1][C] !== "NaN" ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: objProperty,
          };
        }
        ws[cell_ref] = excelCell;
      }
    }

    ws["!images"] = [];
    ws["!images"].push({
      name: "Armatus.jpeg",
      data: this.imgToDataUrl(imgData),
      opts: { base64: true },
      type: "jpeg",
      position: {
        type: "twoCellAnchor",
        attrs: { editAs: "oneCell" },
        from: { col: 0, row: 1 },
        to: { col: 1, row: 3 },
      },
    });

    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    const res = { wb: wb, fileName: fileName };
    return res;
  }

  imgToDataUrl(img: CanvasImageSource) {
    // const canvas = document.createElement("canvas");
    // canvas.width = img.naturalWidth; // or 'width' if you want a special/scaled size
    // canvas.height = img.naturalHeight; // or 'height' if you want a special/scaled size
    // canvas.getContext("2d").drawImage(img, 0, 0);
    // return canvas
    //   .toDataURL("image/png")
    //   .replace(/^data:image\/(png|jpg);base64,/, "");
    const canvas: any = document.createElement("canvas");
    if (img instanceof HTMLImageElement) {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      canvas.getContext("2d").drawImage(img, 0, 0);
    }
    // Make sure img is not null before calling toDataURL
    if (img) {
      return canvas.toDataURL("image/png").replace(/^data:image\/(png|jpg);base64,/, "");
    }
  }
}
