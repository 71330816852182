import { Component, Injectable, Input, Output, EventEmitter } from "@angular/core";

@Injectable()
export class EventEmitterService {
  // dataSubscription: EventEmitter<any> = new EventEmitter<any>();
  // displayProgress: EventEmitter<any> = new EventEmitter<any>();
  psProjectUpdate: EventEmitter<any> = new EventEmitter<any>();
  dataSubscription: EventEmitter<any> = new EventEmitter<any>();
  displayProgress: EventEmitter<any> = new EventEmitter<any>();
  displayNotification: EventEmitter<any> = new EventEmitter<any>();
  dataSubscriptionStoreSearcher: EventEmitter<any> = new EventEmitter<any>();
  dataSubscriptionSoloStore: EventEmitter<any> = new EventEmitter<any>();
  refreshScreens: EventEmitter<any> = new EventEmitter<any>();
  dataSubscriptionProjectParts: EventEmitter<any> = new EventEmitter<any>();
  dataSubscriptionProjectLabor: EventEmitter<any> = new EventEmitter<any>();
  toggleAdminAction: EventEmitter<any> = new EventEmitter<any>();
}
