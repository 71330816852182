import { Injectable } from "@angular/core";
// import { Http, Headers, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/map";
// import { Logger } from "angular2-logger/core";
// import { environment } from "environments/environment";
import { ConstantService } from "../constants/constant.service";
import { CommonService } from "../services/common.service";
import * as moment from "moment-timezone";
import { DMConstantService } from "../constants/dm.constant.service";

declare var $: any;
declare var jQuery: any;
declare var XLSX: any;

@Injectable()
export class ProjectionReportService {
  public projectionDataList: any = [];
  public storeGroup: any = [];
  public snapShotVersion!: any[];
  public snapShotData: any;
  constructor(
    // private http: Http,
    // private _logger: Logger,
    private constantService: ConstantService,
    private commonService: CommonService,
    private DMConstantService: DMConstantService
  ) {}
  init() {
    //
  }
  downloadProjectionReport(
    projectionColumnsList: string | any[],
    data: any[],
    storeGroupData: any[],
    totalDataRow: {
      unadjustedApparentUplift: null;
      armatusOptimizedUplift: null;
      factoryAdjustedUplift: null;
      netUpliftYear1: null;
      rOIInMonths: null;
    },
    imgData: any,
    type: string,
    snapShotVersion: any,
    snapShotData: string
  ) {
    const footerFlag = false;
    this.storeGroup = storeGroupData;
    this.projectionDataList = data;
    this.snapShotVersion = snapShotVersion;
    this.snapShotData = snapShotData;
    const csvData: any = [{}, {}, {}];
    csvData.push({
      0: "Store DBA Name",
      1: " Brand",
      2: " State",
      3: " Annual \n Warranty \n Parts Cost of \n Goods Sold",
      4: " Cust. \n Pay \n Parts \n Gross \n Profit %",
      5: " Effective \n Warranty \n Parts \n Gross \n Profit %",
      6: " Cust. \n Pay \n Gross \n Profit % \n as \n Markup",
      7: " Effective \n Warranty \n Parts \n Gross \n Profit % \n as \n Markup",
      8: " Cust. \n Pay vs. \n Warranty \n Markup \n Delta",
      9: " Additional \n Annual \n Warranty \n Parts \n Profit",
      10: " State \n Statute & \n Armatus \n Impact \n Factor",
      11: " Projected \n Warranty \n Parts \n Markup",
      12: " Armatus \n Like \n Branded \n Approval \n Avg.",
      13: " Add'l \n Points \n Above \n Current Eff.\n  Warranty \n Markup",
      14: " Optimized \n Additional \n Ongoing \n Annual \n Warranty \n Parts \n Profit",
      15: " Current \n Likely \n Factory \n Behavior \n Impact",
      16: "Add'l \nPoints \nAbove \nCurrent Eff. \nWarr MU - \nPost Factory\n Behavior",
      17: " Ongoing \n Warranty \n Parts \n Markup \n Adjusted \n for \n Factory \n Behavior",
      18: " Additional \n Ongoing \n Annual \n Warranty \n Parts \n Profit \n Adjusted \n for \n Factory \n Behavior",
      19: " Post \n Approval \n Submission \n Fee*",
      20: " Monthly \n Uplift \n Fee - 12 \n Months \n Only",
      21: " Total \n Profit \n Acquisition \n Cost",
      22: " Net \n Warranty \n Parts \n Additional \n Profit \n Uplift \n Year 1",
      23: " Payback \n Period - ALL \n FEES \n (months)",
    });

    for (const i in this.projectionDataList) {
      if (this.projectionDataList) {
        csvData.push({
          0: this.projectionDataList[i]["stName"] ? this.projectionDataList[i]["stName"] : null,
          1: this.projectionDataList[i]["brand"] ? this.projectionDataList[i]["brand"] : null,
          2: this.projectionDataList[i]["state"] ? this.projectionDataList[i]["state"] : null,
          3:
            this.projectionDataList[i]["annualWarrPartsCost"] != null
              ? this.projectionDataList[i]["annualWarrPartsCost"]
              : null,
          4:
            this.projectionDataList[i]["cpPartsGrossProfit"] != null
              ? this.projectionDataList[i]["cpPartsGrossProfit"]
              : null,
          5:
            this.projectionDataList[i]["effWpGrossProfit"] != null
              ? this.projectionDataList[i]["effWpGrossProfit"]
              : null,
          6:
            this.projectionDataList[i]["cpGpMarkup"] != null
              ? this.projectionDataList[i]["cpGpMarkup"]
              : null,
          7:
            this.projectionDataList[i]["effWpGrossProfitMarkup"] != null
              ? this.projectionDataList[i]["effWpGrossProfitMarkup"]
              : null,
          8:
            this.projectionDataList[i]["cpWarrMarkupDelta"] != null
              ? this.projectionDataList[i]["cpWarrMarkupDelta"]
              : null,
          9:
            this.projectionDataList[i]["addlAnnualWpProfit"] != null
              ? this.projectionDataList[i]["addlAnnualWpProfit"]
              : null,
          10:
            this.projectionDataList[i]["stStatuteAif"] != null
              ? this.projectionDataList[i]["stStatuteAif"]
              : null,
          11:
            this.projectionDataList[i]["projectedWpMarkup"] != null
              ? this.projectionDataList[i]["projectedWpMarkup"]
              : null,
          12:
            this.projectionDataList[i]["alBrandedApprAvg"] != null
              ? this.projectionDataList[i]["alBrandedApprAvg"]
              : null,
          13:
            this.projectionDataList[i]["addlPtsAbvCurrEffWm"] != null
              ? this.projectionDataList[i]["addlPtsAbvCurrEffWm"]
              : null,
          14:
            this.projectionDataList[i]["optAddlOgAwpProfit"] != null
              ? this.projectionDataList[i]["optAddlOgAwpProfit"]
              : null,
          15:
            this.projectionDataList[i]["currLikelyFactBehaviorImpact"] != null
              ? this.projectionDataList[i]["currLikelyFactBehaviorImpact"]
              : null,
          16:
            this.projectionDataList[i]["currentEffWarrMUPostFactBehavior"] != null
              ? this.projectionDataList[i]["currentEffWarrMUPostFactBehavior"]
              : null,
          17:
            this.projectionDataList[i]["ogWpMarkupAdjForFactory"] != null
              ? this.projectionDataList[i]["ogWpMarkupAdjForFactory"]
              : null,
          18:
            this.projectionDataList[i]["addlOgAwpProfitAdjForFactory"] != null
              ? this.projectionDataList[i]["addlOgAwpProfitAdjForFactory"]
              : null,
          19:
            this.projectionDataList[i]["postApprSubmissionFee"] != null
              ? this.projectionDataList[i]["postApprSubmissionFee"]
              : null,
          20:
            this.projectionDataList[i]["monthlyUpliftFee"] != null
              ? this.projectionDataList[i]["monthlyUpliftFee"]
              : null,
          21:
            this.projectionDataList[i]["totalProfitAcqCost"] != null
              ? this.projectionDataList[i]["totalProfitAcqCost"]
              : null,
          22:
            this.projectionDataList[i]["netWpAddlPuYr1"] != null
              ? this.projectionDataList[i]["netWpAddlPuYr1"]
              : null,
          23:
            this.projectionDataList[i]["nomToReturnFullProfitAcqCost"] != null
              ? this.projectionDataList[i]["nomToReturnFullProfitAcqCost"]
              : null,
        });
      }
    }
    let fileName_ws = "";
    let fileName = "";
    let snapShotCreatedTime = null;
    if (type === "cp") {
      let snapShotName = this.snapShotVersion[0].description;
      if (this.snapShotVersion[0].id < 0) {
        snapShotName = this.snapShotData;
      }
      snapShotName = snapShotName + "_";
      fileName = "Projection_" + snapShotName + moment().format("YYYYMMDDhhmmss") + ".xlsx";
      fileName_ws = "Projection_" + moment().format("YYYYMMDDhhmmss");
      snapShotCreatedTime = moment(this.snapShotVersion[0].createdAt).format(
        "MM-DD-YYYY hh:mm:ss A"
      );
    } else {
      fileName = "Projection_" + moment().format("YYYYMMDDhhmmss") + ".xlsx";
      fileName_ws = "Projection_" + moment().format("YYYYMMDDhhmmss");
    }
    const ws_name = fileName_ws;
    const wb = XLSX.utils.book_new();
    wb.Sheets = {};
    wb.SheetNames = [];
    const ws: any = XLSX.utils.json_to_sheet(csvData);
    const wscols = [
      { wch: 25 }, // "characters"
      { wch: 7 },
      { wch: 7 },
      { wch: 11 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 11 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 11 },
      { wch: 7 },
      { wch: 7 },
      { wch: 11 },
      { wch: 11 },
      { wch: 11 },
      { wch: 11 },
      { wch: 11 },
      { wch: 7 },
      { wch: 9 },
    ];
    /* At 96 PPI, 1 pt = 1 px */
    const wsrows = [
      { hpx: 50, level: 1 },
      { hpx: 70, level: 2 },
      { hpx: 25, level: 3 },
      { hpx: 70, level: 4 },
      { hpx: 140, level: 5 },
    ];
    for (let i = 0; i < this.projectionDataList.length + 5; i++) {
      const obj = { hpx: 28, level: i + 6 };
      wsrows.push(obj);
    }

    ws["!cols"] = wscols;
    for (let i = 0; i < projectionColumnsList.length; i++) {
      if (!projectionColumnsList[i]) {
        ws["!cols"][i] = { hidden: true };
      }
    }
    ws["!pageSetup"] = { orientation: "landscape", scale: "100" };
    /**
     * Set worksheet sheet to "narrow".
     */
    ws["!margins"] = {
      left: 0.25,
      right: 0.25,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };
    ws["!merges"] = [
      { s: { r: 0, c: 1 }, e: { r: 0, c: 23 } },
      { s: { r: 1, c: 1 }, e: { r: 1, c: 23 } },
      { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
      { s: { r: 2, c: 3 }, e: { r: 2, c: 6 } },
      { s: { r: 2, c: 7 }, e: { r: 2, c: 16 } },

      { s: { r: 3, c: 0 }, e: { r: 3, c: 0 } },
      { s: { r: 3, c: 1 }, e: { r: 3, c: 1 } },
      { s: { r: 3, c: 2 }, e: { r: 3, c: 2 } },
      { s: { r: 3, c: 3 }, e: { r: 3, c: 7 } },
      { s: { r: 3, c: 8 }, e: { r: 3, c: 9 } },
      { s: { r: 3, c: 10 }, e: { r: 3, c: 18 } },
      { s: { r: 3, c: 19 }, e: { r: 3, c: 23 } },

      {
        s: { r: this.projectionDataList.length + 5, c: 0 },
        e: { r: this.projectionDataList.length + 6, c: 5 },
      },
      {
        s: { r: this.projectionDataList.length + 5, c: 6 },
        e: { r: this.projectionDataList.length + 5, c: 9 },
      },
      {
        s: { r: this.projectionDataList.length + 5, c: 10 },
        e: { r: this.projectionDataList.length + 5, c: 14 },
      },
      {
        s: { r: this.projectionDataList.length + 5, c: 15 },
        e: { r: this.projectionDataList.length + 5, c: 18 },
      },
      {
        s: { r: this.projectionDataList.length + 5, c: 19 },
        e: { r: this.projectionDataList.length + 5, c: 21 },
      },
      {
        s: { r: this.projectionDataList.length + 5, c: 22 },
        e: { r: this.projectionDataList.length + 5, c: 23 },
      },

      {
        s: { r: this.projectionDataList.length + 6, c: 6 },
        e: { r: this.projectionDataList.length + 6, c: 9 },
      },
      {
        s: { r: this.projectionDataList.length + 6, c: 10 },
        e: { r: this.projectionDataList.length + 6, c: 14 },
      },
      {
        s: { r: this.projectionDataList.length + 6, c: 15 },
        e: { r: this.projectionDataList.length + 6, c: 18 },
      },
      {
        s: { r: this.projectionDataList.length + 6, c: 19 },
        e: { r: this.projectionDataList.length + 6, c: 21 },
      },
      {
        s: { r: this.projectionDataList.length + 6, c: 22 },
        e: { r: this.projectionDataList.length + 6, c: 23 },
      },
    ];
    let footerRow: any = "";
    /*let footerTimestampRow:any = '';
    if(type === 'cp') {
      footerRow = { s: { r: (this.projectionDataList.length + 7), c: 0 }, e: { r: (this.projectionDataList.length + 7), c: 19 } };
      footerTimestampRow = { s: { r: (this.projectionDataList.length + 7), c: 20 }, e: { r: (this.projectionDataList.length + 7), c: 23 } };
      ws['!merges'].push(footerRow);
      ws['!merges'].push(footerTimestampRow);
    } else {
      footerRow = { s: { r: (this.projectionDataList.length + 7), c: 0 }, e: { r: (this.projectionDataList.length + 7), c: 23 } };
      ws['!merges'].push(footerRow);
    }*/
    footerRow = {
      s: { r: this.projectionDataList.length + 7, c: 0 },
      e: { r: this.projectionDataList.length + 7, c: 23 },
    };
    ws["!merges"].push(footerRow);
    ws["!rows"] = wsrows;
    ws["!ref"] = "A1:X" + (this.projectionDataList.length + 8);
    const border = {
      top: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    const alignment = {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    };
    let font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: false,
      italic: false,
      underline: false,
    };

    ws["A1"] = { t: "s", v: " " };
    ws["A2"] = { t: "s", v: " " };
    ws["A3"] = { t: "s", v: " " };

    ws["B1"] = {
      t: "s",
      v: this.constantService.EXCEL_HEADING1,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 18,
          color: { rgb: "0b5394" },
          bold: true,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
        alignment: alignment,
      },
    };
    ws["B2"] = {
      t: "s",
      v: this.constantService.EXCEL_HEADING2,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 10,
          color: { rgb: "0b5394" },
          bold: false,
          italic: true,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
        alignment: alignment,
      },
    };

    ws["D3"] = {
      t: "s",
      v: "Group Name:",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: true,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
        alignment: alignment,
      },
    };
    ws["H3"] = {
      t: "s",
      v: this.storeGroup[0].itemName,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: true,
          italic: false,
          underline: false,
        },
        alignment: { wrapText: true, vertical: "center", horizontal: "left" },
      },
    };

    ws["X1"] = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
      },
    };
    ws["X2"] = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
      },
    };
    ws["X3"] = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
      },
    };

    ws["A4"] = { t: "s", v: " " };
    ws["B4"] = { t: "s", v: " " };
    ws["C4"] = { t: "s", v: " " };
    const head1 = {
      t: "s",
      v: this.constantService.EXCEL_HEADING3,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };

    ws["D4"] = head1;
    ws["E4"] = head1;
    ws["F4"] = head1;
    ws["G4"] = head1;
    ws["H4"] = head1;
    const head2 = {
      t: "s",
      v: "*Unadjusted Uplift: \n CP v. WP MU Delta",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "d9d9d9" },
          bgColor: { rgb: "d9d9d9" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };
    ws["I4"] = head2;
    ws["J4"] = head2;

    const head3 = {
      t: "s",
      v: this.constantService.EXCEL_HEADING4,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "cfe2f3" },
          bgColor: { rgb: "cfe2f3" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };
    ws["K4"] = head3;
    ws["L4"] = head3;
    ws["M4"] = head3;
    ws["N4"] = head3;
    ws["O4"] = head3;
    ws["P4"] = head3;
    ws["Q4"] = head3;
    ws["R4"] = head3;
    ws["S4"] = head3;
    const head4 = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          bottom: { style: "thin", color: { auto: 1 } },
        },
      },
    };
    ws["P3"] = head4;
    ws["Q3"] = head4;
    ws["R3"] = head4;
    ws["S3"] = head4;
    ws["T3"] = head4;
    ws["U3"] = head4;
    ws["V3"] = head4;
    ws["B4"] = head4;
    ws["C4"] = head4;
    const head5 = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
      },
    };
    ws["A1"] = head5;
    ws["A2"] = head5;
    ws["A3"] = head5;
    ws["B3"] = head5;
    ws["C3"] = head5;

    let projObj = {
      t: "s",
      v: this.constantService.EXCEL_HEADING5,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "d9ead3" },
          bgColor: { rgb: "d9ead3" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };

    ws["T4"] = projObj;
    ws["U4"] = projObj;
    ws["V4"] = projObj;
    ws["W4"] = projObj;
    ws["X4"] = projObj;

    font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: true,
      italic: false,
      underline: false,
    };
    projObj = {
      t: "s",
      v: this.constantService.EXCEL_HEADING6,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };
    ws["A" + (this.projectionDataList.length + 6)] = projObj;
    ws["A" + (this.projectionDataList.length + 7)] = projObj;
    ws["B" + (this.projectionDataList.length + 7)] = projObj;
    ws["C" + (this.projectionDataList.length + 7)] = projObj;
    ws["D" + (this.projectionDataList.length + 7)] = projObj;
    ws["E" + (this.projectionDataList.length + 7)] = projObj;
    ws["F" + (this.projectionDataList.length + 7)] = projObj;

    projObj = {
      t: "s",
      v: "Unadjusted Apparent Uplift",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "d9d9d9" },
          bgColor: { rgb: "d9d9d9" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };
    ws["G" + (this.projectionDataList.length + 7)] = projObj;
    ws["H" + (this.projectionDataList.length + 7)] = projObj;
    ws["I" + (this.projectionDataList.length + 7)] = projObj;
    ws["J" + (this.projectionDataList.length + 7)] = projObj;

    projObj = {
      t: "s",
      v: "Armatus Optimized Uplift",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "cfe2f3" },
          bgColor: { rgb: "cfe2f3" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };
    ws["K" + (this.projectionDataList.length + 7)] = projObj;
    ws["L" + (this.projectionDataList.length + 7)] = projObj;
    ws["M" + (this.projectionDataList.length + 7)] = projObj;
    ws["N" + (this.projectionDataList.length + 7)] = projObj;
    ws["O" + (this.projectionDataList.length + 7)] = projObj;

    projObj = {
      t: "s",
      v: "Factory Adjusted Uplift",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "cfe2f3" },
          bgColor: { rgb: "cfe2f3" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };
    ws["P" + (this.projectionDataList.length + 7)] = projObj;
    ws["Q" + (this.projectionDataList.length + 7)] = projObj;
    ws["R" + (this.projectionDataList.length + 7)] = projObj;
    ws["S" + (this.projectionDataList.length + 7)] = projObj;

    projObj = {
      t: "s",
      v: "Net Uplift Year 1",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "d9ead3" },
          bgColor: { rgb: "d9ead3" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };
    ws["T" + (this.projectionDataList.length + 7)] = projObj;
    ws["U" + (this.projectionDataList.length + 7)] = projObj;
    ws["V" + (this.projectionDataList.length + 7)] = projObj;

    projObj = {
      t: "s",
      v: "Payback Period",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "d9ead3" },
          bgColor: { rgb: "d9ead3" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };
    ws["W" + (this.projectionDataList.length + 7)] = projObj;
    ws["X" + (this.projectionDataList.length + 7)] = projObj;

    ws["G" + (this.projectionDataList.length + 6)] = {
      t: totalDataRow.unadjustedApparentUplift !== null ? "n" : "s",
      v:
        totalDataRow.unadjustedApparentUplift !== null ? totalDataRow.unadjustedApparentUplift : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "d9d9d9" },
          bgColor: { rgb: "d9d9d9" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: totalDataRow.unadjustedApparentUplift
          ? this.commonService.getDollarFormat(totalDataRow.unadjustedApparentUplift)
          : "",
      },
    };
    ws["K" + (this.projectionDataList.length + 6)] = {
      t: totalDataRow.armatusOptimizedUplift !== null ? "n" : "s",
      v: totalDataRow.armatusOptimizedUplift !== null ? totalDataRow.armatusOptimizedUplift : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "cfe2f3" },
          bgColor: { rgb: "cfe2f3" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: totalDataRow.armatusOptimizedUplift
          ? this.commonService.getDollarFormat(totalDataRow.armatusOptimizedUplift)
          : "",
      },
    };
    ws["P" + (this.projectionDataList.length + 6)] = {
      t: totalDataRow.factoryAdjustedUplift !== null ? "n" : "s",
      v: totalDataRow.factoryAdjustedUplift !== null ? totalDataRow.factoryAdjustedUplift : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "cfe2f3" },
          bgColor: { rgb: "cfe2f3" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: totalDataRow.factoryAdjustedUplift
          ? this.commonService.getDollarFormat(totalDataRow.factoryAdjustedUplift)
          : "",
      },
    };

    ws["T" + (this.projectionDataList.length + 6)] = {
      t: totalDataRow.netUpliftYear1 !== null ? "n" : "s",
      v: totalDataRow.netUpliftYear1 !== null ? totalDataRow.netUpliftYear1 : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "d9ead3" },
          bgColor: { rgb: "d9ead3" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: totalDataRow.netUpliftYear1
          ? this.commonService.getDollarFormat(totalDataRow.netUpliftYear1)
          : "",
      },
    };
    const projObj1 = {
      t: totalDataRow.rOIInMonths !== null ? "n" : "s",
      v: totalDataRow.rOIInMonths !== null ? totalDataRow.rOIInMonths : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "d9ead3" },
          bgColor: { rgb: "d9ead3" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt:
          totalDataRow.rOIInMonths != null
            ? this.commonService.get2DecimalPointFormat(totalDataRow.rOIInMonths)
            : "",
      },
    };
    ws["W" + (this.projectionDataList.length + 6)] = projObj1;
    ws["X" + (this.projectionDataList.length + 6)] = projObj1;
    let obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFFFF" },
        bgColor: { rgb: "FFFFFF" },
      },
      font: font,
      border: border,
      alignment: alignment,
    };
    ws["A5"].s = obj;
    ws["B5"].s = obj;
    ws["C5"].s = obj;
    font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 9,
      color: { rgb: "#FF0000" },
      bold: false,
      italic: false,
      underline: false,
    };
    obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFFFF" },
        bgColor: { rgb: "FFFFFF" },
      },
      font: font,
      border: border,
      alignment: alignment,
    };

    ws["D5"].s = obj;
    ws["E5"].s = obj;
    ws["F5"].s = obj;
    ws["G5"].s = obj;
    ws["H5"].s = obj;

    obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "d9d9d9" },
        bgColor: { rgb: "d9d9d9" },
      },
      font: font,
      border: border,
      alignment: alignment,
    };

    ws["I5"].s = obj;
    ws["J5"].s = obj;
    obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "cfe2f3" },
        bgColor: { rgb: "cfe2f3" },
      },
      font: font,
      border: border,
      alignment: alignment,
    };

    ws["K5"].s = obj;
    ws["L5"].s = obj;
    ws["M5"].s = obj;
    ws["N5"].s = obj;
    ws["O5"].s = obj;
    ws["P5"].s = obj;
    ws["Q5"].s = obj;
    ws["R5"].s = obj;
    ws["S5"].s = obj;
    obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "f4cccc" },
        bgColor: { rgb: "f4cccc" },
      },
      font: font,
      border: border,
      alignment: alignment,
    };
    ws["P5"].s = obj;
    obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "d9ead3" },
        bgColor: { rgb: "d9ead3" },
      },
      font: font,
      border: border,
      alignment: alignment,
    };
    ws["T5"].s = obj;
    ws["U5"].s = obj;
    ws["V5"].s = obj;
    ws["W5"].s = obj;
    ws["X5"].s = obj;
    if (footerFlag) {
      let footerText = this.constantService.EXCEL_FOOTER_DYNAMIC;
      footerText = footerText.replace(
        /{{footerName}}/i,
        this.storeGroup[0].salesPerson ? this.storeGroup[0].salesPerson : ""
      );
      footerText = footerText.replace(
        /{{footerEmail}}/i,
        this.storeGroup[0].spEmail ? this.storeGroup[0].spEmail : ""
      );
      footerText = footerText.replace(
        /{{footerPhone}}/i,
        this.storeGroup[0].spPhone ? this.storeGroup[0].spPhone : ""
      );
      const borderFooter: any = {
        top: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
      };
      const borderFooterTimeStamp = {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
      };
      const projObjFooter = {
        t: "s",
        v: footerText,
        s: {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
            bgColor: { rgb: "FFFFFF" },
          },
          font: {
            name: "Arial, Helvetica, sans-serif",
            sz: 12,
            color: { rgb: "0b5394" },
            bold: false,
            italic: false,
            underline: false,
          },
          border: type === "cp" ? borderFooter : border,
          alignment: alignment,
        },
      };
      ws["A" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["B" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["C" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["D" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["E" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["F" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["G" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["H" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["I" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["J" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["K" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["L" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["M" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["N" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["O" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["P" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["Q" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["R" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["S" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["T" + (this.projectionDataList.length + 8)] = projObjFooter;
      const alignmentVersionNumber = {
        wrapText: true,
        vertical: "center",
        horizontal: "right",
      };
      let projObjTimestamp = {
        t: "s",
        v: snapShotCreatedTime
          ? this.DMConstantService.REPORT_VERSION_LABEL +
            this.commonService.randomVersionNumberGenerator(
              this.DMConstantService.REPORT_VERSION_FORMAT
            )
          : "",
        s: {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
            bgColor: { rgb: "FFFFFF" },
          },
          font: {
            name: "Arial, Helvetica, sans-serif",
            sz: 12,
            color: { rgb: "0b5394" },
            bold: false,
            italic: false,
            underline: false,
          },
          border: borderFooterTimeStamp,
          alignment: alignmentVersionNumber,
        },
      };

      ws["U" + (this.projectionDataList.length + 8)] =
        type === "cp" ? projObjTimestamp : projObjFooter;
      ws["V" + (this.projectionDataList.length + 8)] =
        type === "cp" ? projObjTimestamp : projObjFooter;
      ws["W" + (this.projectionDataList.length + 8)] =
        type === "cp" ? projObjTimestamp : projObjFooter;
      ws["X" + (this.projectionDataList.length + 8)] =
        type === "cp" ? projObjTimestamp : projObjFooter;
    }
    const headeRange = {
      s: { c: 0, r: 5 },
      e: { c: 23, r: this.projectionDataList.length + 4 },
    };
    let excelCell;
    for (let R = headeRange.s.r; R <= headeRange.e.r; ++R) {
      for (let C = headeRange.s.c; C <= headeRange.e.c; ++C) {
        const cell_address = {
          c: C,
          r: R,
        };
        /* if an A1-style address is needed, encode the address */
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (C < 3) {
          excelCell = {
            v: csvData[R - 1][C],
            t: "s",
            s: {
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C > 2 && C < 8) {
          excelCell = {
            v:
              C > 3
                ? csvData[R - 1][C] !== null
                  ? csvData[R - 1][C] / 100
                  : ""
                : csvData[R - 1][C] !== null
                  ? csvData[R - 1][C]
                  : "",
            t: C > 2 ? (csvData[R - 1][C] !== null ? "n" : "s") : "s",
            s: {
              numFmt: C > 3 ? "0.00%" : this.commonService.getDollarFormat(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C >= 8 && C < 10) {
          excelCell = {
            v:
              C === 8
                ? csvData[R - 1][C] !== null
                  ? csvData[R - 1][C] / 100
                  : ""
                : csvData[R - 1][C] !== null
                  ? csvData[R - 1][C]
                  : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: {
              numFmt: C === 8 ? "0.00%" : this.commonService.getDollarFormat(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFdbdbdb" },
                bgColor: { rgb: "FFdbdbdb" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C >= 10 && C < 19) {
          excelCell = {
            v:
              C === 14 || C === 18
                ? csvData[R - 1][C] !== null
                  ? csvData[R - 1][C]
                  : ""
                : csvData[R - 1][C] !== null
                  ? csvData[R - 1][C] / 100
                  : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: {
              numFmt:
                C === 14 || C === 18
                  ? this.commonService.getDollarFormat(csvData[R - 1][C])
                  : "0.00%",
              fill: {
                patternType: "solid",
                fgColor:
                  C === 15
                    ? csvData[R - 1][C] !== null && csvData[R - 1][C] / 100 < 0
                      ? { rgb: "f4cccc" }
                      : { rgb: "FFFFFF" }
                    : { rgb: "cfe2f3" },
                bgColor:
                  C === 15
                    ? csvData[R - 1][C] !== null && csvData[R - 1][C] / 100 < 0
                      ? { rgb: "f4cccc" }
                      : { rgb: "FFFFFF" }
                    : { rgb: "cfe2f3" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else {
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: {
              numFmt: C === 23 ? "0.00" : this.commonService.getDollarFormat(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: "d9ead3" },
                bgColor: { rgb: "d9ead3" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        }

        ws[cell_ref] = excelCell;
      }
    }
    ws["!images"] = [];
    ws["!images"].push({
      name: "Armatus.jpeg",
      data: this.imgToDataUrl(imgData),
      opts: { base64: true },
      type: "jpeg",
      position: {
        type: "twoCellAnchor",
        attrs: { editAs: "oneCell" },
        from: { col: 0, row: 1 },
        to: { col: 1, row: 3 },
      },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    const op = { wb: wb, fileName: fileName };
    return op;
  }

  imgToDataUrl(img: any) {
    // const canvas = document.createElement("canvas");
    // canvas.width = img.naturalWidth; // or 'width' if you want a special/scaled size
    // canvas.height = img.naturalHeight; // or 'height' if you want a special/scaled size
    // canvas.getContext("2d").drawImage(img, 0, 0);
    // return canvas
    //   .toDataURL("image/png")
    //   .replace(/^data:image\/(png|jpg);base64,/, "");
    const canvas: any = document.createElement("canvas");
    if (img instanceof HTMLImageElement) {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      canvas.getContext("2d").drawImage(img, 0, 0);
    }
    // Make sure img is not null before calling toDataURL
    if (img) {
      return canvas.toDataURL("image/png").replace(/^data:image\/(png|jpg);base64,/, "");
    }
  }
}
