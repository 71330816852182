import { Injectable } from "@angular/core";
// import { Http, Headers, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/map";
// import { Logger } from "angular2-logger/core";
// import { environment } from "environments/environment";
import { ConstantService } from "../constants/constant.service";
import { DMConstantService } from "../constants/dm.constant.service";

import { CommonService } from "../services/common.service";
import * as moment from "moment-timezone";

declare var $: any;
declare var jQuery: any;
declare var XLSX: any;

@Injectable()
export class PreAuditLaborReportService {
  public preAuditLaborReportDataList: any = [];
  public storeGroup: any = [];
  public snapShotVersion!: any[];
  public snapShotData: any;
  constructor(
    // private http: Http,
    // private _logger: Logger,
    private constantService: ConstantService,
    private commonService: CommonService,
    private DMConstantService: DMConstantService
  ) {}
  init() {
    //
  }
  downloadPreAuditLaborReport(
    data: any[],
    storeGroupData: any[],
    totalDataRow: {
      AdditionalMonthlyProfit: any;
      AdditionalAnnualProfit: any;
      CostToSubmitYear1Only: any;
      UpliftShareYear1Only: any;
      TotalProfitAcquisitionCost: any;
      NetProfitUpliftYear: any;
      ROIMonths: any;
      FiveYearProfitImpact: any;
      OngoingProfitUpliftYear2: any;
      TenYearProfitImpact: any;
    },
    imgData: any,
    type: string,
    snapShotVersion: any,
    snapShotData: string
  ) {
    const footerFlag = false;
    this.storeGroup = storeGroupData;
    this.preAuditLaborReportDataList = data;
    this.snapShotVersion = snapShotVersion;
    this.snapShotData = snapShotData;
    const csvData: any = [{}, {}, {}];
    csvData.push({
      0: "Store DBA Name",
      1: " Brand",
      2: " State",
      3: " Warranty Labor & PDI Hours per Month",
      4: " Warranty Labor Rate",
      5: " Uplift Viable?",
      6: " Best Warranty Labor Rate Achievable from Store Raw Data",
      7: " Labor Current Stage",
      8: " Additional Hourly Rate",
      9: " Additional Monthly Profit",
      10: " Additional Annual Profit",
      11: " Cost to Submit (Year 1 Only)",
      12: " Uplift Share (Year 1 Only)",
      13: " Total Profit Acquisition Cost (Year 1 Only)",
      14: " Net Profit Uplift Year 1",
      15: " Payback Period",
      16: " Ongoing Profit Uplift Year 2 +",
      17: " 5 Year Profit Impact",
      18: " 10 Year Profit Impact",
    });

    for (const i in this.preAuditLaborReportDataList) {
      if (this.preAuditLaborReportDataList) {
        csvData.push({
          0: this.preAuditLaborReportDataList[i]["stName"]
            ? this.preAuditLaborReportDataList[i]["stName"]
            : null,
          1: this.preAuditLaborReportDataList[i]["brand"]
            ? this.preAuditLaborReportDataList[i]["brand"]
            : null,
          2: this.preAuditLaborReportDataList[i]["state"]
            ? this.preAuditLaborReportDataList[i]["state"]
            : null,
          3:
            this.preAuditLaborReportDataList[i]["warrLbrPdiHrsPerMonth"] != null
              ? this.preAuditLaborReportDataList[i]["warrLbrPdiHrsPerMonth"]
              : null,
          4:
            this.preAuditLaborReportDataList[i]["warrLbrRate"] != null
              ? this.preAuditLaborReportDataList[i]["warrLbrRate"]
              : null,
          5:
            this.preAuditLaborReportDataList[i]["isUpliftViable"] != null
              ? this.preAuditLaborReportDataList[i]["isUpliftViable"]
              : null,
          6:
            this.preAuditLaborReportDataList[i]["bestCurrWarrRate"] != null
              ? this.preAuditLaborReportDataList[i]["bestCurrWarrRate"]
              : null,
          7:
            this.preAuditLaborReportDataList[i]["laborCurrStage"] != null
              ? this.preAuditLaborReportDataList[i]["laborCurrStage"]
              : null,
          8:
            this.preAuditLaborReportDataList[i]["addlHourlyRate"] != null
              ? this.preAuditLaborReportDataList[i]["addlHourlyRate"]
              : null,
          9:
            this.preAuditLaborReportDataList[i]["addlMonthlyProfit"] != null
              ? this.preAuditLaborReportDataList[i]["addlMonthlyProfit"]
              : null,
          10:
            this.preAuditLaborReportDataList[i]["addlAnnualProfit"] != null
              ? this.preAuditLaborReportDataList[i]["addlAnnualProfit"]
              : null,
          11:
            this.preAuditLaborReportDataList[i]["costToSubmit"] != null
              ? this.preAuditLaborReportDataList[i]["costToSubmit"]
              : null,
          12:
            this.preAuditLaborReportDataList[i]["upliftShare"] != null
              ? this.preAuditLaborReportDataList[i]["upliftShare"]
              : null,
          13:
            this.preAuditLaborReportDataList[i]["totalProfitAcq"] != null
              ? this.preAuditLaborReportDataList[i]["totalProfitAcq"]
              : null,
          14:
            this.preAuditLaborReportDataList[i]["netProfitUpliftFirstYr"] != null
              ? this.preAuditLaborReportDataList[i]["netProfitUpliftFirstYr"]
              : null,
          15:
            this.preAuditLaborReportDataList[i]["roiMonths"] != null
              ? this.preAuditLaborReportDataList[i]["roiMonths"]
              : null,
          16:
            this.preAuditLaborReportDataList[i]["ongoingProfitUpliftYr2Plus"] != null
              ? this.preAuditLaborReportDataList[i]["ongoingProfitUpliftYr2Plus"]
              : null,
          17:
            this.preAuditLaborReportDataList[i]["profitImpactFiveYear"] != null
              ? this.preAuditLaborReportDataList[i]["profitImpactFiveYear"]
              : null,
          18:
            this.preAuditLaborReportDataList[i]["profitImpactTenYear"] != null
              ? this.preAuditLaborReportDataList[i]["profitImpactTenYear"]
              : null,
        });
      }
    }
    let fileName_ws = "";
    let fileName = "";
    let snapShotCreatedTime = null;
    if (type === "cp") {
      let snapShotName = this.snapShotVersion[0].description;
      if (this.snapShotVersion[0].id < 0) {
        snapShotName = this.snapShotData;
      }
      snapShotName = snapShotName + "_";
      fileName = "PreAuditLabor_" + snapShotName + moment().format("YYYYMMDDhhmmss") + ".xlsx";
      fileName_ws = "PreAuditLabor_" + moment().format("YYYYMMDDhhmmss");
      //snapShotCreatedTime = moment(this.snapShotVersion[0].createdAt).format('MM-DD-YYYY hh:mm:ss A');
    } else {
      fileName = "PreAuditLabor_" + moment().format("YYYYMMDDhhmmss") + ".xlsx";
      fileName_ws = "PreAuditLabor_" + moment().format("YYYYMMDDhhmmss");
    }
    const ws_name = fileName_ws;
    const wb = XLSX.utils.book_new();
    wb.Sheets = {};
    wb.SheetNames = [];
    const ws: any = XLSX.utils.json_to_sheet(csvData);
    const wscols = [
      { wch: 25 }, // "characters"
      { wch: 7 },
      { wch: 7 },
      { wch: 11 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 11 },
      { wch: 11 },
      { wch: 11 },
      { wch: 7 },
      { wch: 7 },
      { wch: 11 },
      { wch: 11 },
      { wch: 11 },
      { wch: 11 },
      { wch: 11 },
    ];
    /* At 96 PPI, 1 pt = 1 px */
    const wsrows = [
      { hpx: 50, level: 1 },
      { hpx: 70, level: 2 },
      { hpx: 25, level: 3 },
      { hpx: 70, level: 4 },
      { hpx: 140, level: 5 },
    ];
    for (let i = 0; i < this.preAuditLaborReportDataList.length + 5; i++) {
      const obj = { hpx: 28, level: i + 6 };
      wsrows.push(obj);
    }

    ws["!cols"] = wscols;
    ws["!pageSetup"] = { orientation: "landscape", scale: "100" };
    /**
     * Set worksheet sheet to "narrow".
     */
    ws["!margins"] = {
      left: 0.25,
      right: 0.25,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };
    ws["!merges"] = [
      { s: { r: 0, c: 1 }, e: { r: 0, c: 18 } },
      { s: { r: 1, c: 1 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
      { s: { r: 2, c: 3 }, e: { r: 2, c: 18 } },

      { s: { r: 3, c: 0 }, e: { r: 3, c: 0 } },
      { s: { r: 3, c: 1 }, e: { r: 3, c: 1 } },
      { s: { r: 3, c: 2 }, e: { r: 3, c: 2 } },
      { s: { r: 3, c: 3 }, e: { r: 3, c: 4 } },
      { s: { r: 3, c: 5 }, e: { r: 3, c: 18 } },

      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 0 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 8 },
      },
      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 9 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 9 },
      },
      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 10 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 10 },
      },
      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 11 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 11 },
      },
      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 12 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 12 },
      },
      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 13 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 13 },
      },
      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 14 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 14 },
      },
      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 15 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 15 },
      },
      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 16 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 16 },
      },
      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 17 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 17 },
      },
      {
        s: { r: this.preAuditLaborReportDataList.length + 5, c: 18 },
        e: { r: this.preAuditLaborReportDataList.length + 5, c: 18 },
      },
    ];
    if (footerFlag) {
      let footerRow: any = "";
      let footerTimestampRow: any = "";
      /*if (type === 'cp') {
        footerRow = {
          s: { r: (this.preAuditLaborReportDataList.length + 6), c: 0 },
          e: { r: (this.preAuditLaborReportDataList.length + 6), c: 14 }
        };
        footerTimestampRow = {
          s: {
            r: (this.preAuditLaborReportDataList.length + 6),
            c: 15
          }, e: { r: (this.preAuditLaborReportDataList.length + 6), c: 18 }
        };
        ws['!merges'].push(footerRow);
        ws['!merges'].push(footerTimestampRow);
      } else {
        footerRow = {
          s: {
            r: (this.preAuditLaborReportDataList.length + 6),
            c: 0
          }, e: { r: (this.preAuditLaborReportDataList.length + 6), c: 18 }
        };
        ws['!merges'].push(footerRow);
      }*/
      footerRow = {
        s: {
          r: this.preAuditLaborReportDataList.length + 6,
          c: 0,
        },
        e: { r: this.preAuditLaborReportDataList.length + 6, c: 18 },
      };
      ws["!merges"].push(footerRow);
    }
    ws["!rows"] = wsrows;
    ws["!ref"] = "A1:S" + (this.preAuditLaborReportDataList.length + 7);
    const border = {
      top: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    const alignment = {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    };
    let font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: false,
      italic: false,
      underline: false,
    };
    ws["A1"] = { t: "s", v: " " };
    ws["A2"] = { t: "s", v: " " };
    ws["A3"] = { t: "s", v: " " };
    let topHead = this.DMConstantService.PRE_AUDIT_LABOR_REPORT_LABELS.EXCEL_TOP_HEAD;
    topHead = topHead.replace(
      /{{groupName}}/i,
      this.storeGroup[0].itemName ? this.storeGroup[0].itemName : ""
    );
    ws["B1"] = {
      t: "s",
      v: topHead,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 18,
          color: { rgb: "0b5394" },
          bold: true,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
        alignment: alignment,
      },
    };
    ws["B2"] = {
      t: "s",
      v: this.DMConstantService.PRE_AUDIT_LABOR_REPORT_LABELS.EXCEL_HEADING,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 10,
          color: { rgb: "0b5394" },
          bold: false,
          italic: true,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
        alignment: alignment,
      },
    };
    ws["S1"] = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
      },
    };
    ws["S2"] = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
      },
    };
    ws["S3"] = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
      },
    };

    ws["A4"] = { t: "s", v: " " };
    ws["B4"] = { t: "s", v: " " };
    ws["C4"] = { t: "s", v: " " };
    const head1 = {
      t: "s",
      v: this.DMConstantService.PRE_AUDIT_LABOR_REPORT_LABELS.EXCEL_ROW_HEAD1,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: true,
          italic: false,
          underline: false,
        },
        border: border,
        alignment: alignment,
      },
    };
    ws["D4"] = head1;
    ws["E4"] = head1;
    const head2 = {
      t: "s",
      v: this.DMConstantService.PRE_AUDIT_LABOR_REPORT_LABELS.EXCEL_ROW_HEAD2,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: true,
          italic: false,
          underline: false,
        },
        border: border,
        alignment: alignment,
      },
    };
    ws["F4"] = head2;
    ws["G4"] = head2;
    ws["H4"] = head2;
    ws["I4"] = head2;
    ws["J4"] = head2;
    ws["K4"] = head2;
    ws["L4"] = head2;
    ws["M4"] = head2;
    ws["N4"] = head2;
    ws["O4"] = head2;
    ws["P4"] = head2;
    ws["Q4"] = head2;
    ws["R4"] = head2;
    ws["S4"] = head2;
    const head4 = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          bottom: { style: "thin", color: { auto: 1 } },
        },
      },
    };
    ws["P3"] = head4;
    ws["Q3"] = head4;
    ws["R3"] = head4;
    ws["B4"] = head4;
    ws["C4"] = head4;
    const head5 = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial, Helvetica, sans-serif",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
      },
    };
    ws["A1"] = head5;
    ws["A2"] = head5;
    ws["A3"] = head5;
    ws["B3"] = head5;
    ws["C3"] = head5;

    font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: true,
      italic: false,
      underline: false,
    };
    let projObj = {
      t: "s",
      v: this.DMConstantService.PRE_AUDIT_LABOR_REPORT_LABELS.EXCEL_TOTAL_LABEL,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };
    ws["A" + (this.preAuditLaborReportDataList.length + 6)] = projObj;
    ws["B" + (this.preAuditLaborReportDataList.length + 6)] = projObj;
    ws["C" + (this.preAuditLaborReportDataList.length + 6)] = projObj;
    ws["D" + (this.preAuditLaborReportDataList.length + 6)] = projObj;
    ws["E" + (this.preAuditLaborReportDataList.length + 6)] = projObj;
    ws["F" + (this.preAuditLaborReportDataList.length + 6)] = projObj;
    ws["G" + (this.preAuditLaborReportDataList.length + 6)] = projObj;
    ws["H" + (this.preAuditLaborReportDataList.length + 6)] = projObj;
    ws["I" + (this.preAuditLaborReportDataList.length + 6)] = projObj;
    ws["J" + (this.preAuditLaborReportDataList.length + 6)] = {
      t: "n",
      v: totalDataRow.AdditionalMonthlyProfit ? totalDataRow.AdditionalMonthlyProfit : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "DBEAD5" },
          bgColor: { rgb: "DBEAD5" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: this.commonService.getDollarFormat(totalDataRow.AdditionalMonthlyProfit),
      },
    };
    ws["K" + (this.preAuditLaborReportDataList.length + 6)] = {
      t: "n",
      v: totalDataRow.AdditionalAnnualProfit ? totalDataRow.AdditionalAnnualProfit : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "DBEAD5" },
          bgColor: { rgb: "DBEAD5" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: this.commonService.getDollarFormat(totalDataRow.AdditionalAnnualProfit),
      },
    };
    ws["L" + (this.preAuditLaborReportDataList.length + 6)] = {
      t: "n",
      v: totalDataRow.CostToSubmitYear1Only ? totalDataRow.CostToSubmitYear1Only : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: this.commonService.getDollarFormat(totalDataRow.CostToSubmitYear1Only),
      },
    };

    ws["M" + (this.preAuditLaborReportDataList.length + 6)] = {
      t: "n",
      v: totalDataRow.UpliftShareYear1Only ? totalDataRow.UpliftShareYear1Only : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: this.commonService.getDollarFormat(totalDataRow.UpliftShareYear1Only),
      },
    };

    ws["N" + (this.preAuditLaborReportDataList.length + 6)] = {
      t: "n",
      v: totalDataRow.TotalProfitAcquisitionCost ? totalDataRow.TotalProfitAcquisitionCost : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: this.commonService.getDollarFormat(totalDataRow.TotalProfitAcquisitionCost),
      },
    };
    ws["O" + (this.preAuditLaborReportDataList.length + 6)] = {
      t: "n",
      v: totalDataRow.NetProfitUpliftYear ? totalDataRow.NetProfitUpliftYear : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: this.commonService.getDollarFormat(totalDataRow.NetProfitUpliftYear),
      },
    };

    ws["P" + (this.preAuditLaborReportDataList.length + 6)] = {
      t: "n",
      v: totalDataRow.ROIMonths ? totalDataRow.ROIMonths : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: this.commonService.get2DecimalPointFormat(totalDataRow.ROIMonths),
      },
    };

    ws["Q" + (this.preAuditLaborReportDataList.length + 6)] = {
      t: "n",
      v: totalDataRow.FiveYearProfitImpact ? totalDataRow.OngoingProfitUpliftYear2 : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: this.commonService.getDollarFormat(totalDataRow.OngoingProfitUpliftYear2),
      },
    };
    ws["R" + (this.preAuditLaborReportDataList.length + 6)] = {
      t: "n",
      v: totalDataRow.FiveYearProfitImpact ? totalDataRow.FiveYearProfitImpact : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: this.commonService.getDollarFormat(totalDataRow.FiveYearProfitImpact),
      },
    };
    ws["S" + (this.preAuditLaborReportDataList.length + 6)] = {
      t: "n",
      v: totalDataRow.TenYearProfitImpact ? totalDataRow.TenYearProfitImpact : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignment,
        numFmt: this.commonService.getDollarFormat(totalDataRow.TenYearProfitImpact),
      },
    };
    let obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFFFF" },
        bgColor: { rgb: "FFFFFF" },
      },
      font: font,
      border: border,
      alignment: alignment,
    };
    ws["A5"].s = obj;
    ws["B5"].s = obj;
    ws["C5"].s = obj;
    font = {
      name: "Arial, Helvetica, sans-serif",
      sz: 9,
      color: { rgb: "#FF0000" },
      bold: false,
      italic: false,
      underline: false,
    };
    obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFFFF" },
        bgColor: { rgb: "FFFFFF" },
      },
      font: font,
      border: border,
      alignment: alignment,
    };

    ws["D5"].s = obj;
    ws["E5"].s = obj;
    ws["F5"].s = obj;
    ws["G5"].s = obj;
    ws["H5"].s = obj;
    ws["I5"].s = obj;
    ws["J5"].s = obj;
    ws["K5"].s = obj;
    ws["M5"].s = obj;
    ws["N5"].s = obj;
    ws["O5"].s = obj;
    ws["P5"].s = obj;
    ws["Q5"].s = obj;
    ws["R5"].s = obj;
    ws["S5"].s = obj;
    const fontL5 = {
      name: "Arial, Helvetica, sans-serif",
      sz: 9,
      color: { rgb: "FFFFFF" },
      bold: false,
      italic: false,
      underline: false,
    };
    const objL5 = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFFFF" },
        bgColor: { rgb: "FFFFFF" },
      },
      font: font,
      border: border,
      alignment: alignment,
    };
    ws["L5"].s = objL5;
    if (footerFlag) {
      let footerText = this.constantService.EXCEL_FOOTER_DYNAMIC;
      footerText = footerText.replace(
        /{{footerName}}/i,
        this.storeGroup[0].salesPerson ? this.storeGroup[0].salesPerson : ""
      );
      footerText = footerText.replace(
        /{{footerEmail}}/i,
        this.storeGroup[0].spEmail ? this.storeGroup[0].spEmail : ""
      );
      footerText = footerText.replace(
        /{{footerPhone}}/i,
        this.storeGroup[0].spPhone ? this.storeGroup[0].spPhone : ""
      );
      const borderFooter: any = {
        top: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
      };
      const borderFooterTimeStamp = {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
      };
      const projObjFooter = {
        t: "s",
        v: footerText,
        s: {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
            bgColor: { rgb: "FFFFFF" },
          },
          font: {
            name: "Arial, Helvetica, sans-serif",
            sz: 12,
            color: { rgb: "0b5394" },
            bold: false,
            italic: false,
            underline: false,
          },
          //border: type === 'cp' ? borderFooter : border,
          border: border,
          alignment: alignment,
        },
      };
      ws["A" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["B" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["C" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["D" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["E" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["F" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["G" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["H" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["I" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["J" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["K" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["L" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["M" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["N" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["O" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["P" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["Q" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["R" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
      ws["S" + (this.preAuditLaborReportDataList.length + 7)] = projObjFooter;
    }
    /*const alignmentVersionNumber = { wrapText: true, vertical: 'center', horizontal: 'right' };
    let projObjTimestamp = {
      t: 's', v: snapShotCreatedTime ? this.DMConstantService.REPORT_VERSION_LABEL
        + this.commonService.randomVersionNumberGenerator(this.DMConstantService.REPORT_VERSION_FORMAT) : '',
      s: {
        fill: {
          patternType: 'solid',
          fgColor: { rgb: 'FFFFFF' },
          bgColor: { rgb: 'FFFFFF' }
        },
        font: {
          name: 'Arial, Helvetica, sans-serif',
          sz: 12,
          color: { rgb: '0b5394' },
          bold: false,
          italic: false,
          underline: false
        },
        border: borderFooterTimeStamp,
        alignment: alignmentVersionNumber
      }
    };
    ws['N' + (this.preAuditLaborReportDataList.length + 7)] = type === 'cp' ? projObjTimestamp : projObjFooter;
    ws['O' + (this.preAuditLaborReportDataList.length + 7)] = type === 'cp' ? projObjTimestamp : projObjFooter;
    ws['P' + (this.preAuditLaborReportDataList.length + 7)] = type === 'cp' ? projObjTimestamp : projObjFooter;
    ws['Q' + (this.preAuditLaborReportDataList.length + 7)] = type === 'cp' ? projObjTimestamp : projObjFooter;
    ws['R' + (this.preAuditLaborReportDataList.length + 7)] = type === 'cp' ? projObjTimestamp : projObjFooter;
    ws['S' + (this.preAuditLaborReportDataList.length + 7)] = type === 'cp' ? projObjTimestamp : projObjFooter;*/

    const headeRange = {
      s: { c: 0, r: 5 },
      e: { c: 18, r: this.preAuditLaborReportDataList.length + 4 },
    };
    let excelCell;
    for (let R = headeRange.s.r; R <= headeRange.e.r; ++R) {
      for (let C = headeRange.s.c; C <= headeRange.e.c; ++C) {
        const cell_address = {
          c: C,
          r: R,
        };
        /* if an A1-style address is needed, encode the address */
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (C < 3) {
          excelCell = {
            v: csvData[R - 1][C],
            t: "s",
            s: {
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C === 3) {
          excelCell = {
            v: csvData[R - 1][C] != null ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] != null ? "n" : "s",
            s: {
              numFmt: "",
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C == 4) {
          excelCell = {
            v: csvData[R - 1][C] != null ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] != null ? "n" : "s",
            s: {
              numFmt: this.commonService.getDollarFormat2DecimalPoint(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C == 5) {
          let colorC = "DBEAD5";
          let displayData = "";
          if (csvData[R - 1][C] != null && csvData[R - 1][C] !== "NaN") {
            displayData = csvData[R - 1][C] ? "YES" : "NO";
          }
          excelCell = {
            v: displayData,
            t: "s",
            s: {
              fill: {
                patternType: "solid",
                fgColor: { rgb: colorC },
                bgColor: { rgb: colorC },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C == 6) {
          excelCell = {
            v: csvData[R - 1][C] != null ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] != null ? "n" : "s",
            s: {
              numFmt: this.commonService.getDollarFormat2DecimalPoint(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C == 7) {
          excelCell = {
            v: csvData[R - 1][C] != null ? csvData[R - 1][C] : "",
            t: "s",
            s: {
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C > 7 && C < 11) {
          let colorB = "FFFFFF";
          let fontObj: any = font;
          if (C == 8) {
            let dynamicCellColor = "FFFFFF";
            if (csvData[R - 1][C] != null && csvData[R - 1][C] < 0) {
              dynamicCellColor = "A20F0A";
              fontObj = fontL5;
            } else {
              dynamicCellColor = "FFFFFF";
            }
            colorB = dynamicCellColor;
          } else {
            colorB = "FFFFFF";
          }
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: {
              numFmt:
                C == 8
                  ? csvData[R - 1][C] !== null
                    ? this.commonService.getDollarFormat2DecimalPoint(csvData[R - 1][C])
                    : ""
                  : csvData[R - 1][C] !== null
                    ? this.commonService.getDollarFormat(csvData[R - 1][C])
                    : "",
              fill: {
                patternType: "solid",
                fgColor: { rgb: colorB },
                bgColor: { rgb: colorB },
              },
              font: fontObj,
              border: border,
              alignment: alignment,
            },
          };
        } else if (C > 10 && C < 13) {
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: {
              numFmt:
                csvData[R - 1][C] !== null
                  ? this.commonService.getDollarFormat(csvData[R - 1][C])
                  : "",
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C == 13) {
          excelCell = {
            v: csvData[R - 1][C] != null ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] != null ? "n" : "s",
            s: {
              numFmt:
                csvData[R - 1][C] !== null
                  ? this.commonService.getDollarFormat(csvData[R - 1][C])
                  : "",
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C > 13) {
          let colorC = "DAEAD4";
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: C === 15 ? "n" : csvData[R - 1][C] !== null ? "n" : "s",
            s: {
              numFmt:
                C !== 15
                  ? this.commonService.getDollarFormat(csvData[R - 1][C])
                  : this.commonService.get2DecimalPointFormat(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: colorC },
                bgColor: { rgb: colorC },
              },
              font: {
                name: "Arial, Helvetica, sans-serif",
                sz: 10,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        }

        ws[cell_ref] = excelCell;
      }
    }
    ws["!images"] = [];
    ws["!images"].push({
      name: "Armatus.jpeg",
      data: this.imgToDataUrl(imgData),
      opts: { base64: true },
      type: "jpeg",
      position: {
        type: "twoCellAnchor",
        attrs: { editAs: "oneCell" },
        from: { col: 0, row: 1 },
        to: { col: 1, row: 3 },
      },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    const op = { wb: wb, fileName: fileName };
    return op;
  }

  imgToDataUrl(img: { naturalWidth: any; naturalHeight: any }) {
    // const canvas = document.createElement("canvas");
    // canvas.width = img.naturalWidth; // or 'width' if you want a special/scaled size
    // canvas.height = img.naturalHeight; // or 'height' if you want a special/scaled size
    // canvas.getContext("2d").drawImage(img, 0, 0);
    // return canvas
    //   .toDataURL("image/png")
    //   .replace(/^data:image\/(png|jpg);base64,/, "");
    const canvas: any = document.createElement("canvas");

    if (img instanceof HTMLImageElement) {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      canvas.getContext("2d").drawImage(img, 0, 0);
    }

    // Make sure img is not null before calling toDataURL
    if (img) {
      return canvas.toDataURL("image/png").replace(/^data:image\/(png|jpg);base64,/, "");
    }
  }
}
