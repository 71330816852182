/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
// import "rxjs/add/operator/map";
import { ConstantService } from "../constants/constant.service";
import * as moment from "moment-timezone";
import { MyCurrencyPipe } from "../components/formatter/currency/my-currency.pipe";
import { MyPercentagePipe } from "../components/formatter/percentage/my-percentage.pipe";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { CommonService } from "./common.service";
import { DMConstantService } from "../constants/dm.constant.service";
@Injectable()
export class ImpactReportBetaPdfService {
  public impactReportData = [];
  constructor(
    private constantService: ConstantService,
    private mycurpipe: MyCurrencyPipe,
    private myperpipe: MyPercentagePipe,
    private DMConstantService: DMConstantService,
    private commonService: CommonService
  ) {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
  }
  init() {}
  downloadImpactPdfReport = async (
    partsReportData: any,
    mageImpactReportData: any,
    selectedStore: any,
    partsPreAuditReviewStatusObj: any,
    self: any,
    parentData: any,
    action: any,
    impactFileName: any,
    callback: any
  ) => {
    console.log("downloadImpactPdfReport action parts====>>", action, partsReportData, mageImpactReportData);
    const d = moment();
    const newDate = d.tz(moment.tz.guess()).format("MM-DD-YYYY HH:mm:ss");
    const reportName: any =
      partsReportData.mage_stage === "PostAudit"
        ? this.DMConstantService.SOLVE360_REPORT_FILE_KEYS.POST_IMPACT_REPORT
        : this.DMConstantService.SOLVE360_REPORT_FILE_KEYS.PRE_IMPACT_REPORT;
    let stateName = "";
    stateName = partsReportData.state_name + " (" + partsReportData.state_code + ")";
    stateName += partsReportData.project_reviewed_on
      ? ", " + partsReportData.project_reviewed_on
      : "";
    const mageStage = partsReportData.mage_stage === "PostAudit" ? "Post Audit" : "Pre Audit";
    let projectName =
      parentData.linkProjectObj && parentData.linkProjectObj.itemName
        ? parentData.linkProjectObj.itemName
        : "";
    projectName = projectName.replace(/\W+(?!$)/g, "-");
    projectName = projectName.replace(/\W$/, "");
    const fileName_ws = mageStage + " " + projectName + "_" + moment().format("YYYYMMDD");
    const fileName_solve = mageStage + "-" + projectName + "_" + moment().format("YYYY-MM-DD");
    const loopData = [];
    const loopData1 = [
      {
        text: mageImpactReportData.one_period ? mageImpactReportData.one_period : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [true, false, false, false],
      },

      {
        text: mageImpactReportData.one_cost
          ? this.mycurpipe.transform(mageImpactReportData.one_cost, 0)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.one_ro_count ? mageImpactReportData.one_ro_count : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.one_ro_days ? mageImpactReportData.one_ro_days : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.one_start_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.one_start_date)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.one_end_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.one_end_date)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.one_uplift
          ? this.mycurpipe.transform(mageImpactReportData.one_uplift, 2)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, true, false],
      },
    ];
    loopData.push(loopData1);
    loopData.push([
      {
        colSpan: 7,
        rowSpan: 1,
        text: mageImpactReportData.one_paytypes ? mageImpactReportData.one_paytypes : null,
        style: "hederSectionOrange_1",
        alignment: "left",
        border: [true, false, true, true],
        margin: [90, 4, 0, 0],
      },
    ]);

    const loopData2 = [
      {
        text: mageImpactReportData.two_period ? mageImpactReportData.two_period : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [true, false, false, false],
      },

      {
        text: mageImpactReportData.two_cost
          ? this.mycurpipe.transform(mageImpactReportData.two_cost, 0)
          : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.two_ro_count ? mageImpactReportData.two_ro_count : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.two_ro_days ? mageImpactReportData.two_ro_days : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.two_start_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.two_start_date)
          : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.two_end_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.two_end_date)
          : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.two_uplift
          ? this.mycurpipe.transform(mageImpactReportData.two_uplift, 2)
          : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, true, false],
      },
    ];
    loopData.push(loopData2);
    loopData.push([
      {
        colSpan: 7,
        rowSpan: 1,
        text: mageImpactReportData.two_paytypes ? mageImpactReportData.two_paytypes : null,
        style: "hederSectionOrange_2",
        alignment: "left",
        border: [true, false, true, true],
        margin: [90, 4, 0, 0],
      },
    ]);

    const loopData3 = [
      {
        text: mageImpactReportData.three_period ? mageImpactReportData.three_period : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [true, false, false, false],
      },

      {
        text: mageImpactReportData.three_cost
          ? this.mycurpipe.transform(mageImpactReportData.three_cost, 0)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.three_ro_count ? mageImpactReportData.three_ro_count : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.three_ro_days ? mageImpactReportData.three_ro_days : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.three_start_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.three_start_date)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.three_end_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.three_end_date)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.three_uplift
          ? this.mycurpipe.transform(mageImpactReportData.three_uplift, 2)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, true, false],
      },
    ];

    loopData.push(loopData3);
    loopData.push([
      {
        colSpan: 7,
        rowSpan: 1,
        text: mageImpactReportData.three_paytypes ? mageImpactReportData.three_paytypes : null,
        style: "hederSectionOrange_1",
        alignment: "left",
        border: [true, false, true, true],
        margin: [90, 4, 0, 0],
      },
    ]);
    const cellHeights = [];
    for (let loopCount = 0; loopCount < loopData.length; loopCount++) {
      cellHeights.push(15);
    }
    const inActivePostAuditProjectionReport = {
      pageSize: "Legal",
      pageOrientation: "landscape",
      pageMargins: [19, 19, 19, 19],
      content: [
        //{ text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_HEAD_PARTS, style: 'subheader' },
        "",
        {
          style: "table1",
          color: "#444",
          table: {
            widths: [150, "*", 150],
            headerRows: 2,
            body: [
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SDD,
                    {
                      text: this.transformDateMMDDYYYY(partsReportData.shipping_deadline),
                      style: "topHeaderCell",
                      alignment: "left",
                      fontSize: 12,
                    },
                  ],
                  fontSize: 12,
                  style: "topHeaderCellYellow",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_HEAD_PARTS,
                  style: "topHeaderCell2",
                },
                {
                  colSpan: 1,
                  rowSpan: 2,
                  columns: [
                    // { text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_POST_AUDIT, style: 'topHeaderCell', alignment: 'left', fontSize: 12 },
                    {
                      text: partsReportData.mage_stage,
                      style: "topHeaderCell",
                      alignment: "right",
                      fontSize: 12,
                    },
                  ],
                  fontSize: 12,
                  style: "topHeaderCellYellow",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SNRT,
                    {
                      text: stateName,
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SGN,
                    {
                      text: partsReportData.sg_name,
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                "",
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MNF,
                    {
                      text: selectedStore
                        ? selectedStore.manufacturer + " (" + selectedStore.brandCode + ")"
                        : "",
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SN,
                    {
                      text: partsReportData.st_name,
                      style: "topHeaderCell",
                      alignment: "left",
                      bold: true,
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SALES_PERSON,
                    {
                      text:
                        parentData.linkProjectObj && parentData.linkProjectObj.salesPerson
                          ? parentData.linkProjectObj.salesPerson
                          : "",
                      style: "topHeaderCell",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                  alignment: "right",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.DMS,
                    {
                      text:
                        parentData.linkProjectObj && parentData.linkProjectObj.dms_code
                          ? parentData.linkProjectObj.dms_code
                          : "",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  style: "topHeaderCell",
                  margin: [0, 20, 0, 0],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FIUO,
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {},
              ],
              [
                {
                  text: [
                    {
                      text:
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBR +
                        this.getOverRideLabel(partsReportData, true, "basisRate"),
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text: this.markupToPercentage(partsReportData.basis_markup, true) + "\n",
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 12,
                    },
                    {
                      text:
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBC +
                        this.getOverRideLabel(partsReportData, true, "basisCost"),
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text: this.mycurpipe.transform(partsReportData.basis_cost, 0),
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 11,
                    },
                  ],
                  style: ["topHeaderCell2_1"],
                  alignment: "left",
                  margin: [0, 4, 0, 0],
                },
                {
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MU,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text:
                        this.mycurpipe.transform(partsReportData.monthly_uplift_amount, 0) + "\n",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CU,
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text: this.mycurpipe.transform(partsReportData.calculated_uplift, 0),
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 11,
                    },
                  ],
                  style: ["topHeaderCell2_1"],
                  alignment: "center",
                  margin: [0, 4, 0, 0],
                },
                {
                  text: [
                    partsReportData.mage_stage
                      ? partsReportData.mage_stage +
                        " " +
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_TR
                      : this.DMConstantService.IMPACT_REPORT_LABEL.LBL_TBR,
                    {
                      text: partsReportData.target_markup
                        ? this.markupToPercentage(partsReportData.target_markup, true)
                        : "",
                      alignment: "left",
                    },
                  ],
                  fontSize: 12,
                  style: ["styleBold", "region2Margin"],
                },
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 150, "*", 150, 150],
            body: [
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBMS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    { text: "Calculated", alignment: "right" },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FSDB,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: partsReportData.projection_backing_type,
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBMC,
                      alignment: "center",
                      style: "styleBold",
                    },
                    {
                      text: partsReportData.basis_cost
                        ? this.mycurpipe.transform(partsReportData.basis_cost, 0)
                        : "",
                      alignment: "left",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  style: "topHeaderCellYellow",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PAUDIT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: this.markupToPercentage(partsReportData.post_audit_retail_markup, true),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 150, "*", "*", "*", "*", "*", 150, 150],
            body: [
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CC,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: partsReportData.calculated_warranty_cost
                        ? this.mycurpipe.transform(partsReportData.calculated_warranty_cost, 0)
                        : "",
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FSD_COST,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: partsReportData.projection_warranty_cost
                        ? this.mycurpipe.transform(partsReportData.projection_warranty_cost, 0)
                        : "",
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.ROUND,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.YEAR,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.PREDISC,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.POSTDISC,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.DISCEFFECT,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_DISCOUNT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: this.markupToPercentage(partsReportData.pre_discount_markup, true),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.markupToPercentage(
                        partsReportData.calculated_warranty_markup,
                        true
                      ),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FSD_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.markupToPercentage(
                        partsReportData.projection_warranty_markup,
                        true
                      ),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory
                      ? partsPreAuditReviewStatusObj.latestDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory
                      ? partsPreAuditReviewStatusObj.latestDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory.discount_effect
                      ? parentData.markupPercentage(
                          partsPreAuditReviewStatusObj.latestDiscountHistory.discount_effect
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_AUDIT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: this.markupToPercentage(partsReportData.pre_audit_retail_markup, true),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  colSpan: 2,
                  rowSpan: 1,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },

                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory
                      ? partsPreAuditReviewStatusObj.secondDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory
                      ? partsPreAuditReviewStatusObj.secondDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.secondDiscountHistory.pre_discount_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.secondDiscountHistory.post_audit_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory.discount_effect
                      ? parentData.markupPercentage(
                          partsPreAuditReviewStatusObj.secondDiscountHistory.discount_effect
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PROJECTED,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: parentData.primaryBundleData["parts_no_projection_status"]
                    ? "NA"
                    : this.markupToPercentage(partsReportData.projection_retail_markup, true),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  colSpan: 2,
                  rowSpan: 1,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },

                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory
                      ? partsPreAuditReviewStatusObj.thirdDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory
                      ? partsPreAuditReviewStatusObj.thirdDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.thirdDiscountHistory.pre_discount_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.thirdDiscountHistory.post_audit_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect
                      ? parentData.markupPercentage(
                          partsPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.PREAUDIT_DISCOUNT_MEMO,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: partsReportData
                    ? this.isExistDmsDiscountEffect(
                        parentData.linkProjectObj.dms_code,
                        partsReportData.pre_audit_discount_effect
                      )
                    : "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  colSpan: 2,
                  rowSpan: 1,
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CUST_STATED_RATE,
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.is_msrp !== true &&
                        partsReportData &&
                        partsReportData.cust_stated_warranty_markup !== null
                          ? this.markupToPercentage(
                              partsReportData.cust_stated_warranty_markup,
                              true
                            )
                          : self.preauditReview &&
                              self.preauditReview.rule_data &&
                              self.preauditReview.rule_data.is_msrp == true
                            ? "MSRP"
                            : "",
                      alignment: "right",
                      color:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.is_msrp === true &&
                        self.partsPreAuditReviewStatusObj &&
                        self.partsPreAuditReviewStatusObj.msrpPreauditStatus === "M" &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail
                          .pre_audit_wo_de_msrp === false
                          ? "red"
                          : "black",
                    },
                  ],
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text:
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    self.preauditReview.rule_data.discount_history &&
                    self.preauditReview.rule_data.discount_history.length > 3
                      ? self.preauditReview.rule_data.discount_history.length - 3 + " more exist"
                      : "Discounting History",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 2,
                  rowSpan: 1,
                  text:
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ADDITIONAL_MU +
                    (parentData.primaryBundleData["uplift_markup"]
                      ? this.myperpipe.transform(
                          (parentData.primaryBundleData["uplift_markup"] * 100).toFixed(2)
                        )
                      : "NA"),
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
            ],
          },
        },
        {
          style: "impactReportTable",
          table: {
            heights: [25],
            widths: ["*", "*", "*", "*", "*", "*", "*"],
            border: [false, false, false, true],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PERIOD,
                  style: "impactReportList",
                  alignment: "right",
                  border: [true, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PARTS_COST,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_IC,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ID,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SD,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ED,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_UPLIFT,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, true, true],
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 1;
            },
            vLineWidth: function (i: any, node: any) {
              return 1;
            },
            hLineColor: function (i: any, node: any) {
              return "black";
            },
            vLineColor: function (i: any, node: any) {
              return "black";
            },
          },
        },
        {
          style: "impactReportTableValue",
          table: {
            heights: cellHeights,
            widths: ["*", "*", "*", "*", "*", "*", "*"],
            body: loopData,
          },
          layout: {},
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 10, 180, "*"],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ANALIZED_UPLIFT,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: partsReportData.annual_uplift_amount
                    ? this.mycurpipe.transform(partsReportData.annual_uplift_amount, 0)
                    : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  colSpan: 1,
                  rowSpan: 4,
                  text:
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_RETAIL_SUMMARY_BOX +
                    this.getOverRideLabel(partsReportData, false, ""),
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SUBMISSION_FEE,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: partsReportData.submission_fee
                    ? this.mycurpipe.transform(partsReportData.submission_fee, 0)
                    : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                "",
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MONTHLY_RECURRING_FEE,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: partsReportData.recurring_fee
                    ? this.mycurpipe.transform(partsReportData.recurring_fee, 0)
                    : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                "",
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PAYBACK_PERIOD,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, true],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                {
                  text: this.commonService.get2DecimalValue(partsReportData.payback_period),
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                "",
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          pageBreak: "after",
          table: {
            widths: [150, 10, 180, 400, "*"],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MEGE_SCE_NAME,
                  style: "section3",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: mageImpactReportData.scenario_name,
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  // colSpan: 1, rowSpan: 2, text: this.constantService.SITE_COPYRIGHT_MESSAGE, style: 'section3_1', alignment: 'left', border: [true, false, true, true], margin: [0, 0, 0, 0]
                  colSpan: 1,
                  rowSpan: 2,
                  text: "",
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
                {
                  colSpan: 1,
                  rowSpan: 2,
                  text: "Data Date: " + newDate,
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MEGE_SCE_KEY,
                  style: "section3",
                  alignment: "left",
                  border: [true, false, false, true],
                },
                {
                  text: ":",
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                {
                  text: parentData.linkProjectObj.scenariokey,
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                "",
                "",
              ],
            ],
          },
        },
        {
          style: "table1",
          color: "#444",
          table: {
            widths: [100, 100, 50, 125, 225, 50, "*", "*", 50, 50, 50],
            body: [
              [
                {
                  colSpan: 11,
                  rowSpan: 1,
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.PREAUDIT_REVIEW,
                  style: "subheader",
                  alignment: "center",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBR +
                      this.getOverRideLabel(partsReportData, true, "basisRate"),
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.basis_rate !== null
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.basis_rate,
                              true
                            )
                          : "",
                      alignment: "left",
                    },
                  ],
                  fontSize: 11,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBC +
                      this.getOverRideLabel(partsReportData, true, "basisCost"),
                    {
                      text: partsReportData.basis_cost
                        ? this.mycurpipe.transform(partsReportData.basis_cost, 0)
                        : "",
                      alignment: "left",
                    },
                  ],
                  fontSize: 11,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: "",
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  colSpan: 3,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MU,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.monthly_uplift !== null
                          ? this.mycurpipe.transform(
                              self.preauditReview.rule_data.monthly_uplift,
                              0
                            ) + "\n"
                          : "\n",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },

                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.PREAUDIT_DISCOUNT_MEMO,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.pre_audit_discount !== null
                          ? this.isExistDmsDiscountEffect(
                              parentData.linkProjectObj.dms_code,
                              self.preauditReview.rule_data.pre_audit_discount
                            ) + "\n"
                          : "\n",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, true, true, true],
                  margin: [0, 0, 0, 0],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBMS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    { text: "Calculated", alignment: "right" },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FSDB,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.fsd_basis !== null
                          ? self.preauditReview.rule_data.fsd_basis
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [{ text: "", alignment: "center", style: "styleBold" }],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  colSpan: 3,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CU,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.calculated_uplift !== null
                          ? this.mycurpipe.transform(
                              self.preauditReview.rule_data.calculated_uplift,
                              0
                            )
                          : "",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },

                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, true, true, true],
                  margin: [0, 0, 0, 0],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  text: "",
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {
                  text: "",
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {
                  text: "",
                  fontSize: 12,
                  style: ["styleBold", "region2Margin"],
                },

                {
                  colSpan: 3,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBMC,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text: partsReportData.basis_cost
                        ? this.mycurpipe.transform(partsReportData.basis_cost, 0)
                        : "",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, true, true, true],
                  margin: [0, 0, 0, 0],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.calculated_cost !== null
                          ? this.mycurpipe.transform(
                              self.preauditReview.rule_data.calculated_cost,
                              0
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FSD_COST,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.fsd_cost !== null
                          ? this.mycurpipe.transform(self.preauditReview.rule_data.fsd_cost, 0)
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail.fsd_calc_cost_value !==
                      null
                      ? parentData.markupPercentageCost(
                          self.partsPreAuditReviewStatusObj.warranty_rule_detail.fsd_calc_cost_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail &&
                    parentData.differenceColor(
                      self.partsPreAuditReviewStatusObj.warranty_rule_detail.fsd_calc_cost
                    ),
                  color: "white",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.PROJECTED_RATE,
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.projected_rate !== null
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.projected_rate,
                              true
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? this.DMConstantService.IMPACT_REPORT_LABEL.PRE_AUDIT_RATE_WITHOUT_DE
                      : this.DMConstantService.IMPACT_REPORT_LABEL.PRE_AUDIT_RATE,
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.pre_audit_rate
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.pre_audit_rate,
                              true
                            )
                          : "",
                      alignment: "right",
                      color:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.is_msrp === true &&
                        self.partsPreAuditReviewStatusObj &&
                        self.partsPreAuditReviewStatusObj.msrpPreauditStatus === "M" &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail
                          .pre_audit_wo_de_msrp === false
                          ? "red"
                          : "black",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.pre_audit_projected_rule_detail &&
                    self.partsPreAuditReviewStatusObj.pre_audit_projected_rule_detail
                      .pre_audit_projected_wo_de_value !== null
                      ? parentData.markupPercentage(
                          self.partsPreAuditReviewStatusObj.pre_audit_projected_rule_detail
                            .pre_audit_projected_wo_de_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.pre_audit_projected_rule_detail &&
                    parentData.differenceColor(
                      self.partsPreAuditReviewStatusObj.pre_audit_projected_rule_detail
                        .pre_audit_projected_wo_de
                    ),
                  color: "white",
                },
                {
                  text: [this.DMConstantService.IMPACT_REPORT_LABEL.ROUND],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [this.DMConstantService.IMPACT_REPORT_LABEL.YEAR],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [this.DMConstantService.IMPACT_REPORT_LABEL.PREDISC],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [this.DMConstantService.IMPACT_REPORT_LABEL.POSTDISC],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [this.DMConstantService.IMPACT_REPORT_LABEL.DISCEFFECT],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.calculated_rate !== null
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.calculated_rate,
                              true
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FSD_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.fsd_rate !== null
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.fsd_rate,
                              true
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail.fsd_calc_cost_value !==
                      null
                      ? parentData.markupPercentage(
                          self.partsPreAuditReviewStatusObj.warranty_rule_detail.fsd_calc_rate_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail &&
                    parentData.differenceColor(
                      self.partsPreAuditReviewStatusObj.warranty_rule_detail.fsd_calc_rate
                    ),
                  color: "white",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.WARRANTY_RATE,
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.basis_rate !== null
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.basis_rate,
                              true
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? this.DMConstantService.IMPACT_REPORT_LABEL.PRE_AUDIT_RATE_WITHOUT_DE
                      : this.DMConstantService.IMPACT_REPORT_LABEL.PRE_AUDIT_RATE,
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.pre_audit_rate !== null
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.pre_audit_rate,
                              true
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.pre_audit_warranty_rule_detail &&
                    self.partsPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                      .pre_audit_warranty_wo_disc_effect_value !== null
                      ? parentData.markupPercentage(
                          self.partsPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                            .pre_audit_warranty_wo_disc_effect_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.pre_audit_projected_rule_detail &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.pre_audit_warranty_rule_detail &&
                    parentData.differenceColor(
                      self.partsPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                        .pre_audit_warranty_wo_disc_effect
                    ),
                  color: "white",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.latestDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.latestDiscountHistory.round !== null
                      ? self.partsPreAuditReviewStatusObj.latestDiscountHistory.round
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.latestDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.latestDiscountHistory.project_year !== null
                      ? self.partsPreAuditReviewStatusObj.latestDiscountHistory.project_year
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.latestDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate !==
                      null
                      ? parentData.markupToPercentageConversion(
                          self.partsPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate,
                          true
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.latestDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate !== null
                      ? parentData.markupToPercentageConversion(
                          self.partsPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate,
                          true
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.latestDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.latestDiscountHistory.discount_effect !== null
                      ? parentData.markupPercentage(
                          self.partsPreAuditReviewStatusObj.latestDiscountHistory.discount_effect
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.calculated_rate !== null
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.calculated_rate,
                              true
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.CUST_STATED,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.is_msrp
                          ? "MSRP"
                          : self.preauditReview &&
                              self.preauditReview.rule_data &&
                              self.preauditReview.rule_data.customer_started_rate !== null
                            ? parentData.markupToPercentageConversion(
                                self.preauditReview.rule_data.customer_started_rate,
                                true
                              )
                            : "",
                      alignment: "right",
                      color:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.is_msrp === true &&
                        self.partsPreAuditReviewStatusObj &&
                        self.partsPreAuditReviewStatusObj.msrpPreauditStatus === "M" &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail
                          .pre_audit_msrp === false
                          ? "red"
                          : "black",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.is_msrp &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail
                      .cust_stated_calc_rate_value !== null
                      ? parentData.markupPercentage(
                          partsPreAuditReviewStatusObj.warranty_rule_detail
                            .cust_stated_calc_rate_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail &&
                    parentData.differenceColorWithMsrp(
                      self.partsPreAuditReviewStatusObj.warranty_rule_detail.cust_stated_calc_rate,
                      self.preauditReview.rule_data.is_msrp
                    ),
                  color: "white",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? this.DMConstantService.IMPACT_REPORT_LABEL.PROJECTED_RATE
                      : "",
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.projected_rate !== null &&
                        !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.projected_rate,
                              true
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? this.DMConstantService.IMPACT_REPORT_LABEL.PRE_AUDIT_RATE_WITH_DE
                      : "",
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.pre_audit_rate !== null &&
                        !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                          ? self.preAuditRateWithDiscEffect(self.preauditReview, true)
                          : "",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  color:
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    self.preauditReview.rule_data.is_msrp === true &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.msrpPreauditStatus === "M" &&
                    self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail &&
                    self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail.pre_audit_msrp ===
                      false
                      ? "red"
                      : "black",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    self.preauditReview.rule_data.pre_audit_rate !== null &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? parentData.markupPercentage(
                          self.partsPreAuditReviewStatusObj.pre_audit_projected_rule_detail
                            .pre_audit_projected_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.pre_audit_projected_rule_detail &&
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit &&
                    parentData.differenceColor(
                      self.partsPreAuditReviewStatusObj.pre_audit_projected_rule_detail
                        .pre_audit_projected
                    ),
                  color: "white",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.secondDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.secondDiscountHistory.round !== null
                      ? self.partsPreAuditReviewStatusObj.secondDiscountHistory.round
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.secondDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.secondDiscountHistory.project_year !== null
                      ? self.partsPreAuditReviewStatusObj.secondDiscountHistory.project_year
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.secondDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.secondDiscountHistory.pre_discount_rate !==
                      null
                      ? parentData.markupToPercentageConversion(
                          self.partsPreAuditReviewStatusObj.secondDiscountHistory.pre_discount_rate,
                          true
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.secondDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.secondDiscountHistory.post_audit_rate !== null
                      ? parentData.markupToPercentageConversion(
                          self.partsPreAuditReviewStatusObj.secondDiscountHistory.post_audit_rate,
                          true
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.secondDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.secondDiscountHistory.discount_effect !== null
                      ? parentData.markupPercentage(
                          self.partsPreAuditReviewStatusObj.secondDiscountHistory.discount_effect
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.CUST_STATED,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.is_msrp
                          ? "MSRP"
                          : self.preauditReview &&
                              self.preauditReview.rule_data &&
                              self.preauditReview.rule_data.customer_started_rate !== null
                            ? parentData.markupToPercentageConversion(
                                self.preauditReview.rule_data.customer_started_rate,
                                true
                              )
                            : "",
                      alignment: "right",
                      color:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.is_msrp === true &&
                        self.partsPreAuditReviewStatusObj &&
                        self.partsPreAuditReviewStatusObj.msrpPreauditStatus === "M" &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail
                          .pre_audit_msrp === false
                          ? "red"
                          : "black",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FSD_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.fsd_rate !== null
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.fsd_rate,
                              true
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail
                      .cust_stated_fsd_rate_value !== null
                      ? parentData.markupPercentage(
                          self.partsPreAuditReviewStatusObj.warranty_rule_detail
                            .cust_stated_fsd_rate_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail &&
                    self.partsPreAuditReviewStatusObj.warranty_rule_detail.cust_stated_fsd_rate !==
                      null &&
                    parentData.differenceColorWithMsrp(
                      self.partsPreAuditReviewStatusObj.warranty_rule_detail.cust_stated_fsd_rate,
                      self.preauditReview.rule_data.is_msrp
                    ),
                  color: "white",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? this.DMConstantService.IMPACT_REPORT_LABEL.WARRANTY_RATE
                      : "",
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.basis_rate !== null &&
                        !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                          ? parentData.markupToPercentageConversion(
                              self.preauditReview.rule_data.basis_rate,
                              true
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? this.DMConstantService.IMPACT_REPORT_LABEL.PRE_AUDIT_RATE_WITH_DE
                      : "",
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.pre_audit_rate !== null &&
                        !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                          ? self.preAuditRateWithDiscEffect(self.preauditReview, true)
                          : "",
                      alignment: "right",
                      color:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.is_msrp === true &&
                        self.partsPreAuditReviewStatusObj &&
                        self.partsPreAuditReviewStatusObj.msrpPreauditStatus === "M" &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail
                          .pre_audit_msrp === false
                          ? "red"
                          : "black",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.pre_audit_warranty_rule_detail &&
                    self.partsPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                      .pre_audit_warranty_value !== null &&
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? parentData.markupPercentage(
                          self.partsPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                            .pre_audit_warranty_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.pre_audit_warranty_rule_detail &&
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit &&
                    parentData.differenceColor(
                      self.partsPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                        .pre_audit_warranty
                    ),
                  color: "white",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.thirdDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.thirdDiscountHistory.round !== null
                      ? self.partsPreAuditReviewStatusObj.thirdDiscountHistory.round
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.thirdDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.thirdDiscountHistory.project_year !== null
                      ? self.partsPreAuditReviewStatusObj.thirdDiscountHistory.project_year
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.thirdDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.thirdDiscountHistory.pre_discount_rate !==
                      null
                      ? parentData.markupToPercentageConversion(
                          self.partsPreAuditReviewStatusObj.thirdDiscountHistory.pre_discount_rate,
                          true
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.thirdDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.thirdDiscountHistory.post_audit_rate !== null
                      ? parentData.markupToPercentageConversion(
                          self.partsPreAuditReviewStatusObj.thirdDiscountHistory.post_audit_rate,
                          true
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.partsPreAuditReviewStatusObj &&
                    self.partsPreAuditReviewStatusObj.thirdDiscountHistory &&
                    self.partsPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect !== null
                      ? parentData.markupPercentage(
                          self.partsPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
              ],
              [
                {
                  colSpan: 3,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.CONFIRMER,
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text:
                        self.preauditReview && self.preauditReview.confirmer !== null
                          ? self.preauditReview.confirmer
                          : "",
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 10,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "left",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 3,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.COMMENTS,
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text:
                        self.preauditReview && self.preauditReview.comment !== null
                          ? self.preauditReview.comment
                          : "",
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 10,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "left",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.CONFIRMED_ON,
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text:
                        self.preauditReview && self.preauditReview.confirmed_on !== null
                          ? this.transformDateMMDDYYYY(self.preauditReview.confirmed_on)
                          : "",
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 10,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "left",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
            ],
          },
        },
      ],
      styles: {
        reportHeader: {
          fontSize: 14,
          bold: true,
          alignment: "left",
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          color: "black",
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
          color: "black",
        },
        table1: {
          margin: [10, 10, 0, 0],
          fontSize: 12,
          color: "black",
        },
        table2: {
          margin: [10, 0, 0, 0],
          fontSize: 12,
          border: [true, false, true, true],
          color: "black",
        },
        topHeaderCell2: {
          fontSize: 16,
          bold: true,
          alignment: "center",
          color: "black",
        },
        topHeaderCell2_1: {
          fontSize: 12,
          bold: true,
          alignment: "center",
          color: "black",
        },
        topHeaderCell: {
          alignment: "left",
          fontSize: 10,
          color: "black",
        },
        topHeaderCellYellow: {
          alignment: "left",
          fontSize: 10,
          bold: true,
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        topHeaderCellYellow_1: {
          fontSize: 10,
          bold: true,
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        section3: {
          alignment: "left",
          fontSize: 10,
          border: [true, false, true, true],
          color: "black",
        },
        section3_1: {
          alignment: "center",
          fontSize: 10,
          border: [true, false, true, true],
          color: "black",
        },
        styleBold: {
          bold: true,
          color: "black",
        },
        impactReportTable: {
          margin: [10, 0, 0, 0],
          fontSize: 12,
          color: "black",
        },
        impactReportTableValue: {
          margin: [10, 0, 0, 0],
          fontSize: 10,
          color: "black",
        },
        hederSectionOrange_1: {
          fillColor: this.DMConstantService.IMPACT_TDBG_WHITE,
          fontSize: 10,
          margin: [0, 4, 0, 0],
          color: "black",
        },
        hederSectionOrange_2: {
          fillColor: this.DMConstantService.IMPACT_TDBG_WHITE,
          fontSize: 10,
          margin: [0, 4, 0, 0],
          color: "black",
        },
        region3Margin: {
          margin: [0, 10, 0, 10],
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        region2Margin: {
          margin: [0, 15, 0, 0],
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        impactReportList: {
          bold: true,
          margin: [0, 10, 0, 0],
          color: "black",
        },
      },
    };
    const activePostAuditProjectionReport = {
      pageSize: "Legal",
      pageOrientation: "landscape",
      pageMargins: [19, 19, 19, 19],
      content: [
        //{ text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_HEAD_PARTS, style: 'subheader' },
        "",
        {
          style: "table1",
          color: "#444",
          table: {
            widths: [150, "*", 150],
            headerRows: 2,
            body: [
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SDD,
                    {
                      text: this.transformDateMMDDYYYY(partsReportData.shipping_deadline),
                      style: "topHeaderCell",
                      alignment: "left",
                      fontSize: 12,
                    },
                  ],
                  fontSize: 12,
                  style: "topHeaderCellYellow",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_HEAD_PARTS,
                  style: "topHeaderCell2",
                },
                {
                  colSpan: 1,
                  rowSpan: 2,
                  columns: [
                    // { text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_POST_AUDIT, style: 'topHeaderCell', alignment: 'left', fontSize: 12 },
                    {
                      text: partsReportData.mage_stage,
                      style: "topHeaderCell",
                      alignment: "right",
                      fontSize: 12,
                    },
                  ],
                  fontSize: 12,
                  style: "topHeaderCellYellow",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SNRT,
                    {
                      text: stateName,
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SGN,
                    {
                      text: partsReportData.sg_name,
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                "",
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MNF,
                    {
                      text: selectedStore
                        ? selectedStore.manufacturer + " (" + selectedStore.brandCode + ")"
                        : "",
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SN,
                    {
                      text: partsReportData.st_name,
                      style: "topHeaderCell",
                      alignment: "left",
                      bold: true,
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SALES_PERSON,
                    {
                      text:
                        parentData.linkProjectObj && parentData.linkProjectObj.salesPerson
                          ? parentData.linkProjectObj.salesPerson
                          : "",
                      style: "topHeaderCell",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                  alignment: "right",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.DMS,
                    {
                      text:
                        parentData.linkProjectObj && parentData.linkProjectObj.dms_code
                          ? parentData.linkProjectObj.dms_code
                          : "",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  style: "topHeaderCell",
                  margin: [0, 20, 0, 0],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FIUO,
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {},
              ],
              [
                {
                  text: [
                    {
                      text:
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBR +
                        this.getOverRideLabel(partsReportData, true, "basisRate"),
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text: this.markupToPercentage(partsReportData.basis_markup, true) + "\n",
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 12,
                    },
                    {
                      text:
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBC +
                        this.getOverRideLabel(partsReportData, true, "basisCost"),
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text: this.mycurpipe.transform(partsReportData.basis_cost, 0),
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 11,
                    },
                  ],
                  style: ["topHeaderCell2_1"],
                  alignment: "left",
                  margin: [0, 4, 0, 0],
                },
                {
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MU,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text:
                        this.mycurpipe.transform(partsReportData.monthly_uplift_amount, 0) + "\n",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CU,
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text: this.mycurpipe.transform(partsReportData.calculated_uplift, 0),
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 11,
                    },
                  ],
                  style: ["topHeaderCell2_1"],
                  alignment: "center",
                  margin: [0, 4, 0, 0],
                },
                {
                  text: [
                    partsReportData.mage_stage
                      ? partsReportData.mage_stage +
                        " " +
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_TR
                      : this.DMConstantService.IMPACT_REPORT_LABEL.LBL_TBR,
                    {
                      text: partsReportData.target_markup
                        ? this.markupToPercentage(partsReportData.target_markup, true)
                        : "",
                      alignment: "left",
                    },
                  ],
                  fontSize: 12,
                  style: ["styleBold", "region2Margin"],
                },
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 150, "*", 150, 150],
            body: [
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBMS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    { text: "Calculated", alignment: "right" },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FSDB,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: partsReportData.projection_backing_type,
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBMC,
                      alignment: "center",
                      style: "styleBold",
                    },
                    {
                      text: partsReportData.basis_cost
                        ? this.mycurpipe.transform(partsReportData.basis_cost, 0)
                        : "",
                      alignment: "left",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  style: "topHeaderCellYellow",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PAUDIT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: this.markupToPercentage(partsReportData.post_audit_retail_markup, true),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 150, "*", "*", "*", "*", "*", 150, 150],
            body: [
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CC,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: partsReportData.calculated_warranty_cost
                        ? this.mycurpipe.transform(partsReportData.calculated_warranty_cost, 0)
                        : "",
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FSD_COST,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: partsReportData.projection_warranty_cost
                        ? this.mycurpipe.transform(partsReportData.projection_warranty_cost, 0)
                        : "",
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.ROUND,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.YEAR,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.PREDISC,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.POSTDISC,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.DISCEFFECT,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_DISCOUNT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: this.markupToPercentage(partsReportData.pre_discount_markup, true),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.markupToPercentage(
                        partsReportData.calculated_warranty_markup,
                        true
                      ),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FSD_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.markupToPercentage(
                        partsReportData.projection_warranty_markup,
                        true
                      ),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory
                      ? partsPreAuditReviewStatusObj.latestDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory
                      ? partsPreAuditReviewStatusObj.latestDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory &&
                    partsPreAuditReviewStatusObj.latestDiscountHistory.discount_effect
                      ? parentData.markupPercentage(
                          partsPreAuditReviewStatusObj.latestDiscountHistory.discount_effect
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_AUDIT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: this.markupToPercentage(partsReportData.pre_audit_retail_markup, true),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  colSpan: 2,
                  rowSpan: 1,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },

                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory
                      ? partsPreAuditReviewStatusObj.secondDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory
                      ? partsPreAuditReviewStatusObj.secondDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.secondDiscountHistory.pre_discount_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.secondDiscountHistory.post_audit_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory &&
                    partsPreAuditReviewStatusObj.secondDiscountHistory.discount_effect
                      ? parentData.markupPercentage(
                          partsPreAuditReviewStatusObj.secondDiscountHistory.discount_effect
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PROJECTED,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: parentData.primaryBundleData["parts_no_projection_status"]
                    ? "NA"
                    : this.markupToPercentage(partsReportData.projection_retail_markup, true),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  colSpan: 2,
                  rowSpan: 1,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },

                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory
                      ? partsPreAuditReviewStatusObj.thirdDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory
                      ? partsPreAuditReviewStatusObj.thirdDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.thirdDiscountHistory.pre_discount_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory
                      ? parentData.markupToPercentageConversion(
                          partsPreAuditReviewStatusObj.thirdDiscountHistory.post_audit_rate,
                          true
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    partsPreAuditReviewStatusObj &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory &&
                    partsPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect
                      ? parentData.markupPercentage(
                          partsPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.PREAUDIT_DISCOUNT_MEMO,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: self.partsReportData
                    ? this.isExistDmsDiscountEffect(
                        parentData.linkProjectObj.dms_code,
                        partsReportData.pre_audit_discount_effect
                      )
                    : "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  colSpan: 2,
                  rowSpan: 1,
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CUST_STATED_RATE,
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.is_msrp !== true &&
                        partsReportData &&
                        partsReportData.cust_stated_warranty_markup !== null
                          ? this.markupToPercentage(
                              partsReportData.cust_stated_warranty_markup,
                              true
                            )
                          : self.preauditReview &&
                              self.preauditReview.rule_data &&
                              self.preauditReview.rule_data.is_msrp == true
                            ? "MSRP"
                            : "",
                      alignment: "right",
                      color:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.is_msrp === true &&
                        self.partsPreAuditReviewStatusObj &&
                        self.partsPreAuditReviewStatusObj.msrpPreauditStatus === "M" &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail &&
                        self.partsPreAuditReviewStatusObj.msrp_pre_audit_rule_detail
                          .pre_audit_wo_de_msrp === false
                          ? "red"
                          : "black",
                    },
                  ],
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                  fillColor: "#FFFFE0",
                },

                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text:
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    self.preauditReview.rule_data.discount_history &&
                    self.preauditReview.rule_data.discount_history.length > 3
                      ? self.preauditReview.rule_data.discount_history.length - 3 + " more exist"
                      : "Discounting History",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 2,
                  rowSpan: 1,
                  text:
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ADDITIONAL_MU +
                    (parentData.primaryBundleData["uplift_markup"]
                      ? this.myperpipe.transform(
                          (parentData.primaryBundleData["uplift_markup"] * 100).toFixed(2)
                        )
                      : "NA"),
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
            ],
          },
        },
        {
          style: "impactReportTable",
          table: {
            heights: [25],
            widths: ["*", "*", "*", "*", "*", "*", "*"],
            border: [false, false, false, true],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PERIOD,
                  style: "impactReportList",
                  alignment: "right",
                  border: [true, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PARTS_COST,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_IC,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ID,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SD,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ED,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_UPLIFT,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, true, true],
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 1;
            },
            vLineWidth: function (i: any, node: any) {
              return 1;
            },
            hLineColor: function (i: any, node: any) {
              return "black";
            },
            vLineColor: function (i: any, node: any) {
              return "black";
            },
          },
        },
        {
          style: "impactReportTableValue",
          table: {
            heights: cellHeights,
            widths: ["*", "*", "*", "*", "*", "*", "*"],
            body: loopData,
          },
          layout: {},
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 10, 180, "*"],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ANALIZED_UPLIFT,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: partsReportData.annual_uplift_amount
                    ? this.mycurpipe.transform(partsReportData.annual_uplift_amount, 0)
                    : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  colSpan: 1,
                  rowSpan: 4,
                  text:
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_RETAIL_SUMMARY_BOX +
                    this.getOverRideLabel(partsReportData, false, ""),
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SUBMISSION_FEE,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: partsReportData.submission_fee
                    ? this.mycurpipe.transform(partsReportData.submission_fee, 0)
                    : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                "",
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MONTHLY_RECURRING_FEE,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: partsReportData.recurring_fee
                    ? this.mycurpipe.transform(partsReportData.recurring_fee, 0)
                    : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                "",
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PAYBACK_PERIOD,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, true],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                {
                  text: this.commonService.get2DecimalValue(partsReportData.payback_period),
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                "",
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 10, 180, 400, "*"],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MEGE_SCE_NAME,
                  style: "section3",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: mageImpactReportData.scenario_name,
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  // colSpan: 1, rowSpan: 2, text: this.constantService.SITE_COPYRIGHT_MESSAGE, style: 'section3_1', alignment: 'left', border: [true, false, true, true], margin: [0, 0, 0, 0]
                  colSpan: 1,
                  rowSpan: 2,
                  text: "",
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
                {
                  colSpan: 1,
                  rowSpan: 2,
                  text: "Data Date: " + newDate,
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MEGE_SCE_KEY,
                  style: "section3",
                  alignment: "left",
                  border: [true, false, false, true],
                },
                {
                  text: ":",
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                {
                  text: parentData.linkProjectObj.scenariokey,
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                "",
                "",
              ],
            ],
          },
        },
      ],
      styles: {
        reportHeader: {
          fontSize: 14,
          bold: true,
          alignment: "left",
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          color: "black",
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
          color: "black",
        },
        table1: {
          margin: [10, 10, 0, 0],
          fontSize: 12,
          color: "black",
        },
        table2: {
          margin: [10, 0, 0, 0],
          fontSize: 12,
          border: [true, false, true, true],
          color: "black",
        },
        topHeaderCell2: {
          fontSize: 16,
          bold: true,
          alignment: "center",
          color: "black",
        },
        topHeaderCell2_1: {
          fontSize: 12,
          bold: true,
          alignment: "center",
          color: "black",
        },
        topHeaderCell: {
          alignment: "left",
          fontSize: 10,
          color: "black",
        },
        topHeaderCellYellow: {
          alignment: "left",
          fontSize: 10,
          bold: true,
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        topHeaderCellYellow_1: {
          fontSize: 10,
          bold: true,
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        section3: {
          alignment: "left",
          fontSize: 10,
          border: [true, false, true, true],
          color: "black",
        },
        section3_1: {
          alignment: "center",
          fontSize: 10,
          border: [true, false, true, true],
          color: "black",
        },
        styleBold: {
          bold: true,
          color: "black",
        },
        impactReportTable: {
          margin: [10, 0, 0, 0],
          fontSize: 12,
          color: "black",
        },
        impactReportTableValue: {
          margin: [10, 0, 0, 0],
          fontSize: 10,
          color: "black",
        },
        hederSectionOrange_1: {
          fillColor: this.DMConstantService.IMPACT_TDBG_WHITE,
          fontSize: 10,
          margin: [0, 4, 0, 0],
          color: "black",
        },
        hederSectionOrange_2: {
          fillColor: this.DMConstantService.IMPACT_TDBG_WHITE,
          fontSize: 10,
          margin: [0, 4, 0, 0],
          color: "black",
        },
        region3Margin: {
          margin: [0, 10, 0, 10],
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        region2Margin: {
          margin: [0, 15, 0, 0],
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        impactReportList: {
          bold: true,
          margin: [0, 10, 0, 0],
          color: "black",
        },
      },
    };
    let pdfObject: any = inActivePostAuditProjectionReport;
    if (partsPreAuditReviewStatusObj && partsPreAuditReviewStatusObj.hasActivePostAudit === false) {
      pdfObject = inActivePostAuditProjectionReport;
    } else {
      pdfObject = activePostAuditProjectionReport;
    }
    if (action === "upload") {
      if (parentData.linkProjectObj && parentData.linkProjectObj.id) {
        const self = this;
        pdfMake.createPdf(pdfObject).getBase64(function (encodedString) {
          self.commonService.fileUploadToSolve(
            encodedString,
            fileName_ws + ".pdf",
            parentData.linkProjectObj.id,
            (data: any) => {
              parentData.setLocalStorageFileCount("-1", "parts");
              if (data && data["fileUpload"] && data["fileUpload"].status === "success") {
                parentData.setLocalSolveFileRefs("parts", reportName, data["fileUpload"].id, false);
              }
              if (data && data["fileUpload"] && parseInt(localStorage.getItem("uploadPartsFileCount") as string) === 0) {
                parentData.setFileReferenceData("parts", reportName, data["fileUpload"].id);
              }
            }
          );
        });
      }
    } else if (action === "uploadPS") {
      if (parentData.linkProjectObj && parentData.linkProjectObj.id) {
        const self = this;
        pdfMake.createPdf(pdfObject).getBase64(function (encodedString) {
          const fileName = parentData.getDownloadProjectName(fileName_ws, "Parts UL");
          self.commonService.fileUploadToSolve(
            encodedString,
            fileName + ".pdf",
            parentData.linkProjectObj.id,
            (data: any) => {
              parentData.setLocalStorageFileCount("-1", "parts");
              if (data && data["fileUpload"] && data["fileUpload"].status === "success") {
                parentData.setLocalSolveFileRefs("parts", reportName, data["fileUpload"].id, false);
              }
              if (data && data["fileUpload"] && parseInt(localStorage.getItem("uploadPartsFileCount") as string) === 0) {
                parentData.setFileReferenceData("parts", reportName, data["fileUpload"].id);
              }
            }
          );
        });
      }
    } else if (action === "attachment") {
      const doc = pdfMake.createPdf(pdfObject);
      const pdfName = fileName_ws + ".pdf";
      // doc.getBlob((blob) => {
      //     blob = new Blob([blob]);
      //     callback({
      //       fileName:pdfName,
      //       file:blob
      //     });
      // });

      pdfMake.createPdf(pdfObject).getBase64(function (encodedString) {
        // console.log('getBase64 pdf====>>>>>>>>>>>>',encodedString);
        const base64AttachmentString = encodedString;
        callback({
          filename: pdfName,
          path: base64AttachmentString,
        });
      });
    } else {
      let fileName = parentData.getDownloadProjectName(fileName_ws, "Parts UL");
      fileName = impactFileName ? impactFileName : fileName;
      pdfMake.createPdf(pdfObject).download(fileName + ".pdf");
    }
  };

  transformDateMMDDYYYY(date: any) {
    let newDate = "";
    if (date && date !== null && date !== "") {
      const d = moment(date);
      newDate = d.tz(moment.tz.guess()).format("MM-DD-YYYY");
    }
    return newDate;
  }
  numberDecimalFormatter(value: any, fixedto: any) {
    let returnValue = "";
    if (!fixedto || fixedto == "" || isNaN(value)) {
      fixedto = 0;
    }
    if (value != "" && value != null) {
      if (!isNaN(value)) {
        returnValue = value.toFixed(fixedto);
      }
    }
    return returnValue;
  }
  markupToPercentage(markup: any, symbol = false) {
    let percentage = markup;
    if (markup != "" && markup != null) {
      percentage = ((markup - 1) * 100).toFixed(2);
      if (symbol === true) {
        percentage = percentage + " %";
      }
      return percentage;
    } else {
      return "";
    }
  }

  getOverRideLabel(partsReportData: any, type: any, bType: any) {
    // console.log("partsReportData", partsReportData, type);
    let retMessage = "";
    if (type) {
      if (bType == "basisCost") {
        if (partsReportData.has_cost_override) {
          retMessage = "(O): ";
        } else {
          retMessage = ": ";
        }
      } else if (bType == "basisRate") {
        if (partsReportData.has_markup_override) {
          retMessage = "(O): ";
        } else {
          retMessage = ": ";
        }
      }
    } else {
      if (partsReportData.is_basis_overridden) {
        retMessage = " " + partsReportData.override_comment;
      }
    }
    return retMessage;
    // console.log(partsReportData.is_basis_overridden);
    // console.log(partsReportData.override_comment );
  }

  isExistDmsDiscountEffect(dms: any, discount_effect: any) {
    const dmsList = [
      "ucs",
      "pbs",
      "mpk",
      "quorum",
      "kengarff",
      "adv",
      "advantage",
      "dpc",
      "tekion",
    ];
    // console.log('dms====>>>',dms);
    if (dms && dmsList.indexOf(dms.toLowerCase().trim()) >= 0) {
      return "Predictive Discouting N/A";
    } else if (discount_effect) {
      return this.markupPercentage(discount_effect);
    } else {
      return "0 %";
    }
  }
  markupPercentage(value: any) {
    return value !== null ? (value * 100).toFixed(2) + " %" : null;
  }
}
