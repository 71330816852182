import { NgModule } from "@angular/core";
import { CommonService } from "./structure/services/common.service";
import { environment } from "src/environments/environment";

import { ApolloModule, APOLLO_OPTIONS, Apollo } from "apollo-angular";
import {
  ApolloClientOptions,
  InMemoryCache,
  split,
  ApolloLink,
  ApolloError,
  createHttpLink,
} from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "apollo-utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

let authclient: any;
let duMapperClient: any;
let authorizationLink: any;
let portaldbClient: any;
let authorizationClient: any;
let subscriptionClients360: any;
let duJobsClient: any;
let dashboardClient: any;

@NgModule({
  exports: [ApolloModule],
  providers: [],
})
export class GraphQLModule {
  constructor(
    apollo: Apollo,
    public commonService: CommonService
  ) {
    /** Subscription Section */
    const ws = new WebSocketLink({
      uri: environment.webSocketLink,
      options: {
        reconnect: true,
        timeout: 90000,
        lazy: false,
        connectionParams: {
          authToken: localStorage.getItem("token") || "",
        },
      },
    });
    /** End Section */

    const error = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        this.commonService.errorCallback(graphQLErrors, this);
      }
      if (networkError) {
        this.commonService.errorCallback(networkError.toString(), this);
      }
    });

    const middleware = new ApolloLink((operation: any, forward: any) => {
      if (!localStorage.getItem("token")) {
        location.href = environment.domainUrl + "/#/login";
        location.reload();
        return;
      }
      const token = localStorage.getItem("token");
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      });
      return forward(operation);
    });

    /** End Section */

    let http: any = createHttpLink({
      uri: environment.domainUrl + "/backend/ggs",
      fetch: fetch,
    });
    const link = middleware.concat(http);
    const ggsLink = error.concat(link);
    const authclientlink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      ws,
      ggsLink
    );
    authclient = apollo.createNamed("authclient", {
      link: authclientlink,
      cache: new InMemoryCache() as any,
    });

    const dashBoardHttp = createHttpLink({
      uri: environment.domainUrl + "/backend/s360",
      fetch: fetch,
    });
    const linkDashboard = middleware.concat(dashBoardHttp);
    const s360Link = error.concat(linkDashboard);
    const dashboardClientLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      ws,
      s360Link
    );
    dashboardClient = apollo.createNamed("dashboardClient", {
      link: dashboardClientLink,
      cache: new InMemoryCache(),
    });

    const portalHttp = createHttpLink({
      uri: environment.domainUrl + "/backend/portal",
      fetch: fetch,
    });
    const linkPortal = middleware.concat(portalHttp);
    const portalLink = error.concat(linkPortal);
    const portaldbClientLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      ws,
      portalLink
    );
    portaldbClient = apollo.createNamed("portaldbClient", {
      link: portaldbClientLink,
      cache: new InMemoryCache(),
    });

    const authHttp = createHttpLink({
      uri: environment.domainUrl + "/backend/auth",
      fetch: fetch,
    });
    const linkAuth = middleware.concat(authHttp);
    const authLink = error.concat(linkAuth);
    const authorizationClientLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      ws,
      authLink
    );
    authorizationClient = apollo.createNamed("authorizationClient", {
      link: authorizationClientLink,
      cache: new InMemoryCache(),
    });

    const subscriptionClient = createHttpLink({
      uri: environment.domainUrl + "/backend/solve360web/graphql",
      fetch: fetch,
    });
    const linkAuthSolve360 = middleware.concat(subscriptionClient);
    const authLinkS360 = error.concat(linkAuthSolve360);
    const s360link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      ws,
      authLinkS360
    );

    subscriptionClients360 = apollo.createNamed("solve360updation", {
      link: s360link,
      cache: new InMemoryCache(),
    });

    const duMapperHttp = createHttpLink({
      uri: environment.domainUrl + "/backend/duMapper",
      fetch: fetch,
    });
    const linkduMapper = middleware.concat(duMapperHttp);
    const authLinkduMapper = error.concat(linkduMapper);
    const duMapperlink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      ws,
      authLinkduMapper
    );

    duMapperClient = apollo.createNamed("duMapperClient", {
      link: duMapperlink,
      cache: new InMemoryCache(),
    });

    const duJobsHttp = createHttpLink({
      uri: environment.domainUrl + "/backend/duJobs",
      fetch: fetch,
    });
    const linkduJobs = middleware.concat(duJobsHttp);
    const authLinkduJobs = error.concat(linkduJobs);
    const duJobslink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      ws,
      authLinkduJobs
    );

    duJobsClient = apollo.createNamed("duJobsClient", {
      link: duJobslink,
      cache: new InMemoryCache(),
    });
    commonService.init();
  }
}
export function provideClients() {
  return {
    default: authclient,
    dashboardClient: dashboardClient,
    authorizationClient: authorizationClient,
    portaldbClient: portaldbClient,
    subscriptionClients360: subscriptionClients360,
    duMapperClient: duMapperClient,
    duJobsClient: duJobsClient,
  };
}
