import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { MsalGuard, MsalService } from "@azure/msal-angular";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private msalService: MsalService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url && state.url != "/" && state.url != "/login") {
      localStorage.setItem("previousUrl", state.url);
    }
    return true;
  }
}
