import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SolveSyncRoutingModule } from "./solve-sync-routing.module";
import { SolveSyncComponent } from "./solve-sync/solve-sync.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [SolveSyncComponent],
  imports: [CommonModule, SolveSyncRoutingModule, SharedModule],
})
export class SolveSyncModule {}
