import { Directive, HostListener, ElementRef, OnInit, Input } from "@angular/core";
import { MyCurrencyPipe } from "./my-currency.pipe";

@Directive({
  selector: "[myCurrencyFormatter]",
  host: {
    "[style.text-align]": '"right"',
  },
})
export class MyCurrencyFormatterDirective implements OnInit {
  @Input("integerOnly") integerOnly!: boolean;

  private el: any;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: MyCurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    if (this.integerOnly) {
      this.el.value = this.currencyPipe.transform(this.el.value, 0);
    } else {
      this.el.value = this.currencyPipe.transform(this.el.value);
    }
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value: any) {
    if (this.integerOnly) {
      this.el.value = this.currencyPipe.parse(value, 0); // opossite of transform
    } else {
      this.el.value = this.currencyPipe.parse(value); // opossite of transform
    }
    console.log('currency focus', this.el.value);
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value: any) {
    if (this.integerOnly) {
      this.el.value = this.currencyPipe.transform(value, 0);
    } else {
      this.el.value = this.currencyPipe.transform(value);
    }
    console.log('currency blur', this.el.value);
  }
}
