import { Injectable } from "@angular/core";
// import { Http, Headers, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/map";
// import { Logger } from "angular2-logger/core";
// import { environment } from "environments/environment";
import { ConstantService } from "../constants/constant.service";
import { DMConstantService } from "../constants/dm.constant.service";
import { CommonService } from "../services/common.service";
import * as moment from "moment-timezone";

declare var $: any;
declare var jQuery: any;
declare var XLSX: any;

@Injectable()
export class ProjectionReportServiceV2 {
  public projectionDataList: any[] = [];
  public storeGroup: any = [];
  public snapShotVersion!: any[];
  public snapShotData: any;
  constructor(
    // private http: Http,
    // private _logger: Logger,
    private constantService: ConstantService,
    private commonService: CommonService,
    private DMConstantService: DMConstantService
  ) {}
  init() {
    //
  }
  downloadProjectionReportV2(
    projectionColumnsList: any[],
    data: any[],
    storeGroupData: any[],
    totalDataRow: any,
    imgData: any,
    type: string,
    snapShotVersion: any,
    snapShotData: string
  ) {
    const footerFlag = false;
    this.storeGroup = storeGroupData;
    this.projectionDataList = data;
    this.snapShotVersion = snapShotVersion;
    this.snapShotData = snapShotData;
    const csvData = [{}, {}, {}];
    csvData.push({
      0: "Store",
      1: "",
      2: "",
      3: "",
      4: " Annual \n Warranty \n Parts \n Cost of \n Goods Sold",
      5: " Customer \n Pay \n Gross \n Profit %",
      6: " Effective \n Warranty \n Gross \n Profit %",
      7: " Customer \n Pay Gross \n Profit % \n as Markup",
      8: " Effective \n Warranty \n Gross \n Profit % \n as Markup",
      //9: '',
      // 8: ' Cust. \n Pay vs. \n Warranty \n Markup \n Points Delta',
      // 9: ' Annual \n Uplift \n (COGS x \n Points \n Delta)',
      // 10: ' Statutory \n & \n Armatus \n Impact \n Factor \n (Markup Points)',
      9: " Additional \n Markup Points",
      10: " Projected \n Markup",
      //12: '',
      // 12: ' Add\'l Points \n Above \n Current Eff. \n Warranty Markup',
      11: " Monthly Uplift",
      12: " Annual \n Uplift",
      13: " Factory \n Behavior \n Adjustment \n (Markup Points)",
      14: " Markup \n Increase \n Net of \n Factory \n Behavior",
      15: " Net \n Projected \n Markup",
      16: "Projected Monthly Profit Uplift",
      17: " Projected \n Annual \n Profit \n Uplift",
      18: " Post \n Approval \n Submission \n Fee*",
      19: " Monthly \n Uplift \n Fee - 12 \n Months",
      20: " Total \n Profit \n Acquisition \n Cost",
      21: " Payback \n Period - ALL \n FEES \n (months)",
    });
    let brandName: any = "";
    let incrementer = 0;
    for (let i in this.projectionDataList) {
      if (this.projectionDataList) {
        incrementer++;
        if (type === "cp") {
          brandName = this.projectionDataList[i]["brand"]
            ? incrementer + "    " + this.projectionDataList[i]["brand"]
            : " " + incrementer;
        } else {
          brandName = this.projectionDataList[i]["brand"]
            ? this.projectionDataList[i]["brand"]
            : "";
        }
        csvData.push({
          0: brandName,
          1: this.projectionDataList[i]["msrp"] ? this.projectionDataList[i]["msrp"] : "",
          2: this.projectionDataList[i]["state"] ? this.projectionDataList[i]["state"] : null,
          3: this.projectionDataList[i]["stName"] ? this.projectionDataList[i]["stName"] : null,
          4:
            this.projectionDataList[i]["annualWarrPartsCost"] != null
              ? this.projectionDataList[i]["annualWarrPartsCost"]
              : null,
          5:
            this.projectionDataList[i]["cpPartsGrossProfit"] != null
              ? this.projectionDataList[i]["cpPartsGrossProfit"]
              : null,
          6:
            this.projectionDataList[i]["effWpGrossProfit"] != null
              ? this.projectionDataList[i]["effWpGrossProfit"]
              : null,
          7:
            this.projectionDataList[i]["cpGpMarkup"] != null
              ? this.projectionDataList[i]["cpGpMarkup"]
              : null,
          8:
            this.projectionDataList[i]["effWpGrossProfitMarkup"] != null
              ? this.projectionDataList[i]["effWpGrossProfitMarkup"]
              : null,
          9:
            this.projectionDataList[i]["addlPtsAbvCurrEffWm"] != null
              ? this.projectionDataList[i]["addlPtsAbvCurrEffWm"]
              : null,
          // 10: this.projectionDataList[i]['addlAnnualWpProfit'] != null ? this.projectionDataList[i]['addlAnnualWpProfit'] : null,
          //9: null,
          // 9: this.projectionDataList[i]['stStatuteAif'] != null ? this.projectionDataList[i]['stStatuteAif'] : null,
          10:
            this.projectionDataList[i]["projectedWpMarkup"] != null
              ? this.projectionDataList[i]["projectedWpMarkup"]
              : null,
          //12: null,
          // 12: this.projectionDataList[i]['addlPtsAbvCurrEffWm'] != null ? this.projectionDataList[i]['addlPtsAbvCurrEffWm'] : null,
          11:
            this.projectionDataList[i]["stEntitledMonthlyProfit"] != null
              ? this.projectionDataList[i]["stEntitledMonthlyProfit"]
              : null,
          12:
            this.projectionDataList[i]["stEntitledAnnlProfit"] != null
              ? this.projectionDataList[i]["stEntitledAnnlProfit"]
              : null,
          13:
            this.projectionDataList[i]["factBehaviorFactor"] != null
              ? this.projectionDataList[i]["factBehaviorFactor"]
              : null,
          14:
            this.projectionDataList[i]["muIncrNetOfFactBeh"] != null
              ? this.projectionDataList[i]["muIncrNetOfFactBeh"]
              : null,
          15:
            this.projectionDataList[i]["netProjectedMu"] != null
              ? this.projectionDataList[i]["netProjectedMu"]
              : null,
          16:
            this.projectionDataList[i]["projectedMonthlyPu"] != null
              ? this.projectionDataList[i]["projectedMonthlyPu"]
              : null,
          17:
            this.projectionDataList[i]["projectedAnnlProfitUplift"] != null
              ? this.projectionDataList[i]["projectedAnnlProfitUplift"]
              : null,
          18:
            this.projectionDataList[i]["postApprSubmissionFee"] != null
              ? this.projectionDataList[i]["postApprSubmissionFee"]
              : null,
          19:
            this.projectionDataList[i]["monthlyUpliftFee"] != null
              ? this.projectionDataList[i]["monthlyUpliftFee"]
              : null,
          20:
            this.projectionDataList[i]["totalProfitAcqCost"] != null
              ? this.projectionDataList[i]["totalProfitAcqCost"]
              : null,
          // 24: this.projectionDataList[i]['netWpAddlPuYr1'] != null ? this.projectionDataList[i]['netWpAddlPuYr1'] : null,
          21:
            this.projectionDataList[i]["paybackPeriod"] != null
              ? this.projectionDataList[i]["paybackPeriod"]
              : null,
        });
      }
    }
    let fileName_ws = "";
    let fileName_ws2 = "";

    let fileName = "";
    let snapShotCreatedTime = null;
    if (type === "cp") {
      let snapShotName = this.snapShotVersion[0].description;
      if (this.snapShotVersion[0].id < 0) {
        snapShotName = this.snapShotData;
      }
      snapShotName = snapShotName + "_";
      fileName = "ProjectionV2_" + snapShotName + moment().format("YYYYMMDDhhmmss") + ".xlsx";
      fileName_ws = "No behaviors";
      fileName_ws2 = "With behaviors";
      snapShotCreatedTime = moment(this.snapShotVersion[0].createdAt).format(
        "MM-DD-YYYY hh:mm:ss A"
      );
    } else {
      fileName = "ProjectionV2_" + moment().format("YYYYMMDDhhmmss") + ".xlsx";
      fileName_ws = "No behaviors";
      fileName_ws2 = "With behaviors";
    }
    const ws_name = fileName_ws;
    const ws2_name = fileName_ws2;

    const wb = XLSX.utils.book_new();
    wb.Sheets = {};
    wb.SheetNames = [];

    const ws: any = XLSX.utils.json_to_sheet(csvData);
    this.createSheetProcess(
      ws,
      projectionColumnsList,
      footerFlag,
      totalDataRow,
      type,
      snapShotCreatedTime,
      csvData,
      imgData,
      true,
      ws_name
    );
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    const ws2: any = XLSX.utils.json_to_sheet(csvData);
    this.createSheetProcess(
      ws2,
      projectionColumnsList,
      footerFlag,
      totalDataRow,
      type,
      snapShotCreatedTime,
      csvData,
      imgData,
      false,
      ws2_name
    );
    wb.SheetNames.push(ws2_name);
    wb.Sheets[ws2_name] = ws2;
    const op = { wb: wb, fileName: fileName };
    return op;
  }

  createSheetProcess(
    ws: any,
    projectionColumnsList: string | any[],
    footerFlag: boolean,
    totalDataRow: {
      armatusOptimizedUplift: null;
      factoryAdjustedUplift: null;
      rOIInMonths: null;
    },
    type: string,
    snapShotCreatedTime: string | null,
    csvData: any,
    imgData: any,
    noBehaviorSheetFlag: boolean,
    sheetName: string
  ) {
    const wscols = [
      { wch: type === "cp" ? 5.5 : 11 }, // "characters"
      { wch: 5.5 },
      { wch: 5.5 },
      { wch: 30 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 11 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
    ];
    /* At 96 PPI, 1 pt = 1 px */
    const wsrows = [
      { hpx: 77, level: 1 },
      { hpx: 75, level: 2 },
      { hpx: 50, level: 3 },
      { hpx: 30, level: 4 },
      { hpx: 100, level: 5 },
    ];
    for (let i = 0; i < this.projectionDataList.length + 5; i++) {
      if (i < this.projectionDataList.length) {
        const obj = { hpx: 50, level: i + 6 };
        wsrows.push(obj);
      } else {
        const obj = { hpx: 40, level: i + 6 };
        wsrows.push(obj);
      }
    }
    ws["!width"] = "1 page";
    ws["!cols"] = wscols;
    for (let i = 0; i < projectionColumnsList.length; i++) {
      if (!projectionColumnsList[i]) {
        ws["!cols"][i] = { hidden: true };
      }
    }

    // ws['!cols'][9] = { hidden: true };
    // ws['!cols'][12] = { hidden: true };

    if (noBehaviorSheetFlag) {
      ws["!cols"][13] = { hidden: true };
      ws["!cols"][14] = { hidden: true };
      ws["!cols"][15] = { hidden: true };
      ws["!cols"][16] = { hidden: true };
      ws["!cols"][17] = { hidden: true };
    }

    ws["!pageSetup"] = { orientation: "landscape", scale: "100" };

    /**
     * Set worksheet sheet to "narrow".
     */
    ws["!margins"] = {
      left: 0.25,
      right: 0.25,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };
    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 21 } },
      { s: { r: 1, c: 4 }, e: { r: 1, c: 21 } },
      { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
      { s: { r: 2, c: 4 }, e: { r: 2, c: 6 } },
      { s: { r: 2, c: 7 }, e: { r: 2, c: 21 } },

      { s: { r: 3, c: 0 }, e: { r: 3, c: 0 } },
      { s: { r: 3, c: 1 }, e: { r: 3, c: 1 } },
      { s: { r: 3, c: 2 }, e: { r: 3, c: 2 } },
      { s: { r: 3, c: 3 }, e: { r: 3, c: 3 } },
      { s: { r: 3, c: 4 }, e: { r: 3, c: 8 } },
      { s: { r: 3, c: 9 }, e: { r: 3, c: 21 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: 3 } },

      {
        s: { r: this.projectionDataList.length + 5, c: 0 },
        e: { r: this.projectionDataList.length + 6, c: 7 },
      },

      {
        s: { r: this.projectionDataList.length + 5, c: 8 },
        e: { r: this.projectionDataList.length + 6, c: 8 },
      },

      {
        s: { r: this.projectionDataList.length + 5, c: 9 },
        e: { r: this.projectionDataList.length + 5, c: 12 },
      },

      {
        s: { r: this.projectionDataList.length + 5, c: 13 },
        e: { r: this.projectionDataList.length + 5, c: 17 },
      },
      {
        s: { r: this.projectionDataList.length + 5, c: 18 },
        e: { r: this.projectionDataList.length + 5, c: 21 },
      },

      {
        s: { r: this.projectionDataList.length + 6, c: 9 },
        e: { r: this.projectionDataList.length + 6, c: 12 },
      },
      {
        s: { r: this.projectionDataList.length + 6, c: 13 },
        e: { r: this.projectionDataList.length + 6, c: 17 },
      },
      {
        s: { r: this.projectionDataList.length + 6, c: 18 },
        e: { r: this.projectionDataList.length + 6, c: 21 },
      },
    ];
    let footerRow: any = "";
    if (footerFlag) {
      footerRow = {
        s: { r: this.projectionDataList.length + 7, c: 0 },
        e: { r: this.projectionDataList.length + 7, c: 21 },
      };
      ws["!merges"].push(footerRow);
    }
    ws["!rows"] = wsrows;
    ws["!ref"] = "A1:V" + (this.projectionDataList.length + 8);
    const border = {
      top: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    const border_noTop = {
      top: { style: "", color: { auto: 0 } },
      right: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    const border_noBottom = {
      top: { style: "thin", color: { auto: 1 } },
      right: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
      bottom: { style: "", color: { auto: 0 } },
    };
    const border_Bottom_only = {
      top: { style: "", color: { auto: 0 } },
      right: { style: "", color: { auto: 0 } },
      left: { style: "", color: { auto: 0 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    const alignment = {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    };
    let font = {
      name: "Arial",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: false,
      italic: false,
      underline: false,
    };

    // ws['A1'] = { t: 's', v: ' ' };
    ws["A2"] = { t: "s", v: " " };
    ws["A3"] = { t: "s", v: " " };

    ws["A1"] = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.EXCEL_HEADING,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial",
          sz: 29,
          color: { rgb: "0b5394" },
          bold: true,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
        alignment: alignment,
      },
    };
    ws["E2"] = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.EXCEL_DESCRIPTION,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial",
          sz: 10,
          color: { rgb: "0b5394" },
          bold: false,
          italic: true,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
        alignment: { wrapText: true, vertical: "center", horizontal: "left" },
      },
    };

    ws["E3"] = {
      t: "s",
      v: "Group Name:",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: true,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
        alignment: { wrapText: true, vertical: "center", horizontal: "right" },
      },
    };
    ws["H3"] = {
      t: "s",
      v: this.storeGroup[0].itemName,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: true,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
        alignment: { wrapText: true, vertical: "center", horizontal: "left" },
      },
    };

    ws["Y1"] = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
      },
    };

    ws["Y2"] = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
      },
    };

    ws["Y3"] = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          right: { style: "thin", color: { auto: 1 } },
        },
      },
    };

    ws["A4"] = { t: "s", v: " " };
    ws["B4"] = { t: "s", v: " " };
    ws["C4"] = { t: "s", v: " " };
    ws["D4"] = { t: "s", v: " " };

    const head1 = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.EXCEL_HEADING1,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial",
          sz: 16,
          color: { rgb: "#FF0000" },
          bold: true,
          italic: false,
          underline: false,
        },
        border: border,
        alignment: alignment,
      },
    };

    ws["E4"] = head1;
    ws["F4"] = head1;
    ws["G4"] = head1;
    ws["H4"] = head1;
    ws["I4"] = head1;

    const head3 = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.EXCEL_HEADING3,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "cfe2f3" },
          bgColor: { rgb: "cfe2f3" },
        },
        font: {
          name: "Arial",
          sz: 16,
          color: { rgb: "#FF0000" },
          bold: true,
          italic: false,
          underline: false,
        },
        border: border,
        alignment: alignment,
      },
    };

    ws["J4"] = head3;
    ws["K4"] = head3;
    ws["L4"] = head3;
    ws["M4"] = head3;
    ws["N4"] = head3;
    ws["O4"] = head3;
    ws["P4"] = head3;
    ws["Q4"] = head3;
    ws["R4"] = head3;
    ws["S4"] = head3;
    ws["T4"] = head3;
    ws["U4"] = head3;
    ws["V4"] = head3;

    // ws['W4'] = head3;
    // ws['X4'] = head3;

    const head4 = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
        border: {
          bottom: { style: "thin", color: { auto: 1 } },
        },
      },
    };

    ws["P3"] = head4;
    ws["Q3"] = head4;
    ws["R3"] = head4;
    ws["S3"] = head4;
    ws["T3"] = head4;
    ws["U3"] = head4;
    ws["V3"] = head4;

    ws["B4"] = head4;
    ws["C4"] = head4;
    ws["D4"] = head4;

    const head5 = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: {
          name: "Arial",
          sz: 14,
          color: { rgb: "0b5394" },
          bold: false,
          italic: false,
          underline: false,
        },
      },
    };

    //ws['A1'] = head5;
    ws["A2"] = head5;
    ws["D2"] = head5;
    ws["A3"] = head5;
    ws["B3"] = head5;
    ws["C3"] = head5;
    ws["D3"] = head5;

    let projObj = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.EXCEL_HEADING4,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "d9ead3" },
          bgColor: { rgb: "d9ead3" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };

    // ws['U4'] = projObj;
    // ws['V4'] = projObj;
    // ws['W4'] = projObj;
    // ws['X4'] = projObj;

    font = {
      name: "Arial",
      sz: 16,
      color: { rgb: "#FF0000" },
      bold: true,
      italic: false,
      underline: false,
    };
    const alignmentHead1 = {
      wrapText: true,
      vertical: "center",
      horizontal: "right",
    };

    projObj = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.FOOTER_SECTION_COLUMN_1,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border,
        alignment: alignmentHead1,
      },
    };

    ws["A" + (this.projectionDataList.length + 6)] = projObj;
    ws["A" + (this.projectionDataList.length + 7)] = projObj;
    ws["B" + (this.projectionDataList.length + 7)] = projObj;
    ws["C" + (this.projectionDataList.length + 7)] = projObj;
    ws["D" + (this.projectionDataList.length + 7)] = projObj;
    ws["E" + (this.projectionDataList.length + 7)] = projObj;
    ws["F" + (this.projectionDataList.length + 7)] = projObj;
    ws["G" + (this.projectionDataList.length + 7)] = projObj;

    const projObj_1 = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.FOOTER_SECTION_COLUMN_1,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border_Bottom_only,
        alignment: alignmentHead1,
      },
    };

    ws["H" + (this.projectionDataList.length + 7)] = projObj_1;

    const borderBottomOnly = {};
    const projObj_b = {
      t: "s",
      v: "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFFFF" },
          bgColor: { rgb: "FFFFFF" },
        },
        font: font,
        border: border_Bottom_only,
        alignment: alignmentHead1,
      },
    };

    ws["I" + (this.projectionDataList.length + 7)] = projObj_b;
    // ws['J' + (this.projectionDataList.length + 7)] = projObj_b;

    font = {
      name: "Arial",
      sz: 12,
      color: { rgb: "#FF0000" },
      bold: true,
      italic: false,
      underline: false,
    };

    projObj = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.FOOTER_SECTION_COLUMN_2,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "d9d9d9" },
          bgColor: { rgb: "d9d9d9" },
        },
        font: font,
        border: border,
        alignment: alignment,
      },
    };

    const font_1 = {
      name: "Arial",
      sz: 16,
      color: { rgb: "#FF0000" },
      bold: true,
      italic: false,
      underline: false,
    };

    projObj = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.FOOTER_SECTION_COLUMN_3,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "cfe2f3" },
          bgColor: { rgb: "cfe2f3" },
        },
        font: font_1,
        border: border_noTop,
        alignment: alignment,
      },
    };

    ws["J" + (this.projectionDataList.length + 7)] = projObj;
    ws["K" + (this.projectionDataList.length + 7)] = projObj;
    // ws['M' + (this.projectionDataList.length + 7)] = projObj;
    ws["L" + (this.projectionDataList.length + 7)] = projObj;
    ws["M" + (this.projectionDataList.length + 7)] = projObj;

    projObj = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.FOOTER_SECTION_COLUMN_4,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "cfe2f3" },
          bgColor: { rgb: "cfe2f3" },
        },
        font: font_1,
        border: border_noTop,
        alignment: alignment,
      },
    };

    ws["N" + (this.projectionDataList.length + 7)] = projObj;
    ws["O" + (this.projectionDataList.length + 7)] = projObj;
    ws["P" + (this.projectionDataList.length + 7)] = projObj;
    ws["Q" + (this.projectionDataList.length + 7)] = projObj;
    ws["R" + (this.projectionDataList.length + 7)] = projObj;

    projObj = {
      t: "s",
      v: this.DMConstantService.PROJECTION_REPORT_V2.FOOTER_SECTION_COLUMN_6,
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "D0E3F2" },
          bgColor: { rgb: "D0E3F2" },
        },
        font: font_1,
        border: border_noTop,
        alignment: alignment,
      },
    };

    ws["S" + (this.projectionDataList.length + 7)] = projObj;
    ws["T" + (this.projectionDataList.length + 7)] = projObj;
    ws["U" + (this.projectionDataList.length + 7)] = projObj;
    ws["V" + (this.projectionDataList.length + 7)] = projObj;

    const alignment_b = { wrapText: false, vertical: "", horizontal: "center" };

    ws["J" + (this.projectionDataList.length + 6)] = {
      t: totalDataRow.armatusOptimizedUplift !== null ? "n" : "s",
      v: totalDataRow.armatusOptimizedUplift !== null ? totalDataRow.armatusOptimizedUplift : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "cfe2f3" },
          bgColor: { rgb: "cfe2f3" },
        },
        font: font_1,
        border: border_noBottom,
        alignment: alignment_b,
        numFmt:
          totalDataRow.armatusOptimizedUplift !== null
            ? this.commonService.getDollarFormat(totalDataRow.armatusOptimizedUplift)
            : "",
      },
    };

    const alignment_b1 = {
      wrapText: false,
      vertical: "",
      horizontal: "center",
    };

    ws["N" + (this.projectionDataList.length + 6)] = {
      t: totalDataRow.factoryAdjustedUplift !== null ? "n" : "s",
      v: totalDataRow.factoryAdjustedUplift !== null ? totalDataRow.factoryAdjustedUplift : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "cfe2f3" },
          bgColor: { rgb: "cfe2f3" },
        },
        font: font_1,
        border: border_noBottom,
        alignment: alignment_b1,
        numFmt:
          totalDataRow.factoryAdjustedUplift !== null
            ? this.commonService.getDollarFormat(totalDataRow.factoryAdjustedUplift)
            : "",
      },
    };

    const alignment_b2 = {
      wrapText: false,
      vertical: "",
      horizontal: "center",
    };

    const projObj1 = {
      t: totalDataRow.rOIInMonths !== null ? "n" : "s",
      v: totalDataRow.rOIInMonths !== null ? totalDataRow.rOIInMonths : "",
      s: {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "D0E3F2" },
          bgColor: { rgb: "D0E3F2" },
        },
        font: font_1,
        border: border_noBottom,
        alignment: alignment_b2,
        numFmt:
          totalDataRow.rOIInMonths !== null
            ? this.commonService.get2DecimalPointFormat(totalDataRow.rOIInMonths)
            : "",
      },
    };

    ws["S" + (this.projectionDataList.length + 6)] = projObj1;
    ws["T" + (this.projectionDataList.length + 6)] = projObj1;
    ws["U" + (this.projectionDataList.length + 6)] = projObj1;
    ws["V" + (this.projectionDataList.length + 6)] = projObj1;

    let obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFFFF" },
        bgColor: { rgb: "FFFFFF" },
      },
      font: font,
      border: border,
      alignment: alignment,
    };

    ws["A5"].s = obj;
    ws["B5"].s = obj;
    ws["C5"].s = obj;

    font = {
      name: "Arial",
      sz: 11,
      color: { rgb: "#FF0000" },
      bold: false,
      italic: false,
      underline: false,
    };

    const alignmentHead1_1 = {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    };

    obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFFFF" },
        bgColor: { rgb: "FFFFFF" },
      },
      font: font,
      border: border,
      alignment: alignmentHead1_1,
    };

    ws["D5"].s = obj;
    ws["E5"].s = obj;
    ws["F5"].s = obj;
    ws["G5"].s = obj;
    ws["H5"].s = obj;
    ws["I5"].s = obj;
    // ws['J5'].s = obj;

    obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "cfe2f3" },
        bgColor: { rgb: "cfe2f3" },
      },
      font: font,
      border: border,
      alignment: alignmentHead1_1,
    };

    ws["J5"].s = obj;
    ws["K5"].s = obj;
    ws["L5"].s = obj;
    ws["M5"].s = obj;
    ws["O5"].s = obj;
    ws["P5"].s = obj;
    ws["Q5"].s = obj;
    ws["R5"].s = obj;
    ws["V5"].s = obj;

    // ws['S5'].s = obj;
    // ws['T5'].s = obj;

    obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "ffffff" },
        bgColor: { rgb: "ffffff" },
      },
      font: font,
      border: border,
      alignment: alignmentHead1_1,
    };

    // ws['P5'].s = obj;

    obj = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "D0E3F2" },
        bgColor: { rgb: "D0E3F2" },
      },
      font: font,
      border: border,
      alignment: alignmentHead1_1,
    };

    let obj_w = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFFFF" },
        bgColor: { rgb: "FFFFFF" },
      },
      font: font,
      border: border,
      alignment: alignmentHead1_1,
    };

    ws["N5"].s = obj_w;
    ws["S5"].s = obj_w;
    ws["T5"].s = obj_w;
    ws["U5"].s = obj_w;

    // ws['X5'].s = obj;
    if (footerFlag) {
      let footerText = this.constantService.EXCEL_FOOTER_DYNAMIC;
      footerText = footerText.replace(
        /{{footerName}}/i,
        this.storeGroup[0].salesPerson ? this.storeGroup[0].salesPerson : ""
      );
      footerText = footerText.replace(
        /{{footerEmail}}/i,
        this.storeGroup[0].spEmail ? this.storeGroup[0].spEmail : ""
      );
      footerText = footerText.replace(
        /{{footerPhone}}/i,
        this.storeGroup[0].spPhone ? this.storeGroup[0].spPhone : ""
      );
      const borderFooter: any = {
        top: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
      };
      const borderFooterTimeStamp = {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
      };
      const projObjFooter = {
        t: "s",
        v: footerText,
        s: {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
            bgColor: { rgb: "FFFFFF" },
          },
          font: {
            name: "Arial",
            sz: 12,
            color: { rgb: "0b5394" },
            bold: false,
            italic: false,
            underline: false,
          },
          border: type === "cp" ? borderFooter : border,
          alignment: alignment,
        },
      };

      ws["A" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["B" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["C" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["D" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["E" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["F" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["G" + (this.projectionDataList.length + 8)] = projObjFooter;

      const projObjFooter_1 = {
        t: "s",
        v: footerText,
        s: {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
            bgColor: { rgb: "FFFFFF" },
          },
          font: {
            name: "Arial",
            sz: 12,
            color: { rgb: "0b5394" },
            bold: false,
            italic: false,
            underline: false,
          },
          border: border_Bottom_only,
          alignment: alignment,
        },
      };

      ws["H" + (this.projectionDataList.length + 8)] = projObjFooter_1;

      const projObj_b1 = {
        t: "s",
        v: "",
        s: {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
            bgColor: { rgb: "FFFFFF" },
          },
          font: font,
          border: border_Bottom_only,
          alignment: alignmentHead1,
        },
      };

      ws["I" + (this.projectionDataList.length + 8)] = projObj_b1;
      // ws['J' + (this.projectionDataList.length + 8)] = projObj_b1;
      ws["K" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["L" + (this.projectionDataList.length + 8)] = projObjFooter;
      // ws['M' + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["N" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["O" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["P" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["Q" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["R" + (this.projectionDataList.length + 8)] = projObjFooter;
      ws["S" + (this.projectionDataList.length + 8)] = projObjFooter;

      const alignmentVersionNumber = {
        wrapText: true,
        vertical: "center",
        horizontal: "right",
      };

      let projObjTimestamp = {
        t: "s",
        v: snapShotCreatedTime
          ? this.DMConstantService.REPORT_VERSION_LABEL +
            this.commonService.randomVersionNumberGenerator(
              this.DMConstantService.REPORT_VERSION_FORMAT
            )
          : "",
        s: {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
            bgColor: { rgb: "FFFFFF" },
          },
          font: {
            name: "Arial",
            sz: 12,
            color: { rgb: "0b5394" },
            bold: false,
            italic: false,
            underline: false,
          },
          border: borderFooterTimeStamp,
          alignment: alignmentVersionNumber,
        },
      };

      ws["T" + (this.projectionDataList.length + 8)] =
        type === "cp" ? projObjTimestamp : projObjFooter;
      ws["U" + (this.projectionDataList.length + 8)] =
        type === "cp" ? projObjTimestamp : projObjFooter;
      ws["V" + (this.projectionDataList.length + 8)] =
        type === "cp" ? projObjTimestamp : projObjFooter;
      ws["W" + (this.projectionDataList.length + 8)] =
        type === "cp" ? projObjTimestamp : projObjFooter;
      ws["X" + (this.projectionDataList.length + 8)] =
        type === "cp" ? projObjTimestamp : projObjFooter;
    }

    const headeRange = {
      s: { c: 0, r: 5 },
      e: { c: 23, r: this.projectionDataList.length + 4 },
    };
    let excelCell;

    const borderFirstThree: any = {
      top: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
    };
    const borderFirst: any = {
      top: { style: "thin", color: { auto: 1 } },
      bottom: { style: "thin", color: { auto: 1 } },
      left: { style: "thin", color: { auto: 1 } },
    };

    const alignmentFirstThree = {
      wrapText: true,
      vertical: "center",
      horizontal: "left",
    };

    for (let R = headeRange.s.r; R <= headeRange.e.r; ++R) {
      for (let C = headeRange.s.c; C <= headeRange.e.c; ++C) {
        const cell_address = {
          c: C,
          r: R,
        };
        /* if an A1-style address is needed, encode the address */
        const cell_ref = XLSX.utils.encode_cell(cell_address);

        if (C < 4) {
          excelCell = {
            v: csvData[R - 1][C],
            t: "s",
            s: {
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial",
                sz: 11,
                color: { rgb: "#FF0000" },
                bold: C === 3 ? true : false,
                italic: false,
                underline: false,
              },
              border: C === 0 ? borderFirst : borderFirstThree,
              alignment: C === 3 ? alignmentFirstThree : alignment,
            },
          };
        } else if (C > 3 && C < 7) {
          excelCell = {
            v:
              C > 4
                ? csvData[R - 1][C] !== null
                  ? csvData[R - 1][C] / 100
                  : ""
                : csvData[R - 1][C] !== null
                  ? csvData[R - 1][C]
                  : "",
            t: C > 3 ? (csvData[R - 1][C] !== null ? "n" : "s") : "s",
            s: {
              numFmt: C > 4 ? "0.00%" : this.commonService.getDollarFormat(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial",
                sz: 11,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C > 6 && C < 9) {
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: "n",
            s: {
              numFmt:
                C === 7 || C === 8
                  ? this.commonService.get4DecimalPointFormat(csvData[R - 1][C])
                  : this.commonService.get2DecimalPointFormat(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FFFFFF" },
                bgColor: { rgb: "FFFFFF" },
              },
              font: {
                name: "Arial",
                sz: 11,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C > 8 && C < 13) {
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: "n",
            s: {
              numFmt:
                C === 9
                  ? this.commonService.get2DecimalPointFormat(csvData[R - 1][C])
                  : C === 10
                    ? this.commonService.get4DecimalPointFormat(csvData[R - 1][C])
                    : this.commonService.getDollarFormat(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: "D0E3F2" },
                bgColor: { rgb: "D0E3F2" },
              },
              font: {
                name: "Arial",
                sz: 11,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C > 12 && C < 16) {
          let dColor = "D0E3F2";
          let fontColor = "000";
          if (C == 13) {
            if (csvData[R - 1][C] != null && csvData[R - 1][C] > 0) {
              dColor = "d9ead3";
              fontColor = "000";
            } else if (csvData[R - 1][C] === "0.00") {
              dColor = "FFFFFF";
              fontColor = "000";
            } else if (csvData[R - 1][C] < 0) {
              dColor = "A20F0A";
              fontColor = "FFFFFF";
            } else {
              dColor = "D0E3F2";
              fontColor = "000";
            }
          }
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: "n",
            s: {
              numFmt:
                C > 12 && C < 15
                  ? this.commonService.get2DecimalPointFormat(csvData[R - 1][C])
                  : this.commonService.get4DecimalPointFormat(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: dColor },
                bgColor: { rgb: dColor },
              },
              font: {
                name: "Arial",
                sz: 11,
                color: { rgb: fontColor },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else if (C > 15 && C < 18) {
          let dColor = "D0E3F2";
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: "n",
            s: {
              numFmt: this.commonService.getDollarFormat(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: dColor },
                bgColor: { rgb: dColor },
              },
              font: {
                name: "Arial",
                sz: 11,
                color: { rgb: "#FF0000" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        } else {
          let bgCl = "d9ead3";
          if (C === 18 || C === 19 || C === 20) {
            bgCl = "ffffff";
          } else {
            bgCl = "D0E3F2";
          }
          excelCell = {
            v: csvData[R - 1][C] !== null ? csvData[R - 1][C] : "",
            t: csvData[R - 1][C] !== null ? "n" : "s",
            s: {
              numFmt:
                C === 21
                  ? this.commonService.get2DecimalPointFormat(csvData[R - 1][C])
                  : this.commonService.getDollarFormat(csvData[R - 1][C]),
              fill: {
                patternType: "solid",
                fgColor: { rgb: bgCl },
                bgColor: { rgb: bgCl },
              },
              font: {
                name: "Arial",
                sz: 11,
                color: { rgb: "#FFFFFF" },
                bold: false,
                italic: false,
                underline: false,
              },
              border: border,
              alignment: alignment,
            },
          };
        }

        ws[cell_ref] = excelCell;
      }
    }

    if (sheetName === "With behaviors") {
      ws["!images"] = [];
      ws["!images"].push({
        name: "Armatus.jpeg",
        data: this.imgToDataUrl(imgData),
        opts: { base64: true },
        type: "jpeg",
        position: {
          type: "twoCellAnchor",
          attrs: { editAs: "oneCell" },
          from: { col: 1, row: 1 },
          to: { col: 4, row: 3 },
        },
      });
      ws["!images"].push({
        name: "Armatus.jpeg",
        data: this.imgToDataUrl(imgData),
        opts: { base64: true },
        type: "jpeg",
        position: {
          type: "twoCellAnchor",
          attrs: { editAs: "oneCell" },
          from: { col: 1, row: 1 },
          to: { col: 4, row: 3 },
        },
      });
    }
    if (sheetName === "No behaviors") {
      ws["!images"] = [];
      ws["!images"].push({
        name: "Armatus.jpeg",
        data: this.imgToDataUrl(imgData),
        opts: { base64: true },
        type: "jpeg",
        position: {
          type: "twoCellAnchor",
          attrs: { editAs: "oneCell" },
          from: { col: 1, row: 1 },
          to: { col: 4, row: 3 },
        },
      });
      ws["!images"].push({
        name: "Armatus.jpeg",
        data: this.imgToDataUrl(imgData),
        opts: { base64: true },
        type: "jpeg",
        position: {
          type: "twoCellAnchor",
          attrs: { editAs: "oneCell" },
          from: { col: 1, row: 1 },
          to: { col: 4, row: 3 },
        },
      });
    }
  }

  imgToDataUrl(img: CanvasImageSource) {
    // const canvas = document.createElement("canvas");
    // canvas.width = img.naturalWidth; // or 'width' if you want a special/scaled size
    // canvas.height = img.naturalHeight; // or 'height' if you want a special/scaled size
    // canvas.getContext("2d").drawImage(img, 0, 0);
    // return canvas
    //   .toDataURL("image/png")
    //   .replace(/^data:image\/(png|jpg);base64,/, "");
    const canvas: any = document.createElement("canvas");

    if (img instanceof HTMLImageElement) {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      canvas.getContext("2d").drawImage(img, 0, 0);
    }

    // Make sure img is not null before calling toDataURL
    if (img) {
      return canvas.toDataURL("image/png").replace(/^data:image\/(png|jpg);base64,/, "");
    }
  }
}
