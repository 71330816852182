import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(private router: Router) {}
  ngOnInit() {
    setTimeout(() => {
      this.goToPrevious();
    }, 200);
  }

  goToPrevious() {
    if (
      localStorage.getItem("previousUrl") &&
      localStorage.getItem("previousUrl") != "/" &&
      localStorage.getItem("previousUrl") != "/login" &&
      localStorage.getItem("previousUrl") != "/access_token"
    ) {
      console.log("previous local storage url=======>>>", localStorage.getItem("previousUrl"));
      setTimeout(() => {
        this.router.navigate([localStorage.getItem("previousUrl")]);
        // this.router.navigate(["/home"]);
        return;
      }, 100);
    } else {
      setTimeout(() => {
        this.router.navigate(["/home"]);
      }, 100);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
