import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SolveSyncComponent } from "./solve-sync/solve-sync.component";

const routes: Routes = [
  {
    path: "",
    component: SolveSyncComponent,
    data: {
      title: "Solve Update Log",
      breadcrumb: [{ label: "Solve Update Log", url: "" }],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SolveSyncRoutingModule {}
