import { Injectable } from "@angular/core";
// import { Http, Headers, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, fromEvent } from "rxjs";
import { map, debounceTime } from "rxjs/operators";
const myObservable: Observable<number> = of(1, 2, 3, 4, 5);

// import "rxjs/add/operator/map";
// import { Logger } from "angular2-logger/core";
// import { environment } from "environments/environment";
import { ConstantService } from "../constants/constant.service";
import { CommonService } from "../services/common.service";
import { DMConstantService } from "../constants/dm.constant.service";
import * as moment from "moment-timezone";
import { MyCurrencyPipe } from "../components/formatter/currency/my-currency.pipe";
import { MyPercentagePipe } from "../components/formatter/percentage/my-percentage.pipe";
import * as pdfMake from "pdfmake/build/pdfmake";

import * as pdfFonts from "pdfmake/build/vfs_fonts";
@Injectable()
export class ProjectionReportPdfServiceV2NoBehavior {
  public projectionDataList: any[] = [];
  public storeGroup: any = [];
  public snapShotVersion!: any[];
  public snapShotData: any;
  constructor(
    private http: HttpClient,
    // private _logger: Logger,
    private constantService: ConstantService,
    private commonService: CommonService,
    private mycurpipe: MyCurrencyPipe,
    private myperpipe: MyPercentagePipe,
    private DMConstantService: DMConstantService
  ) {
    // pdfFonts.pdfMake.vfs = pdfMake.vfs;

    // pdfMake.vfs = pdfFonts.pdfMake.vfs;
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
  }
  init() {
    //
  }
  downloadProjectionPdfReport(
    projectionColumnsList: any[],
    data: any[],
    storeGroupData: any[],
    totalDataRow: { armatusOptimizedUplift: string; rOIInMonths: null },
    imgData: any,
    type: string,
    snapShotVersion: any,
    snapShotData: string
  ) {
    console.log(projectionColumnsList);
    console.log(data);
    console.log(totalDataRow);
    let fileName_ws = "";
    let snapShotCreatedTime = null;
    this.storeGroup = storeGroupData;
    this.projectionDataList = data;
    this.snapShotVersion = snapShotVersion;
    this.snapShotData = snapShotData;
    if (type === "cp") {
      let snapShotName = this.snapShotVersion[0].description;
      if (this.snapShotVersion[0].id < 0) {
        snapShotName = this.snapShotData;
      }
      snapShotName = snapShotName + "_";
      fileName_ws = "Projection_no_behavior_" + moment().format("YYYYMMDDhhmmss");
      //snapShotCreatedTime = this.commonService.randomVersionNumberGenerator(this.DMConstantService.REPORT_VERSION_FORMAT);
    } else {
      fileName_ws = "Projection_no_behavior_" + moment().format("YYYYMMDDhhmmss");
    }
    const cellWidths = [28, 40, 12, 117.5, 46, 46, 46, 46, 46, 45, 45, 44, 44, 50, 46, 48, 46];
    const cellWidthsHeaderRow = [224.5, 46, 46, 46, 46, 46, 45, 45, 44, 44, 50, 46, 48, 46];
    let footerText = this.constantService.EXCEL_FOOTER_DYNAMIC;
    footerText = footerText.replace(
      /{{footerName}}/i,
      this.storeGroup[0].salesPerson ? this.storeGroup[0].salesPerson : ""
    );
    footerText = footerText.replace(
      /{{footerEmail}}/i,
      this.storeGroup[0].spEmail ? this.storeGroup[0].spEmail : ""
    );
    footerText = footerText.replace(
      /{{footerPhone}}/i,
      this.storeGroup[0].spPhone ? this.storeGroup[0].spPhone : ""
    );
    let objFooter;
    if (snapShotCreatedTime) {
      objFooter = [
        {
          text: footerText,
          alignment: "center",
          border: [true, false, false, true],
        },
        {
          text: this.DMConstantService.REPORT_VERSION_LABEL + snapShotCreatedTime,
          alignment: "right",
          border: [false, false, true, true],
        },
      ];
    } else {
      objFooter = [
        {
          text: footerText,
          alignment: "center",
          border: [true, false, true, true],
        },
      ];
    }
    const loopData = [];
    let counter = 0;
    for (const i in this.projectionDataList) {
      if (this.projectionDataList) {
        counter++;
        let brandList = this.projectionDataList[i]["brand"];
        if (type === "cp") {
          brandList = brandList ? counter + "   " + brandList : null;
        } else {
          brandList = brandList ? brandList : null;
        }
        let bgColorFactBehaviorFactor = "hederSectionWhite";
        if (this.projectionDataList[i]["factBehaviorFactor"] !== null) {
          if (this.projectionDataList[i]["factBehaviorFactor"] === "0.00") {
            bgColorFactBehaviorFactor = "hederSectionWhite";
          } else if (this.projectionDataList[i]["factBehaviorFactor"] > 0) {
            bgColorFactBehaviorFactor = "headerSectionG";
          } else {
            bgColorFactBehaviorFactor = "dataSectionRed";
          }
        }

        let msrpLabel = this.projectionDataList[i]["msrp"]
          ? this.projectionDataList[i]["msrp"]
          : "";

        const loopData1 = [
          {
            text: brandList,
            style: "hederSection_data",
            border: [true, true, false, true],
            margin: [0, 11, 0, 0],
          },
          {
            text: msrpLabel,
            style: "hederSection_data",
            border: [false, true, false, true],
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["state"] ? this.projectionDataList[i]["state"] : null,
            style: "hederSection_data",
            border: [false, true, false, true],
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["stName"]
              ? this.projectionDataList[i]["stName"]
              : null,
            style: "hederSection_data_s",
            border: [false, true, true, true],
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["annualWarrPartsCost"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["annualWarrPartsCost"], 0)
              : null,
            style: "hederSection_data",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["cpPartsGrossProfit"]
              ? this.myperpipe.transform(this.projectionDataList[i]["cpPartsGrossProfit"], 2)
              : null,
            style: "hederSection_data",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["effWpGrossProfit"]
              ? this.myperpipe.transform(this.projectionDataList[i]["effWpGrossProfit"], 2)
              : "0.00%",
            style: "hederSection_data",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["cpGpMarkup"]
              ? this.get4DecimalPointFormat(this.projectionDataList[i]["cpGpMarkup"])
              : null,
            style: "hederSection_data",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["effWpGrossProfitMarkup"]
              ? this.get4DecimalPointFormat(this.projectionDataList[i]["effWpGrossProfitMarkup"])
              : null,
            style: "hederSection_data",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["addlPtsAbvCurrEffWm"]
              ? this.get2DecimalPointFormat(this.projectionDataList[i]["addlPtsAbvCurrEffWm"])
              : null,
            style: "hederSection_data_b",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["projectedWpMarkup"]
              ? this.get4DecimalPointFormat(this.projectionDataList[i]["projectedWpMarkup"])
              : null,
            style: "hederSection_data_b",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["stEntitledMonthlyProfit"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["stEntitledMonthlyProfit"], 0)
              : null,
            style: "hederSection_data_b",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["stEntitledAnnlProfit"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["stEntitledAnnlProfit"], 0)
              : null,
            style: "hederSection_data_b",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["postApprSubmissionFee"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["postApprSubmissionFee"], 0)
              : null,
            style: "hederSection_data_w",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["monthlyUpliftFee"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["monthlyUpliftFee"], 0)
              : null,
            style: "hederSection_data_w",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["totalProfitAcqCost"]
              ? this.mycurpipe.transform(this.projectionDataList[i]["totalProfitAcqCost"], 0)
              : null,
            style: "hederSection_data_w",
            margin: [0, 11, 0, 0],
          },
          {
            text: this.projectionDataList[i]["paybackPeriod"]
              ? this.get2DecimalPointFormat(this.projectionDataList[i]["paybackPeriod"])
              : null,
            style: "hederSection_data_b",
            margin: [0, 11, 0, 0],
          },
        ];
        loopData.push(loopData1);
      }
    }
    console.log(loopData);
    const cellHeights = [];
    for (let loopCount = 0; loopCount < loopData.length; loopCount++) {
      cellHeights.push(20);
    }
    const projectioReport: any = {
      // pageSize: 'Legal',
      // pageOrientation: 'landscape',
      pageSize: {
        width: 1007.28,
        height: "auto",
      },
      // pageOrientation: 'landscape',
      pageOrientation: "portrait",
      pageMargins: [29, 29, 29, 29],
      content: [
        {
          style: "topHeaderTable",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: this.DMConstantService.PROJECTION_REPORT_V2.EXCEL_HEADING,
                  style: "reportHeader",
                },
              ],
            ],
          },
          layout: { defaultBorder: false },
        },
        {
          style: "topHeaderTable",
          table: {
            widths: [200, 650],
            body: [
              [
                {
                  image: imgData,
                  width: 150,
                  height: 100,
                  margin: [0, 35, 0, 0],
                  absolutePosition: { x: 50, y: 90 },
                },
                {
                  border: [false, false, false, false],
                  text: this.DMConstantService.PROJECTION_REPORT_V2.EXCEL_DESCRIPTION,
                  italics: true,
                  style: "subhead2",
                },
              ],
            ],
          },
          layout: { defaultBorder: false },
        },
        {
          alignment: "left",
          style: "groupNameList",
          columns: [
            {
              text: "Group Name: " + this.storeGroup[0].itemName,
              style: "header",
            },
          ],
        },
        {
          style: "tableExample",
          table: {
            border: [true, true, true, false],
            widths: [266, "*"],
            heights: [30],
            body: [
              [
                {
                  text: this.DMConstantService.PROJECTION_REPORT_V2.EXCEL_HEADING1,
                  style: "topHeader_2",
                },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_V2.EXCEL_HEADING3,
                  style: "topHeader2",
                },
              ],
            ],
          },
          layout: {},
        },
        {
          style: "peojectionTableData",
          table: {
            heights: [70],
            widths: cellWidthsHeaderRow,
            border: [false, false, false, true],
            body: [
              [
                {
                  text: this.DMConstantService.PROJECTION_REPORT_V2_ROW_HEADING.HEAD_0,
                  style: "hederSectionFirstThree",
                  alignment: "center",
                  border: [true, true, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM1,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM2,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM3,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM4,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM5,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM6,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM7,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM8,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM9,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM10,
                  style: "hederSectionWhite",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM11,
                  style: "hederSectionWhite",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM12,
                  style: "hederSectionWhite",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: this.DMConstantService.PROJ_REPORT_NO_BEHAVIOR.CLM13,
                  style: "hederSectionB",
                  alignment: "center",
                  border: [true, false, true, false],
                },
              ],
            ],
          },
          layout: { defaultBorder: true },
        },
        {
          style: "peojectionTableData",
          table: {
            dontBreakRows: true,
            heights: cellHeights,
            widths: cellWidths,
            body: loopData,
          },
          layout: {},
        },
        {
          style: "peojectionTableData",
          table: {
            widths: [500, 205, "*"],
            heights: function (row: number) {
              return (row + 1) * 15;
            },
            body: [
              [
                {
                  text: "",
                  alignment: "center",
                  fillColor: "#FFFFFF",
                  style: "footerRegion",
                  margin: [0, 5, 0, 0],
                  border: [true, false, true, false],
                },
                // {
                //   text: totalDataRow.unadjustedApparentUplift ? this.mycurpipe.transform(totalDataRow.unadjustedApparentUplift, 0) : '$ 0',
                //   alignment: 'center', fillColor: '#D9D9D9', style: 'footerRegion', border: [true, false, true, true]
                // },
                {
                  text: totalDataRow.armatusOptimizedUplift
                    ? this.mycurpipe.transform(totalDataRow.armatusOptimizedUplift, 0)
                    : "$ 0",
                  alignment: "center",
                  fillColor: "#D0E3F2",
                  style: "footerRegion",
                  margin: [0, 5, 0, 0],
                  border: [true, false, true, false],
                },
                // {
                //   text: totalDataRow.factoryAdjustedUplift ? this.mycurpipe.transform(totalDataRow.factoryAdjustedUplift, 0) : '$ 0',
                //   alignment: 'center', fillColor: '#D0E3F2', style: 'footerRegion', border: [true, false, true, true]
                // },
                // {
                //   text: totalDataRow.netUpliftYear1 ? this.mycurpipe.transform(totalDataRow.netUpliftYear1, 0) : '$ 0',
                //   alignment: 'center', fillColor: '#DAEAD4', style: 'footerRegion', border: [true, false, true, true]
                // },
                {
                  text: totalDataRow.rOIInMonths != null ? totalDataRow.rOIInMonths : "",
                  alignment: "center",
                  margin: [0, 5, 0, 0],
                  fillColor: "#D0E3F2",
                  style: "footerRegion",
                  border: [true, false, true, false],
                },
              ],

              [
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_6,
                  alignment: "right",
                  fillColor: "#FFFFFF",
                  style: "footerRegion_1",
                  border: [true, false, false, true],
                },
                // {
                //   text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_1,
                //   alignment: 'center', fillColor: '#D9D9D9', style: 'footerRegion', margin: [0, 10, 0, 0]
                // },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_2,
                  alignment: "center",
                  fillColor: "#D0E3F2",
                  style: "footerRegion",
                  margin: [0, 10, 0, 0],
                  border: [true, false, false, true],
                },
                // {
                //   text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_3,
                //   alignment: 'center', fillColor: '#D0E3F2', style: 'footerRegion', margin: [0, 10, 0, 0]
                // },
                // {
                //   text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_4,
                //   alignment: 'center', fillColor: '#DAEAD4', style: 'footerRegion', margin: [0, 10, 0, 0]
                // },
                {
                  text: this.DMConstantService.PROJECTION_REPORT_PDF_LABELS.FOOTER_SECTION_COLUMN_5,
                  alignment: "center",
                  fillColor: "#D0E3F2",
                  style: "footerRegion",
                  margin: [0, 10, 0, 0],
                  border: [true, false, true, true],
                },
              ],
            ],
          },
          layout: {},
        },
        // {
        //   style: 'peojectionTableDataFooter',
        //   table: {
        //     widths: snapShotCreatedTime ? [600, '*'] : ['*'],
        //     body: [
        //       objFooter,
        //     ]
        //   },
        //   layout: {
        //     hLineWidth: function (i, node) {
        //       return 1;
        //     },
        //     vLineWidth: function (i, node) {
        //       return 1;
        //     },
        //     hLineColor: function (i, node) {
        //       return 'black';
        //     },
        //     vLineColor: function (i, node) {
        //       return 'black';
        //     },
        //   }
        // }
      ],
      styles: {
        row1Style: {
          fontSize: 18,
          bold: true,
          alignment: "center",
          margin: [10, 1, 40, 2],
          color: "#175595",
        },
        subhead2: {
          fontSize: 10,
          bold: false,
          alignment: "left",
          margin: [0, 30, 0, 0],
          color: "#175595",
        },
        reportHeader: {
          fontSize: 18,
          bold: true,
          alignment: "center",
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          color: "#175595",
        },
        topHeaderTable: {
          border: [false, false, false, false],
        },
        topHeader: {
          fillColor: "#FFFFFF",
          fontSize: 11,
          bold: true,
          alignment: "center",
          margin: [0, 7, 0, 0],
          border: [true, true, true, false],
        },
        topHeader1: {
          fillColor: "#D9D9D9",
          fontSize: 9,
          alignment: "center",
          margin: [0, 7, 0, 0],
          border: [true, true, true, false],
        },
        topHeader_2: {
          fillColor: "#FFFFFF",
          fontSize: 12,
          bold: true,
          alignment: "center",
          margin: [0, 7, 0, 0],
          border: [true, true, true, false],
        },
        topHeader2: {
          fillColor: "#D0E3F2",
          fontSize: 12,
          bold: true,
          alignment: "center",
          margin: [0, 7, 0, 0],
          border: [true, true, true, false],
        },
        topHeader3: {
          fillColor: "#DAEAD4",
          fontSize: 9,
          alignment: "center",
          margin: [0, 7, 0, 0],
          border: [true, true, true, false],
        },
        hederSection: {
          fillColor: "#FFFFFF",
          fontSize: 10,
          alignment: "center",
        },
        hederSection_data: {
          fillColor: "#FFFFFF",
          fontSize: 7,
          alignment: "center",
        },
        hederSection_data_s: {
          fillColor: "#FFFFFF",
          fontSize: 8,
          bold: true,
          alignment: "left",
        },
        hederSection_data_b: {
          fillColor: "#CDE2F4",
          fontSize: 7,
          alignment: "center",
        },
        hederSection_data_w: {
          fillColor: "#FFFFFF",
          fontSize: 7,
          alignment: "center",
        },
        hederSectionFirstThree: {
          fillColor: "#FFFFFF",
          fontSize: 10,
          margin: [10, 35, 5, 0],
          bold: true,
        },
        hederSectionGray: {
          fillColor: "#DFDCDC",
          fontSize: 10,
          alignment: "center",
        },
        hederSectionB: {
          fillColor: "#CDE2F4",
          fontSize: 10,
          alignment: "center",
        },
        headerSectionG: {
          fillColor: "#D0E0BF",
          fontSize: 10,
          alignment: "center",
        },
        hederSectionWhite: {
          fillColor: "#FFFFFF",
          fontSize: 10,
          alignment: "center",
        },
        dataSectionRed: {
          fillColor: "#f7c1be",
          fontSize: 10,
          alignment: "center",
        },
        hederSectionRed: {
          fillColor: "#F4C0BF",
          fontSize: 10,
          alignment: "center",
        },
        header: {
          fontSize: 16,
          bold: true,
          alignment: "left",
          margin: [40, 12, 40, 1],
          color: "#175595",
        },
        header1: {
          fontSize: 16,
          bold: true,
          alignment: "left",
          margin: [0, 10, 0, 0],
          color: "#175595",
        },
        subhead: {
          fontSize: 12,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          backgroundColor: "#175595",
          border: 1,
          widths: 200,
        },
        subheadR1: {
          fontSize: 3,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          border: [true, true, true, true],
          fillColor: "red",
        },
        subheadR2: {
          fontSize: 12,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          widths: 300,
        },
        subheadR3: {
          fontSize: 12,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          widths: 400,
        },
        tableHeader: {
          alignment: "center",
          fontSize: 13,
          bold: true,
          color: "black",
          margin: [0, 10, 0, 10],
        },
        tableExample: {
          margin: [233.5, 10, 0, 0],
        },
        groupNameList: {
          margin: [194, 0, 0, 0],
        },
        footerRegion: {
          fontSize: 11,
          alignment: "center",
          bold: true,
          color: "black",
        },
        footerRegion_1: {
          fontSize: 11,
          alignment: "right",
          margin: [0, 0, 50, 0],
          bold: true,
          color: "black",
        },
        peojectionTableDataFooter: {
          fontSize: 10,
          alignment: "center",
          bold: true,
          color: "black",
        },
      },
    };
    pdfMake.createPdf(projectioReport).download(fileName_ws + ".pdf");
  }

  get2DecimalPointFormat(value: string) {
    return value ? parseFloat(value).toFixed(2) : null;
  }

  get4DecimalPointFormat(value: string) {
    return value ? parseFloat(value).toFixed(4) : null;
  }
}
