import { Injectable } from "@angular/core";

@Injectable()
export class SubscriptionConstantService {
  // LOADER_STATUS: boolean;
  // constructor() {
  //   this.LOADER_STATUS = false;
  // }
  STORE_CREATION: string;
  PROJECT_CREATION: string;
  PRODUCTION_SNAPSHOT_EVENTS: string;
  STORE_CREATION_FLAG: boolean;
  GROUP_CRETION_FLAG: boolean;
  LOADER_STATUS: boolean;
  DISPLAY_NOTIFICATION: boolean;
  NOTIFICATION_STORE: string;
  NOTIFICATION_GROUP: string;
  NOTIFICATION_SOLO_STORE: string;
  NOTIFICATION_PROJECT_PARTS: string;
  NOTIFICATION_PROJECT_LABOR: string;
  CREATE_STORE_PORTAL_SUCCESS_MESSAGE: string;
  CREATE_GROUP_PORTAL_SUCCESS_MESSAGE: string;
  CREATE_SOLO_STORE_PORTAL_SUCCESS_MESSAGE: string;
  SUCCESS_MESSAGE: string;
  PROGRESS_MESSAGE_STEP1: string;
  PROGRESS_MESSAGE_STEP2: string;
  PROGRESS_MESSAGE_STEP3: string;
  PROGRESS_MESSAGE_STEP4: string;
  PROGRESS_MESSAGE_STEP5: string;
  PROGRESS_MESSAGE_GROUP: string;
  PROGRESS_MESSAGE_UPDATE_GROUP: string;
  PROGRESS_MESSAGE_UPDATE_S360_GROUP: string;
  PROGRESS_SOLO_STORE: string;
  PROGRESS_SOLO_STORE_UPDATE: string;
  PROGRESS_MESSAGE_GROUP_STEP1: string;
  CREATE_GROUP_PORTAL_ERROR: string;
  UPDATE_GROUP_PORTAL_ERROR: string;
  UPDATE_GROUP_S360_ERROR: string;
  CREATE_SOLO_STORE_PORTAL_ERROR: string;
  CREATE_SOLO_STORE_UPDATE_PORTAL_ERROR: string;
  CREATE_SOLO_STORE_UPDATE_S360_ERROR: string;
  PROGRESS_STORE_GROUP_TO_PORTAL: string;
  PROGRESS_SOLO_STORE_TO_PORTAL: string;
  DEFAULT_STORE_TYPE: string;
  STORE_TYPE_SOLVE360: string;
  STORE_TYPE_PORTAL: string;
  IS_DUAL: string;
  ADD_TO_PORTAL_FLAG: boolean;
  LINK_PORTAL_STORE_TO_SOLVE360: string;
  PROJECT_TYPE_PARTS: string;
  PROJECT_TYPE_LOBOR: string;
  CREATE_PROJECT_SUCCESS_MESSAGE: string;
  LINK_PROJECT_TO_SOLVE360: string;
  CREATE_PROJECT_PORTAL_ERROR: string;
  PROJECT_NAME: string;
  DEFAULT_STAGE: string;
  SUBSCRIPTION_TIMER: number;
  LINK_PROJECT_TO_PORTAL_ERROR: string;
  ADD_STORE_TO_PORTAL_ERROR: string;
  ADD_STORE_TO_S360_ERROR: string;
  ADD_GROUP_TO_PORTAL_ERROR: string;
  ADD_SOLO_STORE_TO_PORTAL_ERROR: string;
  SUBSCRIPTION_DELAY_TIMER: number;
  PROGRESS_MESSAGE_UPDATE_STORE: string;
  PROGRESS_MESSAGE_UPDATE_STORE_PORTAL: string;
  PROGRESS_SOLO_STORE_PORTAL_UPDATE: string;
  REVENUE_NET_UPLIFT_PROCESS: string;
  REVENUE_NET_UPLIFT_CONVERTED: string;
  DUAUDIT_SETAS_AUDIT_SOURCE: string;
  MAGE_SETAS_AUDIT_SOURCE: string;

  constructor() {
    this.STORE_CREATION = "company_create";
    this.PROJECT_CREATION = "project_create";
    this.PRODUCTION_SNAPSHOT_EVENTS = "producton_snapshot_listener";
    this.STORE_CREATION_FLAG = false;
    this.GROUP_CRETION_FLAG = false;
    this.LOADER_STATUS = false;
    this.DISPLAY_NOTIFICATION = false;
    this.CREATE_STORE_PORTAL_SUCCESS_MESSAGE = "New Store created in Solve360.";
    this.CREATE_GROUP_PORTAL_SUCCESS_MESSAGE = "New Group created in Solve360.";
    this.CREATE_SOLO_STORE_PORTAL_SUCCESS_MESSAGE =
      "New Solo Store created in Solve360 and added to portal.";
    this.PROGRESS_MESSAGE_STEP1 = "Creating store in Solve360 ..";
    this.PROGRESS_MESSAGE_STEP2 = "Adding Store to portal ..";
    this.PROGRESS_MESSAGE_STEP3 = "Store Added to Portal";
    this.PROGRESS_MESSAGE_STEP4 = "Reloading Store List ..";
    this.PROGRESS_MESSAGE_STEP5 = "Finishing ..";
    this.PROGRESS_MESSAGE_GROUP = "Creating Store Group in Solve360 ..";
    this.PROGRESS_MESSAGE_UPDATE_GROUP = "Updating Store Group in Portal ..";
    this.PROGRESS_MESSAGE_UPDATE_S360_GROUP = "Updating Store Group in Solve360 ..";
    this.PROGRESS_MESSAGE_UPDATE_STORE = "Updating Store in Solve360 ..";
    this.PROGRESS_MESSAGE_UPDATE_STORE_PORTAL = "Updating Store in Portal ..";
    this.PROGRESS_SOLO_STORE = "Creating Solo Store in Solve360 ..";
    this.PROGRESS_SOLO_STORE_UPDATE = "Updating Solo Store in Solve360 ..";
    this.PROGRESS_SOLO_STORE_PORTAL_UPDATE = "Updating Solo Store in Portal ..";
    this.NOTIFICATION_STORE = "Store";
    this.NOTIFICATION_GROUP = "StoreGroup";
    this.NOTIFICATION_SOLO_STORE = "SoloStore";
    this.NOTIFICATION_PROJECT_PARTS = "Parts UL";
    this.NOTIFICATION_PROJECT_LABOR = "Labor UL";
    this.PROGRESS_MESSAGE_GROUP_STEP1 = "Reloading Group List ..";
    this.SUCCESS_MESSAGE = "New Store added in Solve360.";
    this.CREATE_GROUP_PORTAL_ERROR = "Failed to add Store Group to Portal";
    this.UPDATE_GROUP_PORTAL_ERROR = "Failed to update Store Group to Portal";
    this.UPDATE_GROUP_S360_ERROR = "Failed to update Store Group to s360";
    this.CREATE_SOLO_STORE_PORTAL_ERROR = "Failed to add Solo Store to Portal";
    this.CREATE_SOLO_STORE_UPDATE_PORTAL_ERROR = "Failed to update Solo Store to Portal";
    this.CREATE_SOLO_STORE_UPDATE_S360_ERROR = "Failed to update Solo Store to s360";
    this.PROGRESS_STORE_GROUP_TO_PORTAL = "Store Group Added to Portal";
    this.PROGRESS_SOLO_STORE_TO_PORTAL = "Solo Store Added to Portal";
    this.DEFAULT_STORE_TYPE = "portal";
    this.STORE_TYPE_SOLVE360 = "Solve360";
    this.STORE_TYPE_PORTAL = "portal";
    this.ADD_TO_PORTAL_FLAG = false;
    this.LINK_PORTAL_STORE_TO_SOLVE360 = "Link Store to Solve360";
    this.IS_DUAL = "true";
    this.PROJECT_TYPE_PARTS = "PartsUL";
    this.PROJECT_TYPE_LOBOR = "LaborUL";
    this.CREATE_PROJECT_SUCCESS_MESSAGE = "New Project created in Solve360.";
    this.LINK_PROJECT_TO_SOLVE360 = "Linking DM to Solve360 Project";
    this.CREATE_PROJECT_PORTAL_ERROR = "Failed to add Project to Solve360";
    this.PROJECT_NAME = "";
    this.DEFAULT_STAGE = "Evaluation";
    this.SUBSCRIPTION_TIMER = 10;
    this.LINK_PROJECT_TO_PORTAL_ERROR =
      "Failed to Link Project to Portal. Please refresh UI and link Project to DM manually";
    this.ADD_STORE_TO_PORTAL_ERROR =
      "Failed to add Store to Portal. Please refresh UI and add Store to portal manually";
    this.ADD_STORE_TO_S360_ERROR =
      "Failed to add Store to S360. Please refresh UI and add Store to S360 manually";
    this.ADD_GROUP_TO_PORTAL_ERROR =
      "Failed to add Group to Portal. Please refresh UI and add Store Group to portal manually";
    this.ADD_SOLO_STORE_TO_PORTAL_ERROR =
      "Failed to add Solo Store to Portal. Please refresh UI and add Solo Store to portal manually";
    this.SUBSCRIPTION_DELAY_TIMER = 4;
    this.REVENUE_NET_UPLIFT_PROCESS = "Converting Revenue Net Uplift";
    this.REVENUE_NET_UPLIFT_CONVERTED = "Revenue Net Uplift Converted";
    this.DUAUDIT_SETAS_AUDIT_SOURCE = "DU-Audit set as Audit Source";
    this.MAGE_SETAS_AUDIT_SOURCE = "MAGE set as Audit Source";
  }
}
