import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "safeInput",
})
export class SafeInputPipe implements PipeTransform {
  encoded: any;
  constructor(private sanitized: DomSanitizer) {}
  //pass params.value for UI
  transform(value: any) {
    const replacedValue = value 
      ? value.replace(/</g, "&lt;").replace(/>/g, "&gt;")
      : "";
    //return replacedValue;
    return this.sanitized.sanitize(SecurityContext.HTML, replacedValue);
  }
  transformXss(value: any) {
    const replacedValue = value 
    ? value
     //JSON.stringify(value)
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    : "";
    return replacedValue;
  }
}
