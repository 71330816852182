<section class="card" id="duedateMain">
  <div class="card-header" id="dueDateHeader">
    <div class="row">
      <div class="col-lg-2">
        <span class="cat__core__title">
          <strong>Due Date Module</strong>
        </span>
      </div>
      <div class="col-lg-2">
        <span class="input-group pull-right" [hidden]="loading">
          <input
            type="text"
            placeholder="Search"
            class="form-control input-sm"
            #searchKey
            id="searchKey"
            [(ngModel)]="searchFilterKey"
            (keyup)="setFilterSearch(searchKey)"
            (keydown.enter)="searchFilter(searchKey)"
            (blur)="searchFilter(searchKey)"
          />
          <a
            [hidden]="!searchFilterKeyTrim"
            class="pointer clear-search"
            (click)="clearSearch()"
            title="Clear"
            ><em aria-hidden="true" class="fa fa-times"></em
          ></a>
          <button
            [disabled]="!searchKeyEnable && !searchFilterKeyTrim"
            class="btn btn-sm pointer"
            (click)="searchFilter(searchKey)"
            title="Search"
          >
            <em aria-hidden="true" class="fa fa-search"></em>
          </button>
        </span>
      </div>
      <div class="col-lg-4 text-center">
        <div style="padding-top: 3px">
          <span style="font-weight: bold; font-size: 1.14rem">{{ selectedReportStr }}</span>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="cat__top-bar__right">
          <div class="cat__top-bar__item">
            <div class="cat__menu-right__action--menu-toggle">
              <div class="pull-left">
                <div>
                  As Of <strong>{{ currentTime }}</strong>
                </div>
                <div align="right">
                  <strong>{{ count }} / {{ dueDateReportList.length }}</strong>
                  Records
                </div>
              </div>
              <span
                class="pull-right"
                style="padding-left: 15px"
                data-toggle="tooltip"
                data-placement="top"
                title="Click to refresh"
              >
                <em
                  class="plus fa fa-refresh fa-2x pointer"
                  *ngIf="!loading"
                  (click)="dueDateReports(true)"
                ></em>
                <em class="plus fa fa-refresh fa-spin fa-2x" *ngIf="loading"></em>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <flash-messages></flash-messages>
    <div class="col-lg-12">
      <section>
        <div class="card-block">
          <div class="row" id="reportSection" [hidden]="loading">
            <div class="col-lg-10">
              <div class="row" style="width: 100% !important">
                <div style="width: 12.5% !important; padding-right: 5px !important">
                  <div
                    class="cat__core__widget pointer"
                    [ngClass]="{ selectedReport: notDueSelected }"
                    (click)="notDueListFilter()"
                  >
                    <div
                      class="cat__core__step"
                      style="
                        background-color: rgba(210, 204, 205, 0.82) !important;
                        color: #393749 !important;
                        max-height: 62.75px;
                      "
                    >
                      <div class="cat__core__step__desc">
                        <span class="cat__core__step__title" *ngIf="notDueSelected"
                          ><em class="fa fa-check-circle" aria-hidden="true"></em
                        ></span>
                        <span class="cat__core__step__title"> Not Due</span>
                        <span class="cat__core__step__title pull-right">{{ notDueCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 12.5% !important; padding-right: 5px !important">
                  <div
                    class="cat__core__widget pointer"
                    [ngClass]="{ selectedReport: manfRequestSelected }"
                    (click)="manfRequestListFilter()"
                  >
                    <div
                      class="cat__core__step"
                      style="
                        background-color: rgb(128, 128, 128) !important;
                        color: #ffff !important;
                        max-height: 62.75px;
                      "
                    >
                      <div class="cat__core__step__desc">
                        <span class="cat__core__step__title" *ngIf="manfRequestSelected"
                          ><em class="fa fa-check-circle" aria-hidden="true"></em
                        ></span>
                        <span class="cat__core__step__title"> Manf. Request</span>
                        <span class="cat__core__step__title pull-right">{{
                          manfRequestCount
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 12.5% !important; padding-right: 5px !important">
                  <div
                    class="cat__core__widget pointer"
                    [ngClass]="{ selectedReport: pastDueSelected }"
                    (click)="pastDueListFilter()"
                  >
                    <div
                      class="cat__core__step"
                      style="
                        background-color: rgba(255, 0, 0, 0.3) !important;
                        color: #393749 !important;
                        max-height: 62.75px;
                      "
                    >
                      <div class="cat__core__step__desc">
                        <span class="cat__core__step__title" *ngIf="pastDueSelected"
                          ><em class="fa fa-check-circle" aria-hidden="true"></em
                        ></span>
                        <span class="cat__core__step__title"> Past Due</span>
                        <span class="cat__core__step__title pull-right">{{ pastDueCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 12.5% !important; padding-right: 5px !important">
                  <div
                    class="cat__core__widget pointer"
                    [ngClass]="{ selectedReport: readyToBillSelected }"
                    (click)="readyToListFilter()"
                  >
                    <div
                      class="cat__core__step"
                      style="
                        background-color: rgba(153, 255, 153, 0.3) !important;
                        color: #393749 !important;
                        max-height: 62.75px;
                      "
                    >
                      <div class="cat__core__step__desc">
                        <span class="cat__core__step__title" *ngIf="readyToBillSelected"
                          ><em class="fa fa-check-circle" aria-hidden="true"></em
                        ></span>
                        <span class="cat__core__step__title"> Ready to Bill</span>
                        <span class="cat__core__step__title pull-right">{{
                          readyToBillCount
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 12.5% !important; padding-right: 5px !important">
                  <div
                    class="cat__core__widget pointer"
                    [ngClass]="{ selectedReport: clientSelected }"
                    (click)="clientListFilter()"
                  >
                    <div
                      class="cat__core__step"
                      style="
                        background-color: rgb(147, 225, 147) !important;
                        color: #393749 !important;
                        max-height: 62.75px;
                      "
                    >
                      <div class="cat__core__step__desc">
                        <span class="cat__core__step__title" *ngIf="clientSelected"
                          ><em class="fa fa-check-circle" aria-hidden="true"></em
                        ></span>
                        <span class="cat__core__step__title"> Accounting to Bill</span>
                        <span class="cat__core__step__title pull-right">{{ clientListCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="width: 12.5% !important; padding-right: 5px !important">
                  <div
                    class="cat__core__widget pointer"
                    [ngClass]="{ selectedReport: nonContingentSelected }"
                    (click)="nonContingentFilter()"
                  >
                    <div
                      class="cat__core__step"
                      style="
                        background-color: rgb(142, 238, 238) !important;
                        color: #393749 !important;
                        max-height: 62.75px;
                      "
                    >
                      <div class="cat__core__step__desc">
                        <span class="cat__core__step__title" *ngIf="nonContingentSelected"
                          ><em class="fa fa-check-circle" aria-hidden="true"></em
                        ></span>
                        <span class="cat__core__step__title"> Non-Contingent</span>
                        <span class="cat__core__step__title pull-right">{{
                          nonContingentCount
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="width: 12.5% !important; padding-right: 5px !important">
                  <div
                    class="cat__core__widget pointer"
                    [ngClass]="{ selectedReport: disputedSelected }"
                    (click)="disputeListFilter()"
                  >
                    <div
                      class="cat__core__step"
                      style="
                        background-color: rgba(128, 0, 128, 0.3) !important;
                        color: #393749 !important;
                        max-height: 62.75px;
                      "
                    >
                      <div class="cat__core__step__desc">
                        <span class="cat__core__step__title" *ngIf="disputedSelected"
                          ><em class="fa fa-check-circle" aria-hidden="true"></em
                        ></span>
                        <span class="cat__core__step__title"> Disputed</span>
                        <span class="cat__core__step__title pull-right">{{ disputedCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 12.5% !important; padding-right: 5px !important">
                  <div
                    class="cat__core__widget pointer"
                    [ngClass]="{ selectedReport: punchListSelected }"
                    (click)="punchListFilter()"
                  >
                    <div
                      class="cat__core__step"
                      style="
                        background-color: rgba(255, 165, 0, 0.3) !important;
                        color: #393749 !important;
                        max-height: 62.75px;
                      "
                    >
                      <div class="cat__core__step__desc">
                        <span class="cat__core__step__title" *ngIf="punchListSelected"
                          ><em class="fa fa-check-circle" aria-hidden="true"></em
                        ></span>
                        <span class="cat__core__step__title"> Punch List</span>
                        <span class="cat__core__step__title pull-right">{{ punchListCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 12.5% !important; padding-right: 5px !important">
                  <div
                    class="cat__core__widget pointer"
                    [ngClass]="{ selectedReport: errorSelected }"
                    (click)="errorListFilter()"
                    *ngIf="errorCount > 0"
                  >
                    <div
                      class="cat__core__step cat__core__step--danger"
                      style="color: #393749 !important; max-height: 62.75px"
                    >
                      <div class="cat__core__step__desc">
                        <span class="cat__core__step__title" *ngIf="errorSelected"
                          ><em class="fa fa-check-circle" aria-hidden="true"></em
                        ></span>

                        <span class="cat__core__step__title"> Error</span>
                        <span class="cat__core__step__title pull-right">{{ errorCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="pull-right" style="padding-top: 5px; padding-right: 20px">
                <div class="btn-group pointer">
                  <button
                    type="button"
                    [disabled]="selectAllReportFlag"
                    (click)="selectAllReport()"
                    class="action-selectbtn editClass plus fa fa-check-square-o fa-lg"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span> All</span>
                  </button>
                </div>
                <div class="btn-group pointer">
                  <button
                    type="button"
                    [disabled]="!mainReportSelected.length"
                    (click)="unSelectAllReport()"
                    class="action-selectbtn editClass plus fa fa-square-o fa-lg"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span> None</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div [hidden]="loading">
            <div class="accordion accordion-margin-bottom filter-accordion" id="filterArea">
              <div class="card">
                <div
                  class="card-header"
                  [ngClass]="{ collapsed: !isExpand }"
                  role="tab"
                  id="headingFilterArea"
                >
                  <div class="row">
                    <div class="col-md-2">
                      <span
                        title="Click here to Expand/Collapse filter"
                        data-toggle="tooltip"
                        data-placement="top"
                        class="filter-form accordion-indicator pull-left pointer"
                        style="padding: 10px 0 10px 0 !important;"
                        data-parent="#accordion2"
                        (click)="expandOrCollaseFilter()"
                        aria-expanded="true"
                        aria-controls="collapseOne2"
                      >
                        <em class="plus fa fa-expand fa-2x"></em>
                        <em class="minus fa fa-compress fa-2x"></em>
                      </span>
                      <a style="padding-left: 35px">
                        <span style="font-weight: bold; font-size: 1.3rem">Filter</span>
                      </a>
                    </div>
                    <div class="col-md-8">
                      <div
                        class="btn-group pointer"
                        *ngFor="let data of spList; let i = index"
                        style="padding-right: 10px; padding-bottom: 10px"
                      >
                        <button
                          type="button"
                          id="sp_{{ i }}"
                          data-name="{{ data.itemName }}"
                          (click)="onItemSelectSp($event, i)"
                          style="min-width: 100px"
                          class="btn btn-success btn-sm pointer"
                          [ngClass]="data.selected == true ? '' : 'sp_select_all'"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span class="pull-left" data-id="{{ data.itemName }}"
                            ><em
                              class="fa fa-check-circle fa-lg"
                              data-id="{{ data.itemName }}"
                              aria-hidden="true"
                            ></em></span
                          >{{ data.itemName }} &nbsp;&nbsp;{{ data.count }}
                        </button>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="pull-right">
                        <div class="btn-group pointer">
                          <button
                            type="button"
                            [disabled]="selectAllSpFlag"
                            (click)="onSelectAllSp()"
                            class="action-selectbtn editClass plus fa fa-check-square-o fa-lg"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span> All</span>
                          </button>
                        </div>
                        <div class="btn-group pointer">
                          <button
                            type="button"
                            [disabled]="!sp.length"
                            (click)="onDeSelectAllSp()"
                            class="action-selectbtn editClass plus fa fa-square-o fa-lg"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span> None</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="collapseFilterArea"
                  class="card-collapse"
                  [ngClass]="{ collapse: !isExpand }"
                  role="tabcard"
                  aria-labelledby="headingFilterArea"
                >
                  <div class="card-block">
                    <div class="row">
                      <div class="col-md-3">
                        <label class="col-form-label" style="font-weight: 700">Parent</label>
                        <div id="parent-select">
                          <ng-multiselect-dropdown
                            appFocusOnClick
                            class="select-sp-report searchicon-dropdown filter-type-status multi-search"
                            [settings]="dropdownSettings"
                            [data]="parentList"
                            [(ngModel)]="parent"
                            [placeholder]="'Select Item'"
                            (onSelect)="onItemSelectParent($event)"
                            (onDeSelect)="OnItemDeSelectParent($event)"
                            (onSelectAll)="OnItemSelectAllParent($event)"
                            (onDeSelectAll)="OnItemDeSelectAllParent($event)"
                          ></ng-multiselect-dropdown>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="col-form-label" style="font-weight: 700">Group</label>
                        <div>
                          <ng-multiselect-dropdown
                            appFocusOnClick
                            class="select-sp-report searchicon-dropdown filter-type-status multi-search"
                            [settings]="dropdownSettingsGroup"
                            [data]="reportCategoryList"
                            [(ngModel)]="group"
                            [placeholder]="'Select Item'"
                            (onSelect)="onItemSelect($event)"
                            (onDeSelect)="OnItemDeSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeSelectAll($event)"
                          ></ng-multiselect-dropdown>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <label class="col-form-label" style="font-weight: 700"
                          >Fact. Deadline</label
                        >
                        <div
                          class="input-group"
                          daterangepicker
                          [options]="dateRangePickerOptions"
                          (selected)="selectedDate($event, dateInput)"
                        >
                          <span
                            class="form-control uneditable-input fact_deadline"
                            name="daterange"
                          >
                            <span *ngIf="dateInput.start || dateInput.end">
                              {{ dateInput.start | date: "M/dd/y" }} -
                              {{ dateInput.end | date: "M/dd/y" }}
                            </span>
                          </span>
                          <span class="input-group-btn">
                            <a type="button" class="btn btn-default"
                              ><em class="fa fa-calendar"></em
                            ></a>
                          </span>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <label class="col-form-label" style="font-weight: 700">Year-Month</label>
                        <div id="year-month-select">
                          <ng-multiselect-dropdown
                            appFocusOnClick
                            class="select-sp-report searchicon-dropdown filter-type-status multi-search"
                            [settings]="dropdownSettings"
                            [data]="yearMonthList"
                            [(ngModel)]="yearMonth"
                            [placeholder]="'Select Item'"
                            (onSelect)="onItemSelectDate($event)"
                            (onDeSelect)="OnItemDeSelectDate($event)"
                            (onSelectAll)="OnItemSelectAllDate($event)"
                            (onDeSelectAll)="OnItemDeSelectAllDate($event)"
                          ></ng-multiselect-dropdown>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="pull-right" style="margin-top: 46px; padding-right: 5px">
                          <button
                            type="button"
                            (click)="resetFilter()"
                            class="btn btn-primary btn pointer btn-sm"
                          >
                            Reset Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [hidden]="!loading">
            <div class="text-center">
              <em class="fa fa-spinner fa-pulse fa-2x fa-fw"></em
              ><span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            style="height: 1px; z-index: 10; position: relative; padding-right: 25px"
            class="pull-right"
            *ngIf="!loading"
          >
            <div>
              <div style="font-size: large;" class="pull-left text-danger mr-2 margin-auto" *ngIf="portalSelectedStList.length && hasDifferentGroupProjectSelected && hasDifferentFollowupProjectSelected">
                <strong>Projects from different groups and follow-up stages are selected! </strong>
              </div>
              <div style="font-size: large;" class="pull-left text-danger mr-2 margin-auto" *ngIf="portalSelectedStList.length && hasDifferentGroupProjectSelected && !hasDifferentFollowupProjectSelected">
                <strong>Projects from different groups are selected! </strong>
              </div>
              <div style="font-size: large;" class="pull-left text-danger mr-2 margin-auto" *ngIf="portalSelectedStList.length && hasDifferentFollowupProjectSelected && !hasDifferentGroupProjectSelected">
                <strong>Projects from different follow-up stages are selected! </strong>
              </div>
              <div style="font-size: large;" class="pull-left text-danger mr-2 margin-auto" *ngIf="portalSelectedStList.length && hasCompletedFollowupProjectSelected && !hasDifferentGroupProjectSelected && !hasDifferentFollowupProjectSelected">
                <strong>The first follow-up has already been sent! </strong>
              </div>
              <button
                *ngIf="
                  commonService.adminActionPermissionCheck() > constantService.ADMIN_WEIGTAGE ||
                  currentUserIsProjectAdmin == true
                "
                [disabled]="!portalSelectedStList.length || hasDifferentGroupProjectSelected || hasDifferentFollowupProjectSelected || hasCompletedFollowupProjectSelected"
                type="button"
                (click)="showMultipleApprovalNoticeFollowUpPopup()"
                class="btn btn-primary btn mr-2 btn-sm margin-auto pointer"
              >
              Approval Notice Follow-Up
              </button>
              <button
                *ngIf="
                  commonService.adminActionPermissionCheck() > constantService.ADMIN_WEIGTAGE &&
                  !hasNotBillingMgmtApprover()
                "
                type="button"
                (click)="mgmtReportPopup()"
                class="btn btn-primary btn mr-2 btn-sm margin-auto pointer"
              >
                MGMT Review Report
              </button>
              <button
                type="button"
                (click)="showSummaryTable()"
                class="btn btn-primary btn mr-2 btn-sm margin-auto pointer"
              >
                Show SP-Report Summary
              </button>
              <button
                type="button"
                [disabled]="downloading || count == 0"
                (click)="getReportXlsxData()"
                class="btn btn-primary btn-sm pull-right pointer"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <em class="fa fa-download" aria-hidden="true"></em> Download XLSX
              </button>
              <span [hidden]="!downloading" class="pull-right"
                ><em class="fa fa-spinner fa-pulse fa-2x fa-fw"></em
                ><span class="sr-only">Loading...</span>
              </span>
            </div>
          </div>
          <div *ngIf="count === 0 && !loading">
            <div class="text-center">No records available</div>
          </div>
          <div class="table-responsive private-network-table" [hidden]="loading || !count">
            <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper no-footer">
              <div id="DataTables_Table_0_filter" class="dataTables_filter"></div>
              <table
                id="dueDateReportTable"
                class="table dataTable no-footer duedateReport"
                role="grid"
                aria-describedby="DataTables_Table_0_info"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>
                      <input name="select_all" value="1" id="storeSelectAll" type="checkbox" />
                    </th>
                    <th>Age</th>
                    <th>SP</th>
                    <th>Parent</th>
                    <th>Project Title</th>
                    <th>MGMT Review</th>
                    <th>Report</th>
                    <th>DMS</th>
                    <th>Manufacturer</th>
                    <th>State</th>
                    <th>Start Date</th>
                    <th>Shipped</th>
                    <th>Delivered</th>
                    <th>Deadline</th>
                    <th>Last Action</th>
                    <th>Ready To Bill Age</th>
                    <th>Deadline Notes</th>
                    <th id="info-details"></th>
                    <th>Approval Follow-Up</th>
                    <th>Color Spec</th>
                    <th>Ref Date</th>
                    <th>Formatted Deadline Notes</th>
                    <th>Approval Notify On</th>
                    <th>Approval Effective On</th>
                    <th>Id</th>
                    <th>Age Spec</th>
                    <th>Group</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
<div
  class="modal fade"
  id="deadlineNoteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 800px">
      <div class="modal-header">
        <h5 class="modal-title" id="requestModalLabel">Edit Deadline Note</h5>
        <button
          type="button"
          class="close"
          (click)="!showSpinnerButton ? closeDeadlineNoteModal() : ''"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="editDeadlineNoteForm">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="deadlineNote" class="form-control-label" style="font-weight: 700"
                >Deadline Note <span class="required"> *</span>
              </label>
              <div>
                <label class="input-group">
                  <textarea
                    rows="15"
                    formControlName="deadlineNote"
                    class="form-control"
                    id="deadlineNote"
                    name="deadlineNote"
                    #deadLineNote
                  ></textarea>
                </label>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label
                for="deadlineNoteLastAction"
                class="form-control-label"
                style="font-weight: 700"
                >Last Action Date
                <span class="required"> *</span>
              </label>
              <div>
                <label class="input-group">
                  <input
                    type="text"
                    formControlName="deadlineNoteDate"
                    class="form-control datepicker-only-init"
                    id="deadlineLastActionDate"
                    name="deadlineNoteDate"
                    #deadlineLastActionDate
                    (blur)="setdeadlineLastActionDate(deadlineLastActionDate)"
                    placeholder="Select Date"
                  />
                  <span class="input-group-addon pointer">
                    <em class="icmn-calendar"></em>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              [disabled]="showSpinnerButton"
              class="btn btn-secondary"
              (click)="closeDeadlineNoteModal()"
            >
              Cancel
            </button>
            <button
              type="button"
              [disabled]="
                showSpinnerButton ||
                editDeadlineNoteForm.get('deadlineNote')?.value.trim() === '' ||
                !editDeadlineNoteForm.valid
              "
              class="btn btn-primary"
              (click)="updateDeadlineNoteFormSubmit()"
            >
              <em *ngIf="showSpinnerButton" class="fa fa-spinner fa-pulse fa-1x fa-fw"></em>Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="mgmtReportModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 500px">
      <div class="modal-header">
        <h5 class="modal-title" id="requestModalLabel">MGMT Review Request/Approval Report</h5>
        <button
          type="button"
          class="close"
          (click)="!showSpinnerButton ? closeMgmtReportPopup() : ''"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="mgmtReportForm">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="fromDate" class="form-control-label" style="font-weight: 700"
                >From <span class="required"> *</span>
              </label>
              <div>
                <label class="input-group">
                  <input
                    type="text"
                    formControlName="fromDate"
                    class="form-control datepicker-only-init"
                    id="mgmtReportFromDate"
                    name="mgmtReportFromDate"
                    #mgmtReportFromDate
                    (blur)="setmgmtReportFromDate(mgmtReportFromDate)"
                    placeholder="Select Date"
                  />
                  <span class="input-group-addon pointer">
                    <em class="icmn-calendar"></em>
                  </span>
                </label>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="toDate" class="form-control-label" style="font-weight: 700"
                >To <span class="required"> *</span>
              </label>
              <div>
                <label class="input-group">
                  <input
                    type="text"
                    formControlName="toDate"
                    class="form-control datepicker-only-init"
                    id="mgmtReportToDate"
                    name="mgmtReportToDate"
                    #mgmtReportToDate
                    (blur)="setmgmtReportToDate(mgmtReportToDate)"
                    placeholder="Select Date"
                  />
                  <span class="input-group-addon pointer">
                    <em class="icmn-calendar"></em>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              [disabled]="showSpinnerButton"
              class="btn btn-secondary"
              (click)="closeMgmtReportPopup()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="mgmtReportFormSubmit()"
              [disabled]="!mgmtReportForm.valid || showSpinnerButton"
            >
              <em *ngIf="showSpinnerButton" class="fa fa-spinner fa-pulse fa-1x fa-fw"></em>Download
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="showMemmoryModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 1175px; left: -405px">
      <div class="modal-header">
        <h5 class="modal-title" id="requestModalLabel">Summary Table</h5>
        <button type="button" class="close" (click)="closeSummary()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div [innerHtml]="summaryData | safeHtml"></div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="button" (click)="closeSummary()">OK</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deadlieNoteDetails"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 800px; left: -220px">
      <div class="modal-header">
        <h5 class="modal-title" id="requestModalLabel">Deadline Notes</h5>
        <button type="button" class="close" (click)="closeAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <textarea
          rows="15"
          class="form-control"
          disabled
          style="
            overflow: auto;
            resize: none;
            border: none !important;
            outline: none !important;
            background-color: white;
          "
          id="deadlineNoteRead"
          name="deadlineNoteRead"
          #deadlineNoteRead
          >{{ deadlineNoteView }}</textarea
        >
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" (click)="closeAlert()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="showInfo"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 800px; left: -220px">
      <div class="modal-header">
        <h5 class="modal-title" id="requestModalLabel">More Info</h5>
        <button type="button" class="close" (click)="closeInfo()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="white-space: pre-wrap">
        <div [innerHtml]="infoData | safeHtml"></div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" (click)="closeInfo()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="approvalNoticeFollowUpModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="custom-modal-dialog modal-dialog" style="width: 60% !important" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="requestModalLabel">Approval Notice Follow-Up</h5>
        <button
          type="button"
          class="close"
          (click)="closeApprovalNoticeFollowUpPopup()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="approvalNoticeFollowUpForm">
          <div class="accordion accordion-margin-bottom" id="accordionApprovalNoticeFollowUp">
            <div class="card">
              <div class="card-header cursor_default">
                <div
                  class="card-title cat__core__step cat__core__step--squared cat__core__step--success"
                >
                  <a class="text-success">
                    <strong>Email Preview <span *ngIf="approvalNoticeFollowUpForm.get('subject')?.value">
                      ({{ approvalNoticeFollowUpForm.get('subject')?.value }})</span>
                    </strong>
                  </a>
                </div>
              </div>

              <div class="card-block">
                <div class="card">
                    <div class="card-block">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group row">
                            <div class="col-md-12 text-center">
                              <label class="col-form-label" style="font-weight: 700">Follow-Up <span class="required"> *</span></label>
                              &nbsp;&nbsp;
                              <label>
                                <input
                                  type="radio"
                                  formControlName="approvalFollowupDetailsCount"
                                  name="approvalFollowupDetailsCount"
                                  value="0"
                                  [attr.disabled]="true"
                                />
                                First Follow-Up
                              </label>
                              &nbsp;
                              <label>
                                <input
                                  type="radio"
                                  formControlName="approvalFollowupDetailsCount"
                                  name="approvalFollowupDetailsCount"
                                  value="1"
                                  [attr.disabled]="true"
                                />
                                Second Follow-Up
                              </label>
                              &nbsp;
                              <label>
                                <input
                                  type="radio"
                                  formControlName="approvalFollowupDetailsCount"
                                  name="approvalFollowupDetailsCount"
                                  value="2"
                                  [attr.disabled]="true"
                                />
                                Third Follow-Up
                              </label>
                            </div>
                            <!-- <div class="col-md-12 text-center" *ngIf="approvalNoticeFollowUpForm.get('hasMultipleProject')?.value == true">
                              <label class="col-form-label" style="font-weight: 700">Follow-Up <span class="required"> *</span></label>
                              &nbsp;&nbsp;
                              <label>
                                <input
                                  type="radio"
                                  formControlName="approvalFollowupDetailsCount"
                                  name="approvalFollowupDetailsCount"
                                  value="0"
                                  (change)="approvalFollowupChange(0)"
                                />
                                First Follow-Up
                              </label>
                              &nbsp;
                              <label>
                                <input
                                  type="radio"
                                  formControlName="approvalFollowupDetailsCount"
                                  name="approvalFollowupDetailsCount"
                                  value="1"
                                  (change)="approvalFollowupChange(1)"
                                />
                                Second Follow-Up
                              </label>
                              &nbsp;
                              <label>
                                <input
                                  type="radio"
                                  formControlName="approvalFollowupDetailsCount"
                                  name="approvalFollowupDetailsCount"
                                  value="2"
                                  (change)="approvalFollowupChange(2)"
                                />
                                Third Follow-Up
                              </label>
                            </div> -->
                          </div>
                          <div class="form-group row">
                            <div class="col-md-12">
                              <label
                                for="deadlineNotes"
                                class="form-control-label"
                                style="font-weight: 700"
                                >Deadline Notes <span class="required"> *</span>
                                <span class="project-field-edit-button-container mr-2">
                                  <button
                                    [hidden]="editDeadlineNoteFlag"
                                    class="editClass plus fa fa-edit fa-lg action-btn-group"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit"
                                    data-animation="false"
                                    (click)="editDeadlineNoteFields()"
                                  ></button>
                                  <button
                                    [hidden]="!editDeadlineNoteFlag"
                                    class="editClass plus fa fa-save fa-lg action-btn-group"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Save"
                                    data-animation="false"
                                    (click)="saveDeadlineNoteFields()"
                                  ></button>
                                  <button
                                    [hidden]="!editDeadlineNoteFlag"
                                    class="editClass plus fa fa-close fa-lg action-btn-group"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Cancel"
                                    data-animation="false"
                                    (click)="closeDeadlineNoteFields()"
                                  ></button>
                                </span>
                              </label>
                              <div>
                                <label class="input-group">
                                  <textarea
                                    [readonly]="!editDeadlineNoteFlag"
                                    cols="20"
                                    rows="2"
                                    class="form-control"
                                    formControlName="deadlineNotes"
                                  ></textarea>
                                </label>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>

                    </div>
                </div>
                <div [hidden]="!billingLoading">
                  <div class="text-center">
                    <em class="fa fa-spinner fa-pulse fa-2x fa-fw"></em
                    ><span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div [hidden]="billingLoading">
                  <div class="card">
                    <div
                      class="card-header"
                      role="tab"
                      id="headingApprovalNoticeFollowUp"
                      data-toggle="collapse"
                      data-parent="#accordionApprovalNoticeFollowUp"
                      [ngClass]="{ collapsed: approvalNoticeFollowUpSectionCollapsed }"
                      (click)="approvalNoticeFollowUpToggle()"
                      aria-controls="collapseApprovalNoticeFollowUp"
                    >
                      <div
                        class="cat__core__step__title cat__core__step cat__core__step--squared cat__core__step--warning"
                      >
                        <span class="accordion-indicator pull-right">
                          <em class="plus fa fa-plus"></em>
                          <em class="minus fa fa-minus"></em>
                        </span>
                        <a class="text-warning">
                          <span style="float: left">Send To &nbsp;&nbsp;</span>&nbsp;
                          <span
                            class="contactEmailCustomList"
                            *ngFor="
                              let contact of billingEmailIdList | slice: 0 : 3;
                              let i = index;
                              trackBy: trackByFn
                            "
                          >
                            <span class="contactEmailCustomList" *ngIf="i == 0">
                              [{{ contact.id }}
                            </span>
                            <span class="contactEmailCustomList" *ngIf="i == 1">
                              ,{{ contact.id }}
                            </span>
                            <span class="contactEmailCustomList" *ngIf="i == 2">
                              ,{{ contact.id }}]
                            </span>
                            <span
                              class="contactEmailCustomList"
                              *ngIf="
                                (i == 0 && billingEmailIdList.length == 1) ||
                                (i == 1 && billingEmailIdList.length == 2)
                              "
                            >
                              ]
                            </span>
                          </span>
                          &nbsp;
                          <span class="contactEmailCustomList" *ngIf="billingEmailIdList.length > 3">
                            &nbsp;+{{ billingEmailIdList.length - 3 }}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div
                      id="collapseApprovalNoticeFollowUp"
                      class="card-collapse"
                      [ngClass]="{ collapse: approvalNoticeFollowUpSectionCollapsed }"
                      role="tabcard"
                      aria-labelledby="headingApprovalNoticeFollowUp"
                    >
                      <div class="card-block">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <div class="col-md-12">
                                <label class="col-form-label" style="font-weight: 700"
                                  >Project Liaison</label
                                >
                                &nbsp;&nbsp;
                              </div>
                              <div class="col-md-12">
                                <ng-multiselect-dropdown appFocusOnClick
                                  class="searchicon-dropdown rounded-selection rouded-sel-label multi-search filter-type-status"
                                  [data]="billingProcessUserCategory3List"
                                  formControlName="category3Email"
                                  [(ngModel)]="billingCategory3EmailIdList"
                                  [settings]="dropdownSettingsShowLimitEmail"
                                  (onSelect)="onSelectBillingCategory3User($event)"
                                  (onDeSelect)="onDeSelectBillingCategory3User($event)"
                                  (onSelectAll)="onSelectAllBillingCategory3User($event)"
                                  (onDeSelectAll)="onDeSelectAllBillingCategory3User($event)"
                                ></ng-multiselect-dropdown>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <div class="col-md-12">
                                <label class="col-form-label" style="font-weight: 700"
                                  >Dealer Principal</label
                                >
                                &nbsp;&nbsp;
                              </div>
                              <div class="col-md-12">
                                <ng-multiselect-dropdown appFocusOnClick
                                  class="searchicon-dropdown rounded-selection rouded-sel-label multi-search filter-type-status"
                                  [data]="billingProcessUserCategory1List"
                                  formControlName="category1Email"
                                  [(ngModel)]="billingCategory1EmailIdList"
                                  [settings]="dropdownSettingsShowLimitEmail"
                                  (onSelect)="onSelectBillingCategory1User($event)"
                                  (onDeSelect)="onDeSelectBillingCategory1User($event)"
                                  (onSelectAll)="onSelectAllBillingCategory1User($event)"
                                  (onDeSelectAll)="onDeSelectAllBillingCategory1User($event)"
                                ></ng-multiselect-dropdown>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <div class="col-md-12">
                                <label class="col-form-label" style="font-weight: 700"
                                  >Billing Contact</label
                                >
                                &nbsp;&nbsp;
                              </div>
                              <div class="col-md-12">
                                <ng-multiselect-dropdown appFocusOnClick
                                  class="searchicon-dropdown rounded-selection rouded-sel-label multi-search filter-type-status"
                                  [data]="billingProcessUserCategory2List"
                                  formControlName="category2Email"
                                  [(ngModel)]="billingCategory2EmailIdList"
                                  [settings]="dropdownSettingsShowLimitEmail"
                                  (onSelect)="onSelectBillingCategory2User($event)"
                                  (onDeSelect)="onDeSelectBillingCategory2User($event)"
                                  (onSelectAll)="onSelectAllBillingCategory2User($event)"
                                  (onDeSelectAll)="onDeSelectAllBillingCategory2User($event)"
                                ></ng-multiselect-dropdown>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <div class="col-md-12">
                                <label class="col-form-label" style="font-weight: 700"
                                  >DMS/IT Contact</label
                                >
                                &nbsp;&nbsp;
                              </div>
                              <div class="col-md-12">
                                <ng-multiselect-dropdown appFocusOnClick
                                  class="searchicon-dropdown rounded-selection rouded-sel-label multi-search filter-type-status"
                                  [data]="billingProcessUserCategory4List"
                                  formControlName="category4Email"
                                  [(ngModel)]="billingCategory4EmailIdList"
                                  [settings]="dropdownSettingsShowLimitEmail"
                                  (onSelect)="onSelectBillingCategory4User($event)"
                                  (onDeSelect)="onDeSelectBillingCategory4User($event)"
                                  (onSelectAll)="onSelectAllBillingCategory4User($event)"
                                  (onDeSelectAll)="onDeSelectAllBillingCategory4User($event)"
                                ></ng-multiselect-dropdown>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <div class="col-md-12">
                                <label class="col-form-label" style="font-weight: 700">Other</label>
                                &nbsp;&nbsp;
                              </div>
                              <div class="col-md-12">
                                <ng-multiselect-dropdown appFocusOnClick
                                  class="searchicon-dropdown rounded-selection rouded-sel-label multi-search filter-type-status"
                                  [data]="billingProcessUserCategory5List"
                                  formControlName="category5Email"
                                  [(ngModel)]="billingCategory5EmailIdList"
                                  [settings]="dropdownSettingsShowLimitEmail"
                                  (onSelect)="onSelectBillingCategory5User($event)"
                                  (onDeSelect)="onDeSelectBillingCategory5User($event)"
                                  (onSelectAll)="onSelectAllBillingCategory5User($event)"
                                  (onDeSelectAll)="onDeSelectAllBillingCategory5User($event)"
                                ></ng-multiselect-dropdown>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <div class="col-md-12" style="margin-top: 20px">
                                <label class="col-form-label" style="font-weight: 700"
                                  >Add New Contact</label
                                >
                                &nbsp;&nbsp;
                                <div>
                                  <span
                                    *ngFor="
                                      let emailIds of customBillingEmailList;
                                      let i = index;
                                      trackBy: trackByFn
                                    "
                                    class="name contactListCustom"
                                    >{{ emailIds }}
                                    <i
                                      class="fa fa-times"
                                      style="cursor: pointer"
                                      (click)="removeEmailIdsFromList(emailIds)"
                                    ></i>
                                  </span>
                                  <button
                                    class="btn btn-primary btn-sm pointer"
                                    title="Add New Contact"
                                    data-toggle="tooltip"
                                    data-animation="false"
                                    data-placement="top"
                                    (click)="addRecipients('M')"
                                  >
                                    <span
                                      style="
                                        position: relative;
                                        top: 3px;
                                        font-size: 17px;
                                        right: 0px;
                                      "
                                    >
                                      <i class="fa fa-plus" style="cursor: pointer"></i>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <ckeditor [editor]="Editor" [config]="editorConfig" formControlName="message" name="myckeditor" required debounce="500">
                      </ckeditor>
                    </div>
                  </div>
                </div>
                <br />
                <div style="overflow: hidden; height: 0">
                  <div class="WordSection1" id="emailTemplate2021">
                    <div style="font-size: 11pt; font-family: Calibri !important">
                      <div>
                        <p class="MsoNormal" style="line-height: 150%">
                          <span
                            style="font-size: 11pt"
                            *ngIf="approvalNoticeFollowUpForm.get('projectId')?.value"
                            >Hello <span
                            class="contactEmailCustomList"
                          >
                          {{emailListToString}},
                           </span>
                          </span>
                        </p>
                        <p class="MsoNormal" style="line-height: 150%">
                          <span style="font-size: 11pt"
                            >This is a reminder that the notice from {{approvalNoticeFollowUpForm.get('manufacturer')?.value}} to approve your retail warranty {{approvalNoticeFollowUpForm.get('projectType')?.value}} request for {{approvalNoticeFollowUpForm.get('storeName')?.value}} is expected in by {{approvalNoticeFollowUpForm.get('deadlineDate')?.value}}.</span
                          >
                        </p>
                        <p class="MsoNormal" style="line-height: 150%">
                          <span style="font-size: 11pt"
                            >Please check to see if the notice has arrived. If you do have it, please attach it and “Reply All” to send it to us, or forward the email notice to approvals@dealeruplift.com, so we can review the details and make sure the approved rate reflects what was requested, or expected and file it accordingly. </span
                          >
                        </p>
                        <p class="MsoNormal" style="line-height: 150%">
                          <span style="font-size: 11pt">Thanks for your help and have a great day.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="WordSection1" id="emailTemplateGroup2021">
                    <div style="font-size: 11pt; font-family: Calibri !important">
                      <div>
                        <p class="MsoNormal" style="line-height: 150%">
                          <span
                            style="font-size: 11pt"
                            *ngIf="approvalNoticeFollowUpForm.get('projectId')?.value"
                            >Hello <span
                            class="contactEmailCustomList"
                          >
                          {{emailListToString}},
                           </span>
                          </span>
                        </p>
                        <p class="MsoNormal" style="line-height: 150%">
                          <span style="font-size: 11pt"
                            >Below is a schedule of upcoming Deadlines Dates for your Manufacturers to respond and approve your retail warranty {{projectListTypeString}} requests for {{approvalNoticeFollowUpForm.get('groupName')?.value}}.</span
                          >
                        </p>
                        <table
                          id="dueDateReportTable1111"
                          class="table no-footer"
                          role="grid"
                          border="1"
                        >
                          <thead>
                            <tr>
                              <th>Store Name</th>
                              <th style="width: 90px;">Project Type</th>
                              <th style="width: 170px;">Factory Deadline Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of portalSelectedList; let i = index">
                              <td>
                               {{data.storeName}}
                              </td>
                              <td>
                                {{data.projectType=='Parts UL'? 'Parts': 'Labor'}}
                              </td>
                              <td>
                                {{data.deadline}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p class="MsoNormal" style="line-height: 150%">
                          <span style="font-size: 11pt"
                            >
                            When the notices arrive, please attach them and “Reply All” to send it to us, or forward the email notices to approvals@dealeruplift.com, so we can review the details and make sure the approved rates reflect what was requested, or expected and file it accordingly.</span
                          >
                        </p>
                        <p class="MsoNormal" style="line-height: 150%">
                          <span style="font-size: 11pt"
                            >
                            Thanks for your help and have a great day.</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-6 text-left" *ngIf="displayProgressBar">
                    <span class="text-center text-success">Sending Mail .... </span>
                    <progressbar
                      [max]="3"
                      type="success"
                      [striped]="true"
                      [animate]="true"
                      [value]="mailSentStatus"
                      style="height: 2.28rem !important"
                    >
                      <span
                        class="text-center"
                        style="
                          color: #101010 !important;
                          position: relative !important;
                          font-weight: bold !important;
                          top: 7px !important;
                          z-index: 100 !important;
                        "
                        >{{ progressMessage }}</span
                      >
                    </progressbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="closeApprovalNoticeFollowUpPopup()"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              #billingProcessStep4Btn
              (click)="sentMailForm(billingProcessStep4Btn)"
              [disabled]="
                !(
                  approvalNoticeFollowUpForm.valid &&
                  billingEmailIdList.length
                )
              "
            >
              Send Email <i class="fa fa-envelope" aria-hidden="true"></i>
              <em class="fa fa-refresh fa-spin" *ngIf="billingLoading"></em>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
