import { Injectable } from "@angular/core";
// import "rxjs/add/operator/map";
import { ConstantService } from "../constants/constant.service";
import { CommonService } from "../services/common.service";
import { DMConstantService } from "../constants/dm.constant.service";
import * as moment from "moment-timezone";
import { MyCurrencyPipe } from "../components/formatter/currency/my-currency.pipe";
import { MyPercentagePipe } from "../components/formatter/percentage/my-percentage.pipe";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { HttpClient } from "@angular/common/http";
@Injectable()
export class billingTemplatePdfServiceLabor {
  public storeGroup: any = [];
  public headerLabels: any = {};
  constructor(
    private http: HttpClient,
    private constantService: ConstantService,
    private commonService: CommonService,
    private mycurpipe: MyCurrencyPipe,
    private myperpipe: MyPercentagePipe,
    private DMConstantService: DMConstantService
  ) {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
  }
  init() {
    //
  }
  downloadBillingTemplatePdfReport(
    data: any,
    storeGroup: any,
    store: any,
    imgData: any,
    action: any,
    callback: any
  ) {
    data = data[0];
    storeGroup = storeGroup.length ? storeGroup[0].itemName : "";
    let storeName = store ? store.itemName : "";

    this.headerLabels = {
      TOP_0: "Data Provided",
      TOP_1: "Factory Approved Results",
      HEAD_0: "Warranty Labor & PDI Hours Per Month",
      HEAD_1: "Previous Warranty Rate",
      HEAD_2: "Submitted Labor Rate",
      HEAD_3: "Factory Adjustment",
      HEAD_4: "Rate Impact",
      HEAD_5: "Annual Profit Impact",
      HEAD_6: "Approved Warranty Rate",
      HEAD_7: "Increase Over Previous Rate",
      HEAD_8: "Additional Monthly profit",
      HEAD_9: "Additional Annual profit",
    };
    let fileName_ws = "";
    // fileName_ws = 'Billing Template Labor ' + moment().format('YYYYMMDDhhmmss');
    fileName_ws = `${storeName} Labor Uplift Summary ${moment().format("YYYYMMDD")}`;

    const cellWidths = [28, 60, 156, 66.4, 66.4, 66.4, 66.4, 66.4, 66.4, 66.4, 66.4, 66.4];
    const cellWidthsHeaderRow = [
      262,

      66.4, 66.4, 66.4,

      142,

      66.4, 66.4, 66.4, 66.4,
    ];
    const cellWidthsHeaderRow_1 = [
      262,

      66.4, 66.4, 66.4, 66.4,

      66.4, 66.4,

      66.4, 66.4, 66.4,
    ];

    const loopData = [];
    let counter = 0;
    counter++;
    let brandList = data.brand;
    brandList = brandList ? brandList : null;

    const loopData1 = [
      {
        text: brandList,
        style: "hederSection_data",
        border: [true, true, false, true],
        margin: [0, 8, 0, 0],
      },
      {
        text: data.state,
        style: "hederSection_data",
        border: [false, true, false, true],
        margin: [0, 8, 0, 0],
      },
      {
        text: data.stName,
        style: "hederSection_data",
        border: [false, true, false, true],
        margin: [0, 8, 0, 0],
      },
      {
        text: data.averageWarrantyLaborPDIHoursperMonth != null ? Number(data.averageWarrantyLaborPDIHoursperMonth).toFixed(0) || "": "",
        style: "hederSection_data",
        margin: [0, 8, 0, 0],
      },
      {
        text: data.warrantyLaborRate ? data.warrantyLaborRate : "",
        style: "hederSection_data",
        margin: [0, 8, 0, 0],
      },
      {
        text: data.bestRateAcheivedIncludingStoreDiscountsFinalAudit || "",
        style: "hederSection_data",
        margin: [0, 8, 0, 0],
      },
      {
        text: data.rateImpact ? data.rateImpact : "",
        style: this.getCellColor(3, data.rateImpact),
        margin: [0, 8, 0, 0],
      },
      {
        text: this.mycurpipe.transform(
          data.annualProfitImpact ? Number(data.annualProfitImpact).toFixed(0) : data.annualProfitImpact,
          0
        ),
        style: this.getCellColor(3, data.annualProfitImpact),
        margin: [0, 8, 0, 0],
      },
      {
        text: data.approvedLaborRate ? data.approvedLaborRate : "",
        style: "hederSection_data",
        margin: [0, 8, 0, 0],
      },
      {
        text: this.mycurpipe.transform(
          data.increaseOverCurrentRate
            ? data.increaseOverCurrentRate.toString()
            : data.increaseOverCurrentRate,
          2
        ),
        style: this.getCellColor(1, data.increaseOverCurrentRate),
        margin: [0, 8, 0, 0],
      },
      {
        text: this.mycurpipe.transform(
          data.factoryAdjustedMonthlyProfit
            ? Number(data.factoryAdjustedMonthlyProfit).toFixed(0)
            : data.factoryAdjustedMonthlyProfit,
          0
        ),
        style: this.getCellColor(1, data.factoryAdjustedMonthlyProfit),
        margin: [0, 8, 0, 0],
      },
      {
        text: this.mycurpipe.transform(
          data.factoryAdjustedAnnualProfit
            ? Number(data.factoryAdjustedAnnualProfit).toFixed(0)
            : data.factoryAdjustedAnnualProfit,
          0
        ),
        style: this.getCellColor(1, data.factoryAdjustedAnnualProfit),
        margin: [0, 8, 0, 0],
      },
    ];
    loopData.push(loopData1);

    console.log(loopData);
    const cellHeights = [];
    for (let loopCount = 0; loopCount < loopData.length; loopCount++) {
      cellHeights.push(30);
    }
    const projectioReport: any = {
      pageSize: "Legal",
      pageOrientation: "landscape",
      pageMargins: [29, 29, 29, 29],
      content: [
        {
          style: "topHeaderTable",
          table: {
            widths: [200, 650],
            body: [
              [
                {
                  image: imgData,
                  width: 150,
                  height: 100,
                  margin: [0, 35, 0, 0],
                  absolutePosition: { x: 90, y: 110 },
                },
                {
                  border: [false, false, false, false],
                  text: "",
                  italics: true,
                  style: "subhead2",
                },
              ],
            ],
          },
          layout: { defaultBorder: false },
        },
        {
          alignment: "center",
          style: "groupNameList",
          columns: [{ text: "Labor Uplift Summary", style: "header" }],
        },
        {
          style: "tableExample",
          table: {
            border: [true, true, true, false],
            widths: [141, "*"],
            heights: [30],
            body: [
              [
                { text: this.headerLabels.TOP_0, style: "topHeader_2" },
                { text: this.headerLabels.TOP_1, style: "topHeader_2" },
              ],
            ],
          },
          layout: {},
        },

        {
          style: "peojectionTableData",
          table: {
            heights: [70],
            widths: cellWidthsHeaderRow,
            border: [false, false, false, true],
            body: [
              [
                {
                  text: "",
                  style: "hederSectionFirstThree",
                  alignment: "center",
                  border: [false, false, true, false],
                },
                {
                  text: this.headerLabels.HEAD_0,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                  margin: [1, 17, 1, 1],
                },
                {
                  text: this.headerLabels.HEAD_1,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                  margin: [1, 17, 1, 1],
                },
                {
                  text: this.headerLabels.HEAD_2,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                  margin: [1, 17, 1, 1],
                },
                {
                  text: this.headerLabels.HEAD_3,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                  margin: [1, 32, 1, 1],
                },
                {
                  text: this.headerLabels.HEAD_6,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                  margin: [1, 17, 1, 1],
                },
                {
                  text: this.headerLabels.HEAD_7,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                  margin: [1, 17, 1, 1],
                },
                {
                  text: this.headerLabels.HEAD_8,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                  margin: [1, 17, 1, 1],
                },
                {
                  text: this.headerLabels.HEAD_9,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                  margin: [1, 17, 1, 1],
                },
              ],
            ],
          },
          layout: { defaultBorder: true },
        },
        {
          style: "peojectionTableData",
          table: {
            heights: [30],
            widths: cellWidthsHeaderRow_1,
            border: [false, false, false, true],
            body: [
              [
                {
                  text: `Group: ${storeGroup}`,
                  style: "hederSectionFirstThree_1",
                  alignment: "center",
                  border: [false, false, true, false],
                },
                {
                  text: "",
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: "",
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: "",
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },

                {
                  text: this.headerLabels.HEAD_4,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, true, true, false],
                },
                {
                  text: this.headerLabels.HEAD_5,
                  style: "hederSection",
                  alignment: "center",
                  border: [true, true, true, false],
                },
                {
                  text: "",
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: "",
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: "",
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
                {
                  text: "",
                  style: "hederSection",
                  alignment: "center",
                  border: [true, false, true, false],
                },
              ],
            ],
          },
          layout: { defaultBorder: true },
        },
        {
          style: "peojectionTableData",
          table: {
            dontBreakRows: true,
            heights: cellHeights,
            widths: cellWidths,
            body: loopData,
          },
          layout: {},
        },
      ],
      styles: {
        row1Style: {
          fontSize: 18,
          bold: true,
          alignment: "center",
          margin: [10, 1, 40, 2],
          color: "#175595",
        },
        subhead2: {
          fontSize: 10,
          bold: false,
          alignment: "center",
          margin: [0, 30, 0, 0],
          color: "#175595",
        },
        reportHeader: {
          fontSize: 18,
          bold: true,
          alignment: "center",
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          color: "#175595",
        },
        topHeaderTable: {
          border: [false, false, false, false],
        },
        topHeader: {
          fillColor: "#FFFFFF",
          fontSize: 11,
          bold: true,
          alignment: "center",
          margin: [0, 7, 0, 0],
          border: [true, true, true, false],
        },
        topHeader1: {
          fillColor: "#D9D9D9",
          fontSize: 9,
          alignment: "center",
          margin: [0, 7, 0, 0],
          border: [true, true, true, false],
        },
        topHeader_2: {
          fillColor: "#FFFFFF",
          fontSize: 12,
          bold: true,
          alignment: "center",
          margin: [0, 7, 0, 0],
          border: [true, true, true, false],
        },
        topHeader2: {
          fillColor: "#D0E3F2",
          fontSize: 12,
          bold: true,
          alignment: "center",
          margin: [0, 7, 0, 0],
          border: [true, true, true, false],
        },
        topHeader3: {
          fillColor: "#DAEAD4",
          fontSize: 9,
          alignment: "center",
          margin: [0, 7, 0, 0],
          border: [true, true, true, false],
        },
        hederSection: {
          fillColor: "#FFFFFF",
          fontSize: 10,
          alignment: "center",
        },
        hederSection_data: {
          fillColor: "#FFFFFF",
          fontSize: 9,
          alignment: "center",
        },
        hederSection_data_b: {
          fillColor: "#CDE2F4",
          fontSize: 9,
          alignment: "center",
        },
        hederSection_data_g: {
          fillColor: "#C1D69A",
          fontSize: 9,
          alignment: "center",
        },
        hederSection_data_w: {
          fillColor: "#FFFFFF",
          fontSize: 9,
          alignment: "center",
        },
        hederSectionFirstThree: {
          fillColor: "#FFFFFF",
          fontSize: 10,
          margin: [5, 35, 5, 0],
          bold: true,
        },
        hederSectionFirstThree_1: {
          fillColor: "#FFFFFF",
          fontSize: 10,
          margin: [5, 0, 5, 0],
          bold: true,
        },
        hederSectionGray: {
          fillColor: "#DFDCDC",
          fontSize: 10,
          alignment: "center",
        },
        hederSectionB: {
          fillColor: "#CDE2F4",
          fontSize: 10,
          alignment: "center",
        },
        headerSectionG: {
          fillColor: "#D0E0BF",
          fontSize: 10,
          alignment: "center",
        },
        hederSectionWhite: {
          fillColor: "#FFFFFF",
          fontSize: 10,
          alignment: "center",
        },
        dataSectionRed: {
          fillColor: "#f7c1be",
          fontSize: 10,
          alignment: "center",
        },
        hederSectionRed: {
          fillColor: "#f7c1be",
          fontSize: 10,
          alignment: "center",
        },
        header: {
          fontSize: 20,
          bold: true,
          alignment: "center",
          margin: [40, 12, 40, 1],
          color: "#175595",
        },
        header1: {
          fontSize: 16,
          bold: true,
          alignment: "left",
          margin: [0, 10, 0, 0],
          color: "#175595",
        },
        subhead: {
          fontSize: 12,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          backgroundColor: "#175595",
          border: 1,
          widths: 200,
        },
        subheadR1: {
          fontSize: 3,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          border: [true, true, true, true],
          fillColor: "red",
        },
        subheadR2: {
          fontSize: 12,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          widths: 300,
        },
        subheadR3: {
          fontSize: 12,
          alignment: "left",
          bold: false,
          margin: [0, 10, 0, 0],
          widths: 400,
        },
        tableHeader: {
          alignment: "center",
          fontSize: 13,
          bold: true,
          color: "black",
          margin: [0, 10, 0, 10],
        },
        tableExample: {
          margin: [271.5, 10, 0, 0],
        },
        groupNameList: {
          margin: [194, 0, 0, 0],
        },
        footerRegion: {
          fontSize: 11,
          alignment: "center",
          bold: true,
          color: "black",
        },
        footerRegion_1: {
          fontSize: 11,
          alignment: "right",
          margin: [0, 0, 50, 0],
          bold: true,
          color: "black",
        },
        peojectionTableDataFooter: {
          fontSize: 10,
          alignment: "center",
          bold: true,
          color: "black",
        },
      },
    };
    if (action && action === "attachment") {
      const pdfName = fileName_ws + ".pdf";
      pdfMake.createPdf(projectioReport).getBase64(function (encodedString) {
        // console.log('getBase64 pdf====>>>>>>>>>>>>', encodedString);
        const base64AttachmentString = encodedString;
        callback({
          filename: pdfName,
          path: base64AttachmentString,
        });
      });
    } else {
      pdfMake.createPdf(projectioReport).download(fileName_ws + ".pdf");
    }
  }

  get2DecimalPointFormat(value: any) {
    return value ? parseFloat(value).toFixed(2) : null;
  }

  get4DecimalPointFormat(value: any) {
    return value ? parseFloat(value).toFixed(4) : null;
  }

  getCellColor(group: any, value: any) {
    let bgClass = "hederSection_data";
    if (group === 1) {
      if (value != null) {
        if (value < 0) {
          bgClass = "dataSectionRed";
        } else if (value > 0) {
          bgClass = "headerSectionG";
        } else {
          bgClass = "hederSection_data";
        }
      }
    } else {
      bgClass = "hederSection_data";
      if (value != null) {
        if (value < -1) {
          bgClass = "dataSectionRed";
        } else if (value >= -1 && value <= 1) {
          bgClass = "hederSection_data";
        } else {
          bgClass = "headerSectionG";
        }
      }
    }
    return bgClass;
  }
}
