<section class="card" id="solveSyncContainer" *ngIf="isAuthenticated">
  <div class="card-header" id="solveSyncHeader">
    <span class="cat__core__title">
      <strong>Solve Update Log</strong>
    </span>
    <div class="cat__top-bar__right">
      <div class="cat__top-bar__item">
        <div class="cat__menu-right__action--menu-toggle">
          <span
            class="pull-right"
            style="padding-left: 15px"
            data-toggle="tooltip"
            data-placement="top"
            title="Click to refresh"
          >
            <em class="plus fa fa-refresh fa-2x pointer" *ngIf="!loading" (click)="refresh()"></em>
            <em class="plus fa fa-refresh fa-spin fa-2x" *ngIf="loading"></em>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 m-2" *ngIf="loading">
      <div class="text-center">
        <em class="fa fa-spinner fa-pulse fa-2x fa-fw"></em><span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <!-- <div class="row mt-3">
    <div class="col-lg-12 row">
      <div class="col-lg-6">
        <div class="pull-left">
          <label class="input-group input-group-sm">
            <span style="padding: 10px 13px 9px 20px"
              ><strong title="" data-toggle="tooltip" data-animation="false" data-placement="top"
                >From Date</strong
              ></span
            >
            <input
              type="text"
              [(ngModel)]="inStartDate"
              class="form-control datepicker-only-init"
              id="inStartDate"
              name="inStartDate"
              #dtInStartDate
              (blur)="setInStartDate(dtInStartDate)"
              placeholder="Select Date"
            />
            <span class="input-group-addon pointer">
              <em class="icmn-calendar"></em>
            </span>
          </label>
        </div>
        <div class="pull-left">
          <label class="input-group input-group-sm">
            <span style="padding: 10px 13px 9px 20px"
              ><strong title="" data-toggle="tooltip" data-animation="false" data-placement="top"
                >To Date</strong
              ></span
            >
            <input
              type="text"
              [(ngModel)]="inEndDate"
              class="form-control datepicker-only-init"
              id="inEndDate"
              name="inEndDate"
              #dtinEndDate
              (blur)="setinEndDate(dtinEndDate)"
              placeholder="Select Date"
            />
            <span class="input-group-addon pointer">
              <em class="icmn-calendar"></em>
            </span>
          </label>
        </div>
        <div class="pull-left ml-3">
          <button class="btn btn-primary" style="font-weight: bold" (click)="filterResponse()">
            Filter
          </button>
        </div>
      </div>
    </div>
  </div> -->
  <div class="row">
    <div class="col-lg-12" *ngIf="!loading">
      <section>
        <div class="card-block">
          <ag-grid-angular
            style="width: 100%; height: ''"
            class="ag-theme-balham custom-grid"
            [pagination]="false"
            [animateRows]="true"
            [quickFilterText]=""
            [rowData]="rowDataPortal"
            [columnDefs]="columnDefsPortal"
            [defaultColDef]="defaultColDef"
            [overlayLoadingTemplate]="overlayLoadingTemplate"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            (gridReady)="onGridReadyPortal($event)"
            [domLayout]="'autoHeight'"
          >
          </ag-grid-angular>
        </div>
      </section>
    </div>
  </div>
</section>
