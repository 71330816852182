import { NgModule } from "@angular/core";
import { HomeRoutingModule } from "./home-routing.module";
import { FlashMessagesModule } from "angular2-flash-messages";

// import { DashboardComponent } from "./dashboard/dashboard.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { SharedModule } from "../shared/shared.module";
// import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
@NgModule({
  declarations: [
    // DashboardComponent,
  ],
  imports: [CommonModule, FormsModule, HomeRoutingModule, AgGridModule, SharedModule],
  exports: [FlashMessagesModule],
})
export class HomeModule {}
