import { Injectable } from "@angular/core";
// import { Http, Headers, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/map";
// import { Logger } from "angular2-logger/core";
// import { environment } from "environments/environment";
import { DMConstantService } from "../constants/dm.constant.service";
import { ConstantService } from "../constants/constant.service";
import { CommonService } from "../services/common.service";
import * as moment from "moment-timezone";
import {
  FormControl,
  FormGroup,
  Validators,
  FormArray,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";

declare var $: any;
declare var jQuery: any;
declare var XLSX: any;

@Injectable()
export class DmFormGroupService {
  public resolveDeferredBecause: any[] = [];
  constructor(
    // private http: Http,
    // private _logger: Logger,
    private constantService: ConstantService,
    private commonService: CommonService,
    public DMConstantService: DMConstantService
  ) {}
  init() {}
  sourceDataForm() {
    return {
      bundleClassification: new FormControl("", Validators.required),
      description: new FormControl(),
      totalWarrantyCost: new FormControl(),
      totalWarrantySale: new FormControl(),
      warrantyMarkup: new FormControl(),
      totalRetailCost: new FormControl(),
      totalRetailSale: new FormControl(),
      retailMarkup: new FormControl(),
      numberOfMonths: new FormControl(),
      dataBundleId: new FormControl(),
      bundleVersion: new FormControl(),
    };
  }
  createStoreSolve360() {
    return {
      testDataTag: new FormControl(""),
      storeType: new FormControl("", Validators.required),
      storeGroup: new FormControl(""),
      storeName: new FormControl("", Validators.required),
      brand: new FormControl("", Validators.required),
      state: new FormControl("", Validators.required),
      assignedTo: new FormControl(""),
      assignedToSalesPerson: new FormControl(""),
      dmsCode: new FormControl("", Validators.required),
      marketingSource: new FormControl("", Validators.required),
    };
  }
  confirmTagOption() {
    return {
      testDataTag: new FormControl(""),
      assignedTo: new FormControl("", Validators.required),
      assignedToSalesPerson: new FormControl(""),
    };
  }
  createProjectSolve360() {
    return {
      testDataTag: new FormControl(""),
      projectName: new FormControl("", Validators.required),
      contractStatus: new FormControl("", Validators.required),
      primaryProjectTypes360: new FormControl("", Validators.required),
      laborProjectType: new FormControl(""),
      controlStatute: new FormControl("", Validators.required),
      dmsCode: new FormControl("", Validators.required),
      brand: new FormControl("", Validators.required),
      projectStartDate: new FormControl("", Validators.required),
      isDualProject: new FormControl("", Validators.required),
      inIsRush: new FormControl(""),
      stage: new FormControl(""),
      assignedTo: new FormControl("", Validators.required),
      assignedToSalesPerson: new FormControl(""),
      contractDate: new FormControl(""),
      bookingDate: new FormControl(""),
      dlrLtrSource: new FormControl("", Validators.required),
      dlrLtrRequested: new FormControl({ value: "", disabled: false }),
      factoryForms: new FormControl("", Validators.required),
      formsRequested: new FormControl({ value: "", disabled: false }),
      thirdPartyForm: new FormControl("", Validators.required),
      thirdPartyFormRequested: new FormControl({ value: "", disabled: false }),
      roPullAssignedTo: new FormControl(""),
      thirdPartyFormReceived: new FormControl({ value: "", disabled: false }),
      dlrLtrInHouse: new FormControl({ value: "", disabled: false }),
      formsInHouse: new FormControl({ value: "", disabled: false }),
      laborMaintenance: new FormControl(""),
      startUpNotes: new FormControl(""),
    };
  }
  warrantyBasisForm() {
    return {
      bundleClassification: new FormControl("", Validators.required),
      description: new FormControl(),
      source_BundleClassification: new FormControl(),
      sourceVersion: new FormControl(),
      isPrimary: new FormControl(),
      isActive: new FormControl(),
      monthlyWarrantyCost: new FormControl("", Validators.required),
      monthlyWarrantySale: new FormControl("", Validators.required),
      initialMarkup: new FormControl("", Validators.required),
      dataBundleId: new FormControl(),
      bundleVersion: new FormControl(),
    };
  }
  upliftTargetForm() {
    return {
      bundleClassification: new FormControl("", Validators.required),
      description: new FormControl(),
      source_BundleClassification: new FormControl("", Validators.required),
      sourceVersion: new FormControl(),
      isActive: new FormControl(),
      targetMarkup: new FormControl("", Validators.required),
      sourceRetailMarkup: new FormControl("", Validators.required),
      additionalMu: new FormControl("", Validators.required),
      uplift: new FormControl("", Validators.required),
      annualizedUplift: new FormControl("", Validators.required),
      brandAverage: new FormControl(""),
      brandNormalizationFactor: new FormControl(""),
      factoryBehaviorFactor: new FormControl("", Validators.required),
      brandCapFactor: new FormControl("", Validators.required),
      factorEmpOverride: new FormControl("", Validators.required),
      cpGpAverage: new FormControl(),
      dataBundleId: new FormControl(),
      bundleVersion: new FormControl(),
      brandCapFactorStr: new FormControl(),
    };
  }
  fsdForm() {
    return {
      dmId: new FormControl(),
      fsdId: new FormControl(),
      inType: new FormControl("", Validators.required),
      fsdSampleId: new FormControl(), // sample data
      description: new FormControl("", Validators.required),
      wpSalesFromLastYear: new FormControl(),
      wpGrossFromLastYear: new FormControl(),
      wpSalesSoFarThisYear: new FormControl(),
      wpGrossSoFarThisYear: new FormControl(),
      noOfMonthsInPYPartsData: new FormControl(),
      noOfMonthsInYTDPartsData: new FormControl(),
      cpGpAverageFromLastYear: new FormControl(),
      cpGpAverageSoFarThisYear: new FormControl(),
      cpPartsSalesFromLastYear: new FormControl(),
      cpPartsProfitFromLastYear: new FormControl(),
      cpPartsSalesSoFarThisYear: new FormControl(),
      cpPartsProfitSoFarThisYear: new FormControl(),
      wpGpAverageFromLastYear: new FormControl(),
      wpGpAverageSoFarThisYear: new FormControl(),
      totalLaborHoursLastYear: new FormControl(),
      totalLaborHoursSoFarThisYear: new FormControl(),
      noOfMonthsInYTDLaborData: new FormControl(),
      tireSales: new FormControl(),
      tireProfit: new FormControl(),
      gogSales: new FormControl(),
      gogProfit: new FormControl(),
      wpSalesTtm: new FormControl(),
      wpGrossTtm: new FormControl(),
      cpPartsSalesTtm: new FormControl(),
      cpPartsProfitTtm: new FormControl(),
      cpGpAverageTtm: new FormControl(),
      wpGpAverageTtm: new FormControl(),
      defaultBasisBundle: new FormControl("", Validators.required),
      defaultTargetBundle: new FormControl("", Validators.required),
      noOfMonthsInTTMPartsData: new FormControl(),
      tireSalesYtd: new FormControl(),
      tireProfitYtd: new FormControl(),
      gogSalesYtd: new FormControl(),
      gogProfitYtd: new FormControl(),
      cpCogsFromLastYear: new FormControl(),
      cpCogsSoFarThisYear: new FormControl(),
      cpCogsTtm: new FormControl(),
      wpCogsFromLastYear: new FormControl(),
      wpCogsSoFarThisYear: new FormControl(),
      wpCogsTtm: new FormControl(),
      projectionStatus: new FormControl(),
    };
  }
  linkProjectForm() {
    return {
      linkProject: new FormControl("", Validators.required),
    };
  }
  dmManageForm() {
    return {
      typeFsd: new FormControl(),
      typeLws: new FormControl(),
      type: new FormControl("", Validators.required),
      dmDescription: new FormControl(),
      custStatedWpMarkup: new FormControl(""),
      approvedMarkupAverage: new FormControl(""),
      brandNormalizationFactor: new FormControl("", Validators.required),
      inCustStatedWlr: new FormControl(""),
      inIsMsrp: new FormControl("", Validators.required),
    };
  }
  magePreAuditForm() {
    return {
      preAuditSampleId: new FormControl(),
      preAuditId: new FormControl(),
      description: new FormControl("", Validators.required),
      wpSales: new FormControl(),
      wpCost: new FormControl("", Validators.required),
      wpMarkup: new FormControl("", Validators.required),
      cpSales: new FormControl("", Validators.required),
      cpCost: new FormControl("", Validators.required),
      cpMarkup: new FormControl("", Validators.required),
      dtExpriesOn: new FormControl("", Validators.required),
      isBest: new FormControl(""),
      noOfRo: new FormControl(""),
      extendedRangeStartingRo: new FormControl(""),
      selectedRangeStartingRo: new FormControl(""),
      selectedRangeEndingRo: new FormControl(""),
      qualifiedType: new FormControl(""),
      selectedRangeQualified: new FormControl(""),
      extendedRangeEndingRo: new FormControl(""),
      roRange: new FormControl(""),
      roDateRange: new FormControl(""),
      inFrAllAuditorData: new FormControl(""),
      inFrQualAuditorData: new FormControl(""),
      extendedNoOfRo: new FormControl(""),
      extRoRange: new FormControl(""),
      extRoDateRange: new FormControl(""),
      rangeBy: new FormControl(),
      impactFactorsNotes: new FormControl(),
      mappedBy: new FormControl(),
      mapOn: new FormControl(""),
      rosRequested: new FormControl(""),
      preAuditComments: new FormControl(),
    };
  }
  magePostAuditForm() {
    return {
      postAuditId: new FormControl(),
      postAuditSampleId: new FormControl(),
      description: new FormControl("", Validators.required),
      wpSales: new FormControl(),
      wpCost: new FormControl("", Validators.required),
      wpMarkup: new FormControl("", Validators.required),
      cpSales: new FormControl("", Validators.required),
      cpCost: new FormControl("", Validators.required),
      preAuditCpMarkup: new FormControl(),
      cpMarkup: new FormControl("", Validators.required),
      preDiscountCpMarkup: new FormControl("", Validators.required),
      discountEffect: new FormControl(),
      nonDiscCpMarkupAdj: new FormControl(),
      discountEffectStr: new FormControl(),
      nonDiscCpMarkupAdjStr: new FormControl(),
      dtExpriesOn: new FormControl("", Validators.required),
      isBest: new FormControl(""),
      noOfRo: new FormControl(""),
      extendedRangeStartingRo: new FormControl(""),
      selectedRangeStartingRo: new FormControl(""),
      selectedRangeEndingRo: new FormControl(""),
      qualifiedType: new FormControl(""),
      selectedRangeQualified: new FormControl(""),
      extendedRangeEndingRo: new FormControl(""),
      roRange: new FormControl(""),
      roDateRange: new FormControl(""),
      inFrAllAuditorData: new FormControl(""),
      inFrQualAuditorData: new FormControl(""),
      finalizedBy: new FormControl(),
      impactFactorsNotesPost: new FormControl(),
      isReviewed: new FormControl(),
      auditedBy: new FormControl("", Validators.required),
      sendtoFinalizeFlag: new FormControl("", Validators.required),
      auditReviewOn: new FormControl(""),
      reviewPerformedBy: new FormControl(""),
      auditReviewCompletedOn: new FormControl(""),
      sct: new FormControl(),
    };
  }
  mageApprovedForm() {
    return {
      approveSampleId: new FormControl(""),
      type: new FormControl("", Validators.required),
      submittedRate: new FormControl("", Validators.required),
      initAppRate: new FormControl(""),
      approvedRate: new FormControl("", Validators.required),
      difference: new FormControl("", Validators.required),
      differenceSign: new FormControl(),
      approvedDate: new FormControl("", Validators.required),
      effectiveDate: new FormControl("", Validators.required),
    };
  }
  mageSubmittedForm() {
    return {
      submittedDate: new FormControl("", Validators.required),
      shippedBy: new FormControl(),
      submittedTracking: new FormControl("", Validators.required),
    };
  }
  mageReceivedForm() {
    return {
      receivedDate: new FormControl("", Validators.required),
      factoryDeadlineDate: new FormControl(""),
      deadlineReminderDate: new FormControl(""),
      assignedTo: new FormControl(""),
      reminderDate: new FormControl(""),
    };
  }
  mageInitAppForm() {
    return {
      initAppDate: new FormControl("", Validators.required),
      initAppRate: new FormControl("", Validators.required),
    };
  }
  lwsForm() {
    return {
      tsv: new FormControl(""),
      lwsSampleId: new FormControl(""),
      inCurrPricingPolicyAsOf: new FormControl(""),
      inPlannedCpDoorLrIncrOn: new FormControl(""),
      inPlannedCpDoorLrIncrOf: new FormControl(""),
      inIsGridPricingAbvDr: new FormControl(""),
      inGridPricingFloor: new FormControl(""),
      inGridPricingCeiling: new FormControl(""),
      inCustStatedCpDoorLr: new FormControl(""),
      inPrevCpDoorLr: new FormControl(""),
      inAcceptFactoryCoupons: new FormControl(""),
      inIsInFactAiOrCpiPgm: new FormControl(""),
      inFactAiPgmExpiresOn: new FormControl(""),
      inCustStatedWarrLr: new FormControl("", Validators.required),
      inFactAiPgmAsOf: new FormControl("", Validators.required),
      warrHoursFromLastYear: new FormControl(""),
      warrHoursSoFarThisYear: new FormControl(""),
      noOfMonthsInYtdLaborData: new FormControl(""),
      noOfMonthsInPyLaborData: new FormControl(""),
      cpLaborRate: new FormControl(""),
      noOfMonthsInP3mLaborData: new FormControl(""),
      noOfMonthsInTtmLaborData: new FormControl(""),
      warrHoursTtm: new FormControl(""),
      inDefaultBasisBundle: new FormControl("", Validators.required),
      inType: new FormControl("", Validators.required),
      cpLaborRateOfP3m: new FormControl(""),
      lwsDmId: new FormControl(""),
    };
  }
  mageLaborPreAuditForm() {
    return {
      preAuditSampleId: new FormControl(),
      preAuditId: new FormControl(),
      description: new FormControl("", Validators.required),
      warrSales: new FormControl(),
      warrHours: new FormControl("", Validators.required),
      warrLaborRate: new FormControl("", Validators.required),
      cpSales: new FormControl("", Validators.required),
      cpHours: new FormControl("", Validators.required),
      cpLaborRate: new FormControl("", Validators.required),
      dtExpriesOn: new FormControl("", Validators.required),
      isBest: new FormControl(""),
      noOfRo: new FormControl(""),
      extendedRangeStartingRo: new FormControl(""),
      selectedRangeStartingRo: new FormControl(""),
      selectedRangeEndingRo: new FormControl(""),
      qualifiedType: new FormControl(""),
      selectedRangeQualified: new FormControl(""),
      extendedRangeEndingRo: new FormControl(""),
      roRange: new FormControl(""),
      roDateRange: new FormControl(""),
      bestCalcProtocol: new FormControl("", Validators.required),
      inFrAllAuditorData: new FormControl(""),
      inFrQualAuditorData: new FormControl(""),
      extendedNoOfRo: new FormControl(""),
      extRoRange: new FormControl(""),
      extRoDateRange: new FormControl(""),
      rangeBy: new FormControl(""),
      impactFactorsNotes: new FormControl(),
      mappedBy: new FormControl(),
      mapOn: new FormControl(""),
      rosRequested: new FormControl(""),
      preAuditComments: new FormControl(),
    };
  }
  mageLaborPostAuditForm() {
    return {
      postAuditSampleId: new FormControl(),
      postAuditId: new FormControl(),
      description: new FormControl("", Validators.required),
      warrSales: new FormControl(),
      warrHours: new FormControl("", Validators.required),
      warrLaborRate: new FormControl("", Validators.required),
      cpSales: new FormControl("", Validators.required),
      cpHours: new FormControl("", Validators.required),
      preAuditCpLaborRate: new FormControl(),
      cpLaborRate: new FormControl("", Validators.required),
      preDiscountCpLaborRate: new FormControl("", Validators.required),
      discountEffect: new FormControl(),
      nonDiscCpMarkupAdj: new FormControl(),
      discountEffectStr: new FormControl(),
      nonDiscCpMarkupAdjStr: new FormControl(),
      dtExpriesOn: new FormControl("", Validators.required),
      noOfRo: new FormControl(""),
      extendedRangeStartingRo: new FormControl(""),
      selectedRangeStartingRo: new FormControl(""),
      selectedRangeEndingRo: new FormControl(""),
      qualifiedType: new FormControl(""),
      selectedRangeQualified: new FormControl(""),
      extendedRangeEndingRo: new FormControl(""),
      roRange: new FormControl(""),
      roDateRange: new FormControl(""),
      inFrAllAuditorData: new FormControl(""),
      inFrQualAuditorData: new FormControl(""),
      finalizedBy: new FormControl(""),
      impactFactorsNotesPost: new FormControl(),
      isReviewed: new FormControl(),
      auditedBy: new FormControl("", Validators.required),
      sendtoFinalizeFlag: new FormControl("", Validators.required),
      auditReviewOn: new FormControl(""),
      reviewPerformedBy: new FormControl(""),
      auditReviewCompletedOn: new FormControl(""),
      sct: new FormControl(""),
      wbt: new FormControl(""),
    };
  }
  mageLaborSubmittedForm() {
    return {
      submittedDate: new FormControl("", Validators.required),
      shippedBy: new FormControl(),
      submittedTracking: new FormControl("", Validators.required),
    };
  }
  mageLaborReceivedForm() {
    return {
      receivedDate: new FormControl("", Validators.required),
      factoryDeadlineDate: new FormControl(""),
      deadlineReminderDate: new FormControl(""),
      assignedTo: new FormControl(""),
      reminderDate: new FormControl(""),
    };
  }
  mageLaborInitAppForm() {
    return {
      initAppDate: new FormControl("", Validators.required),
      initAppRate: new FormControl("", Validators.required),
    };
  }
  mageLaborApprovedForm() {
    return {
      approveSampleId: new FormControl(""),
      type: new FormControl("", Validators.required),
      submittedRate: new FormControl("", Validators.required),
      initAppRate: new FormControl(""),
      approvedRate: new FormControl("", Validators.required),
      difference: new FormControl("", Validators.required),
      differenceSign: new FormControl(),
      approvedDate: new FormControl("", Validators.required),
      effectiveDate: new FormControl("", Validators.required),
    };
  }
  sourceDataLaborForm() {
    return {
      bundleClassification: new FormControl("", Validators.required),
      description: new FormControl(),
      totalWarrantyHours: new FormControl(),
      totalWarrantySale: new FormControl(),
      warrantyLaborRate: new FormControl(),
      cpHours: new FormControl(),
      cpSales: new FormControl(),
      cpLaborRate: new FormControl(),
      numberOfMonths: new FormControl(),
      dataBundleId: new FormControl(),
      bundleVersion: new FormControl(),
    };
  }
  linkLaborProjectForm() {
    return {
      linkProject: new FormControl("", Validators.required),
    };
  }
  mageLaborBestForm() {
    return {
      bestLr: new FormControl("", Validators.required),
      noOfRo: new FormControl("", Validators.required),
      description: new FormControl("", Validators.required),
      bestCalcProtocol: new FormControl("", Validators.required),
    };
  }
  mageBestForm() {
    return {
      bestMu: new FormControl("", Validators.required),
      noOfRo: new FormControl("", Validators.required),
      description: new FormControl("", Validators.required),
    };
  }
  partsRevenueForm() {
    return {
      inSubmissionFeeCap: new FormControl("", Validators.required),
      inUpliftShare: new FormControl("", Validators.required),
      inUpliftShareTerm: new FormControl("", Validators.required),
      inSubmissionOverrideFee: new FormControl(""),
      inHaveSplitCommission: new FormControl("", Validators.required),
      inSplitCommissionWith: new FormControl(""),
      inIsNonContingent: new FormControl("", Validators.required),
    };
  }
  laborRevenueForm() {
    return {
      inSubmissionFeeCap: new FormControl("", Validators.required),
      inUpliftShare: new FormControl("", Validators.required),
      inUpliftShareTerm: new FormControl("", Validators.required),
      inSubmissionOverrideFee: new FormControl(""),
      inHaveSplitCommission: new FormControl("", Validators.required),
      inSplitCommissionWith: new FormControl(""),
      inIsNonContingent: new FormControl("", Validators.required),
    };
  }

  partsRetractForm() {
    return {
      inactiveStatus: new FormControl("", Validators.required),
      retractionNotes: new FormControl("", Validators.required),
      retractionDateParts: new FormControl("", Validators.required),
    };
  }
  laborRetractForm() {
    return {
      inactiveStatus: new FormControl("", Validators.required),
      retractionNotes: new FormControl("", Validators.required),
      retractionDateLabor: new FormControl("", Validators.required),
    };
  }
  partsRetractFormPS() {
    return {
      inactiveStatus: new FormControl("", Validators.required),
      retractionNotes: new FormControl("", Validators.required),
      retractionDateParts: new FormControl("", Validators.required),
      inProjectId: new FormControl("", Validators.required),
      inDmId: new FormControl("", Validators.required),
    };
  }
  laborRetractFormPS() {
    return {
      inactiveStatus: new FormControl("", Validators.required),
      retractionNotes: new FormControl("", Validators.required),
      retractionDateLabor: new FormControl("", Validators.required),
      inProjectId: new FormControl("", Validators.required),
      inDmId: new FormControl("", Validators.required),
    };
  }
  partsOverrideBasisForm() {
    return {
      currWarrMu: new FormControl(""),
      inWarrMu: new FormControl("", Validators.required),
      inWarrCost: new FormControl("", Validators.required),
      inOverrideComment: new FormControl("", Validators.required),
    };
  }

  projectionForm() {
    return {
      projectionColumn0: new FormControl(""),
      projectionColumn1: new FormControl(""),
      projectionColumn2: new FormControl(""),
      projectionColumn3: new FormControl(""),
      projectionColumn4: new FormControl(""),
      projectionColumn5: new FormControl(""),
      projectionColumn6: new FormControl(""),
      projectionColumn7: new FormControl(""),
      projectionColumn8: new FormControl(""),
      projectionColumn9: new FormControl(""),
      projectionColumn10: new FormControl(""),
      projectionColumn11: new FormControl(""),
      projectionColumn12: new FormControl(""),
      projectionColumn13: new FormControl(""),
      projectionColumn14: new FormControl(""),
      projectionColumn15: new FormControl(""),
      projectionColumn16: new FormControl(""),
      projectionColumn17: new FormControl(""),
      projectionColumn18: new FormControl(""),
      projectionColumn19: new FormControl(""),
      projectionColumn20: new FormControl(""),
      projectionColumn21: new FormControl(""),
      projectionColumn22: new FormControl(""),
      projectionColumn23: new FormControl(""),
    };
  }

  partsPssForm() {
    return {
      partsPssMainColumn0: new FormControl(""),
      partsPssMainColumn1: new FormControl(""),
      partsPssMainColumn2: new FormControl(""),
      partsPssMainColumn3: new FormControl(""),
      partsPssMainColumn4: new FormControl(""),
      partsPssColumn0: new FormControl(""),
      partsPssColumn1: new FormControl(""),
      partsPssColumn2: new FormControl(""),
      partsPssColumn3: new FormControl(""),
      partsPssColumn4: new FormControl(""),
      partsPssColumn5: new FormControl(""),
      partsPssColumn6: new FormControl(""),
      partsPssColumn7: new FormControl(""),
      partsPssColumn8: new FormControl(""),
      partsPssColumn9: new FormControl(""),
      partsPssColumn10: new FormControl(""),
      partsPssColumn11: new FormControl(""),
      partsPssColumn12: new FormControl(""),
      partsPssColumn13: new FormControl(""),
      partsPssColumn14: new FormControl(""),
      partsPssColumn15: new FormControl(""),
      partsPssColumn16: new FormControl(""),
      partsPssColumn17: new FormControl(""),
      partsPssColumn18: new FormControl(""),
      partsPssColumn19: new FormControl(""),
      partsPssColumn20: new FormControl(""),
    };
  }

  internalPssForm() {
    return {
      internalPssMainColumn0: new FormControl(""),
      internalPssMainColumn1: new FormControl(""),
      internalPssMainColumn2: new FormControl(""),
      internalPssMainColumn3: new FormControl(""),
      internalPssMainColumn4: new FormControl(""),
      internalPssColumn0: new FormControl(""),
      internalPssColumn1: new FormControl(""),
      internalPssColumn2: new FormControl(""),
      internalPssColumn3: new FormControl(""),
      internalPssColumn4: new FormControl(""),
      internalPssColumn5: new FormControl(""),
      internalPssColumn6: new FormControl(""),
      internalPssColumn7: new FormControl(""),
      internalPssColumn8: new FormControl(""),
      internalPssColumn9: new FormControl(""),
      internalPssColumn10: new FormControl(""),
      internalPssColumn11: new FormControl(""),
      internalPssColumn12: new FormControl(""),
      internalPssColumn13: new FormControl(""),
      internalPssColumn14: new FormControl(""),
      internalPssColumn15: new FormControl(""),
      internalPssColumn16: new FormControl(""),
      internalPssColumn17: new FormControl(""),
      internalPssColumn18: new FormControl(""),
      internalPssColumn19: new FormControl(""),
      internalPssColumn20: new FormControl(""),
      internalPssColumn21: new FormControl(""),
      internalPssColumn22: new FormControl(""),
    };
  }

  laborOverrideBasisForm() {
    return {
      currWarrLr: new FormControl(""),
      inWarrLr: new FormControl("", Validators.required),
      inWarrHours: new FormControl("", Validators.required),
      inOverrideComment: new FormControl("", Validators.required),
    };
  }

  allStoreGroupList(listData: any, callback: any) {
    const storeGroupList: any[] = [];
    $.each(listData["data"]["allSolve360StoreGroups"]["edges"], (key: any, val: any) => {
      const obj = {
        sgId: val.node["sgId"],
        isInPortal: val.node["isInPortal"],
        brandList: val.node["brandList"],
        companyName: val.node["companyName"],
        state: val.node["state"],
        companyId: val.node["companyId"],
        tagStoreGroup: val.node["tagStoreGroup"],
        tagGroupRegion: val.node["tagGroupRegion"],
        tagSoloStore: val.node["tagSoloStore"],
        tagCustomer: val.node["tagCustomer"],
        sp: val.node["sp"],
        salesPerson: val.node["salesPerson"],
        spEmail: val.node["spEmail"],
        spPhone: val.node["spPhone"],
        itemName: val.node["companyName"],
        createdBy: val.node["createdBy"],
        createdAt: val.node["createdAt"],
        updatedBy: val.node["updatedBy"],
        updatedAt: val.node["updatedAt"],
      };
      storeGroupList.push(obj);
    });
    callback(storeGroupList);
  }

  allStoreList(
    listData: any,
    callback: (arg0: { storeList: any[]; storeListSubscription: any[] }) => void
  ) {
    const storeList: any[] = [];
    const storeListSubscription: any[] = [];
    $.each(listData["data"]["getStoresForStoreGroup"]["edges"], (key: any, val: any) => {
      const obj = {
        stId: val.node["stId"],
        sgId: val.node["sgId"],
        storeName: val.node["storeName"],
        manufacturer: val.node["manufacturer"],
        state: val.node["state"],
        companyId: val.node["companyId"],
        isInPortal: val.node["isInPortal"],
        isInSolve: val.node["isInSolve"],
        mgIdList: val.node["mgIdList"],
        sp: val.node["sp"],
        salesPerson: val.node["salesPerson"],
        contact: val.node["contact"],
        itemName: val.node["storeName"],
        dms: val.node["dms"],
        brandCode: val.node["brandCode"],
        stateName: val.node["stateName"],
        createdBy: val.node["createdBy"],
        createdAt: val.node["createdAt"],
        updatedBy: val.node["updatedBy"],
        updatedAt: val.node["updatedAt"],
        dealerCode: val.node["dealerCode"],
        marketingSource: val.node["marketingSource"],
        tagCustomer: val.node["tagCustomer"],
        isFopcStore: val.node["isFopcStore"],
      };
      if (!(obj.isInPortal === false && obj.isInSolve === true)) {
        storeList.push(obj);
      }
      storeListSubscription.push(obj);
    });
    const reponseData = {
      storeList: storeList,
      storeListSubscription: storeListSubscription,
    };
    callback(reponseData);
  }

  getFsdListsForDm(listData: any, callback: any) {
    const fsdLists: any[] = [];
    $.each(listData["data"]["getFsdListForDm"]["edges"], (key: any, val: any) => {
      const obj = {
        type: val.node["type"],
        fsdId: val.node["fsdId"],
        stId: val.node["stId"],
        componentData: val.node["componentData"],
        isEditable: val.node["isEditable"],
        version: val.node["version"],
        description: val.node["description"],
        defaultBasisBundle: val.node["defaultBasisBundle"],
        defaultTargetBundle: val.node["defaultTargetBundle"],
      };
      fsdLists.push(obj);
    });
    callback(fsdLists);
  }

  getBasisBundleLists(listData: any, callback: any) {
    const warrantyBasis: any[] = [];
    $.each(listData["data"]["getPartsBasisListForDm"]["edges"], (key: any, val: any) => {
      const obj = {
        dataBundleId: val.node["dataBundleId"],
        stId: val.node["stId"],
        bundleClassification: val.node["bundleClassification"],
        description: val.node["description"],
        bundleVersion: val.node["bundleVersion"],
        isPrimary: val.node["isPrimary"],
        isActive: val.node["isActive"],
        monthlyWarrantyCost: val.node["monthlyWarrantyCost"],
        monthlyWarrantySale: val.node["monthlyWarrantySale"],
        initialMarkup: val.node["initialMarkup"],
        sourceClassification: val.node["sourceClassification"],
        sourceVersion: val.node["sourceVersion"],
        createdAt: val.node["createdAt"],
        id: val.node["dataBundleId"],
        itemName:
          val.node["bundleClassification"] +
          "_" +
          val.node["bundleVersion"] +
          "_" +
          val.node["sourceClassification"] +
          "_" +
          val.node["sourceVersion"],
        hasMarkupOverride: val.node["hasMarkupOverride"],
        hasCostOverride: val.node["hasCostOverride"],
        overrideComment: val.node["overrideComment"],
      };
      warrantyBasis.push(obj);
    });
    callback(warrantyBasis);
  }

  getTargetBundleLists(listData: any, callback: any) {
    const upliftTarget: any[] = [];
    $.each(
      listData["data"]["getPartsTargetListForDm"]["edges"],
      (key: any, val: { node: { [x: string]: string } }) => {
        const obj = {
          dataBundleId: val.node["dataBundleId"],
          stId: val.node["stId"],
          bundleClassification: val.node["bundleClassification"],
          description: val.node["description"],
          bundleVersion: val.node["bundleVersion"],
          isActive: val.node["isActive"],
          sourceRetailMarkup: val.node["sourceRetailMarkup"],
          targetMarkup: val.node["targetMarkup"],
          sourceClassification: val.node["sourceClassification"],
          sourceVersion: val.node["sourceVersion"],
          brandNormalizationFactor: val.node["brandNormalizationFactor"]
            ? parseFloat(val.node["brandNormalizationFactor"])
            : null,
          factoryBehaviorFactor: val.node["factoryBehaviorFactor"],
          factorEmpOverride: val.node["factorEmpOverride"],
          brandCapFactor: val.node["brandCapFactor"],
          cpGpAverage: val.node["cpGpAverage"],
          createdAt: val.node["createdAt"],
          ineffectiveDate: val.node["ineffectiveDate"],
          actionDate: val.node["actionDate"],
          isBest: val.node["isBest"],
          id: val.node["dataBundleId"],
          additionalInfo: val.node["additionalInfo"],
          itemName:
            val.node["bundleClassification"] +
            "_" +
            val.node["bundleVersion"] +
            "_" +
            val.node["sourceClassification"] +
            "_" +
            val.node["sourceVersion"],
        };
        upliftTarget.push(obj);
      }
    );
    callback(upliftTarget);
  }

  getSourceOfTargetInit(result: any, callback: any) {
    const sourceOfTarget: any[] = [];
    $.each(result, (key: any, val: { [x: string]: any }) => {
      const obj = {
        retail_markup: val["retail_markup"],
        bundle_version: val["bundle_version"],
        bundle_classification: val["bundle_classification"],
        id: val["bundle_version"],
        itemName: val["bundle_classification"],
      };
      sourceOfTarget.push(obj);
    });
    callback(sourceOfTarget);
  }

  getPssDataLists(
    listData: any,
    markupType: any,
    callback: (arg0: {
      pssDataList: any[];
      sumUpAddlAnnualProfit: any;
      sumPreDiscAddlAnnualProfit: any;
      sumPostDiscAddlAnnualProfit: any;
      sumFaAnnualImpact: any;
      sumApprAdjAddlAnnualProfit: any;
    }) => void
  ) {
    const pssDataList: any[] = [];
    let sumUpAddlAnnualProfit: any = null;
    let sumPreDiscAddlAnnualProfit: any = null;
    let sumPostDiscAddlAnnualProfit: any = null;
    let sumFaAnnualImpact: any = null;
    let sumApprAdjAddlAnnualProfit: any = null;
    $.each(listData["data"]["getPssForStoresAndDm"]["edges"], (key: any, val: any) => {
      let oemApprovalDeadline = null;
      if (val.node["oemApprovalDeadline"]) {
        oemApprovalDeadline = val.node["oemApprovalDeadline"].replace(
          this.constantService.REG_EXP_FOR_DATE_FORMAT,
          "$2-$3-$1"
        );
      }
      const obj = {
        stId: val.node["stId"],
        stName: val.node["stName"],
        upAvgMonthlyWarrCogs: val.node["upAvgMonthlyWarrCogs"]
          ? Number(val.node["upAvgMonthlyWarrCogs"])
          : null,
        upCurrEffWpMu: this.commonService.printMarkUp(val.node["upCurrEffWpMu"], markupType),
        upProAdjMarkup: this.commonService.printMarkUp(val.node["upProAdjMarkup"], markupType),
        upAddlPtsOvrCurrEffWpMu: this.commonService.get2DecimalValue(
          val.node["upAddlPtsOvrCurrEffWpMu"]
        ),
        upAddlAnnualProfit: val.node["upAddlAnnualProfit"]
          ? Number(val.node["upAddlAnnualProfit"])
          : null,
        preDiscBestPostAuditMu: this.commonService.printMarkUp(
          val.node["preDiscBestPostAuditMu"],
          markupType
        ),
        preDiscIncrOvrCurrEffWpMu: this.commonService.get2DecimalValue(
          val.node["preDiscIncrOvrCurrEffWpMu"]
        ),
        preDiscAddlAnnualProfit: val.node["preDiscAddlAnnualProfit"]
          ? Number(val.node["preDiscAddlAnnualProfit"])
          : null,
        postDiscBestPostAuditMu: this.commonService.printMarkUp(
          val.node["postDiscBestPostAuditMu"],
          markupType
        ),
        postDiscIncrOvrCurrEffWpMu: this.commonService.get2DecimalValue(
          val.node["postDiscIncrOvrCurrEffWpMu"]
        ),
        postDiscAddlAnnualProfit: val.node["postDiscAddlAnnualProfit"]
          ? Number(val.node["postDiscAddlAnnualProfit"])
          : null,
        oemApprovalDeadline: oemApprovalDeadline ? Number(oemApprovalDeadline) : null,
        approvedWpMarkup: this.commonService.printMarkUp(val.node["approvedWpMarkup"], markupType),
        faPointImpact: this.commonService.get2DecimalValue(val.node["faPointImpact"]),
        faAnnualImpact: val.node["faAnnualImpact"] ? Number(val.node["faAnnualImpact"]) : null,
        apprAddlPtsAbvCurrEffWpMu: this.commonService.get2DecimalValue(
          val.node["apprAddlPtsAbvCurrEffWpMu"]
        ),
        apprAdjAddlMonthlyProfit: val.node["apprAdjAddlMonthlyProfit"]
          ? Number(val.node["apprAdjAddlMonthlyProfit"])
          : null,
        apprAdjAddlAnnualProfit: val.node["apprAdjAddlAnnualProfit"]
          ? Number(val.node["apprAdjAddlAnnualProfit"])
          : null,
        brand: val.node["manufacturer"],
        state: val.node["state"],
      };
      pssDataList.push(obj);
      sumUpAddlAnnualProfit += obj.upAddlAnnualProfit;
      sumPreDiscAddlAnnualProfit += obj.preDiscAddlAnnualProfit;
      sumPostDiscAddlAnnualProfit += obj.postDiscAddlAnnualProfit;
      sumFaAnnualImpact += obj.faAnnualImpact;
      sumApprAdjAddlAnnualProfit += obj.apprAdjAddlAnnualProfit;
      const responseData = {
        pssDataList: pssDataList,
        sumUpAddlAnnualProfit: sumUpAddlAnnualProfit,
        sumPreDiscAddlAnnualProfit: sumPreDiscAddlAnnualProfit,
        sumPostDiscAddlAnnualProfit: sumPostDiscAddlAnnualProfit,
        sumFaAnnualImpact: sumFaAnnualImpact,
        sumApprAdjAddlAnnualProfit: sumApprAdjAddlAnnualProfit,
      };
      if (callback) {
        callback(responseData);
      }
    });
  }

  getProjectionDataLists(
    listData: any,
    callback: (arg0: {
      projectionDataList: any[];
      unadjustedApparentUplift: null;
      armatusOptimizedUplift: null;
      factoryAdjustedUplift: null;
      netUpliftYear1: null;
      totalProfitAcqCost: null;
      addlAnnualWpProfit: null;
    }) => void
  ) {
    const projectionDataList: any[] = [];
    let unadjustedApparentUplift: null = null;
    let armatusOptimizedUplift: null = null;
    let factoryAdjustedUplift: null = null;
    let netUpliftYear1: null = null;
    let totalProfitAcqCost: null = null;
    let addlAnnualWpProfit: null = null;
    $.each(listData["data"]["getProjectionForStoresAndDm"]["edges"], (key: any, val: any) => {
      const obj = {
        stId: val.node["stId"],
        addlAnnualWpProfit: val.node["addlAnnualWpProfit"],
        addlOgAwpProfitAdjForFactory: val.node["addlOgAwpProfitAdjForFactory"],
        addlPtsAbvCurrEffWm: val.node["addlPtsAbvCurrEffWm"],
        alBrandedApprAvg: val.node["alBrandedApprAvg"],
        annualWarrPartsCost: val.node["annualWarrPartsCost"],
        cpGpMarkup: val.node["cpGpMarkup"],
        cpPartsGrossProfit: val.node["cpPartsGrossProfit"],
        cpWarrMarkupDelta: val.node["cpWarrMarkupDelta"],
        currLikelyFactBehaviorImpact: val.node["currLikelyFactBehaviorImpact"],
        effWpGrossProfit: val.node["effWpGrossProfit"],
        effWpGrossProfitMarkup: val.node["effWpGrossProfitMarkup"],
        monthlyUpliftFee: val.node["monthlyUpliftFee"],
        netWpAddlPuYr1: val.node["netWpAddlPuYr1"],
        nomToReturnFullProfitAcqCost: val.node["nomToReturnFullProfitAcqCost"]
          ? val.node["nomToReturnFullProfitAcqCost"].toFixed(2)
          : "0.00",
        ogWpMarkupAdjForFactory: val.node["ogWpMarkupAdjForFactory"],
        optAddlOgAwpProfit: val.node["optAddlOgAwpProfit"],
        postApprSubmissionFee: val.node["postApprSubmissionFee"],
        projectedWpMarkup: val.node["projectedWpMarkup"],
        stName: val.node["stName"],
        stStatuteAif: val.node["stStatuteAif"],
        totalProfitAcqCost: val.node["totalProfitAcqCost"],
        currentEffWarrMUPostFactBehavior: val.node["addlPtsAbvCeWmPostFactBeh"],
        brand: val.node["manufacturer"],
        state: val.node["state"],
      };
      projectionDataList.push(obj);
      unadjustedApparentUplift += obj.addlAnnualWpProfit;
      armatusOptimizedUplift += obj.optAddlOgAwpProfit;
      factoryAdjustedUplift += obj.addlOgAwpProfitAdjForFactory;
      netUpliftYear1 += obj.netWpAddlPuYr1;
      totalProfitAcqCost += obj.totalProfitAcqCost;
      addlAnnualWpProfit += obj.addlAnnualWpProfit;
    });

    const responseData = {
      projectionDataList: projectionDataList,
      unadjustedApparentUplift: unadjustedApparentUplift,
      armatusOptimizedUplift: armatusOptimizedUplift,
      factoryAdjustedUplift: factoryAdjustedUplift,
      netUpliftYear1: netUpliftYear1,
      totalProfitAcqCost: totalProfitAcqCost,
      addlAnnualWpProfit: addlAnnualWpProfit,
    };

    callback(responseData);
  }

  getAllDataMasters(listData: any, callback: any) {
    const dataMasterLists: any[] = [];
    $.each(
      listData["data"]["allDataMasters"]["edges"],
      (key: any, val: { node: { [x: string]: { [x: string]: any } } }) => {
        const obj = {
          dmId: val.node["dmId"],
          stId: val.node["stId"],
          isActive: val.node["isActive"],
          description: val.node["description"],
          version: val.node["version"],
          id: val.node["dmId"],
          itemName: val.node["version"],
          createdBy: val.node["createdBy"],
          createdAt: val.node["createdAt"],
          updatedBy: val.node["updatedBy"],
          updatedAt: val.node["updatedAt"],
          auditSource: val.node["auditSource"],
          approvedMarkupAverage: null,
          brandNormalizationFactor: null,
          custStatedWpMarkup: null,
          custStatedWarrLr: null,
          custStatedWlr: null,
          isMsrp: null,
          lwsData: val.node["laborWorksheetDataByDmIdAndStId"]["edges"],
        };
        $.each(
          val.node["dmPartsByDmIdAndStId"]["edges"],
          (k: any, v: { node: { [x: string]: null } }) => {
            obj.approvedMarkupAverage = v.node["approvedMarkupAverage"];
            obj.brandNormalizationFactor = v.node["brandNormalizationFactor"]
              ? parseFloat(v.node["brandNormalizationFactor"])
              : (null as any);
            obj.custStatedWpMarkup = v.node["custStatedWpMarkup"];
            obj.isMsrp = v.node["isMsrp"];
          }
        );
        $.each(val.node["laborWorksheetDataByDmIdAndStId"]["edges"], (k: any, v: any) => {
          obj.custStatedWarrLr =
            v.node && v.node["custStatedWarrLr"] ? v.node["custStatedWarrLr"] : null;
        });
        $.each(val.node["dmLaborsByDmIdAndStId"]["edges"], (k: any, v: any) => {
          obj.custStatedWlr = v.node && v.node["custStatedWlr"] ? v.node["custStatedWlr"] : null;
        });
        dataMasterLists.push(obj);
      }
    );
    callback(dataMasterLists);
  }

  getPartProjectsStore(listData: any, callback: any) {
    const projectList: any[] = [];
    $.each(listData["data"]["getPartProjectsForStore"]["edges"], (key: any, val: any) => {
      const obj = {
        projectId: val.node["projectId"],
        name: val.node["projectName"],
      };
      projectList.push(obj);
    });
    callback(projectList);
  }

  getSourceBundles(listData: any, callback: any) {
    const souceBundleList: any[] = [];
    $.each(listData["data"]["getSourceBundlesForDm"]["edges"], (key: any, val: any) => {
      const obj = {
        dataBundleId: val.node["dataBundleId"],
        bundleClassification: val.node["bundleClassification"],
        description: val.node["description"],
        bundleVersion: val.node["bundleVersion"],
        totalWarrantyCost: val.node["totalWarrantyCost"],
        totalWarrantySale: val.node["totalWarrantySale"],
        warrantyMarkup: val.node["warrantyMarkup"],
        totalRetailCost: val.node["totalRetailCost"],
        totalRetailSale: val.node["totalRetailSale"],
        retailMarkup: val.node["retailMarkup"],
        cpGpAverage: val.node["cpGpAverage"],
        numberOfMonths: val.node["numberOfMonths"],
        fsdId: val.node["fsdId"],
        bundleDoc: val.node["bundleDoc"],
        bundleLink: val.node["bundleLink"],
        mageId: val.node["mageId"],
        isLatestBundle: val.node["isLatestBundle"],
        isActiveBasis: val.node["isActiveBasis"],
      };
      souceBundleList.push(obj);
    });
    callback(souceBundleList);
  }
  getSnapshots(listData: any, callback: any) {
    const snapshotList: any[] = [];
    $.each(listData["data"]["getSnapshotsForDm"]["edges"], (key: any, val: any) => {
      const obj = {
        basisCost: val.node["basisCost"],
        basisMu: val.node["basisMu"],
        createdAt: val.node["createdAt"],
        description: val.node["description"],
        isPrimaryBasis: val.node["isPrimaryBasis"],
        isPrimaryTarget: val.node["isPrimaryTarget"],
        targetMu: val.node["targetMu"],
        version: val.node["version"],
        stage: val.node["stage"],
      };
      snapshotList.push(obj);
    });
    callback(snapshotList);
  }

  getAuditListsForDm(listData: any, callback: any) {
    const preAuditLists: any[] = [];
    const postAuditLists: any[] = [];
    $.each(listData["data"]["allMages"]["edges"], (key: any, val: any) => {
      const obj = {
        mageId: val.node["mageId"],
        version: val.node["version"],
        componentData: val.node["componentData"],
        description: val.node["description"],
        targetClassification: val.node["targetClassification"],
        createdAt: val.node["createdAt"],
        frAllAuditorData: null,
        frQualAuditorData: null,
      };
      $.each(val["node"]["mageAuditorFileReferencesByMageId"]["edges"], (k: any, v: any) => {
        obj.frAllAuditorData = v.node["frAllAuditorData"];
        obj.frQualAuditorData = v.node["frQualAuditorData"];
      });
      if (val.node["targetClassification"] === "PreAudit") {
        preAuditLists.push(obj);
      } else if (val.node["targetClassification"] === "PostAudit") {
        postAuditLists.push(obj);
      }
    });
    const responseData = {
      preAuditLists: preAuditLists,
      postAuditLists: postAuditLists,
    };
    callback(responseData);
  }

  getActivePreAuditDataProcess(listData: any, callback: any) {
    const activePreAuditList: any[] = [];
    $.each(listData["data"]["allDataBundleTargetParts"]["edges"], (key: any, val: any) => {
      const obj = {
        componentData: val["node"][
          "dataBundleSourcePartByDmIdAndSourceClassificationAndSourceVersion"
        ]["mageByMageId"]
          ? val["node"]["dataBundleSourcePartByDmIdAndSourceClassificationAndSourceVersion"][
              "mageByMageId"
            ]["componentData"]
          : null,
        description: val["node"][
          "dataBundleSourcePartByDmIdAndSourceClassificationAndSourceVersion"
        ]["mageByMageId"]
          ? val["node"]["dataBundleSourcePartByDmIdAndSourceClassificationAndSourceVersion"][
              "mageByMageId"
            ]["description"]
          : null,
      };
      activePreAuditList.push(obj);
    });
    callback(activePreAuditList);
  }

  sampleDataLists(listData: any, callback: any) {
    const sampleFsdList: any[] = [];
    const samplePreAuiditList: any[] = [];
    const samplePostAuiditList: any[] = [];
    const sampleApprovedList: any[] = [];
    const sampleLaborPreAuiditList: any[] = [];
    const sampleLaborPostAuiditList: any[] = [];
    const sampleLaborApprovedList: any[] = [];
    const sampleLaborLwsList: any[] = [];

    $.each(listData["data"]["allSampleDataSets"]["edges"], (key: any, val: any) => {
      if (val["node"]["type"] === "fsd_parts" && val["node"]["payload"]) {
        sampleFsdList.push(JSON.parse(val["node"]["payload"]));
      } else if (val["node"]["type"] === "preaudit_parts" && val["node"]["payload"]) {
        samplePreAuiditList.push(JSON.parse(val["node"]["payload"]));
      } else if (val["node"]["type"] === "postaudit_parts" && val["node"]["payload"]) {
        samplePostAuiditList.push(JSON.parse(val["node"]["payload"]));
      } else if (val["node"]["type"] === "approve_bundle" && val["node"]["payload"]) {
        sampleApprovedList.push(JSON.parse(val["node"]["payload"]));
      } else if (val["node"]["type"] === "preaudit_labor" && val["node"]["payload"]) {
        sampleLaborPreAuiditList.push(JSON.parse(val["node"]["payload"]));
      } else if (val["node"]["type"] === "postaudit_labor" && val["node"]["payload"]) {
        sampleLaborPostAuiditList.push(JSON.parse(val["node"]["payload"]));
      } else if (val["node"]["type"] === "approve_labor" && val["node"]["payload"]) {
        sampleLaborApprovedList.push(JSON.parse(val["node"]["payload"]));
      } else if (val["node"]["type"] === "lws" && val["node"]["payload"]) {
        sampleLaborLwsList.push(JSON.parse(val["node"]["payload"]));
      }
    });
    const responseData = {
      sampleFsdList: sampleFsdList,
      samplePreAuiditList: samplePreAuiditList,
      samplePostAuiditList: samplePostAuiditList,
      sampleApprovedList: sampleApprovedList,
      sampleLaborPreAuiditList: sampleLaborPreAuiditList,
      sampleLaborPostAuiditList: sampleLaborPostAuiditList,
      sampleLaborApprovedList: sampleLaborApprovedList,
      sampleLaborLwsList: sampleLaborLwsList,
    };
    callback(responseData);
  }

  getInternalPssDataLists(
    listData: any,
    markupType: any,
    callback: (arg0: {
      internalPssDataList: any[];
      intPssSumUpAddlAnnualProfit: null;
      intPssSumOptPreRoAuditUplift: null;
      intPssSumOptPreDiscountUplift: null;
      intPssSumOptPostDiscountUplift: null;
      intPssSumFactoryAprovedUplift: null;
    }) => void
  ) {
    const internalPssDataList: any[] = [];
    let intPssSumUpAddlAnnualProfit: any = null;
    let intPssSumOptPreRoAuditUplift: any = null;
    let intPssSumOptPreDiscountUplift: any = null;
    let intPssSumOptPostDiscountUplift: any = null;
    let intPssSumFactoryAprovedUplift: any = null;
    $.each(listData["data"]["getInternalPssForStoresAndDm"]["edges"], (key: any, val: any) => {
      let oemApprovalDeadline = null;
      if (val.node["postDiscDueDate"]) {
        oemApprovalDeadline = val.node["postDiscDueDate"].replace(
          this.constantService.REG_EXP_FOR_DATE_FORMAT,
          "$2-$3-$1"
        );
      }
      const obj = {
        stId: val.node["stId"],
        stName: val.node["stName"],
        upAvgMonthlyWarrCogs: val.node["upAvgMonthlyWarrCogs"],
        upCurrWpMu: this.commonService.printMarkUp(val.node["upCurrWpMu"], markupType),
        upNormProjectionMu: this.commonService.printMarkUp(
          val.node["upNormProjectionMu"],
          markupType
        ),
        upIncrOvrCurr: this.commonService.printMarkUp(val.node["upIncrOvrCurr"], markupType),
        upAddlAnnualProfit: val.node["upAddlAnnualProfit"],
        preAudMu: this.commonService.printMarkUp(val.node["preAudMu"], markupType),
        preAudIncrOvrCurr: this.commonService.printMarkUp(
          val.node["preAudIncrOvrCurr"],
          markupType
        ),
        preAudAnnualUplift: val.node["preAudAnnualUplift"],
        preDiscInitMu: this.commonService.printMarkUp(val.node["preDiscInitMu"], markupType),
        preDiscIncrOvrCurr: this.commonService.printMarkUp(
          val.node["preDiscIncrOvrCurr"],
          markupType
        ),
        preDiscAddlAnnualProfit: val.node["preDiscAddlAnnualProfit"],
        postDiscMu: this.commonService.printMarkUp(val.node["postDiscMu"], markupType),
        postDiscIncrOvrCurr: this.commonService.printMarkUp(
          val.node["postDiscIncrOvrCurr"],
          markupType
        ),
        postDiscAddlAnnualProfit: val.node["postDiscAddlAnnualProfit"],
        postDiscDueDate: oemApprovalDeadline,
        approvedWpMu: this.commonService.printMarkUp(val.node["approvedWpMu"], markupType),
        approvedImpact: this.commonService.printMarkUp(val.node["approvedImpact"], markupType),
        approvedAnnualImpact: val.node["approvedAnnualImpact"],
        approvedAdjAddlMonthlyProfit: val.node["approvedAdjAddlMonthlyProfit"],
        approvedAdjAddlAnnualProfit: val.node["approvedAdjAddlAnnualProfit"],
        brand: val.node["manufacturer"],
        state: val.node["state"],
      };
      internalPssDataList.push(obj);
      intPssSumUpAddlAnnualProfit += obj.upAddlAnnualProfit;
      intPssSumOptPreRoAuditUplift += obj.preAudAnnualUplift;
      intPssSumOptPreDiscountUplift += obj.preDiscAddlAnnualProfit;
      intPssSumOptPostDiscountUplift += obj.postDiscAddlAnnualProfit;
      intPssSumFactoryAprovedUplift += obj.approvedAdjAddlAnnualProfit;
    });
    const responseData = {
      internalPssDataList: internalPssDataList,
      intPssSumUpAddlAnnualProfit: intPssSumUpAddlAnnualProfit,
      intPssSumOptPreRoAuditUplift: intPssSumOptPreRoAuditUplift,
      intPssSumOptPreDiscountUplift: intPssSumOptPreDiscountUplift,
      intPssSumOptPostDiscountUplift: intPssSumOptPostDiscountUplift,
      intPssSumFactoryAprovedUplift: intPssSumFactoryAprovedUplift,
    };
    callback(responseData);
  }

  getAllDmLabors(listData: any, callback: any) {
    const allDmLabors: any[] = [];
    $.each(listData["data"]["allLaborWorksheetData"]["edges"], (key: any, val: any) => {
      const obj = {
        stId: val.node["stId"],
        custStatedCpDoorLr: val.node["custStatedCpDoorLr"],
        prevCpDoorLr: val.node["prevCpDoorLr"],
        custStatedWarrLr: val.node["custStatedWarrLr"],
        currPricingPolicyAsOf: val.node["currPricingPolicyAsOf"],
        plannedCpDoorLrIncrOn: val.node["plannedCpDoorLrIncrOn"],
        plannedCpDoorLrIncrOf: val.node["plannedCpDoorLrIncrOf"],
        gridPricingCeiling: val.node["gridPricingCeiling"],
        gridPricingFloor: val.node["gridPricingFloor"],
        acceptFactoryCoupons: val.node["acceptFactoryCoupons"],
        factAiPgmAsOf: val.node["factAiPgmAsOf"],
        factAiPgmExpiresOn: val.node["factAiPgmExpiresOn"],
        isGridPricingAbvDr: val.node["isGridPricingAbvDr"],
        isInFactAiOrCpiPgm: val.node["isInFactAiOrCpiPgm"],
        lwsComponentData: val.node["lwsComponentData"],
        defaultBundle: val.node["defaultBundle"],
        inDefaultBasisBundle: val.node["defaultBasisBundle"],
        inType: val.node["modeType"],
      };
      allDmLabors.push(obj);
    });

    callback(allDmLabors);
  }

  getLaborAuditListsForDm(listData: any, callback: any) {
    const laborPreAuditLists: any[] = [];
    const laborPostAuditLists: any[] = [];
    $.each(listData["data"]["allLaborMages"]["edges"], (key: any, val: any) => {
      let obj = {
        lbrMageId: val.node["lbrMageId"],
        version: val.node["version"],
        componentData: val.node["componentData"],
        description: val.node["description"],
        targetClassification: val.node["targetClassification"],
        createdAt: val.node["createdAt"],
        frAllAuditorData: null,
        frQualAuditorData: null,
      };
      $.each(
        val["node"]["laborMageAuditorFileReferencesByLbrMageId"]["edges"],
        (k: any, v: any) => {
          obj.frAllAuditorData = v.node["frAllAuditorData"];
          obj.frQualAuditorData = v.node["frQualAuditorData"];
        }
      );
      if (val.node["targetClassification"] === "PreAudit") {
        laborPreAuditLists.push(obj);
      } else if (val.node["targetClassification"] === "PostAudit") {
        laborPostAuditLists.push(obj);
      }
    });
    const responseData = {
      laborPreAuditLists: laborPreAuditLists,
      laborPostAuditLists: laborPostAuditLists,
    };
    callback(responseData);
  }

  getActiveLaborPreAuditDataProcess(listData: any, callback: any) {
    const activeLaborPreAuditList: any[] = [];
    $.each(listData["data"]["allDataBundleTargetLabors"]["edges"], (key: any, val: any) => {
      const obj = {
        componentData: val["node"][
          "dataBundleSourceLaborByDmIdAndSourceClassificationAndSourceVersion"
        ]["laborMageByLbrMageId"]
          ? val["node"]["dataBundleSourceLaborByDmIdAndSourceClassificationAndSourceVersion"][
              "laborMageByLbrMageId"
            ]["componentData"]
          : null,
        description: val["node"][
          "dataBundleSourceLaborByDmIdAndSourceClassificationAndSourceVersion"
        ]["laborMageByLbrMageId"]
          ? val["node"]["dataBundleSourceLaborByDmIdAndSourceClassificationAndSourceVersion"][
              "laborMageByLbrMageId"
            ]["description"]
          : null,
      };
      activeLaborPreAuditList.push(obj);
    });
    callback(activeLaborPreAuditList);
  }

  getLaborSourceBundles(listData: any, callback: any) {
    const souceBundleLaborList: any[] = [];
    $.each(listData["data"]["getLaborSourceBundlesForDm"]["edges"], (key: any, val: any) => {
      const obj = {
        dataBundleId: val.node["dataBundleId"],
        bundleClassification: val.node["bundleClassification"],
        description: val.node["description"],
        bundleVersion: val.node["bundleVersion"],
        totalWarrantyHours: val.node["totalWarrantyHours"],
        totalWarrantySale: val.node["totalWarrantySale"],
        warrantyLaborRate: val.node["warrantyLaborRate"],
        cpHours: val.node["cpHours"],
        cpSales: val.node["cpSales"],
        cpLaborRate: val.node["cpLaborRate"],
        numberOfMonths: val.node["numberOfMonths"],
        fsdId: val.node["fsdId"],
        bundleDoc: val.node["bundleDoc"],
        bundleLink: val.node["bundleLink"],
        mageId: val.node["mageId"],
        isLatestBundle: val.node["isLatestBundle"],
        isActiveBasis: val.node["isActiveBasis"],
      };
      souceBundleLaborList.push(obj);
    });
    callback(souceBundleLaborList);
  }

  getLaborSnapshots(listData: any, callback: any) {
    const snapshotLaborList: any[] = [];
    $.each(listData["data"]["getLaborSnapshotsForDm"]["edges"], (key: any, val: any) => {
      const obj = {
        basisHours: val.node["basisHours"],
        basisLr: val.node["basisLr"],
        createdAt: val.node["createdAt"],
        description: val.node["description"],
        isPrimaryBasis: val.node["isPrimaryBasis"],
        isPrimaryTarget: val.node["isPrimaryTarget"],
        targetLr: val.node["targetLr"],
        version: val.node["version"],
        stage: val.node["stage"],
      };
      snapshotLaborList.push(obj);
    });
    callback(snapshotLaborList);
  }

  getLaborTargetBundleLists(listData: any, callback: any) {
    const upliftTargetLabor: any[] = [];
    $.each(listData["data"]["getLaborTargetListForDm"]["edges"], (key: any, val: any) => {
      const obj = {
        dataBundleId: val.node["dataBundleId"],
        bundleClassification: val.node["bundleClassification"],
        description: val.node["description"],
        bundleVersion: val.node["bundleVersion"],
        isActive: val.node["isActive"],
        sourceRetailLaborRate: val.node["sourceRetailLaborRate"],
        targetLaborRate: val.node["targetLaborRate"],
        sourceClassification: val.node["sourceClassification"],
        sourceVersion: val.node["sourceVersion"],
        discountEffect: val.node["discountEffect"],
        nonDiscCpLrAdj: val.node["nonDiscCpLrAdj"],
        expiresOn: val.node["expiresOn"],
        actionDate: val.node["actionDate"],
        ineffectiveDate: val.node["ineffectiveDate"],
        createdAt: val.node["createdAt"],
        isBest: val.node["isBest"],
        id: val.node["dataBundleId"],
        additionalInfo: val.node["additionalInfo"],
        itemName:
          val.node["bundleClassification"] +
          "_" +
          val.node["bundleVersion"] +
          "_" +
          val.node["sourceClassification"] +
          "_" +
          val.node["sourceVersion"],
        hasHoursOverride: val.node["hasHoursOverride"],
        hasLrOverride: val.node["hasLrOverride"],
      };
      upliftTargetLabor.push(obj);
    });
    callback(upliftTargetLabor);
  }

  getLaborProjectsStore(listData: any, callback: any) {
    const laborProjectList: any[] = [];
    $.each(listData["data"]["getLaborProjectsForStore"]["edges"], (key: any, val: any) => {
      const obj = {
        projectId: val.node["projectId"],
        name: val.node["projectName"],
      };
      laborProjectList.push(obj);
    });
    callback(laborProjectList);
  }

  getAllDmBests(listData: any, callback: any) {
    const dmBestData: any[] = [];
    $.each(listData["data"]["allDmBests"]["edges"], (key: any, val: any) => {
      const obj = {
        dmId: val.node["dmId"],
        partsBestMu: val.node["partsBestMu"],
        partsNoOfRo: val.node["partsNoOfRo"],
        partsDescription: val.node["partsDescription"],
        laborBestLr: val.node["laborBestLr"],
        laborBestCalcProtocol: val.node["laborBestCalcProtocol"],
        laborNoOfRo: val.node["laborNoOfRo"],
        laborDescription: val.node["laborDescription"],
      };
      dmBestData.push(obj);
    });
    callback(dmBestData);
  }

  getLaborPssDataLists(listData: any, callback: any) {
    const pssLaborDataList: any[] = [];
    let preAuditAddlAnnualProfitV2: any = null;
    let preAuditNetUpliftV2: any = null;
    let preAuditPaybackV2: any = null;
    let additionalAnnualProfitV2: any = null;
    let expectedAdditionalAnnualProfitV2: any = null;
    let approvedWpMaNetProfitUpliftYearV2: any = null;
    let ROIMonthsV2: any = null;
    let annualProfitImpactV2: any = null;
    let factoryAdjustedAnnualProfitV2: any = null;
    let apprNetUpliftV2: any = null;
    let apprPaybackV2: any = null;

    let PreDiscountOptimizedLaborRate: any = null;
    let OptimizedPostDiscount: any = null;
    let CosttoSubmitYearOnly: any = null;
    let UpliftShareYearOnly: any = null;
    let TotalProfitAcquisitionCost: any = null;
    let NetProfitUpliftYear: any = null;
    let ROIMonths: any = null;
    let FactoryAdjustment: any = null;
    let sumFactoryApprovedUpliftResults: any = null;
    let preAuditTotalAcqCost: any = null;
    let apprTotalAcqCost: any = null;
    let calculatedPayBackPriod: any = null;
    let totalFinalAcqTotal: any = null;

    $.each(listData["data"]["getLaborPssForStoresAndDm"]["edges"], (key: any, val: any) => {
      let oemApprovalDeadline = null;
      if (val.node["oemApprovalDeadline"]) {
        oemApprovalDeadline = val.node["oemApprovalDeadline"].replace(
          this.constantService.REG_EXP_FOR_DATE_FORMAT,
          "$2-$3-$1"
        );
      }
      const obj = {
        stId: val.node["stId"],
        stName: val.node["stName"],
        state: val.node["state"],
        brand: val.node["manufacturer"],
        averageWarrantyLaborPDIHoursperMonth: val.node["avgWarrLbrPdiHrsPerMonth"],
        warrantyLaborRate: val.node["warrLbrRate"]
          ? this.commonService.get2DecimalValue(val.node["warrLbrRate"])
          : "",
        isUpliftViable: val.node["isUpliftViable"],
        preAuditLbrRate: val.node["preAuditLbrRate"],
        preAuditAddlRate: val.node["preAuditAddlRate"],
        preAuditAddlMonthlyProfit: val.node["preAuditAddlMonthlyProfit"],
        preAuditAddlAnnualProfit: val.node["preAuditAddlAnnualProfit"],
        preAuditCostToSubmit: val.node["preAuditCostToSubmit"],
        preAuditUpliftShare: val.node["preAuditUpliftShare"],
        preAuditAnnualUpliftFee: val.node["preAuditAnnualUpliftFee"],
        preAuditTotalAcqCost: val.node["preAuditTotalAcqCost"],
        preAuditNetUplift: val.node["preAuditNetUplift"],
        preAuditPayback: val.node["preAuditPayback"]
          ? this.commonService.get2DecimalValue(val.node["preAuditPayback"])
          : "",
        bestRateAcheivedAfterAnalysisofROs: val.node["preDiscBestRate"],
        increaseOverCurrentLaborRate: val.node["preDiscIncrOvrCurrLr"],
        preDiscAddlMonthlyProfit: val.node["preDiscAddlMonthlyProfit"],
        additionalAnnualProfit: val.node["preDiscAddlAnnualProfit"],
        bestRateAcheivedIncludingStoreDiscountsFinalAudit: val.node["postDiscBestRate"]
          ? this.commonService.get2DecimalValue(val.node["postDiscBestRate"])
          : "",
        increaseOverCurrentLaborRatePreDiscount: val.node["postDiscIncrOvrCurrLr"]
          ? this.commonService.get2DecimalValue(val.node["postDiscIncrOvrCurrLr"])
          : "",
        postDiscAddlMonthlyProfit: val.node["postDiscAddlMonthlyProfit"],
        expectedAdditionalAnnualProfit: val.node["postDiscAddlAnnualProfit"],
        OEMApprovalDeadline: oemApprovalDeadline,
        costtoSubmit: val.node["postDiscCostToSubmit"],
        upliftShare: val.node["postDiscUpliftShare"],
        postDiscAnnualUpliftFee: val.node["postDiscAnnualUpliftFee"],
        totalProfitAcquisitionCost: val.node["postDiscTotalProfitAcqCost"],
        approvedWpMaNetProfitUpliftYear: val.node["postDiscNetProfitUplift"],
        ROIMonths: val.node["postDiscRoiMonths"]
          ? this.commonService.get2DecimalValue(val.node["postDiscRoiMonths"])
          : "",
        approvedLaborRate: val.node["apprLaborRate"]
          ? this.commonService.get2DecimalValue(val.node["apprLaborRate"])
          : "",
        rateImpact: val.node["apprFaRateImpact"]
          ? this.commonService.get2DecimalValue(val.node["apprFaRateImpact"])
          : "",
        annualProfitImpact: val.node["apprFaAnnualProfitImpact"],
        increaseOverCurrentRate: val.node["apprIncrOvrCurrLr"],
        factoryAdjustedMonthlyProfit: val.node["apprFaAddlMonthlyProfit"],
        factoryAdjustedAnnualProfit: val.node["apprFaAddlAnnualProfit"],
        apprCostToSubmit: val.node["apprCostToSubmit"],
        apprUpliftShare: val.node["apprUpliftShare"],
        apprAnnualUpliftFee: val.node["apprAnnualUpliftFee"],
        apprTotalAcqCost: val.node["apprTotalAcqCost"],
        apprNetUplift: val.node["apprNetUplift"],
        apprPayback: val.node["apprPayback"]
          ? this.commonService.get2DecimalValue(val.node["apprPayback"])
          : "",
        clientCommunicationComment: val.node["clientCommunicationComment"],

        finalCostToSubmit: val.node["finalCostToSubmit"],
        finalUpliftShare: val.node["isLaborMaintenance"] ? null : val.node["finalUpliftShare"],
        finalAnnualUpliftFee: val.node["isLaborMaintenance"]
          ? val.node["laborMaintenanceFee"]
          : val.node["finalAnnualUpliftFee"],
        finalTotalAcqCost: val.node["finalTotalAcqCost"],
        finalNetUplift: val.node["finalNetUplift"],
        finalPayback: val.node["finalPayback"]
          ? this.commonService.get2DecimalValue(val.node["finalPayback"])
          : "",
        isRetracted: val.node["isRetracted"],
        retractionType: val.node["retractionType"],
        isLaborMaintenance: val.node["isLaborMaintenance"],
        isSecondaryResolved: val.node["isSecondaryResolved"],
      };
      pssLaborDataList.push(obj);

      if (obj.finalTotalAcqCost != null && obj.finalPayback != null) {
        calculatedPayBackPriod += val.node["finalTotalAcqCost"] / val.node["finalPayback"];
      }

      if (obj.preAuditAddlAnnualProfit > 0) {
        preAuditAddlAnnualProfitV2 += Number(obj.preAuditAddlAnnualProfit);
      }

      if (obj.preAuditNetUplift > 0) {
        preAuditNetUpliftV2 += Number(obj.preAuditNetUplift);
      }

      // if (obj.preAuditPayback > "0") {
      //   preAuditPaybackV2 += Number(obj.preAuditPayback);
      // }
      if (obj.preAuditPayback !== null && obj.preAuditPayback > "0") {
        preAuditPaybackV2 += Number(obj.preAuditPayback);
      }

      if (obj.additionalAnnualProfit > 0) {
        additionalAnnualProfitV2 += Number(obj.additionalAnnualProfit);
      }

      if (obj.expectedAdditionalAnnualProfit > 0) {
        expectedAdditionalAnnualProfitV2 += Number(obj.expectedAdditionalAnnualProfit);
      }

      if (obj.approvedWpMaNetProfitUpliftYear > 0) {
        approvedWpMaNetProfitUpliftYearV2 += Number(obj.approvedWpMaNetProfitUpliftYear);
      }

      // if (obj.ROIMonths > "0") {
      //   ROIMonthsV2 += Number(obj.ROIMonths);
      // }
      if (obj.ROIMonths !== null && obj.ROIMonths > "0") {
        ROIMonthsV2 += Number(obj.ROIMonths);
      }

      if (obj.annualProfitImpact) {
        annualProfitImpactV2 += Number(obj.annualProfitImpact);
      }
      if (obj.factoryAdjustedAnnualProfit > 0) {
        factoryAdjustedAnnualProfitV2 += Number(obj.factoryAdjustedAnnualProfit);
      }

      if (obj.finalNetUplift > 0) {
        apprNetUpliftV2 += Number(obj.finalNetUplift);
      }

      if (obj.apprPayback !== null && obj.apprPayback > "0") {
        apprPaybackV2 += Number(obj.apprPayback);
      }

      if (obj.additionalAnnualProfit > 0) {
        PreDiscountOptimizedLaborRate += Number(obj.additionalAnnualProfit);
      }

      if (obj.expectedAdditionalAnnualProfit > 0) {
        OptimizedPostDiscount += Number(obj.expectedAdditionalAnnualProfit);
      }

      if (obj.costtoSubmit > 0) {
        CosttoSubmitYearOnly += Number(obj.costtoSubmit);
      }

      if (obj.upliftShare > 0) {
        UpliftShareYearOnly += Number(obj.upliftShare);
      }

      if (obj.totalProfitAcquisitionCost > 0) {
        TotalProfitAcquisitionCost += Number(obj.totalProfitAcquisitionCost);
      }

      if (obj.approvedWpMaNetProfitUpliftYear > 0) {
        NetProfitUpliftYear += Number(obj.approvedWpMaNetProfitUpliftYear);
      }

      if (obj.ROIMonths !== null && obj.ROIMonths > "0") {
        ROIMonths += Number(obj.ROIMonths);
      }

      if (obj.annualProfitImpact > 0) {
        FactoryAdjustment += Number(obj.annualProfitImpact);
      }

      if (obj.factoryAdjustedAnnualProfit > 0) {
        sumFactoryApprovedUpliftResults += Number(obj.factoryAdjustedAnnualProfit);
      }

      if (obj.preAuditTotalAcqCost > 0) {
        preAuditTotalAcqCost += Number(obj.preAuditTotalAcqCost);
      }

      if (obj.apprTotalAcqCost > 0) {
        apprTotalAcqCost += Number(obj.apprTotalAcqCost);
      }

      if (obj.finalTotalAcqCost > 0) {
        totalFinalAcqTotal += Number(obj.finalTotalAcqCost);
      }
    });

    const responseData = {
      pssLaborDataList: pssLaborDataList,
      preAuditAddlAnnualProfitV2: preAuditAddlAnnualProfitV2,
      preAuditNetUpliftV2: preAuditNetUpliftV2,
      preAuditPaybackV2: preAuditPaybackV2,
      additionalAnnualProfitV2: additionalAnnualProfitV2,
      expectedAdditionalAnnualProfitV2: expectedAdditionalAnnualProfitV2,
      approvedWpMaNetProfitUpliftYearV2: approvedWpMaNetProfitUpliftYearV2,
      ROIMonthsV2: ROIMonthsV2,
      annualProfitImpactV2: annualProfitImpactV2,
      factoryAdjustedAnnualProfitV2: factoryAdjustedAnnualProfitV2,
      apprNetUpliftV2: apprNetUpliftV2,
      apprPaybackV2: apprPaybackV2,
      PreDiscountOptimizedLaborRate: PreDiscountOptimizedLaborRate,
      OptimizedPostDiscount: OptimizedPostDiscount,
      CosttoSubmitYearOnly: CosttoSubmitYearOnly,
      UpliftShareYearOnly: UpliftShareYearOnly,
      TotalProfitAcquisitionCost: TotalProfitAcquisitionCost,
      NetProfitUpliftYear: NetProfitUpliftYear,
      ROIMonths: ROIMonths,
      FactoryAdjustment: FactoryAdjustment,
      sumFactoryApprovedUpliftResults: sumFactoryApprovedUpliftResults,
      preAuditTotalAcqCost: preAuditTotalAcqCost,
      apprTotalAcqCost: apprTotalAcqCost,
      calculatedPayBackPriod: calculatedPayBackPriod,
      totalFinalAcqTotal: totalFinalAcqTotal,
    };
    callback(responseData);
  }

  getBrandList(listData: any, callback: any) {
    const brandList: any[] = [];
    $.each(listData["data"]["allManufacturers"]["edges"], (key: any, val: any) => {
      const obj = {
        itemName: val.node["manufacturer"]
          ? val.node["manufacturer"] + " (" + val.node["abbreviation"] + ")"
          : val.node["abbreviation"],
        id: val.node["manufacturerId"],
        approvedMarkupAverage: val.node["approvedMarkupAverage"],
        discountedNFactor: val.node["discountedNFactor"],
        aliases: val.node["aliases"],
        name: val.node["abbreviation"],
        item: val.node["manufacturer"],
      };
      brandList.push(obj);
    });

    callback(brandList);
  }

  getStateList(listData: any, callback: any) {
    const stateList: any[] = [];
    $.each(listData["data"]["allStates"]["edges"], (key: any, val: any) => {
      const obj = {
        itemName: val.node["name"]
          ? val.node["name"] + " (" + val.node["state"] + ")"
          : val.node["state"],
        id: val.node["stateId"],
        name: val.node["name"],
        manufacturerReviewDays: val.node["manufacturerReviewDays"],
        item: val.node["state"],
      };
      stateList.push(obj);
    });
    callback(stateList);
  }

  getProjectList(listData: { [x: string]: { [x: string]: string } }, callback: any) {
    const projectListPL: any[] = [];
    $.each(JSON.parse(listData["data"]["getProjectListForStoreGroup"]), (key: any, val: any) => {
      const projectObj = { projectName: val.project_name };
      projectListPL.push(projectObj);
    });
    callback(projectListPL);
  }

  getLaborBasisBundleLists(listData: any, callback: any) {
    const laborWarrantyBasis: any[] = [];
    $.each(listData["data"]["getLaborBasisListForDm"]["edges"], (key: any, val: any) => {
      const obj = {
        dataBundleId: val.node["dataBundleId"],
        stId: val.node["stId"],
        bundleClassification: val.node["bundleClassification"],
        description: val.node["description"],
        bundleVersion: val.node["bundleVersion"],
        isPrimary: val.node["isPrimary"],
        isActive: val.node["isActive"],
        monthlyWarrantyHours: val.node["monthlyWarrantyHours"],
        monthlyWarrantySale: val.node["monthlyWarrantySale"],
        initialLaborRate: val.node["initialLaborRate"],
        sourceClassification: val.node["sourceClassification"],
        sourceVersion: val.node["sourceVersion"],
        createdAt: val.node["createdAt"],
        id: val.node["dataBundleId"],
        itemName:
          val.node["bundleClassification"] +
          "_" +
          val.node["bundleVersion"] +
          "_" +
          val.node["sourceClassification"] +
          "_" +
          val.node["sourceVersion"],
        hasHoursOverride: val.node["hasHoursOverride"],
        hasLrOverride: val.node["hasLrOverride"],
        overrideComment: val.node["overrideComment"],
      };
      laborWarrantyBasis.push(obj);
    });
    callback(laborWarrantyBasis);
  }

  getPartsRevenue(listData: any, callback: any) {
    const allPartsRevenues: any[] = [];
    $.each(listData["data"]["allPartsRevenues"]["edges"], (key: any, val: any) => {
      const obj = {
        submissionFee:
          val.node["submissionOverrideFee"] !== null
            ? val.node["submissionOverrideFee"]
            : Math.min(val.node["submissionFeeCap"], val.node["submissionFee"]),
        submissionFeeCap: val.node["submissionFeeCap"],
        submissionOverrideFee: val.node["submissionOverrideFee"],
        upliftShare: val.node["upliftShare"],
        upliftShareTerm: val.node["upliftShareTerm"],
        partsBasis: val.node["partsBasis"],
        partsTarget: val.node["partsTarget"],
        haveSplitCommission: val.node["haveSplitCommission"],
        splitCommissionWith: val.node["splitCommissionWith"],
        isNonContingent: val.node["isNonContingent"],
        inIsOverride: false,
      };
      allPartsRevenues.push(obj);
    });
    callback(allPartsRevenues);
  }

  getLaborRevenue(listData: any, callback: any) {
    const allLaborRevenues: any[] = [];
    $.each(listData["data"]["allLaborRevenues"]["edges"], (key: any, val: any) => {
      const obj = {
        submissionFee:
          val.node["submissionOverrideFee"] !== null
            ? val.node["submissionOverrideFee"]
            : Math.min(val.node["submissionFeeCap"], val.node["submissionFee"]),
        submissionFeeCap: val.node["submissionFeeCap"],
        submissionOverrideFee: val.node["submissionOverrideFee"],
        upliftShare: val.node["upliftShare"],
        upliftShareTerm: val.node["upliftShareTerm"],
        laborBasis: val.node["laborBasis"],
        laborTarget: val.node["laborTarget"],
        maintenanceFee: val.node["maintenanceFee"],
        haveSplitCommission: val.node["haveSplitCommission"],
        splitCommissionWith: val.node["splitCommissionWith"],
        isNonContingent: val.node["isNonContingent"],
        inIsOverride: false,
      };
      allLaborRevenues.push(obj);
    });
    callback(allLaborRevenues);
  }

  getDmsList(listData: any, callback: any) {
    const dmsList: any[] = [];
    $.each(listData["data"]["allS360Dms"]["edges"], (key: any, val: any) => {
      const obj = {
        itemName: val.node["name"],
        aliases: val.node["aliases"],
        abbreviation: val.node["abbreviation"],
        position: val.node["position"],
      };
      dmsList.push(obj);
    });
    callback(dmsList);
  }

  getSourceOfBasis(result: any, callback: any) {
    const sourceOfBasis: any[] = [];
    $.each(result, (key: any, val: any) => {
      const obj = {
        warr_cost: val["warr_cost"],
        warr_sale: val["warr_sale"],
        warranty_markup: val["warranty_markup"],
        bundle_version: val["bundle_version"],
        bundle_classification: val["bundle_classification"],
        id: val["bundle_version"],
        itemName: val["bundle_classification"],
      };
      sourceOfBasis.push(obj);
    });
    callback(sourceOfBasis);
  }

  getS360UserList(listData: any, callback: any) {
    const userListS360: any[] = [];
    $.each(listData["data"]["allS360Users"]["edges"], (key: any, val: any) => {
      const obj = {
        userId: val.node["userId"],
        itemName: val.node["name"],
        email: val.node["email"],
      };
      userListS360.push(obj);
    });
    callback(userListS360);
  }

  getSalesPersonDetails(listData: any, callback: any) {
    const userListSalesPersonS360: any[] = [];
    $.each(listData["data"]["allSalesPeople"]["edges"], (key: any, val: any) => {
      const obj = {
        userId: val.node["solveUserId"],
        itemName: val.node["spName"],
        email: val.node["spEmail"],
        phone: val.node["spPhone"],
        haveSplitCommission: val.node["haveSplitCommission"],
      };
      userListSalesPersonS360.push(obj);
    });
    callback(userListSalesPersonS360);
  }

  getBaseSubmissionFee(listData: any, callback: any) {
    let result = null;
    if (listData["data"] && listData["data"]["getBaseSubmissionFee"]) {
      result = JSON.parse(listData["data"]["getBaseSubmissionFee"]);
      result = result["base_submission_fee"];
    }
    callback(result);
  }

  dropdownSettingsEventReminder() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "itemName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      enableCheckAll: true,
      //disabled: false,
      // maxHeight: 200,
    };
    return dropdownSettings;
  }
  dropdownSettingsShowLimitEmail() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "itemName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      enableCheckAll: true,
      itemsShowLimit: 1,
    };
    return dropdownSettings;
  }
  dropdownSettingsUser() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "itemName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      enableCheckAll: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettingsDisablePS() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      //disabled: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettingsBrand() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettingsAssignedTo() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      maxHeight: 200,
      closeDropDownOnSelection: true,
      // position: "top",
    };
    return dropdownSettings;
  }
  singleDropdownSettingsThirdPartyFormDisabled() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      maxHeight: 200,
      // position: "bottom",
      //disabled: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettingsDisableGroup() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      //disabled: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettingsShippedBy() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      maxHeight: 200,
      // position: "bottom",
    };
    return dropdownSettings;
  }
  singleDropdownSettingsThirdPartyForm() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      maxHeight: 200,
      closeDropDownOnSelection: true,
      // position: "bottom",
    };
    return dropdownSettings;
  }

  editStartUpNotesForm() {
    return {
      inStartUpNotes: new FormControl("", Validators.required),
      inProjectId: new FormControl("", Validators.required),
      inProjectType: new FormControl("", Validators.required),
      isEnableEdit: new FormControl(""),
    };
  }
  commentActionForm() {
    return {
      commentText: new FormControl("", Validators.required),
    };
  }
  salesCommentPartsForm() {
    return {
      commentText: new FormControl(""),
      inProjectId: new FormControl("", Validators.required),
      isEnableEdit: new FormControl(""),
    };
  }

  salesCommentLaborForm() {
    return {
      commentText: new FormControl(""),
      inProjectId: new FormControl("", Validators.required),
      isEnableEdit: new FormControl(""),
    };
  }

  projectNotesActionForm() {
    return {
      commentText: new FormControl(""),
      projectNoteDate: new FormControl("", Validators.required),
    };
  }

  receivedDateActionForm() {
    return {
      dateReceived: new FormControl("", Validators.required),
    };
  }
  getPreAuditLaborReportLists(
    listData: any,
    callback: (arg0: { preAuditLaborReportDataList: any[]; preAuditTotalResult: any }) => void
  ) {
    const preAuditLaborReportDataList: any = [];
    let preAuditTotalResult: any = {
      AdditionalMonthlyProfit: null,
      AdditionalAnnualProfit: null,
      CostToSubmitYear1Only: null,
      UpliftShareYear1Only: null,
      TotalProfitAcquisitionCost: null,
      NetProfitUpliftYear: null,
      ROIMonths: null,
      OngoingProfitUpliftYear2: null,
      FiveYearProfitImpact: null,
      TenYearProfitImpact: null,
    };
    $.each(listData["data"]["getPreAuditLaborForStoresAndDm"]["edges"], (key: any, val: any) => {
      const obj = {
        stId: val.node["stId"],
        stName: val.node["stName"],
        state: val.node["state"],
        brand: val.node["manufacturer"],
        warrLbrPdiHrsPerMonth: val.node["warrLbrPdiHrsPerMonth"]
          ? Math.round(val.node["warrLbrPdiHrsPerMonth"])
          : "",
        warrLbrRate: val.node["warrLbrRate"],
        isUpliftViable: val.node["isUpliftViable"],
        bestCurrWarrRate: val.node["bestCurrWarrRate"],
        laborCurrStage: val.node["laborCurrStage"],
        addlHourlyRate: val.node["addlHourlyRate"],
        addlMonthlyProfit: val.node["addlMonthlyProfit"],
        addlAnnualProfit: val.node["addlAnnualProfit"],
        costToSubmit: val.node["costToSubmit"],
        upliftShare: val.node["upliftShare"],
        totalProfitAcq: val.node["totalProfitAcq"],
        netProfitUpliftFirstYr: val.node["netProfitUpliftFirstYr"],
        roiMonths: val.node["roiMonths"],
        ongoingProfitUpliftYr2Plus: val.node["ongoingProfitUpliftYr2Plus"],
        profitImpactFiveYear: val.node["profitImpactFiveYear"],
        profitImpactTenYear: val.node["profitImpactTenYear"],
        clientCommunicationComment: val.node["clientCommunicationComment"],
      };
      preAuditLaborReportDataList.push(obj);
      preAuditTotalResult.AdditionalMonthlyProfit += obj.addlMonthlyProfit;
      preAuditTotalResult.AdditionalAnnualProfit += obj.addlAnnualProfit;
      preAuditTotalResult.CostToSubmitYear1Only += obj.costToSubmit;
      preAuditTotalResult.UpliftShareYear1Only += obj.upliftShare;
      preAuditTotalResult.TotalProfitAcquisitionCost += obj.totalProfitAcq;
      preAuditTotalResult.NetProfitUpliftYear += obj.netProfitUpliftFirstYr;
      preAuditTotalResult.ROIMonths += obj.roiMonths;
      preAuditTotalResult.OngoingProfitUpliftYear2 += obj.ongoingProfitUpliftYr2Plus;
      preAuditTotalResult.FiveYearProfitImpact += obj.profitImpactFiveYear;
      preAuditTotalResult.TenYearProfitImpact += obj.profitImpactTenYear;
    });

    const responseData = {
      preAuditLaborReportDataList: preAuditLaborReportDataList,
      preAuditTotalResult: preAuditTotalResult,
    };

    callback(responseData);
  }

  isValidScenariokey(listData: any, callback: any) {
    callback(listData["data"]["isValidScenariokey"]);
  }

  isValidSubmissionUuid(listData: any, callback: any) {
    callback(listData["data"]["isValidSubmissionUuid"]);
  }

  allFilereferences(listData: any, callback: any) {
    const responseData: any[] = [];
    $.each(listData["data"]["allFilereferences"]["edges"], (key: any, val: any) => {
      const obj = {
        frDocumentcontent: val.node["frDocumentcontent"]
          .replace('"Invoice Number"', '"RO Number"')
          .replace('"Invoice Date"', '"RO Date"'),
        frDocumentdate: val.node["frDocumentdate"],
        frTitle: val.node["frTitle"],
      };
      responseData.push(obj);
    });
    callback(responseData);
  }

  getBuildoutTime(listData: any, callback: any) {
    let result = null;
    if (listData["data"] && listData["data"]["getBuildoutTime"]) {
      result = listData["data"]["getBuildoutTime"];
    }
    callback(result);
  }

  allAuditFiles(listData: any, callback: any) {
    const responseData: any[] = [];
    $.each(listData["data"]["allAuditFiles"]["edges"], (key: any, val: any) => {
      let frDocumentcontent = val.node["documentContent"].replace(
        '"Invoice Number"',
        '"RO Number"'
      );
      frDocumentcontent = frDocumentcontent.replace('"Invoice Date"', '"RO Date"');
      const obj = {
        documentType: val.node["documentType"],
        fileContentType: val.node["fileContentType"],
        frDocumentcontent: frDocumentcontent,
        frDocumentdate: val.node["uploadAt"] ? val.node["uploadAt"] : null,
        frTitle: val.node["fileTitle"],
      };
      responseData.push(obj);
    });
    callback(responseData);
  }

  getSubmissionBuildOverviewParts(listData: any, callback: any) {
    let result = null;
    if (listData["data"] && listData["data"]["getSubmissionBuildOverviewParts"]) {
      result = JSON.parse(listData["data"]["getSubmissionBuildOverviewParts"]);
    }
    callback(result);
  }

  getSubmissionBuildOverviewLabor(listData: any, callback: any) {
    let result = null;
    if (listData["data"] && listData["data"]["getSubmissionBuildOverviewLabor"]) {
      result = JSON.parse(listData["data"]["getSubmissionBuildOverviewLabor"]);
    }
    callback(result);
  }

  getScenarioOverview(listData: any, callback: any) {
    let result = null;
    if (listData["data"] && listData["data"]["getScenarioOverview"]) {
      result = JSON.parse(listData["data"]["getScenarioOverview"]);
      const obj = {
        count_of_overview: result["count_of_overview"],
        count_of_detail: result["count_of_detail"],
      };
      result = obj;
    }
    callback(result);
  }

  getProjectionDataListsV2(listData: any, callback: any) {
    const projectionDataListV2: any[] = [];
    let unadjustedApparentUplift: any = null;
    let armatusOptimizedUplift: any = null;
    let factoryAdjustedUplift: any = null;
    let netUpliftYear1: any = null;
    let totalProfitAcqCost: any = null;
    let addlAnnualWpProfit: any = null;
    $.each(listData["data"]["getProjectionForStoresAndDmV2"]["edges"], (key: any, val: any) => {
      console.log("getProjectionDataListsV2");

      const obj = {
        stId: val.node["stId"],
        stName: val.node["stName"],
        state: val.node["state"],
        brand: val.node["manufacturer"],
        annualWarrPartsCost: val.node["annualWarrPartsCost"]
          ? Number(val.node["annualWarrPartsCost"])
          : null,
        cpPartsGrossProfit: val.node["cpPartsGrossProfit"]
          ? Number(val.node["cpPartsGrossProfit"])
          : null,
        effWpGrossProfit: val.node["effWpGrossProfit"]
          ? Number(val.node["effWpGrossProfit"])
          : null,
        cpGpMarkup: val.node["cpGpMarkup"] ? Number(val.node["cpGpMarkup"]).toFixed(4) : "0.0000", // --- Updated following fixed(4) and fixed(2) format as per projection pop up in group overview.
        effWpGrossProfitMarkup: val.node["effWpGrossProfitMarkup"]
          ? Number(val.node["effWpGrossProfitMarkup"]).toFixed(4)
          : "",
        cpWarrMarkupDelta: val.node["cpWarrMarkupDelta"]
          ? Number(val.node["cpWarrMarkupDelta"]).toFixed(2)
          : "0.00",
        addlAnnualWpProfit: val.node["addlAnnualWpProfit"]
          ? Number(val.node["addlAnnualWpProfit"])
          : null,
        stStatuteAif: val.node["stStatuteAif"]
          ? Number(val.node["stStatuteAif"]).toFixed(2)
          : "0.00",
        projectedWpMarkup: val.node["projectedWpMarkup"]
          ? Number(val.node["projectedWpMarkup"]).toFixed(4)
          : "0.0000",
        addlPtsAbvCurrEffWm: val.node["addlPtsAbvCurrEffWm"]
          ? Number(val.node["addlPtsAbvCurrEffWm"]).toFixed(2)
          : "",
        stEntitledMonthlyProfit: val.node["stEntitledMonthlyProfit"]
          ? Number(val.node["stEntitledMonthlyProfit"])
          : null,
        stEntitledAnnlProfit: val.node["stEntitledAnnlProfit"]
          ? Number(val.node["stEntitledAnnlProfit"])
          : null,
        factBehaviorFactor: val.node["factBehaviorFactor"]
          ? Number(val.node["factBehaviorFactor"]).toFixed(2)
          : "0.00",
        muIncrNetOfFactBeh: val.node["muIncrNetOfFactBeh"]
          ? Number(val.node["muIncrNetOfFactBeh"]).toFixed(2)
          : "0.00",
        netProjectedMu: val.node["netProjectedMu"]
          ? Number(val.node["netProjectedMu"]).toFixed(4)
          : "0.0000",
        projectedMonthlyPu: val.node["projectedMonthlyPu"] ? val.node["projectedMonthlyPu"] : null,
        projectedAnnlProfitUplift: val.node["projectedAnnlProfitUplift"]
          ? Number(val.node["projectedAnnlProfitUplift"])
          : null,
        postApprSubmissionFee: val.node["postApprSubmissionFee"]
          ? Number(val.node["postApprSubmissionFee"])
          : null,
        monthlyUpliftFee: val.node["monthlyUpliftFee"]
          ? Number(val.node["monthlyUpliftFee"])
          : null,
        totalProfitAcqCost: val.node["totalProfitAcqCost"]
          ? Number(val.node["totalProfitAcqCost"])
          : null,
        netWpAddlPuYr1: val.node["netWpAddlPuYr1"] ? Number(val.node["netWpAddlPuYr1"]) : null,
        paybackPeriod: val.node["paybackPeriod"]
          ? Number(val.node["paybackPeriod"]).toFixed(2)
          : "0.00",
        alBrandedApprAvg: val.node["alBrandedApprAvg"]
          ? Number(val.node["alBrandedApprAvg"])
          : null,
        clientCommunicationComment: val.node["clientCommunicationComment"],
        msrp: val.node["pricingPolicy"] ? val.node["pricingPolicy"] : "",
      };
      projectionDataListV2.push(obj);
      if (obj.addlAnnualWpProfit && obj.addlAnnualWpProfit > 0) {
        unadjustedApparentUplift += obj.addlAnnualWpProfit;
      }

      if (obj.addlAnnualWpProfit && obj.addlAnnualWpProfit > 0) {
        unadjustedApparentUplift += obj.addlAnnualWpProfit;
      }

      if (obj.stEntitledAnnlProfit && obj.stEntitledAnnlProfit > 0) {
        armatusOptimizedUplift += obj.stEntitledAnnlProfit;
      }

      if (obj.projectedAnnlProfitUplift && obj.projectedAnnlProfitUplift > 0) {
        factoryAdjustedUplift += obj.projectedAnnlProfitUplift;
      }

      if (obj.netWpAddlPuYr1 && obj.netWpAddlPuYr1 > 0) {
        netUpliftYear1 += obj.netWpAddlPuYr1;
      }

      if (obj.totalProfitAcqCost && obj.totalProfitAcqCost > 0) {
        totalProfitAcqCost += obj.totalProfitAcqCost;
      }

      if (obj.addlAnnualWpProfit && obj.addlAnnualWpProfit > 0) {
        addlAnnualWpProfit += obj.addlAnnualWpProfit;
      }
    });
    const responseData = {
      projectionDataList: projectionDataListV2,
      unadjustedApparentUplift: unadjustedApparentUplift,
      armatusOptimizedUplift: armatusOptimizedUplift,
      factoryAdjustedUplift: factoryAdjustedUplift,
      netUpliftYear1: netUpliftYear1,
      totalProfitAcqCost: totalProfitAcqCost,
      addlAnnualWpProfit: addlAnnualWpProfit,
    };
    callback(responseData);
  }

  projectionFormV2() {
    return {
      projectionColumn0: new FormControl(""),
      projectionColumn1: new FormControl(""),
      projectionColumn2: new FormControl(""),
      projectionColumn3: new FormControl(""),
      projectionColumn4: new FormControl(""),
      projectionColumn5: new FormControl(""),
      projectionColumn6: new FormControl(""),
      projectionColumn7: new FormControl(""),
      projectionColumn8: new FormControl(""),
      projectionColumn9: new FormControl(""),
      projectionColumn10: new FormControl(""),
      projectionColumn11: new FormControl(""),
      projectionColumn12: new FormControl(""),
      projectionColumn13: new FormControl(""),
      projectionColumn14: new FormControl(""),
      projectionColumn15: new FormControl(""),
      projectionColumn16: new FormControl(""),
      projectionColumn17: new FormControl(""),
      projectionColumn18: new FormControl(""),
      projectionColumn19: new FormControl(""),
      projectionColumn20: new FormControl(""),
      projectionColumn21: new FormControl(""),
      projectionColumn22: new FormControl(""),
    };
  }

  partsPssFormV2() {
    return {
      partsPssMainColumn0: new FormControl(""),
      partsPssMainColumn1: new FormControl(""),
      partsPssMainColumn2: new FormControl(""),
      partsPssMainColumn3: new FormControl(""),
      partsPssMainColumn4: new FormControl(""),
      partsPssMainColumn5: new FormControl(""),
      partsPssMainColumn6: new FormControl(""),
      partsPssColumn0: new FormControl(""),
      partsPssColumn1: new FormControl(""),
      partsPssColumn2: new FormControl(""),
      partsPssColumn3: new FormControl(""),
      partsPssColumn4: new FormControl(""),
      partsPssColumn5: new FormControl(""),
      partsPssColumn6: new FormControl(""),
      partsPssColumn7: new FormControl(""),
      partsPssColumn8: new FormControl(""),
      partsPssColumn9: new FormControl(""),
      partsPssColumn10: new FormControl(""),
      partsPssColumn11: new FormControl(""),
      partsPssColumn12: new FormControl(""),
      partsPssColumn13: new FormControl(""),
      partsPssColumn14: new FormControl(""),
      partsPssColumn15: new FormControl(""),
      partsPssColumn16: new FormControl(""),
      partsPssColumn17: new FormControl(""),
      partsPssColumn18: new FormControl(""),
      partsPssColumn19: new FormControl(""),
      partsPssColumn20: new FormControl(""),
      partsPssColumn21: new FormControl(""),
      partsPssColumn22: new FormControl(""),
      partsPssColumn23: new FormControl(""),
      partsPssColumn24: new FormControl(""),
      partsPssColumn25: new FormControl(""),
      partsPssColumn26: new FormControl(""),
      partsPssColumn27: new FormControl(""),
      partsPssColumn28: new FormControl(""),
      partsPssColumn29: new FormControl(""),
      partsPssColumn30: new FormControl(""),
      partsPssColumn31: new FormControl(""),
      partsPssColumn32: new FormControl(""),
    };
  }

  getPssDataListsV2(listData: any, markupType: any, type: any, callback: any) {
    const pssDataListV2: any[] = [];
    let reportTotal: any = {
      totalProjectionFormdataProvided: null,
      totalProfitAcqCost: null,
      totalOPRFDMSDataOngoingAnualUplift: null,
      total: null,
      totalPreDiscAnnualProfitUplift: null,
      totalPostAuditAnnualProfitUplift: null,
      totalPostAuditImpactNetUplift: null,
      totalApprFaAnnualImpact: null,
      totalApprAdjAddlAnnualProfit: null,
      totalApprImpactNetUplift: null,
      totalProjAcqTotal: null,
      totalProjAnnualProfitUplift: null,
      totalPreAuditAcqTotal: null,
      totalPreAuditAnnualProfitUplift: null,
      totalPostAuditAcqTotal: null,
      totalApprAcqTotal: null,
      initialI12MIPaybackPeriod1: null,
      initialI12MIPaybackPeriod2: null,
      initialI12MIPaybackPeriod3: null,
      initialI12MIPaybackPeriod4: null,
      anualDiscountImpact: null,
      anualFactoryAdjustment: null,
      totalFinalImpactNetUplift: null,
      totalFinalAcqTotal: null,
      calculatedPayBackPriod: null,
    };
    let queryResponseData = "getPssForStoresAndDm";
    if (type === "GO") {
      queryResponseData = "getPssForSnapshot";
    }
    $.each(listData["data"][queryResponseData]["edges"], (key: any, val: any) => {
      let postAuditOemApprovalDeadline = null;
      if (val.node["oemApprovalDeadline"]) {
        postAuditOemApprovalDeadline = val.node["oemApprovalDeadline"].replace(
          this.constantService.REG_EXP_FOR_DATE_FORMAT,
          "$2-$3-$1"
        );
      }
      const obj = {
        stId: val.node["stId"],
        stName: val.node["stName"],
        state: val.node["state"],
        brand: val.node["manufacturer"],
        avgMonthlyWarrCogs: val.node["upAvgMonthlyWarrCogs"]
          ? Number(val.node["upAvgMonthlyWarrCogs"])
          : null,
        currWpMu: this.commonService.printMarkUp(val.node["upCurrEffWpMu"], markupType),
        projOptMarkup: this.commonService.printMarkUp(val.node["upProAdjMarkup"], markupType),
        projAddlPtsOvrCurr: this.commonService.get2DecimalValue(
          val.node["upAddlPtsOvrCurrEffWpMu"]
        ),
        upAddlMonthlyProfit: val.node["upAddlMonthlyProfit"]
          ? Number(val.node["upAddlMonthlyProfit"])
          : null,
        projAnnualProfitUplift: val.node["upAddlAnnualProfit"]
          ? Number(val.node["upAddlAnnualProfit"])
          : null,
        projAcqSubmissionFee: val.node["projAcqSubmissionFee"]
          ? Number(val.node["projAcqSubmissionFee"])
          : null,
        projAcqMonthlyUplift: val.node["projAcqMonthlyUplift"]
          ? Number(val.node["projAcqMonthlyUplift"])
          : null,
        projAcqAnnualUpliftFee: val.node["projAcqAnnualUpliftFee"]
          ? Number(val.node["projAcqAnnualUpliftFee"])
          : null,
        projAcqTotal: val.node["projAcqTotal"] ? Number(val.node["projAcqTotal"]) : null,
        projImpactNetUplift: val.node["projImpactNetUplift"]
          ? Number(val.node["projImpactNetUplift"])
          : null,
        projImpactPayback: this.commonService.get2DecimalValue(val.node["projImpactPayback"]),
        preAuditMu: this.commonService.printMarkUp(val.node["preAuditMu"], markupType),
        preAuditAddlPtsOvrCurr: this.commonService.get2DecimalValue(
          val.node["preAuditAddlPtsOvrCurr"]
        ),
        preAuditMonthlyProfitUplift: val.node["preAuditMonthlyProfitUplift"]
          ? Number(val.node["preAuditMonthlyProfitUplift"])
          : null,
        preAuditAnnualProfitUplift: val.node["preAuditAnnualProfitUplift"]
          ? Number(val.node["preAuditAnnualProfitUplift"])
          : null,
        preAuditAcqSubmissionFee: val.node["preAuditAcqSubmissionFee"]
          ? Number(val.node["preAuditAcqSubmissionFee"])
          : null,
        preAuditAcqMonthlyUplift: val.node["preAuditAcqMonthlyUplift"]
          ? Number(val.node["preAuditAcqMonthlyUplift"])
          : null,
        preAuditAcqAnnualUpliftFee: val.node["preAuditAcqAnnualUpliftFee"]
          ? Number(val.node["preAuditAcqAnnualUpliftFee"])
          : null,
        preAuditAcqTotal: val.node["preAuditAcqTotal"]
          ? Number(val.node["preAuditAcqTotal"])
          : null,
        preAuditImpactNetUplift: val.node["preAuditImpactNetUplift"]
          ? Number(val.node["preAuditImpactNetUplift"])
          : null,
        preAuditImpactPayback: this.commonService.get2DecimalValue(
          val.node["preAuditImpactPayback"]
        ),
        preDiscMu: this.commonService.printMarkUp(val.node["preDiscBestPostAuditMu"], markupType),
        preDiscAddlPtsOvrCurr: this.commonService.get2DecimalValue(
          val.node["preDiscIncrOvrCurrEffWpMu"]
        ),
        preDiscAddlMonthlyProfit: val.node["preDiscAddlMonthlyProfit"]
          ? Number(val.node["preDiscAddlMonthlyProfit"])
          : null,
        preDiscAnnualProfitUplift: val.node["preDiscAddlAnnualProfit"]
          ? Number(val.node["preDiscAddlAnnualProfit"])
          : null,
        postAuditMu: this.commonService.printMarkUp(
          val.node["postDiscBestPostAuditMu"],
          markupType
        ),
        postAuditAddlPtsOvrCurr: this.commonService.get2DecimalValue(
          val.node["postDiscIncrOvrCurrEffWpMu"]
        ),
        postDiscAddlMonthlyProfit: val.node["postDiscAddlMonthlyProfit"]
          ? Number(val.node["postDiscAddlMonthlyProfit"])
          : null,
        postAuditAnnualProfitUplift: val.node["postDiscAddlAnnualProfit"]
          ? Number(val.node["postDiscAddlAnnualProfit"])
          : null,
        postAuditOemApprovalDeadline: postAuditOemApprovalDeadline,
        postAuditAcqSubmissionFee: val.node["postAuditAcqSubmissionFee"]
          ? Number(val.node["postAuditAcqSubmissionFee"])
          : null,
        postAuditAcqMonthlyUplift: val.node["postAuditAcqMonthlyUplift"]
          ? Number(val.node["postAuditAcqMonthlyUplift"])
          : null,
        postAuditAcqAnnualUpliftFee: val.node["postAuditAcqAnnualUpliftFee"]
          ? Number(val.node["postAuditAcqAnnualUpliftFee"])
          : null,
        postAuditAcqTotal: val.node["postAuditAcqTotal"]
          ? Number(val.node["postAuditAcqTotal"])
          : null,
        postAuditImpactNetUplift: val.node["postAuditImpactNetUplift"]
          ? Number(val.node["postAuditImpactNetUplift"])
          : null,
        postAuditImpactPayback: this.commonService.get2DecimalValue(
          val.node["postAuditImpactPayback"]
        ),
        apprMarkup: this.commonService.printMarkUp(val.node["approvedWpMarkup"], markupType),
        apprFaPointImpact: this.commonService.get2DecimalValue(val.node["faPointImpact"]),
        apprFaAnnualImpact: val.node["faAnnualImpact"] ? Number(val.node["faAnnualImpact"]) : null,
        apprAddlPtsAbvCurrEffWpMu: this.commonService.get2DecimalValue(
          val.node["apprAddlPtsAbvCurrEffWpMu"]
        ),
        apprAdjAddlMonthlyProfit: val.node["apprAdjAddlMonthlyProfit"]
          ? Number(val.node["apprAdjAddlMonthlyProfit"])
          : null,
        apprAdjAddlAnnualProfit: val.node["apprAdjAddlAnnualProfit"]
          ? Number(val.node["apprAdjAddlAnnualProfit"])
          : null,
        apprAcqSubmissionFee: val.node["apprAcqSubmissionFee"]
          ? Number(val.node["apprAcqSubmissionFee"])
          : null,
        apprAcqMonthlyUplift: val.node["apprAcqMonthlyUplift"]
          ? Number(val.node["apprAcqMonthlyUplift"])
          : null,
        apprAcqAnnualUpliftFee: val.node["apprAcqAnnualUpliftFee"]
          ? Number(val.node["apprAcqAnnualUpliftFee"])
          : null,
        apprAcqTotal: val.node["apprAcqTotal"] ? Number(val.node["apprAcqTotal"]) : null,
        apprImpactNetUplift: val.node["apprImpactNetUplift"]
          ? Number(val.node["apprImpactNetUplift"])
          : null,
        apprImpactPayback: this.commonService.get2DecimalValue(val.node["apprImpactPayback"]),
        clientCommunicationComment: val.node["clientCommunicationComment"],

        finalAcqSubmissionFee: val.node["finalAcqSubmissionFee"]
          ? Number(val.node["finalAcqSubmissionFee"])
          : null,
        finalAcqMonthlyUplift: val.node["finalAcqMonthlyUplift"]
          ? Number(val.node["finalAcqMonthlyUplift"])
          : null,
        finalAcqAnnualUpliftFee: val.node["finalAcqAnnualUpliftFee"]
          ? Number(val.node["finalAcqAnnualUpliftFee"])
          : null,
        finalAcqTotal: val.node["finalAcqTotal"] ? Number(val.node["finalAcqTotal"]) : null,
        finalImpactNetUplift: val.node["finalImpactNetUplift"]
          ? Number(val.node["finalImpactNetUplift"])
          : null,
        finalImpactPayback: this.commonService.get2DecimalValue(val.node["finalImpactPayback"]),
        isRetracted: val.node["isRetracted"],
        retractionType: val.node["retractionType"],
        isSecondaryResolved: val.node["isSecondaryResolved"],
      };
      pssDataListV2.push(obj);

      if (!obj.isRetracted) {
        if (obj.finalAcqTotal != null && obj.finalImpactPayback != null) {
          reportTotal.calculatedPayBackPriod +=
            val.node["finalAcqTotal"] / val.node["finalImpactPayback"];
        }

        if (obj.projAnnualProfitUplift && obj.projAnnualProfitUplift > 0) {
          reportTotal.totalProjectionFormdataProvided += obj.projAnnualProfitUplift;
        }

        if (obj.projImpactNetUplift && obj.projImpactNetUplift > 0) {
          reportTotal.totalProfitAcqCost += obj.projImpactNetUplift;
        }

        if (obj.preAuditAnnualProfitUplift && obj.preAuditAnnualProfitUplift > 0) {
          reportTotal.totalOPRFDMSDataOngoingAnualUplift += obj.preAuditAnnualProfitUplift;
        }
        if (obj.preAuditImpactNetUplift && obj.preAuditImpactNetUplift > 0) {
          reportTotal.total += obj.preAuditImpactNetUplift;
        }

        if (obj.preDiscAnnualProfitUplift && obj.preDiscAnnualProfitUplift > 0) {
          reportTotal.totalPreDiscAnnualProfitUplift += obj.preDiscAnnualProfitUplift;
        }
        if (obj.postAuditAnnualProfitUplift && obj.postAuditAnnualProfitUplift > 0) {
          reportTotal.totalPostAuditAnnualProfitUplift += obj.postAuditAnnualProfitUplift;
        }

        if (obj.postAuditImpactNetUplift && obj.postAuditImpactNetUplift > 0) {
          reportTotal.totalPostAuditImpactNetUplift += obj.postAuditImpactNetUplift;
        }

        if (obj.apprFaAnnualImpact) {
          reportTotal.totalApprFaAnnualImpact += obj.apprFaAnnualImpact;
        }

        if (obj.apprAdjAddlAnnualProfit && obj.apprAdjAddlAnnualProfit > 0) {
          reportTotal.totalApprAdjAddlAnnualProfit += obj.apprAdjAddlAnnualProfit;
        }

        if (obj.apprImpactNetUplift && obj.apprImpactNetUplift > 0) {
          reportTotal.totalApprImpactNetUplift += obj.apprImpactNetUplift;
        }

        if (obj.projAcqTotal && obj.projAcqTotal > 0) {
          reportTotal.totalProjAcqTotal += obj.projAcqTotal;
        }

        if (obj.projAnnualProfitUplift && obj.projAnnualProfitUplift > 0) {
          reportTotal.totalProjAnnualProfitUplift += obj.projAnnualProfitUplift;
        }

        if (obj.preAuditAcqTotal && obj.preAuditAcqTotal > 0) {
          reportTotal.totalPreAuditAcqTotal += obj.preAuditAcqTotal;
        }

        if (obj.preAuditAnnualProfitUplift && obj.preAuditAnnualProfitUplift > 0) {
          reportTotal.totalPreAuditAnnualProfitUplift += obj.preAuditAnnualProfitUplift;
        }

        if (obj.postAuditAcqTotal && obj.postAuditAcqTotal > 0) {
          reportTotal.totalPostAuditAcqTotal += obj.postAuditAcqTotal;
        }

        if (obj.apprAcqTotal && obj.apprAcqTotal > 0) {
          reportTotal.totalApprAcqTotal += obj.apprAcqTotal;
        }

        if (obj.finalImpactNetUplift && obj.finalImpactNetUplift > 0) {
          reportTotal.totalFinalImpactNetUplift += obj.finalImpactNetUplift;
        }

        if (obj.finalAcqTotal && obj.finalAcqTotal > 0) {
          reportTotal.totalFinalAcqTotal += obj.finalAcqTotal;
        }
      }
      console.log(obj.finalAcqTotal);
      console.log(reportTotal.totalApprAdjAddlAnnualProfit);

      const responseData = {
        pssDataList: pssDataListV2,
        reportTotal: reportTotal,
      };
      if (callback) {
        callback(responseData);
      }
    });
  }

  laborPssFormV2() {
    return {
      laborPssMainColumn0: new FormControl(""),
      laborPssMainColumn1: new FormControl(""),
      laborPssMainColumn2: new FormControl(""),
      laborPssMainColumn3: new FormControl(""),
      laborPssMainColumn4: new FormControl(""),
      laborPssMainColumn5: new FormControl(""),
      laborPssMainColumn6: new FormControl(""),
      laborPssColumn0: new FormControl(""),
      laborPssColumn1: new FormControl(""),
      laborPssColumn2: new FormControl(""),
      laborPssColumn3: new FormControl(""),
      laborPssColumn4: new FormControl(""),
      laborPssColumn5: new FormControl(""),
      laborPssColumn6: new FormControl(""),
      laborPssColumn7: new FormControl(""),
      laborPssColumn8: new FormControl(""),
      laborPssColumn9: new FormControl(""),
      laborPssColumn10: new FormControl(""),
      laborPssColumn11: new FormControl(""),
      laborPssColumn12: new FormControl(""),
      laborPssColumn13: new FormControl(""),
      laborPssColumn14: new FormControl(""),
      laborPssColumn15: new FormControl(""),
      laborPssColumn16: new FormControl(""),
      laborPssColumn17: new FormControl(""),
      laborPssColumn18: new FormControl(""),
      laborPssColumn19: new FormControl(""),
      laborPssColumn20: new FormControl(""),
      laborPssColumn21: new FormControl(""),
      laborPssColumn22: new FormControl(""),
      laborPssColumn23: new FormControl(""),
      laborPssColumn24: new FormControl(""),
      laborPssColumn25: new FormControl(""),
      laborPssColumn26: new FormControl(""),
      laborPssColumn27: new FormControl(""),
      laborPssColumn28: new FormControl(""),
      laborPssColumn29: new FormControl(""),
    };
  }

  laborPssFormV1() {
    return {
      laborPssMainColumn0: new FormControl(""),
      laborPssMainColumn1: new FormControl(""),
      laborPssMainColumn2: new FormControl(""),
      laborPssMainColumn3: new FormControl(""),
      laborPssColumn0: new FormControl(""),
      laborPssColumn1: new FormControl(""),
      laborPssColumn2: new FormControl(""),
      laborPssColumn3: new FormControl(""),
      laborPssColumn4: new FormControl(""),
      laborPssColumn5: new FormControl(""),
      laborPssColumn6: new FormControl(""),
      laborPssColumn7: new FormControl(""),
      laborPssColumn8: new FormControl(""),
      laborPssColumn9: new FormControl(""),
      laborPssColumn10: new FormControl(""),
      laborPssColumn11: new FormControl(""),
      laborPssColumn12: new FormControl(""),
      laborPssColumn13: new FormControl(""),
      laborPssColumn14: new FormControl(""),
      laborPssColumn15: new FormControl(""),
      laborPssColumn16: new FormControl(""),
      laborPssColumn17: new FormControl(""),
      laborPssColumn18: new FormControl(""),
      laborPssColumn19: new FormControl(""),
      laborPssColumn20: new FormControl(""),
      laborPssColumn21: new FormControl(""),
      laborPssColumn22: new FormControl(""),
    };
  }

  resolveProjectForm() {
    return {
      resolveStatus: new FormControl("", Validators.required),
      projectResolutionNote: new FormControl(""),
      resolvedOnDate: new FormControl(""),
      salesLeadOnDate: new FormControl(""),
      prospectOnDate: new FormControl(""),
      deferredOnDate: new FormControl(""),
      deferredBecause: new FormControl(""),
      plannedFollowUp: new FormControl(""),
      salesNotes: new FormControl(""),
    };
  }
  disputeAnalysisForm() {
    return {
      inDisputeAnalysisOn: new FormControl("", Validators.required),
      inComment: new FormControl("", Validators.required),
    };
  }

  disputeForm() {
    return {
      inDisputedOn: new FormControl("", Validators.required),
      inComment: new FormControl(""),
    };
  }

  supplimentRequestForm() {
    return {
      supplimentRequestOn: new FormControl("", Validators.required),
      supplimentRequestReceivedOn: new FormControl("", Validators.required),
    };
  }

  supplimentRequestCompleteForm() {
    return {
      supplimentRequestCompletedOn: new FormControl("", Validators.required),
    };
  }

  manfRequestForm() {
    return {
      manfRequestOn: new FormControl("", Validators.required),
      manfRequestReceivedOn: new FormControl("", Validators.required),
      manfRequestNotes: new FormControl("", Validators.required),
    };
  }

  manfRequestCompleteForm() {
    return {
      manfRequestCompletedOn: new FormControl("", Validators.required),
    };
  }

  getReviewersList(listData: any, callback: any) {
    const reviewerList: any[] = [];
    $.each(listData["data"]["allReviewers"]["edges"], (key: any, val: any) => {
      const obj = {
        itemName: val.node["name"],
        shortName: val.node["shortName"],
        id: val.node["name"],
      };
      reviewerList.push(obj);
    });

    callback(reviewerList);
  }

  getAuditReviewersList(listData: any, callback: any) {
    const reviewerList: any[] = [];
    $.each(listData["data"]["allAuditReviewers"]["edges"], (key: any, val: any) => {
      const obj = {
        itemName: val.node["name"],
        id: val.node["name"],
      };
      reviewerList.push(obj);
    });

    callback(reviewerList);
  }

  getAuditList(listData: any, callback: any) {
    const auditorList: any[] = [];
    $.each(listData["data"]["allAuditors"]["edges"], (key: any, val: any) => {
      const obj = {
        itemName: val.node["name"],
        id: val.node["name"],
        email: val.node["email"],
      };
      auditorList.push(obj);
    });

    callback(auditorList);
  }
  /** dropdown section starting */

  dropdownSettings() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "itemName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      enableCheckAll: true,
      // disabled: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettings() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettingsDisable() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      //disabled: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettingsState() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettingsAssignedToConfirm() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      maxHeight: 200,
      // position: "bottom",
    };
    return dropdownSettings;
  }
  singleDropdownSettingsBrandDisabled() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      //disabled: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettingsAssignedToDisabled() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      // allowSearchFilter: true,
      // maxHeight: 200,
      //position: "top",
      //disabled: true,
    };
    return dropdownSettings;
  }

  singleDropdownSettingsMarketingSource() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      //disabled: false,
    };
    return dropdownSettings;
  }

  singleDropdownSettingsMarketingSourceDisable() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      //disabled: true,
    };
    return dropdownSettings;
  }
  singleDropdownSettingsTop() {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "itemName",
      allowSearchFilter: true,
      // position: "top",
    };
    return dropdownSettings;
  }
  /**End */
  laborMaintenanceForm() {
    return {
      inSubmissionFeeCap: new FormControl("", Validators.required),
      laborMaintenanceFee: new FormControl("", Validators.required),
      inSubmissionOverrideFee: new FormControl(""),
      inHaveSplitCommission: new FormControl("", Validators.required),
      inSplitCommissionWith: new FormControl(""),
      inIsNonContingent: new FormControl("", Validators.required),
    };
  }

  magePreAuditReviewForm() {
    return {
      inDmId: new FormControl("", Validators.required),
      inConfirmer: new FormControl("", Validators.required),
      inConfirmedOn: new FormControl(""),
      inPreAuditComment: new FormControl(""),
      inComment: new FormControl(""),
      inUpdatedBy: new FormControl(""),
      inEvalOverride: new FormControl(""),
    };
  }

  mageLaborPreAuditReviewForm() {
    return {
      inDmId: new FormControl("", Validators.required),
      inConfirmer: new FormControl("", Validators.required),
      inConfirmedOn: new FormControl(""),
      inPreAuditComment: new FormControl(""),
      inComment: new FormControl(""),
      inUpdatedBy: new FormControl(""),
      inEvalOverride: new FormControl(""),
    };
  }

  getDeferredBecauseSelectList() {
    let selectOptions = this.DMConstantService.DEFERRED_BECAUSE_SELECT_LIST;
    this.resolveDeferredBecause = [
      {
        id: "",
        itemName: "N/A",
      },
    ];
    selectOptions.forEach((element) => {
      this.resolveDeferredBecause.push({ id: element, itemName: element });
    });
  }
  deadlineNotesActionForm() {
    return {
      commentText: new FormControl(""),
      deadlineNoteDate: new FormControl("", Validators.required),
    };
  }
  stageNotesActionForm() {
    return {
      stage: new FormControl("", Validators.required),
      commentText: new FormControl(""),
      isEnableEdit: new FormControl(""),
    };
  }

  salesConfirmationForm() {
    return {
      inReadyToShipOn: new FormControl("", Validators.required),
    };
  }

  billingProcessForm() {
    return {
      from: new FormControl("", Validators.required),
      to: new FormControl(""),
      subject: new FormControl("", Validators.required),
      message: new FormControl("", Validators.required),
      inIsVerbalApproval: new FormControl(""),
      inIsNonResponsiveCustomer: new FormControl(""),
      category1Email: new FormControl(""),
      category2Email: new FormControl(""),
      category3Email: new FormControl(""),
      category4Email: new FormControl(""),
      category5Email: new FormControl(""),
      customEmail: new FormControl(""),
    };
  }
  updateEffDateAndApproveDateForm() {
    return {
      _approvedDate: new FormControl("", Validators.required),
      _effectiveDate: new FormControl("", Validators.required),
    };
  }
}
