import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-common-bar",
  templateUrl: "./common-bar.component.html",
})
export class CommonBarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
