import { Injectable } from "@angular/core";
import { ConstantService } from "../constants/constant.service";
import { CommonService } from "./common.service";
import { DMConstantService } from "../constants/dm.constant.service";

import * as moment from "moment-timezone";
import { MyCurrencyPipe } from "../components/formatter/currency/my-currency.pipe";
import { MyPercentagePipe } from "../components/formatter/percentage/my-percentage.pipe";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { HttpClient } from "@angular/common/http";
@Injectable()
export class ImpactReportBetaLaborPdfService {
  public impactReportData = [];
  constructor(
    private http: HttpClient,
    private constantService: ConstantService,
    private commonService: CommonService,
    private mycurpipe: MyCurrencyPipe,
    private myperpipe: MyPercentagePipe,
    private DMConstantService: DMConstantService
  ) {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
  }
  init() {}
  downloadImpactPdfReport = async (
    labourReportData: any,
    mageImpactReportData: any,
    selectedStore: any,
    laborPreAuditReviewStatusObj: any,
    self: any,
    parentData: any,
    action: any,
    impactFileName: any,
    callback: any
  ) => {
    console.log("action====>>", action);
    const d = moment();
    var newDate = d.tz(moment.tz.guess()).format("MM-DD-YYYY HH:mm:ss");
    let reportName =
      labourReportData.mage_stage === "PostAudit"
        ? this.DMConstantService.SOLVE360_REPORT_FILE_KEYS.POST_IMPACT_REPORT
        : this.DMConstantService.SOLVE360_REPORT_FILE_KEYS.PRE_IMPACT_REPORT;
    let stateName = "";
    stateName = labourReportData.state_name + "(" + labourReportData.state_code + ")";
    stateName += labourReportData.project_reviewed_on
      ? ", " + labourReportData.project_reviewed_on
      : "";

    let mageStage = labourReportData.mage_stage === "PostAudit" ? "Post Audit" : "Pre Audit";
    let projectName =
      parentData.linkLaborProjectObj && parentData.linkLaborProjectObj.itemName
        ? parentData.linkLaborProjectObj.itemName
        : "";
    projectName = projectName.replace(/\W+(?!$)/g, "-");
    projectName = projectName.replace(/\W$/, "");
    let fileName_ws = mageStage + " " + projectName + "_" + moment().format("YYYYMMDD");
    let fileName_solve = mageStage + "-" + projectName + "_" + moment().format("YYYY-MM-DD");

    const loopData = [];
    const loopData1 = [
      {
        text: mageImpactReportData.one_period ? mageImpactReportData.one_period : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [true, false, false, false],
      },

      {
        text: mageImpactReportData.one_hours
          ? this.numberDecimalFormatter(mageImpactReportData.one_hours, 0)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.one_ro_count ? mageImpactReportData.one_ro_count : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.one_ro_days ? mageImpactReportData.one_ro_days : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.one_start_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.one_start_date)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.one_end_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.one_end_date)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.one_uplift
          ? this.mycurpipe.transform(mageImpactReportData.one_uplift, 2)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, true, false],
      },
    ];
    loopData.push(loopData1);
    loopData.push([
      {
        colSpan: 7,
        rowSpan: 1,
        text: mageImpactReportData.one_paytypes ? mageImpactReportData.one_paytypes : null,
        style: "hederSectionOrange_1",
        alignment: "left",
        border: [true, false, true, true],
        margin: [90, 4, 0, 0],
      },
    ]);
    const loopData2 = [
      {
        text: mageImpactReportData.two_period ? mageImpactReportData.two_period : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [true, false, false, false],
      },

      {
        text: mageImpactReportData.two_hours
          ? this.numberDecimalFormatter(mageImpactReportData.two_hours, 0)
          : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.two_ro_count ? mageImpactReportData.two_ro_count : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.two_ro_days ? mageImpactReportData.two_ro_days : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.two_start_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.two_start_date)
          : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.two_end_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.two_end_date)
          : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.two_uplift
          ? this.mycurpipe.transform(mageImpactReportData.two_uplift, 2)
          : null,
        style: "hederSectionOrange_2",
        alignment: "right",
        border: [false, false, true, false],
      },
    ];
    loopData.push(loopData2);
    loopData.push([
      {
        colSpan: 7,
        rowSpan: 1,
        text: mageImpactReportData.two_paytypes ? mageImpactReportData.two_paytypes : null,
        style: "hederSectionOrange_2",
        alignment: "left",
        border: [true, false, true, true],
        margin: [90, 4, 0, 0],
      },
    ]);
    const loopData3 = [
      {
        text: mageImpactReportData.three_period ? mageImpactReportData.three_period : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [true, false, false, false],
      },

      {
        text: mageImpactReportData.three_hours
          ? this.numberDecimalFormatter(mageImpactReportData.three_hours, 0)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.three_ro_count ? mageImpactReportData.three_ro_count : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.three_ro_days ? mageImpactReportData.three_ro_days : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.three_start_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.three_start_date)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.three_end_date
          ? this.transformDateMMDDYYYY(mageImpactReportData.three_end_date)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: mageImpactReportData.three_uplift
          ? this.mycurpipe.transform(mageImpactReportData.three_uplift, 2)
          : null,
        style: "hederSectionOrange_1",
        alignment: "right",
        border: [false, false, true, false],
      },
    ];

    loopData.push(loopData3);
    loopData.push([
      {
        colSpan: 7,
        rowSpan: 1,
        text: mageImpactReportData.three_paytypes ? mageImpactReportData.three_paytypes : null,
        style: "hederSectionOrange_1",
        alignment: "left",
        border: [true, false, true, true],
        margin: [90, 4, 0, 0],
      },
    ]);
    const cellHeights = [];
    for (let loopCount = 0; loopCount < loopData.length; loopCount++) {
      cellHeights.push(15);
    }
    const inActivePostAuditProjectionReport = {
      pageSize: "Legal",
      pageOrientation: "landscape",
      pageMargins: [19, 19, 19, 19],
      content: [
        //{ text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_HEAD_LABOR, style: 'subheader' },
        "",
        {
          style: "table1",
          color: "#444",
          table: {
            widths: [150, "*", 150],
            headerRows: 2,
            body: [
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SDD,
                    {
                      text: this.transformDateMMDDYYYY(labourReportData.shipping_deadline),
                      style: "topHeaderCell",
                      alignment: "left",
                      fontSize: 12,
                    },
                  ],
                  fontSize: 12,
                  style: "topHeaderCellYellow",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_HEAD_LABOR,
                  style: "topHeaderCell2",
                },
                {
                  colSpan: 1,
                  columns: [
                    //{ text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_POST_AUDIT, style: 'topHeaderCell', alignment: 'left', fontSize: 12 },
                    {
                      text: labourReportData.mage_stage,
                      style: "topHeaderCell",
                      alignment: "right",
                      fontSize: 12,
                    },
                  ],
                  fontSize: 12,
                  style: "topHeaderCellYellow",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SNRT,
                    {
                      text: stateName,
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SGN,
                    {
                      text: labourReportData.sg_name,
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SALES_PERSON,
                    {
                      text:
                        parentData.linkLaborProjectObj && parentData.linkLaborProjectObj.salesPerson
                          ? parentData.linkLaborProjectObj.salesPerson
                          : "",
                      style: "topHeaderCell",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                  alignment: "right",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MNF,
                    {
                      text: selectedStore
                        ? selectedStore.manufacturer + "(" + selectedStore.brandCode + ")"
                        : "",
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SN,
                    {
                      text: labourReportData.st_name,
                      style: "topHeaderCell",
                      alignment: "left",
                      bold: true,
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FACT_STAT,
                    {
                      text: labourReportData.labor_project_type,
                      style: "topHeaderCell",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                  alignment: "right",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.DMS,
                    {
                      text:
                        parentData.linkLaborProjectObj && parentData.linkLaborProjectObj.dms_code
                          ? parentData.linkLaborProjectObj.dms_code
                          : "",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  style: "topHeaderCell",
                  margin: [0, 20, 0, 0],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FIUO,
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {},
              ],
              [
                {
                  text: [
                    {
                      text:
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBR +
                        this.getOverRideLabel(labourReportData, true, "basisRate"),
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text: this.mycurpipe.transform(labourReportData.basis_lr, 2) + "\n",
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 12,
                    },
                    {
                      text:
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBH +
                        this.getOverRideLabel(labourReportData, true, "basisHour"),
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text: parentData.numberDecimalFormatter(labourReportData.basis_hours, 0),
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 11,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "left",
                },
                {
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MU,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text: this.mycurpipe.transform(labourReportData.monthly_uplift, 0) + "\n",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CU,
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text: this.mycurpipe.transform(labourReportData.calculated_uplift, 0),
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 11,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {
                  text: [
                    labourReportData.mage_stage
                      ? labourReportData.mage_stage +
                        " " +
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_TR
                      : this.DMConstantService.IMPACT_REPORT_LABEL.LBL_TBR,
                    {
                      text: this.mycurpipe.transform(labourReportData.target_lr, 2),
                      alignment: "left",
                    },
                  ],
                  fontSize: 12,
                  style: ["styleBold", "region2Margin"],
                },
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 150, "*", 150, 150],
            body: [
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBMS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    { text: "Calculated", alignment: "right" },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_LWSB,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: labourReportData.lws_basis_type,
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BASIS_MONTHLY_HOURS,
                      alignment: "center",
                      style: "styleBold",
                    },
                    {
                      text: labourReportData.basis_hours
                        ? this.numberDecimalFormatter(labourReportData.basis_hours, 0)
                        : "",
                      alignment: "left",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  style: "topHeaderCellYellow",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PAUDIT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: this.mycurpipe.transform(labourReportData.post_audit_retail_lr, 2),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
            ],
          },
        },
        // {
        //   style: 'table2',
        //   color: '#444',
        //   table: {
        //     widths: [150, 150, '*', 150, 150],
        //     body: [
        //       [
        //         {
        //           columns: [
        //             { text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_HOURS + this.getOverRideLabel(labourReportData, true, 'calcHours'), alignment: 'left', style: 'styleBold' },
        //             { text: labourReportData.calculated_warranty_hours ? this.numberDecimalFormatter(labourReportData.calculated_warranty_hours,0) : '', alignment: 'right' }
        //           ], border: [true, false, true, true], fontSize: 10, color: 'black'
        //         },
        //         {
        //           columns: [
        //             { text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_LWS_HOURS + this.getOverRideLabel(labourReportData, true, 'lwsHours'), alignment: 'left', style: 'styleBold' },
        //             { text: this.numberDecimalFormatter(labourReportData.lws_warranty_hours,0), alignment: 'right' },
        //           ], border: [true, false, true, true], fontSize: 10, color: 'black'
        //         },
        //         {
        //           colSpan: 1, rowSpan: 1, style: 'table2',
        //           alignment: 'center', border: [true, false, true, true],
        //           table: {
        //             widths: ['*', '*', '*', '*'],
        //             body: [
        //               [
        //                 { text: this.DMConstantService.IMPACT_REPORT_LABEL.ROUND, alignment: 'left', style: 'styleBold' },
        //                 { text: this.DMConstantService.IMPACT_REPORT_LABEL.PREDISC, alignment: 'left', style: 'styleBold' },
        //                 { text: this.DMConstantService.IMPACT_REPORT_LABEL.POSTDISC, alignment: 'left', style: 'styleBold' },
        //                 { text: this.DMConstantService.IMPACT_REPORT_LABEL.DISCEFFECT, alignment: 'left', style: 'styleBold' },
        //               ],
        //               [
        //                 { text: laborPreAuditReviewStatusObj.latestDiscountHistory.round, alignment: 'left' },
        //                 { text: this.mycurpipe.transform(laborPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate, 2), alignment: 'left' },
        //                 { text: this.mycurpipe.transform(laborPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate, 2), alignment: 'left' },
        //                 { text: (laborPreAuditReviewStatusObj.latestDiscountHistory.discount_effect !== null ? this.mycurpipe.transform(laborPreAuditReviewStatusObj.latestDiscountHistory.discount_effect, 2) : ''), alignment: 'left' },
        //               ]
        //             ]
        //           }
        //         },
        //         { text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_DISCOUNT, style: 'styleBold', alignment: 'left', border: [true, false, true, true], fontSize: 10, fillColor:'#FFFFE0' },
        //         { text: this.mycurpipe.transform(labourReportData.pre_discount_lr, 2), style: 'styleBold', alignment: 'left', border: [true, false, true, true], fontSize: 10, fillColor:'#FFFFE0' },

        //       ],
        //       [
        //         {
        //           columns: [
        //             { text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_RATE + this.getOverRideLabel(labourReportData, true, 'calcRate'), alignment: 'left', style: 'styleBold' },
        //             { text: this.mycurpipe.transform(labourReportData.calculated_warranty_lr, 2), alignment: 'right' }
        //           ], border: [true, false, true, true], fontSize: 10, color: 'black'
        //         },

        //         {
        //           columns: [
        //             { text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_LWS_RATE + this.getOverRideLabel(labourReportData, true, 'lwsRate'), alignment: 'left', style: 'styleBold' },
        //             { text: this.mycurpipe.transform(labourReportData.cust_stated_warranty_lr, 2), alignment: 'right' }
        //           ], border: [true, false, true, true], fontSize: 10, color: 'black'
        //         },
        //         '',
        //         {  colSpan: 1, rowSpan: 1,text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_AUDIT, style: 'styleBold', alignment: 'left', border: [true, false, true, true], fontSize: 10, fillColor:'#FFFFE0', margin: [0, 0, 0, 0] },
        //         {  colSpan: 1, rowSpan: 1,text: this.mycurpipe.transform(labourReportData.pre_audit_retail_lr, 2), style: 'styleBold', alignment: 'left', border: [true, false, true, true], fontSize: 10, fillColor:'#FFFFE0', margin: [0, 0, 0, 0] },

        //       ],
        //       [
        //         '',
        //         {
        //           columns: [
        //             { text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_DOOR_RATE, alignment: 'left', style: 'styleBold' },
        //             { text: this.mycurpipe.transform(parentData.doorRate, 2), alignment: 'right' }
        //           ], border: [true, false, true, true], fontSize: 10, color: 'black'
        //         },
        //         '',
        //         { colSpan: 1, rowSpan: 1, text: '', style: 'styleBold', alignment: 'left', border: [true, false, true, true], fontSize: 10, fillColor: '#FFFFE0', margin: [0, 0, 0, 0] },
        //         { colSpan: 1, rowSpan: 1, text: '', style: 'styleBold', alignment: 'left', border: [true, false, true, true], fontSize: 10, fillColor: '#FFFFE0', margin: [0, 0, 0, 0] },

        //       ],
        //       [
        //         { colSpan: 2, rowSpan: 1, text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CUST_STATED_RATE + this.mycurpipe.transform(labourReportData.cust_stated_warranty_lr, 2), style: 'styleBold', alignment: 'center', border: [true, false, true, true], fillColor: '#FFFFE0' },
        //         '',
        //         '',
        //         { colSpan: 2, rowSpan: 1, text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ADDITIONAL_LR + (parentData.primaryBundleData['lbr_uplift_labor_rate'] ? this.mycurpipe.transform(((parentData.primaryBundleData['lbr_uplift_labor_rate']).toFixed(2))) : 'NA'), style: 'styleBold', alignment: 'center', border: [true, false, true, true], fillColor: '#FFFFE0' }
        //       ],
        //     ]
        //   }
        // },

        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 150, "*", "*", "*", "*", "*", 150, 150],
            body: [
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_HOURS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: labourReportData.calculated_warranty_hours
                        ? this.numberDecimalFormatter(labourReportData.calculated_warranty_hours, 0)
                        : "",
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_LWS_HOURS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.numberDecimalFormatter(labourReportData.lws_warranty_hours, 0),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.ROUND,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.YEAR,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.PREDISC,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.POSTDISC,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.DISCEFFECT,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_DISCOUNT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: this.mycurpipe.transform(labourReportData.pre_discount_lr, 2),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.mycurpipe.transform(labourReportData.calculated_warranty_lr, 2),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_LWS_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.mycurpipe.transform(labourReportData.cust_stated_warranty_lr, 2),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory
                      ? laborPreAuditReviewStatusObj.latestDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory
                      ? laborPreAuditReviewStatusObj.latestDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory !== null &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory.discount_effect !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.latestDiscountHistory.discount_effect,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  colSpan: 1,
                  rowSpan: 1,
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_AUDIT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                  margin: [0, 0, 0, 0],
                },
                {
                  colSpan: 1,
                  rowSpan: 1,
                  text: this.mycurpipe.transform(labourReportData.pre_audit_retail_lr, 2),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                  margin: [0, 0, 0, 0],
                },
              ],
              [
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_DOOR_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.mycurpipe.transform(parentData.doorRate, 2),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory !== null
                      ? laborPreAuditReviewStatusObj.secondDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory !== null
                      ? laborPreAuditReviewStatusObj.secondDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.secondDiscountHistory.pre_discount_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.secondDiscountHistory.post_audit_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory !== null &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory.discount_effect !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.secondDiscountHistory.discount_effect,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.PREAUDIT_DISCOUNT_MEMO,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: labourReportData
                    ? this.isExistDmsDiscountEffect(
                        parentData.linkLaborProjectObj.dms_code,
                        labourReportData.pre_audit_discount_effect
                      )
                    : "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                  color: "black",
                },
              ],

              [
                {
                  colSpan: 2,
                  rowSpan: 1,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory !== null
                      ? laborPreAuditReviewStatusObj.thirdDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory !== null
                      ? laborPreAuditReviewStatusObj.thirdDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.thirdDiscountHistory.pre_discount_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.thirdDiscountHistory.post_audit_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory !== null &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  colSpan: 2,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  colSpan: 2,
                  rowSpan: 1,
                  text:
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CUST_STATED_RATE +
                    this.mycurpipe.transform(labourReportData.cust_stated_warranty_lr, 2),
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text:
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    self.preauditReview.rule_data.discount_history &&
                    self.preauditReview.rule_data.discount_history.length > 3
                      ? self.preauditReview.rule_data.discount_history.length - 3 + " more exist"
                      : "Discounting History",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 2,
                  rowSpan: 1,
                  text:
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ADDITIONAL_LR +
                    (parentData.primaryBundleData["lbr_uplift_labor_rate"]
                      ? this.mycurpipe.transform(
                          parentData.primaryBundleData["lbr_uplift_labor_rate"].toFixed(2)
                        )
                      : "NA"),
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
            ],
          },
        },
        {
          style: "impactReportTable",
          table: {
            heights: [25],
            widths: ["*", "*", "*", "*", "*", "*", "*"],
            border: [false, false, false, true],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PERIOD,
                  style: "impactReportList",
                  alignment: "right",
                  border: [true, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_HOURS,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_IC,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ID,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SD,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ED,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_UPLIFT,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, true, true],
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 1;
            },
            vLineWidth: function (i: any, node: any) {
              return 1;
            },
            hLineColor: function (i: any, node: any) {
              return "black";
            },
            vLineColor: function (i: any, node: any) {
              return "black";
            },
          },
        },
        {
          style: "impactReportTableValue",
          table: {
            heights: cellHeights,
            widths: ["*", "*", "*", "*", "*", "*", "*"],
            body: loopData,
          },
          layout: {},
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 10, 180, "*"],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ANALIZED_UPLIFT,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: labourReportData.annual_uplift
                    ? this.mycurpipe.transform(labourReportData.annual_uplift, 0)
                    : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  colSpan: 1,
                  rowSpan: 4,
                  text:
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_RETAIL_SUMMARY_BOX +
                    this.getOverRideLabel(labourReportData, false, ""),
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SUBMISSION_FEE,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: labourReportData.submission_fee
                    ? this.mycurpipe.transform(labourReportData.submission_fee, 0)
                    : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                "",
              ],

              [
                {
                  text: !labourReportData.isLaborMaintenance
                    ? this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MONTHLY_RECURRING_FEE
                    : this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MAINTENANCE_FEE,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: !labourReportData.isLaborMaintenance
                    ? labourReportData.recurring_fee
                      ? this.mycurpipe.transform(labourReportData.recurring_fee, 0)
                      : ""
                    : labourReportData.maintenance_fee
                      ? this.mycurpipe.transform(labourReportData.maintenance_fee, 0)
                      : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                "",
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PAYBACK_PERIOD,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, true],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                {
                  text: this.commonService.get2DecimalValue(labourReportData.payback_period),
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                "",
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          pageBreak: "after",
          table: {
            widths: [150, 10, 180, 400, "*"],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MEGE_SCE_NAME,
                  style: "section3",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: mageImpactReportData.scenario_name,
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  // colSpan: 1, rowSpan: 2, text: this.constantService.SITE_COPYRIGHT_MESSAGE, style: 'section3_1', alignment: 'left', border: [true, false, true, true], margin: [0, 0, 0, 0]
                  colSpan: 1,
                  rowSpan: 2,
                  text: this.constantService.SITE_COPYRIGHT_MESSAGE,
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
                {
                  colSpan: 1,
                  rowSpan: 2,
                  text: "Data Date: " + newDate,
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MEGE_SCE_KEY,
                  style: "section3",
                  alignment: "left",
                  border: [true, false, false, true],
                },
                {
                  text: ":",
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                {
                  text: parentData.linkLaborProjectObj.scenariokey,
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                "",
                "",
              ],
            ],
          },
        },
        {
          style: "table1",
          color: "#444",
          table: {
            widths: [100, 100, 50, 125, 225, 50, "*", "*", 50, 50, 50],
            body: [
              [
                {
                  colSpan: 10,
                  rowSpan: 1,
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.PREAUDIT_REVIEW,
                  style: "subheader",
                  alignment: "center",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: ["styleBold", "region2Margin"],
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 12,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBR +
                      this.getOverRideLabel(labourReportData, true, "basisRate"),
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.basis_rate !== null
                          ? this.mycurpipe.transform(self.preauditReview.rule_data.basis_rate)
                          : "",
                      alignment: "left",
                    },
                  ],
                  fontSize: 11,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBH +
                      this.getOverRideLabel(labourReportData, true, "basisHour"),
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.basis_hrs !== null
                          ? parentData.numberDecimalFormatter(
                              self.preauditReview.rule_data.basis_hrs,
                              0
                            )
                          : "",
                      alignment: "left",
                    },
                  ],
                  fontSize: 11,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: "",
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  colSpan: 3,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MU,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.monthly_uplift !== null
                          ? this.mycurpipe.transform(
                              self.preauditReview.rule_data.monthly_uplift,
                              0
                            ) + "\n"
                          : "",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },

                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.PREAUDIT_DISCOUNT_MEMO,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.pre_audit_discount !== null
                          ? this.isExistDmsDiscountEffect(
                              parentData.linkLaborProjectObj.dms_code,
                              self.preauditReview.rule_data.pre_audit_discount
                            ) + "\n"
                          : "\n",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, true, true, true],
                  margin: [0, 0, 0, 0],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBMS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    { text: "Calculated", alignment: "right" },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [{ text: "", alignment: "left", style: "styleBold" }],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [{ text: "", alignment: "center", style: "styleBold" }],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  colSpan: 3,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CU,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.calculated_uplift !== null
                          ? this.mycurpipe.transform(
                              self.preauditReview.rule_data.calculated_uplift,
                              0
                            )
                          : "",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },

                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, true, true, true],
                  margin: [0, 0, 0, 0],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  text: "",
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {
                  text: "",
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {
                  text: "",
                  fontSize: 12,
                  style: ["styleBold", "region2Margin"],
                },

                {
                  colSpan: 3,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BASIS_MONTHLY_HOURS,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.basis_hrs !== null
                          ? parentData.numberDecimalFormatter(
                              self.preauditReview.rule_data.basis_hrs,
                              0
                            )
                          : "",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, true, true, true],
                  margin: [0, 0, 0, 0],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_HOURS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.calculated_hrs !== null
                          ? self.preauditReview.rule_data.calculated_hrs
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_LWS_HOURS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.lws_warranty_hours !== null &&
                        parentData.allDmLabors &&
                        parentData.allDmLabors.length
                          ? self.preauditReview.rule_data.lws_warranty_hours
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.lws_calculated_hours_rule_detail &&
                    self.laborPreAuditReviewStatusObj.lws_calculated_hours_rule_detail
                      .lws_calc_hours_value !== null &&
                    parentData.allDmLabors &&
                    parentData.allDmLabors.length
                      ? parentData.markupPercentageCost(
                          laborPreAuditReviewStatusObj.lws_calculated_hours_rule_detail
                            .lws_calc_hours_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.lws_calculated_hours_rule_detail &&
                    parentData.allDmLabors &&
                    parentData.allDmLabors.length &&
                    parentData.differenceColor(
                      self.laborPreAuditReviewStatusObj.lws_calculated_hours_rule_detail
                        .lws_calc_hours
                    ),
                  color: "white",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.WARRANTY_RATE,
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.basis_rate !== null
                          ? this.mycurpipe.transform(self.preauditReview.rule_data.basis_rate)
                          : "",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? this.DMConstantService.IMPACT_REPORT_LABEL.PRE_AUDIT_RATE_WITHOUT_DE
                      : this.DMConstantService.IMPACT_REPORT_LABEL.PRE_AUDIT_RATE,
                    {
                      text:
                        laborPreAuditReviewStatusObj &&
                        laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail &&
                        laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                          .pre_audit_warranty_wo_disc_effect_value !== null &&
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.pre_audit_rate !== null
                          ? this.mycurpipe.transform(self.preauditReview.rule_data.pre_audit_rate)
                          : "",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail &&
                    self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                      .pre_audit_warranty_wo_disc_effect_value !== null
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                            .pre_audit_warranty_wo_disc_effect_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail &&
                    parentData.differenceColor(
                      self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                        .pre_audit_warranty_wo_disc_effect
                    ),
                  color: "white",
                },
                {
                  text: [this.DMConstantService.IMPACT_REPORT_LABEL.ROUND],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [this.DMConstantService.IMPACT_REPORT_LABEL.YEAR],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [this.DMConstantService.IMPACT_REPORT_LABEL.PREDISC],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [this.DMConstantService.IMPACT_REPORT_LABEL.POSTDISC],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [this.DMConstantService.IMPACT_REPORT_LABEL.DISCEFFECT],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.calculated_rate !== null
                          ? this.mycurpipe.transform(self.preauditReview.rule_data.calculated_rate)
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_LWS_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.lws_warranty_lr !== null
                          ? this.mycurpipe.transform(self.preauditReview.rule_data.lws_warranty_lr)
                          : "",
                      alignment: "right",
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.calculated_lws_rate_rule_detail &&
                    self.laborPreAuditReviewStatusObj.calculated_lws_rate_rule_detail
                      .calc_lws_rate_value === null
                      ? ""
                      : self.laborPreAuditReviewStatusObj &&
                          self.laborPreAuditReviewStatusObj.calculated_lws_rate_rule_detail &&
                          self.laborPreAuditReviewStatusObj.calculated_lws_rate_rule_detail
                            .calc_lws_rate_value !== 0
                        ? this.mycurpipe.transform(
                            self.laborPreAuditReviewStatusObj.calculated_lws_rate_rule_detail
                              .calc_lws_rate_value
                          )
                        : "$ 0",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.calculated_lws_rate_rule_detail &&
                    parentData.differenceColor(
                      self.laborPreAuditReviewStatusObj.calculated_lws_rate_rule_detail
                        .calc_lws_rate
                    ),
                  color: "white",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? this.DMConstantService.IMPACT_REPORT_LABEL.WARRANTY_RATE
                      : "",
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.basis_rate &&
                        !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                          ? this.mycurpipe.transform(self.preauditReview.rule_data.basis_rate)
                          : "",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? this.DMConstantService.IMPACT_REPORT_LABEL.PRE_AUDIT_RATE_WITH_DE
                      : "",
                    {
                      text:
                        self.preauditReview &&
                        self.preauditReview.rule_data &&
                        self.preauditReview.rule_data.pre_audit_rate !== null &&
                        !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                          ? this.mycurpipe.transform(
                              self.preAuditRateWithDiscEffect(self.preauditReview)
                            )
                          : "",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail &&
                    self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                      .pre_audit_warranty_value !== null &&
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                            .pre_audit_warranty_value
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                  fillColor:
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail &&
                    self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                      .pre_audit_warranty !== null &&
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    !self.preauditReview.rule_data.has_discount_effect_in_pre_audit &&
                    parentData.differenceColor(
                      self.laborPreAuditReviewStatusObj.pre_audit_warranty_rule_detail
                        .pre_audit_warranty
                    ),
                  color: "white",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.latestDiscountHistory
                      ? self.laborPreAuditReviewStatusObj.latestDiscountHistory.round
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.latestDiscountHistory
                      ? self.laborPreAuditReviewStatusObj.latestDiscountHistory.project_year
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.latestDiscountHistory &&
                    self.laborPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate !==
                      null
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.latestDiscountHistory &&
                    self.laborPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate !== null
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.latestDiscountHistory &&
                    self.laborPreAuditReviewStatusObj.latestDiscountHistory.discount_effect !== null
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.latestDiscountHistory.discount_effect
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
              ],
              [
                {
                  colSpan: 6,
                  rowSpan: 1,
                  text: [{ text: "", style: "styleBold", fontSize: 10 }],
                  style: ["styleBold", "region3Margin"],
                  alignment: "left",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.secondDiscountHistory
                      ? self.laborPreAuditReviewStatusObj.secondDiscountHistory.round
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.secondDiscountHistory
                      ? self.laborPreAuditReviewStatusObj.secondDiscountHistory.project_year
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.secondDiscountHistory &&
                    self.laborPreAuditReviewStatusObj.secondDiscountHistory.pre_discount_rate !==
                      null
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.secondDiscountHistory.pre_discount_rate
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.secondDiscountHistory &&
                    self.laborPreAuditReviewStatusObj.secondDiscountHistory.post_audit_rate !== null
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.secondDiscountHistory.post_audit_rate
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.secondDiscountHistory &&
                    self.laborPreAuditReviewStatusObj.secondDiscountHistory.discount_effect !== null
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.secondDiscountHistory.discount_effect
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
              ],
              [
                {
                  colSpan: 6,
                  rowSpan: 1,
                  text: [{ text: "", style: "styleBold", fontSize: 10 }],
                  style: ["styleBold", "region3Margin"],
                  alignment: "left",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.thirdDiscountHistory
                      ? self.laborPreAuditReviewStatusObj.thirdDiscountHistory.round
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.thirdDiscountHistory
                      ? self.laborPreAuditReviewStatusObj.thirdDiscountHistory.project_year
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.thirdDiscountHistory &&
                    self.laborPreAuditReviewStatusObj.thirdDiscountHistory.pre_discount_rate !==
                      null
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.thirdDiscountHistory.pre_discount_rate
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.thirdDiscountHistory &&
                    self.laborPreAuditReviewStatusObj.thirdDiscountHistory.post_audit_rate !== null
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.thirdDiscountHistory.post_audit_rate
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
                {
                  text: [
                    self.preauditReview &&
                    self.laborPreAuditReviewStatusObj &&
                    self.laborPreAuditReviewStatusObj.thirdDiscountHistory &&
                    self.laborPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect !== null
                      ? this.mycurpipe.transform(
                          self.laborPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect
                        )
                      : "",
                  ],
                  fontSize: 10,
                  style: ["styleBold", "region2Margin"],
                },
              ],
              [
                {
                  colSpan: 3,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.CONFIRMER,
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text:
                        self.preauditReview && self.preauditReview.confirmer
                          ? self.preauditReview.confirmer
                          : "",
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 10,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "left",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 3,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.COMMENTS,
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text:
                        self.preauditReview && self.preauditReview.comment !== null
                          ? self.preauditReview.comment
                          : "",
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 10,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "left",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.CONFIRMED_ON,
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text:
                        self.preauditReview && self.preauditReview.confirmed_on
                          ? this.transformDateMMDDYYYY(self.preauditReview.confirmed_on)
                          : "",
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 10,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "left",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
            ],
          },
        },
      ],
      styles: {
        reportHeader: {
          fontSize: 14,
          bold: true,
          alignment: "left",
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          color: "black",
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
          color: "black",
        },
        table1: {
          margin: [10, 10, 0, 0],
          fontSize: 12,
          color: "black",
        },
        table2: {
          margin: [10, 0, 0, 0],
          fontSize: 12,
          border: [true, false, true, true],
          color: "black",
        },
        topHeaderCell2: {
          fontSize: 16,
          bold: true,
          alignment: "center",
          color: "black",
        },
        topHeaderCell2_1: {
          fontSize: 12,
          bold: true,
          alignment: "center",
          color: "black",
        },
        topHeaderCell: {
          alignment: "left",
          fontSize: 10,
          color: "black",
        },
        topHeaderCellYellow: {
          alignment: "left",
          fontSize: 10,
          bold: true,
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        topHeaderCellYellow_1: {
          fontSize: 10,
          bold: true,
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        section3: {
          alignment: "left",
          fontSize: 10,
          border: [true, false, true, true],
          color: "black",
        },
        section3_1: {
          alignment: "center",
          fontSize: 10,
          border: [true, false, true, true],
          color: "black",
        },
        styleBold: {
          bold: true,
          color: "black",
        },
        impactReportTable: {
          margin: [10, 0, 0, 0],
          fontSize: 12,
          color: "black",
        },
        impactReportTableValue: {
          margin: [10, 0, 0, 0],
          fontSize: 10,
          color: "black",
        },
        hederSectionOrange_1: {
          fillColor: this.DMConstantService.IMPACT_TDBG_WHITE,
          fontSize: 10,
          margin: [0, 4, 0, 0],
          color: "black",
        },
        hederSectionOrange_2: {
          fillColor: this.DMConstantService.IMPACT_TDBG_WHITE,
          fontSize: 10,
          margin: [0, 4, 0, 0],
          color: "black",
        },
        region3Margin: {
          margin: [0, 10, 0, 10],
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        region2Margin: {
          margin: [0, 20, 0, 0],
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        impactReportList: {
          bold: true,
          margin: [0, 10, 0, 0],
          color: "black",
        },
      },
    };
    const activePostAuditProjectionReport = {
      pageSize: "Legal",
      pageOrientation: "landscape",
      pageMargins: [19, 19, 19, 19],
      content: [
        //{ text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_HEAD_LABOR, style: 'subheader' },
        "",
        {
          style: "table1",
          color: "#444",
          table: {
            widths: [150, "*", 150],
            headerRows: 2,
            body: [
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SDD,
                    {
                      text: this.transformDateMMDDYYYY(labourReportData.shipping_deadline),
                      style: "topHeaderCell",
                      alignment: "left",
                      fontSize: 12,
                    },
                  ],
                  fontSize: 12,
                  style: "topHeaderCellYellow",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_HEAD_LABOR,
                  style: "topHeaderCell2",
                },
                {
                  colSpan: 1,
                  columns: [
                    //{ text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_POST_AUDIT, style: 'topHeaderCell', alignment: 'left', fontSize: 12 },
                    {
                      text: labourReportData.mage_stage,
                      style: "topHeaderCell",
                      alignment: "right",
                      fontSize: 12,
                    },
                  ],
                  fontSize: 12,
                  style: "topHeaderCellYellow",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SNRT,
                    {
                      text: stateName,
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SGN,
                    {
                      text: labourReportData.sg_name,
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SALES_PERSON,
                    {
                      text:
                        parentData.linkLaborProjectObj && parentData.linkLaborProjectObj.salesPerson
                          ? parentData.linkLaborProjectObj.salesPerson
                          : "",
                      style: "topHeaderCell",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                  alignment: "right",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MNF,
                    {
                      text: selectedStore
                        ? selectedStore.manufacturer + "(" + selectedStore.brandCode + ")"
                        : "",
                      style: "topHeaderCell",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SN,
                    {
                      text: labourReportData.st_name,
                      style: "topHeaderCell",
                      alignment: "left",
                      bold: true,
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FACT_STAT,
                    {
                      text: labourReportData.labor_project_type,
                      style: "topHeaderCell",
                      alignment: "right",
                    },
                  ],
                  fontSize: 10,
                  color: "black",
                  alignment: "right",
                },
              ],
              [
                {
                  text: [
                    this.DMConstantService.IMPACT_REPORT_LABEL.DMS,
                    {
                      text:
                        parentData.linkLaborProjectObj && parentData.linkLaborProjectObj.dms_code
                          ? parentData.linkLaborProjectObj.dms_code
                          : "",
                      alignment: "left",
                    },
                  ],
                  fontSize: 10,
                  style: "topHeaderCell",
                  margin: [0, 20, 0, 0],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_FIUO,
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {},
              ],
              [
                {
                  text: [
                    {
                      text:
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBR +
                        this.getOverRideLabel(labourReportData, true, "basisRate"),
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text: this.mycurpipe.transform(labourReportData.basis_lr, 2) + "\n",
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 12,
                    },
                    {
                      text:
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBH +
                        this.getOverRideLabel(labourReportData, true, "basisHour"),
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text: parentData.numberDecimalFormatter(labourReportData.basis_hours, 0),
                      style: "styleBold",
                      alignment: "left",
                      fontSize: 11,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "left",
                },
                {
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MU,
                      style: "styleBold",
                      fontSize: 12,
                    },
                    {
                      text: this.mycurpipe.transform(labourReportData.monthly_uplift, 0) + "\n",
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 12,
                    },
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CU,
                      style: "styleBold",
                      fontSize: 10,
                    },
                    {
                      text: this.mycurpipe.transform(labourReportData.calculated_uplift, 0),
                      style: "styleBold",
                      alignment: "center",
                      fontSize: 11,
                    },
                  ],
                  style: ["styleBold", "region3Margin"],
                  alignment: "center",
                },
                {
                  text: [
                    labourReportData.mage_stage
                      ? labourReportData.mage_stage +
                        " " +
                        this.DMConstantService.IMPACT_REPORT_LABEL.LBL_TR
                      : this.DMConstantService.IMPACT_REPORT_LABEL.LBL_TBR,
                    {
                      text: this.mycurpipe.transform(labourReportData.target_lr, 2),
                      alignment: "left",
                    },
                  ],
                  fontSize: 12,
                  style: ["styleBold", "region2Margin"],
                },
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 150, "*", 150, 150],
            body: [
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BBMS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    { text: "Calculated", alignment: "right" },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_LWSB,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: labourReportData.lws_basis_type,
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_BASIS_MONTHLY_HOURS,
                      alignment: "center",
                      style: "styleBold",
                    },
                    {
                      text: labourReportData.basis_hours
                        ? this.numberDecimalFormatter(labourReportData.basis_hours, 0)
                        : "",
                      alignment: "left",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  style: "topHeaderCellYellow",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PAUDIT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: this.mycurpipe.transform(labourReportData.post_audit_retail_lr, 2),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 150, "*", "*", "*", "*", "*", 150, 150],
            body: [
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_HOURS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: labourReportData.calculated_warranty_hours
                        ? this.numberDecimalFormatter(labourReportData.calculated_warranty_hours, 0)
                        : "",
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_LWS_HOURS,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.numberDecimalFormatter(labourReportData.lws_warranty_hours, 0),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.ROUND,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.YEAR,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.PREDISC,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.POSTDISC,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.DISCEFFECT,
                  alignment: "center",
                  style: "styleBold",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_DISCOUNT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: this.mycurpipe.transform(labourReportData.pre_discount_lr, 2),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CALC_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.mycurpipe.transform(labourReportData.calculated_warranty_lr, 2),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_LWS_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.mycurpipe.transform(labourReportData.cust_stated_warranty_lr, 2),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory
                      ? laborPreAuditReviewStatusObj.latestDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory
                      ? laborPreAuditReviewStatusObj.latestDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.latestDiscountHistory.pre_discount_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.latestDiscountHistory.post_audit_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory !== null &&
                    laborPreAuditReviewStatusObj.latestDiscountHistory.discount_effect !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.latestDiscountHistory.discount_effect,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  colSpan: 1,
                  rowSpan: 1,
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PRE_AUDIT,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                  margin: [0, 0, 0, 0],
                },
                {
                  colSpan: 1,
                  rowSpan: 1,
                  text: this.mycurpipe.transform(labourReportData.pre_audit_retail_lr, 2),
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                  margin: [0, 0, 0, 0],
                },
              ],
              [
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  columns: [
                    {
                      text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_DOOR_RATE,
                      alignment: "left",
                      style: "styleBold",
                    },
                    {
                      text: this.mycurpipe.transform(parentData.doorRate, 2),
                      alignment: "right",
                    },
                  ],
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory !== null
                      ? laborPreAuditReviewStatusObj.secondDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory !== null
                      ? laborPreAuditReviewStatusObj.secondDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.secondDiscountHistory.pre_discount_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.secondDiscountHistory.post_audit_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory !== null &&
                    laborPreAuditReviewStatusObj.secondDiscountHistory.discount_effect !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.secondDiscountHistory.discount_effect,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.PREAUDIT_DISCOUNT_MEMO,
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: labourReportData
                    ? this.isExistDmsDiscountEffect(
                        parentData.linkLaborProjectObj.dms_code,
                        labourReportData.pre_audit_discount_effect
                      )
                    : "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                  color: "black",
                },
              ],

              [
                {
                  colSpan: 2,
                  rowSpan: 1,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory !== null
                      ? laborPreAuditReviewStatusObj.thirdDiscountHistory.round
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory !== null
                      ? laborPreAuditReviewStatusObj.thirdDiscountHistory.project_year
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.thirdDiscountHistory.pre_discount_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.thirdDiscountHistory.post_audit_rate,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },
                {
                  text:
                    laborPreAuditReviewStatusObj &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory !== null &&
                    laborPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect !== null
                      ? this.mycurpipe.transform(
                          laborPreAuditReviewStatusObj.thirdDiscountHistory.discount_effect,
                          2
                        )
                      : "",
                  alignment: "center",
                  border: [true, false, true, true],
                  fontSize: 10,
                  color: "black",
                },

                {
                  colSpan: 2,
                  text: "",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
              [
                {
                  colSpan: 2,
                  rowSpan: 1,
                  text:
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_CUST_STATED_RATE +
                    this.mycurpipe.transform(labourReportData.cust_stated_warranty_lr, 2),
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 5,
                  rowSpan: 1,
                  text:
                    self.preauditReview &&
                    self.preauditReview.rule_data &&
                    self.preauditReview.rule_data.discount_history &&
                    self.preauditReview.rule_data.discount_history.length > 3
                      ? self.preauditReview.rule_data.discount_history.length - 3 + " more exist"
                      : "Discounting History",
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },

                {
                  colSpan: 2,
                  rowSpan: 1,
                  text:
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ADDITIONAL_LR +
                    (parentData.primaryBundleData["lbr_uplift_labor_rate"]
                      ? this.mycurpipe.transform(
                          parentData.primaryBundleData["lbr_uplift_labor_rate"].toFixed(2)
                        )
                      : "NA"),
                  style: "styleBold",
                  alignment: "center",
                  border: [true, false, true, true],
                  fillColor: "#FFFFE0",
                },
                {
                  text: "",
                  style: "styleBold",
                  alignment: "left",
                  border: [true, false, true, true],
                  fontSize: 10,
                  fillColor: "#FFFFE0",
                },
              ],
            ],
          },
        },
        {
          style: "impactReportTable",
          table: {
            heights: [25],
            widths: ["*", "*", "*", "*", "*", "*", "*"],
            border: [false, false, false, true],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PERIOD,
                  style: "impactReportList",
                  alignment: "right",
                  border: [true, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_HOURS,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_IC,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ID,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SD,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ED,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, false, true],
                },
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_UPLIFT,
                  style: "impactReportList",
                  alignment: "right",
                  border: [false, false, true, true],
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 1;
            },
            vLineWidth: function (i: any, node: any) {
              return 1;
            },
            hLineColor: function (i: any, node: any) {
              return "black";
            },
            vLineColor: function (i: any, node: any) {
              return "black";
            },
          },
        },
        {
          style: "impactReportTableValue",
          table: {
            heights: cellHeights,
            widths: ["*", "*", "*", "*", "*", "*", "*"],
            body: loopData,
          },
          layout: {},
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 10, 180, "*"],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_ANALIZED_UPLIFT,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: labourReportData.annual_uplift
                    ? this.mycurpipe.transform(labourReportData.annual_uplift, 0)
                    : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  colSpan: 1,
                  rowSpan: 4,
                  text:
                    this.DMConstantService.IMPACT_REPORT_LABEL.LBL_RETAIL_SUMMARY_BOX +
                    this.getOverRideLabel(labourReportData, false, ""),
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_SUBMISSION_FEE,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: labourReportData.submission_fee
                    ? this.mycurpipe.transform(labourReportData.submission_fee, 0)
                    : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                "",
              ],

              [
                {
                  text: !labourReportData.isLaborMaintenance
                    ? this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MONTHLY_RECURRING_FEE
                    : this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MAINTENANCE_FEE,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: !labourReportData.isLaborMaintenance
                    ? labourReportData.recurring_fee
                      ? this.mycurpipe.transform(labourReportData.recurring_fee, 0)
                      : ""
                    : labourReportData.maintenance_fee
                      ? this.mycurpipe.transform(labourReportData.maintenance_fee, 0)
                      : "",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                "",
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_PAYBACK_PERIOD,
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [true, false, false, true],
                },
                {
                  text: ":",
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                {
                  text: this.commonService.get2DecimalValue(labourReportData.payback_period),
                  style: "topHeaderCellYellow",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                "",
              ],
            ],
          },
        },
        {
          style: "table2",
          color: "#444",
          table: {
            widths: [150, 10, 180, 400, "*"],
            body: [
              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MEGE_SCE_NAME,
                  style: "section3",
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {
                  text: ":",
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: mageImpactReportData.scenario_name,
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  // colSpan: 1, rowSpan: 2, text: this.constantService.SITE_COPYRIGHT_MESSAGE, style: 'section3_1', alignment: 'left', border: [true, false, true, true], margin: [0, 0, 0, 0]
                  colSpan: 1,
                  rowSpan: 2,
                  text: this.constantService.SITE_COPYRIGHT_MESSAGE,
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
                {
                  colSpan: 1,
                  rowSpan: 2,
                  text: "Data Date: " + newDate,
                  style: "section3_1",
                  alignment: "left",
                  border: [true, false, true, true],
                  margin: [0, 0, 0, 0],
                },
              ],

              [
                {
                  text: this.DMConstantService.IMPACT_REPORT_LABEL.LBL_MEGE_SCE_KEY,
                  style: "section3",
                  alignment: "left",
                  border: [true, false, false, true],
                },
                {
                  text: ":",
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                {
                  text: parentData.linkLaborProjectObj.scenariokey,
                  style: "section3",
                  alignment: "left",
                  border: [false, false, false, true],
                },
                "",
                "",
              ],
            ],
          },
        },
      ],
      styles: {
        reportHeader: {
          fontSize: 14,
          bold: true,
          alignment: "left",
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          color: "black",
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
          color: "black",
        },
        table1: {
          margin: [10, 10, 0, 0],
          fontSize: 12,
          color: "black",
        },
        table2: {
          margin: [10, 0, 0, 0],
          fontSize: 12,
          border: [true, false, true, true],
          color: "black",
        },
        topHeaderCell2: {
          fontSize: 16,
          bold: true,
          alignment: "center",
          color: "black",
        },
        topHeaderCell2_1: {
          fontSize: 12,
          bold: true,
          alignment: "center",
          color: "black",
        },
        topHeaderCell: {
          alignment: "left",
          fontSize: 10,
          color: "black",
        },
        topHeaderCellYellow: {
          alignment: "left",
          fontSize: 10,
          bold: true,
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        topHeaderCellYellow_1: {
          fontSize: 10,
          bold: true,
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        section3: {
          alignment: "left",
          fontSize: 10,
          border: [true, false, true, true],
          color: "black",
        },
        section3_1: {
          alignment: "center",
          fontSize: 10,
          border: [true, false, true, true],
          color: "black",
        },
        styleBold: {
          bold: true,
          color: "black",
        },
        impactReportTable: {
          margin: [10, 0, 0, 0],
          fontSize: 12,
          color: "black",
        },
        impactReportTableValue: {
          margin: [10, 0, 0, 0],
          fontSize: 10,
          color: "black",
        },
        hederSectionOrange_1: {
          fillColor: this.DMConstantService.IMPACT_TDBG_WHITE,
          fontSize: 10,
          margin: [0, 4, 0, 0],
          color: "black",
        },
        hederSectionOrange_2: {
          fillColor: this.DMConstantService.IMPACT_TDBG_WHITE,
          fontSize: 10,
          margin: [0, 4, 0, 0],
          color: "black",
        },
        region3Margin: {
          margin: [0, 10, 0, 10],
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        region2Margin: {
          margin: [0, 20, 0, 0],
          fillColor: this.DMConstantService.IMPACT_TDBG_HIGHLIGHT,
          color: "black",
        },
        impactReportList: {
          bold: true,
          margin: [0, 10, 0, 0],
          color: "black",
        },
      },
    };
    let pdfObject: any = inActivePostAuditProjectionReport;
    if (laborPreAuditReviewStatusObj && laborPreAuditReviewStatusObj.hasActivePostAudit === false) {
      pdfObject = inActivePostAuditProjectionReport;
    } else {
      pdfObject = activePostAuditProjectionReport;
    }
    if (action === "upload") {
      if (parentData.linkLaborProjectObj && parentData.linkLaborProjectObj.id) {
        let self = this;
        pdfMake.createPdf(pdfObject).getBase64(function (encodedString) {
          self.commonService.fileUploadToSolve(
            encodedString,
            fileName_ws + ".pdf",
            parentData.linkLaborProjectObj.id,
            (data: any) => {
              parentData.setLocalStorageFileCount("-1", "labor");
              if (data && data["fileUpload"].status === "success") {
                parentData.setLocalSolveFileRefs("labor", reportName, data["fileUpload"].id, false);
              }
              if (data && data["fileUpload"] && parseInt(localStorage.getItem("uploadLaborFileCount")!) === 0) {
                parentData.setFileReferenceData("labor", reportName, data["fileUpload"].id);
              }
            }
          );
        });
      }
    } else if (action === "uploadPS") {
      if (parentData.linkLaborProjectObj && parentData.linkLaborProjectObj.id) {
        let self = this;
        pdfMake.createPdf(pdfObject).getBase64(function (encodedString) {
          const fileName = parentData.getDownloadProjectName(fileName_ws, "Labor UL");
          self.commonService.fileUploadToSolve(
            encodedString,
            fileName + ".pdf",
            parentData.linkLaborProjectObj.id,
            (data: any) => {
              parentData.setLocalStorageFileCount("-1", "labor");
              if (data && data["fileUpload"] && data["fileUpload"].status === "success") {
                parentData.setLocalSolveFileRefs("labor", reportName, data["fileUpload"].id, false);
              }
              if (data && data["fileUpload"] && parseInt(localStorage.getItem("uploadLaborFileCount")!) === 0) {
                parentData.setFileReferenceData("labor", reportName, data["fileUpload"].id);
              }
            }
          );
        });
      }
    } else if (action === "attachment") {
      let doc = pdfMake.createPdf(pdfObject);
      const pdfName = fileName_ws + ".pdf";
      // doc.getBlob((blob) => {
      //     blob = new Blob([blob]);
      //     callback({
      //       fileName:pdfName,
      //       file:blob
      //     });
      // });

      pdfMake.createPdf(pdfObject).getBase64(function (encodedString) {
        // console.log('getBase64 pdf====>>>>>>>>>>>>',encodedString);
        const base64AttachmentString = encodedString;
        callback({
          filename: pdfName,
          path: base64AttachmentString,
        });
      });
    } else {
      let fileName = parentData.getDownloadProjectName(fileName_ws, "Labor UL");
      fileName = impactFileName ? impactFileName : fileName;
      pdfMake.createPdf(pdfObject).download(fileName + ".pdf");
    }
  };

  transformDateMMDDYYYY(date: any) {
    let newDate = "";
    if (date && date !== null && date !== "") {
      const d = moment(date);
      newDate = d.tz(moment.tz.guess()).format("MM-DD-YYYY");
    }
    return newDate;
  }
  numberDecimalFormatter(value: any, fixedto: any) {
    var returnValue = "";
    if (!fixedto || fixedto == "" || isNaN(value)) {
      fixedto = 0;
    }
    if (value != "" && value != null) {
      if (!isNaN(value)) {
        returnValue = value.toFixed(fixedto);
      }
    }
    return returnValue;
  }

  getOverRideLabel(labourReportData: any, type: any, bType: any) {
    // console.log("labourReportData", labourReportData);
    let retMessage = "";
    if (type) {
      if (bType == "basisHour") {
        if (labourReportData.has_hours_override) {
          retMessage = "(O): ";
        } else {
          retMessage = ": ";
        }
      } else if (bType == "basisRate") {
        if (labourReportData.has_lr_override) {
          retMessage = "(O): ";
        } else {
          retMessage = ": ";
        }
      }
    } else {
      if (labourReportData.is_basis_overridden) {
        retMessage = " " + labourReportData.override_comment;
      }
    }
    return retMessage;
    // console.log(labourReportData.is_basis_overridden);
    // console.log(labourReportData.override_comment );
  }

  isExistDmsDiscountEffect(dms: any, discount_effect: any) {
    const dmsList = [
      "ucs",
      "pbs",
      "mpk",
      "quorum",
      "kengarff",
      "adv",
      "advantage",
      "dpc",
      "tekion",
    ];
    // console.log('dms====>>>',dms);
    if (dms && dmsList.indexOf(dms.toLowerCase().trim()) >= 0) {
      return "Predictive Discouting N/A";
    } else if (discount_effect) {
      return this.mycurpipe.transform(discount_effect, 2);
    } else {
      return "$ 0";
    }
  }
  markupPercentage(value: any) {
    return value !== null ? (value * 100).toFixed(2) + " %" : null;
  }
}
