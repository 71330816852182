import { Directive, HostListener, ElementRef, OnInit, Input } from "@angular/core";
import { RatePerhourPipe } from "./rate-perhour.pipe";

@Directive({
  selector: "[ratePerhourFormatter]",
  host: {
    "[style.text-align]": '"right"',
  },
})
export class RatePerhourFormatterDirective implements OnInit {
  @Input("integerOnly") integerOnly!: boolean;
  private el: any;

  constructor(
    private elementRef: ElementRef,
    private ratePerhourPipe: RatePerhourPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    if (this.integerOnly) {
      this.el.value = this.ratePerhourPipe.transform(this.el.value, 0);
    } else {
      this.el.value = this.ratePerhourPipe.transform(this.el.value);
    }
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value: any) {
    if (this.integerOnly) {
      this.el.value = this.ratePerhourPipe.parse(value, 0); // opossite of transform
    } else {
      this.el.value = this.ratePerhourPipe.parse(value); // opossite of transform
    }
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value: any) {
    if (this.integerOnly) {
      this.el.value = this.ratePerhourPipe.transform(value, 0);
    } else {
      this.el.value = this.ratePerhourPipe.transform(value);
    }
  }
}
