import { Directive, ElementRef, HostListener, Input } from "@angular/core";
@Directive({
  selector: "[appOnlyNumber]",
  host: {
    "[style.text-align]": '"right"',
  },
})
export class OnlyNumberDirective {
  @Input() appOnlyNumber!: string;
  @Input() allowNegativeNumber!: string;
  @Input("validMonth") validMonth!: string;
  @Input("lessThanTen") lessThanTen!: string;
  DIGITS_REGEXP = new RegExp(/\D/g);
  constructor(private el: ElementRef) {
    // Sanatize clipboard by removing any non-numeric input after pasting
    this.el.nativeElement.onpaste = (e: any) => {
      e.preventDefault();
      let text;
      let clp = (e.originalEvent || e).clipboardData;
      if (this.validMonth) {
        if (clp === undefined || clp === null) {
          text = (<any>window).clipboardData.getData("text") || "";
          text = text.replace(this.DIGITS_REGEXP, "").substring(0, 2);
          if (Number(text) > 12) {
            text = text.substring(0, 1);
          } else {
            text = "";
          }
          if (text !== "") {
            if (window.getSelection) {
              const newNode = document.createElement("span");
              newNode.innerHTML = text;
              window.getSelection()?.getRangeAt(0).insertNode(newNode);
            } else {
              (<any>window).selection.createRange().pasteHTML(text);
            }
          }
        } else {
          text = clp.getData("text/plain") || "";
          text = text.replace(this.DIGITS_REGEXP, "").substring(0, 2);
          if (Number(text) > 12) {
            text = text.substring(0, 1);
          } else {
            text = "";
          }
          if (text !== "") {
            document.execCommand("insertText", false, text);
          }
        }
      } else if (this.lessThanTen) {
        const re = /^(([1-9]{1,1})|(([1-9]{1,1})\.([0-9]{1,4})))$/;
        if (clp === undefined || clp === null) {
          text = (<any>window).clipboardData.getData("text") || "";
          text = text.replace(/[^^0-9.]+/g, "");
          if (text !== "" && text.match(re)) {
            if (window.getSelection) {
              const newNode = document.createElement("span");
              newNode.innerHTML = text;
              window.getSelection()?.getRangeAt(0).insertNode(newNode);
            } else {
              (<any>window).selection.createRange().pasteHTML(text);
            }
          }
        } else {
          text = clp.getData("text/plain") || "";
          text = text.replace(/[^^0-9.]+/g, "");
          if (text !== "" && text.match(re)) {
            document.execCommand("insertText", false, text);
          }
        }
      } else if (this.appOnlyNumber === "true") {
        if (clp === undefined || clp === null) {
          text = (<any>window).clipboardData.getData("text") || "";
          text = text.split(".")[0];
          if (text !== "") {
            if (this.allowNegativeNumber === "true") {
              text = text.replace(/[^^[-]0-9.]+/g, "");
            } else {
              text = text.replace(/[^^0-9.]+/g, "");
            }
            if (window.getSelection) {
              const newNode = document.createElement("span");
              newNode.innerHTML = text;
              window.getSelection()?.getRangeAt(0).insertNode(newNode);
            } else {
              (<any>window).selection.createRange().pasteHTML(text);
            }
          }
        } else {
          text = clp.getData("text/plain") || "";
          text = text.split(".")[0];
          if (text !== "") {
            text = text.replace(/[^^0-9.]+/g, "");
            document.execCommand("insertText", false, text);
          }
        }
      } else {
        const re = /^^-?(([0-9]*)|(([0-9]*)\.([0-9]*)))$/;
        if (clp === undefined || clp === null) {
          text = (<any>window).clipboardData.getData("text") || "";
          if (this.allowNegativeNumber === "true") {
            text = text.replace(/[^^[-]0-9.]+/g, "");
          } else {
            text = text.replace(/[^^0-9.]+/g, "");
          }
          if (text !== "" && text.match(re)) {
            if (window.getSelection) {
              const newNode = document.createElement("span");
              newNode.innerHTML = text;
              window.getSelection()?.getRangeAt(0).insertNode(newNode);
            } else {
              (<any>window).selection.createRange().pasteHTML(text);
            }
          }
        } else {
          text = clp.getData("text/plain") || "";
          if (this.allowNegativeNumber === "true") {
            text = text.replace(/[^^[-]0-9.]+/g, "");
          } else {
            text = text.replace(/[^^0-9.]+/g, "");
          }
          if (text !== "" && text.match(re)) {
            document.execCommand("insertText", false, text);
          }
        }
      }
    };
  }

  @HostListener("keydown", ["$event"]) onKeyDown(event: any) {
    let e: any = <KeyboardEvent>event;
    if (this.validMonth) {
      const aary = [46, 8, 9, 27, 13];
      if (
        aary.indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if (this.allowValidMonthNumber(e.target["value"], e.keyCode, e.shiftKey)) {
        e.preventDefault();
      }
    } else if (this.lessThanTen) {
      const aary = [46, 8, 9, 27, 13];
      if (
        aary.indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if (this.allowLessThanTenNumber(e.target["value"], e.keyCode, e.shiftKey)) {
        e.preventDefault();
      }
    } else {
      let aary = [];
      if (this.appOnlyNumber === "true") {
        aary = [46, 8, 9, 27, 13];
        if (this.allowNegativeNumber === "true") {
          if (!e.target["value"].includes("-")) {
            aary.push(109);
            aary.push(189);
          }
        }
      } else {
        aary = [46, 8, 9, 27, 13, 110, 190];
        if (!e.target["value"]) {
          if (e.keyCode === 110 || e.keyCode === 190) {
            e.target["value"] = 0;
          }
        }
        if (e.target["value"].includes(".")) {
          aary = [46, 8, 9, 27, 13];
        }
        if (this.allowNegativeNumber === "true") {
          if (!e.target["value"].includes("-")) {
            aary.push(109);
            aary.push(189);
          }
        }
      }

      if (
        aary.indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    }
  }

  allowValidMonthNumber(value: any, keyCode: any, shiftKey: any) {
    if (!value) {
      if ((shiftKey || keyCode < 49 || keyCode > 57) && (keyCode < 97 || keyCode > 105)) {
        return true;
      }
    } else if (value.length === 1) {
      if (value === "1") {
        if ((shiftKey || keyCode < 48 || keyCode > 50) && (keyCode < 96 || keyCode > 98)) {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  allowLessThanTenNumber(value: any, keyCode: any, shiftKey: any) {
    if (!value) {
      if ((shiftKey || keyCode < 49 || keyCode > 57) && (keyCode < 97 || keyCode > 105)) {
        return true;
      }
    } else if (value.length === 1) {
      if ((shiftKey || keyCode < 110 || keyCode > 110) && (keyCode < 190 || keyCode > 190)) {
        return true;
      }
    } else if (value.length > 1) {
      if (value.includes(".")) {
        const v = value.split(".");
        if (v[1].length < 4) {
          if ((shiftKey || keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105)) {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }
}
