import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";
import { MyPercentagePipe } from "./my-percentage.pipe";

@Directive({
  selector: "[myPercentageFormatter]",
  host: {
    "[style.text-align]": '"right"',
  },
})
export class MyPercentageFormatterDirective implements OnInit {
  private el: any;

  constructor(
    private elementRef: ElementRef,
    private percentagePipe: MyPercentagePipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.percentagePipe.transform(this.el.value);
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value: any) {
    this.el.value = this.percentagePipe.parse(value); // opossite of transform
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value: any) {
    this.el.value = this.percentagePipe.transform(value);
  }
}
