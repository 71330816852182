import { Injectable } from "@angular/core";

@Injectable()
export class DMConstantService {
  EXCEL_INTERNAL_PSSHEADING_LABOR: string;
  EXCEL_INTERNAL_PSSHEADING_SUB1_LABOR: string;
  EXCEL_INTERNAL_PSSHEADING_SUB2_LABOR: string;
  EXCEL_INTERNAL_PSSHEADING_SUB3_LABOR: string;
  EXCEL_INTERNAL_PSSHEADING_SUB4_LABOR: string;
  PROJECT_NAME_MAX_LENGTH: number;
  PROJECT_NAME_ALREADY_EXIST: string;
  TEST_DATA_TAG_LABEL: string;
  ADD_TEST_DATA_FLAG_CONFIRM_MESSAGE: string;
  DM_REPORT_FILTER_KEY: string;
  PORTAL_TAG_STORE: string;
  PORTAL_TAG_STORE_GROUP: string;
  PORTAL_TAG_SOLO_STORE: string;
  PORTAL_TAG_PROJECT: string;
  SAVE_COMMENT_ERROR: string;
  DEFAULT_COMMENT_MESSAGE: string;
  DEFAULT_PROJECT_NAME_PARTS_PREFIX: string;
  DEFAULT_PROJECT_NAME_LABOR_PREFIX: string;
  OUT_OF_SYNC_LABEL: string;
  PRE_AUDIT_LABOR_REPORT_LABELS: any = {};
  SOLVE360_MAPPING_FIELDS: any = {};
  SYNC_WITH_SOLVE360: string;
  PROJECTION_REPORT_PDF_LABELS: any = {};
  PROJECT_NAME_EXCEED_MAX_LENGTH: string;
  CREATE_GROUP_MODAL_TITLE: string;
  UPDATE_GROUP_MODAL_TITLE: string;
  CREATE_SOLO_STORE_MODAL_TITLE: string;
  UPDATE_SOLO_STORE_MODAL_TITLE: string;
  STORE_NAME_ALREADY_EXIST: string;
  GROUP_NAME_ALREADY_EXIST: string;
  SOLO_STORE_NAME_ALREADY_EXIST: string;
  CREATE_STORE_MODAL_TITLE: string;
  UPDATE_STORE_MODAL_TITLE: string;
  BUTTON_LABEL_UPDATE: string;
  UPDATE_STORE_SUCCESS_MESSAGE: string;
  UPDATE_GROUP_SUCCESS_MESSAGE: string;
  UPDATE_SOLO_STORE_SUCCESS_MESSAGE: string;
  COMPANY_ALREADY_EXIST: string;
  PROJECTION_REPORT_V2: any = {};
  REPORT_VERSION_LABEL: string;
  REPORT_VERSION_FORMAT: string;
  PROJECTION_REPORT_V2_ROW_HEADING: any = {};
  PARTS_PSS_REPORT_V2: any = {};
  LABOR_PSS_REPORT_V2: any = {};
  SECONDARY_PROJECT_RESOLVE_LABEL: any = {};
  RESOLVE_PROJECT_DELAY_COUNTER: number;
  CONVERT_SOLO_STORE_TO_GROUP: string;
  CONFIRM_MESSAGE_SOLO_STORE_TO_GROUP: string;
  CONVERT_SOLO_STORE_TO_GROUP_ERROR: string;
  CONVERT_SOLO_STORE_TO_GROUP_NAME_IS_EXIST: string;
  CONVERT_SUCCESS: string;
  CONVERT_ERROR: string;
  STORE_GROUP_CONVERSION_COUNTER: number;
  CONVERT_GROUP_TO_SOLO_STORE: string;
  CONVERT_GROUP_TO_SOLO_STORE_ERROR: string;
  CONFIRM_MESSAGE_GROUP_TO_SOLO_STORE: string;
  RELATED_STORE_EXIST: string;
  RELATED_STORE_ORPHAN_STORE_EXIST: string;
  RELATED_STORE_ORPHAN_META_GROUP_EXIST: string;
  RELATED_STORE_META_GROUP_EXIST: string;
  RELATED_ORPHAN_META_GROUP_EXIST: string;
  RELATED_ORPHAN_STORE_EXIST: string;
  RELATED_META_GROUP_EXIST: string;
  IMPACT_TDBG_WHITE: string;
  IMPACT_TDBG_HIGHLIGHT: string;
  DEL_OPERATION: any = {};
  SWITCH_PARENT_GROUP_WARNING: string;
  SWITCH_PARENT_GROUP_SUCCESS: string;
  SWITCH_OPERATION_ERROR_FROM_PORTAL: string;
  SWITCH_OPERATION_ERROR_FROM_S360: string;
  CONVERT_META_GROUP_TO_GROUP: any = {};
  CONVERT_GROUP_TO_META_GROUP: any = {};
  CONVERT_SOLO_STORE_TO_STORE: any = {};
  IMPACT_REPORT_LABEL: any = {};
  AUDITREPORT_HEADERS_PARTS: any = {};
  AUDITREPORT_HEADERS_LABOR: any = {};
  AUDITREPORT_HEADERS_PARTS_V2: any = {};
  AUDITREPORT_HEADERS_LABOR_V2: any = {};
  AUDITREPORT_HEADERS_PARTS_V2_MAGE: any = {};
  AUDITREPORT_HEADERS_LABOR_V2_MAGE: any = {};
  AUDITREPORT_HEADERS_PARTS_V2_BETA: any = {};
  AUDITREPORT_HEADERS_LABOR_V2_BETA: any = {};
  NON_QUALIFIED_REPORT_HEADER: any = {};
  AUDITREPORT_TEMPLATE_URL_PARTS: string;
  AUDITREPORT_TEMPLATE_URL_LABOR: string;
  AUDITREPORT_TEMPLATE_URL_CUSTOMER_PARTS: string;
  AUDITREPORT_TEMPLATE_URL_CUSTOMER_LABOR: string;
  AUDITREPORT_TEMPLATE_URL_CUSTOMER_PARTS_QA: string;
  AUDITREPORT_TEMPLATE_URL_CUSTOMER_LABOR_QA: string;
  AUDITREPORT_DATA_SAVED_SUCCESS: string;
  AUDITREPORT_DB_SAVING_FAILED: string;
  AUDITREPORT_NO_EDIT_PERFORMED: string;
  AUDITREPORT_TEMPLATE_URL_PARTS_V2: string;
  AUDITREPORT_TEMPLATE_URL_LABOR_V2: string;
  AUDITREPORT_TEMPLATE_URL_PARTS_V2_MAGE: string;
  AUDITREPORT_TEMPLATE_URL_LABOR_V2_MAGE: string;
  NON_QUALIFIED_REPORT_TEMPLATE_URL: string;
  NON_QUALIFIED_DATA_DOWNLOAD_FILE_NAME: string;
  EXCEL_CREATION_ERROR_MESSAGE: string;
  NOT_VIABLE: string;
  NOT_APPLICABLE: string;
  IS_SECONDARY_RESOLVED: string;
  DLR_SOURCE_LIST: any[] = [];
  FACTORY_FORM_LIST: any[] = [];
  THIRD_PARTY_FORM_LIST: any[] = [];
  RO_PULLED_ASSIGNED_LIST: any[] = [];
  DEFAULT_PROJECT_NOTE: string;
  DEFAULT_DEADLINE_NOTE: string;
  DEFAULT_INTERNAL_NOTE: string;
  DEFAULT_STARTUP_NOTE: string;
  DEALER_LTR_IN_HOUSE: string;
  FORMS_IN_HOUSE: string;
  TPA_RECEIVED: string;
  ENABLE_PROJECTION: string;
  DISABLE_PROJECTION: string;
  REMOVE_SYNC_FIELDS_PARTS: any[] = [];
  REMOVE_SYNC_FIELDS_LABOR: any[] = [];
  DEFAULT_MAINTENANCE_FEE: string;
  LABOR_MAINTENANCE_PROJECT: string;
  LABOR_MAINTENANCE_PROJECT_PARTS: string;
  VERIFIED_REPORT_LABELS: any = {};
  ADD_SOLO_GROUP_TO_PORTAL: any = {};
  ADD_SOLO_STORE_TO_PORTAL: any = {};
  DEFERRED_BECAUSE_SELECT_LIST: any[] = [];
  SOLVE360_REPORT_FILE_KEYS: any = {};
  S360EVENT_DESCRIPTION: string;
  S360SCHEDULED_EMAIL_DESCRIPTION: string;
  USER_ARRAY_TO_NOTIFY_EVENTS: any[] = [];
  USER_ARRAY_TO_NOTIFY_EVENTS_BASED_ON_CONDITION: any[] = [];
  AWS_ALLOWED_FILES: string;
  BRAND_WITH_DIFFERENT_REVIEW_DATE: any[] = [];
  PROJ_REPORT_NO_BEHAVIOR;
  PROJ_REPORT_WITH_BEHAVIOR;
  DEFAULT_STAGE_NOTE: string;
  AUDITREPORT_TEMPLATE_URL_CUSTOMER_PARTS_WITOUT_FORMULA: string;
  AUDITREPORT_TEMPLATE_URL_CUSTOMER_LABOR_WITOUT_FORMULA: string;

  AUDITREPORT_TEMPLATE_URL_CUSTOMER_PARTS_WITOUT_FORMULA_DUAUDIT_PARTS: string;
  AUDITREPORT_TEMPLATE_URL_CUSTOMER_PARTS_WITOUT_FORMULA_DUAUDIT_LABOR: string;
  LABOR_GRID_TEMPLATE_URL: string;

  constructor() {
    this.EXCEL_INTERNAL_PSSHEADING_LABOR = "Project Stage Summary: Warranty Labor";
    this.EXCEL_INTERNAL_PSSHEADING_SUB1_LABOR = "Current";
    this.EXCEL_INTERNAL_PSSHEADING_SUB2_LABOR = "Pre-Discount Optimized Labor Rate";
    this.EXCEL_INTERNAL_PSSHEADING_SUB3_LABOR = "Optimized Post-Discount (Submitted)";
    this.EXCEL_INTERNAL_PSSHEADING_SUB4_LABOR = "Factory Approved Uplift Results";
    this.PROJECT_NAME_MAX_LENGTH = 60;
    this.PROJECT_NAME_ALREADY_EXIST = "The project name already exists";
    this.TEST_DATA_TAG_LABEL = "Add Test Data Tag";
    this.ADD_TEST_DATA_FLAG_CONFIRM_MESSAGE = "Add 'Test Data' tag";
    this.DM_REPORT_FILTER_KEY = "stName";
    this.PORTAL_TAG_STORE = "Store";
    this.PORTAL_TAG_STORE_GROUP = "StoreGroup";
    this.PORTAL_TAG_SOLO_STORE = "SoloStore";
    this.PORTAL_TAG_PROJECT = "Project";
    this.SAVE_COMMENT_ERROR = "Error occurred while Comment Creation";
    this.DEFAULT_COMMENT_MESSAGE = "-- No Comments --";
    this.DEFAULT_PROJECT_NAME_PARTS_PREFIX = ": Parts";
    this.DEFAULT_PROJECT_NAME_LABOR_PREFIX = ": Labor";
    this.OUT_OF_SYNC_LABEL = " fields are out of sync";
    this.PRE_AUDIT_LABOR_REPORT_LABELS = {
      EXCEL_TOP_HEAD: "{{groupName}} - Current Results",
      EXCEL_HEADING:
        "* Pre-Audit Labor Rate projections are based on actual DMS Service & Parts data files from the sample months as allowed by state statute." +
        "Final Rate increase will vary from this projection due to factors such as, but not limited to: unique manufacturer conditions;" +
        "factory protocols; updated Financial Statement Data; and dealer's discount practices.",
      EXCEL_ROW_HEAD1: "Current",
      EXCEL_ROW_HEAD2: "Pre-Audit Optimized Uplift Expectations",
      EXCEL_TOTAL_LABEL: "Pre-Discount Warranty Labor Uplift Projection:",
    };
    this.SYNC_WITH_SOLVE360 = "Portal project values are in sync with Solve360.";
    this.SOLVE360_MAPPING_FIELDS = {
      projectName: "Project Title",
      contractStatus: "Contract Status",
      controlStatute: "Control Statute",
      brand: "Brand",
      projectStartDate: "Project Start Date",
      assignedToId: "Assigned To",
      calculated: "Calculated",
      previousYearFsd: "Prev.Yr. FSD",
      ytdFsd: "YTD FSD",
      startUpNotes: "Start Up Notes",
      previous12MonthsFsd: "Prev 12 Mo. FSD",
      adjustedOrBillingCogs: "Adj. or Billing COGS",
      customerStatedWmuType: "Cust Stated WMU Type",
      customerStatedWmu: "Cust. Stated WMU",
      currentCalculatedWmu: "Current Calc. WMU",
      fsdEffectiveWmu: "FSD Eff. WMU",
      markupBillingBasis: "Markup Billing Basis",
      normalizedWmu: "Normalized WMU",
      preAuditMu: "Pre-Audit MU",
      postDiscountWmu: "Post-Disc. WMU",
      approvedWmu: "Approved WMU",
      preDiscountWmu: "Pre-Disc. WMU",
      billingBasisCogs: "Billing Basis COGS",
      costCalculatedHrsPerMonth: "Calculated Hrs/Mo",
      previousYearHrsPerMonth: "Fr.Cust.-Prev.Yr.Hrs/Mo",
      ytdHrsPerMonth: "Fr.Cust.-YTD Hrs/Mo",
      prev12MonthsHrsPerMonth: "Fr.Cust.-Prev. 12 Mos. Hrs/Mo",
      currentWarrantyLr: "Fr Cust - Curr Warr LR",
      currentObservedWarrantyLr: "Curr Obsrvd Warr LR",
      preAuditLr: "Pre-Audit LR",
      preDiscountLr: "Pre-Discount LR",
      postDiscountLr: "Post-Discount LR",
      approvedLr: "Approved LR",
      billingBasisLaborHrs: "Billing Basis Labor Hrs.",
      laborDoorRate: "Door Rate",
      shippingDeadline: "Shipping Deadline",
      shippedOn: "Pkg. Shipped",
      approvalNotifyOn: "Factory Approval Date",
      approvalEffectiveOn: "Factory Eff Date",
      billedSubmissionFee: "Submit Fee Amount",
      billedRecurringTerm: "UL Fee Term",
      approvedMonthlyUplift: "Apprvd Cust. UL / Mo.",
      billedRecurringFee: "Monthly UL Fee",
      retractedOn: "Primary Retracted on",
      fsdCpMu: "FSD CP MU",
      bestMarkup: "Best Markup",
      normalizedFsdProject: "Normalized FSD Proj.",
      preAuditPul: "Pre-Audit PUL",
      preDiscountPul: "Pre-Discount PUL",
      postDiscountPul: "Post-Discount PUL",
      approvedPul: "Approved PUL",
      partsImpactAsOf: "Parts Impact as of",
      additionalMuPoints: "Additional MU Points",
      partsRODateRange: "Parts RO Date Range",
      partsRONumericRange: "Parts RO Numeric Range",
      factoryInitialResponseOn: "Factory Initial Response on",
      upliftFeePercentage: "UL Fee %",
      laborHoursBillingBasis: "Labor Hours Billing Basis",
      bestLrCalculatedProtocol: "Best LR Calc Protocol",
      bestLr: "Best LR",
      preAuditLul: "Pre-Audit LUL",
      preDiscountLul: "Pre-Discount LUL",
      postDiscountLul: "Post-Discount LUL",
      approvedLul: "Approved LUL",
      statutoryPreAuditLR: "Statutory Pre-Audit LR",
      factoryPreAuditLR: "Factory Pre-Audit LR",
      additionalLR: "Additional LR",
      laborImpactAsOf: "Labor Impact as of",
      laborRODateRange: "Labor RO Date Range",
      laborRONumericRange: "Labor RO Numeric Range",
      addInactiveStatus: "InactiveStatus",
    };
    this.PROJECT_NAME_EXCEED_MAX_LENGTH = "Project name exceeds maximum length";
    this.PROJECTION_REPORT_PDF_LABELS = {
      COLUMN_1: "Store DBA Name",
      COLUMN_2: "Brand",
      COLUMN_3: "State",
      COLUMN_4: "Annual Warranty Parts Cost of Goods Sold",
      COLUMN_5: "Cust. Pay Parts Gross Profit %",
      COLUMN_6: "Effective Warranty Parts Gross Profit %",
      COLUMN_7: "Cust.Pay Gross Profit % as Markup",
      COLUMN_8: "Effective Warranty Parts Gross Profit % as Markup",
      COLUMN_9: "Cust. Pay vs. Warranty Markup Delta",
      COLUMN_10: "Additional Annual Warranty Parts Profit",
      COLUMN_11: "State Statute & Armatus Impact Factor",
      COLUMN_12: "Proj ected Warranty Parts Markup",
      COLUMN_13: "Armatus Like Branded Approval Avg.",
      COLUMN_14: "Add'l Points Above Current Eff. Warranty Markup",
      COLUMN_15: "Optimized Additional Ongoing Annual Warranty Parts Profit",
      COLUMN_16: "Current Likely Factory Behavior Impact",
      COLUMN_17: "Add'l Points Above Current Eff. Warr MU - Post Factory Behavior",
      COLUMN_18: "Ongoing Warranty Parts Markup Adjusted for Factory Behavior",
      COLUMN_19: "Additional Ongoing Annual Warranty Parts Profit Adjusted for Factory Behavior",
      COLUMN_20: "Post Approval Submission Fee*",
      COLUMN_21: "Monthly Uplift Fee - 12  Months Only",
      COLUMN_22: "Total Profit Acquisition Cost",
      COLUMN_23: "Net Warranty Parts Additional Profit Uplift Year 1",
      COLUMN_24: "Payback Period - ALL FEES (months)",
      SUB_HEAD_COLUMN2: "*Unadjusted Uplift: CP v. WP MU Delta",
      FOOTER_SECTION_COLUMN_1: "Unadjusted Apparent Uplift",
      FOOTER_SECTION_COLUMN_2: "Armatus Optimized Uplift",
      FOOTER_SECTION_COLUMN_3: "Factory Adjusted Uplift",
      FOOTER_SECTION_COLUMN_4: "Net Uplift Year 1",
      FOOTER_SECTION_COLUMN_5: "Payback Period",
      FOOTER_SECTION_COLUMN_6: "Expectations Based on Data Provided",
    };
    this.PROJ_REPORT_NO_BEHAVIOR = {
      CLM1: "Annual Warranty Parts Cost of Goods Sold",
      CLM2: "Customer Pay Gross Profit %",
      CLM3: "Effective Warranty Gross Profit %",
      CLM4: "Customer Pay Gross Profit % as Markup",
      CLM5: "Effective Warranty Gross Profit % as Markup",
      // CLM6: 'Statutory & Armatus Impact Factor (Markup Points)',
      CLM6: "Additional Markup Points",
      CLM7: "Projected Markup",
      CLM8: "Monthly Uplift",
      CLM9: "Annual Uplift",
      CLM10: "Post Approval Submission Fee*",
      CLM11: "Monthly Uplift Fee - 12 Months",
      CLM12: "Total Profit Acquisition Cost",
      CLM13: "Payback Period - ALL FEES (months)",
    };

    this.PROJ_REPORT_WITH_BEHAVIOR = {
      CLM1: "Annual Warranty Parts Cost of Goods Sold",
      CLM2: "Customer Pay Gross Profit %",
      CLM3: "Effective Warranty Gross Profit %",
      CLM4: "Customer Pay Gross Profit % as Markup",
      CLM5: "Effective Warranty Gross Profit % as Markup",
      // CLM6: 'Statutory & Armatus Impact Factor (Markup Points)',
      CLM6: "Additional Markup Points",
      CLM7: "Projected Markup",
      CLM8: "Monthly Uplift",
      CLM9: "Annual Uplift",
      CLM10: "Factory Behavior Adjustment (Markup Points)",
      CLM11: "Markup Increase Net of Factory Behavior",
      CLM12: "Net Projected Markup",
      CLM13: "Projected Monthly Profit Uplift",
      CLM14: "Projected Annual Profit Uplift",
      CLM15: "Post Approval Submission Fee*",
      CLM16: "Monthly Uplift Fee - 12 Months",
      CLM17: "Total Profit Acquisition Cost",
      CLM18: "Payback Period - ALL FEES (months)",
    };

    this.CREATE_GROUP_MODAL_TITLE = "Create Group";
    this.UPDATE_GROUP_MODAL_TITLE = "Edit Group";
    this.CREATE_SOLO_STORE_MODAL_TITLE = "Create Solo Store";
    this.UPDATE_SOLO_STORE_MODAL_TITLE = "Edit Solo Store";
    this.CREATE_STORE_MODAL_TITLE = "Create Store";
    this.UPDATE_STORE_MODAL_TITLE = "Edit Store";
    this.BUTTON_LABEL_UPDATE = "Update";
    this.STORE_NAME_ALREADY_EXIST = "The store name already exists";
    this.GROUP_NAME_ALREADY_EXIST = "The group name already exists";
    this.SOLO_STORE_NAME_ALREADY_EXIST = "The solo store name already exists";
    this.COMPANY_ALREADY_EXIST = "The Group/Store name already exists";
    this.UPDATE_STORE_SUCCESS_MESSAGE = "Store Updated Successfully";
    this.UPDATE_GROUP_SUCCESS_MESSAGE = "Group Updated Successfully";
    this.UPDATE_SOLO_STORE_SUCCESS_MESSAGE = "Solo Store Updated Successfully";
    this.PROJECTION_REPORT_V2 = {
      EXCEL_HEADING: "Armatus Warranty Parts Uplift Projection*",
      EXCEL_DESCRIPTION:
        '* IMPORTANT: Projection is based on our database of thousands of like branded dealers with similar strength of state statute.  The projection assumes we have been provided accurate information regarding a store\'s CP pricing (MSRP vs Matrix) and average discounting.  Any expected OEM behavior that may materially impact your submitted numbers has been reflected here. Armatus evaluation of the store DMS data will allow us to determine actual uplift expectations.',
      EXCEL_HEADING1: "Parts Data Provided",
      EXCEL_HEADING2: "Unadjusted Uplift*",
      EXCEL_HEADING3: "Armatus Optimized Uplift*",
      EXCEL_HEADING4: "Profit Acquisition Fee and Payback Period Assuming Approval as Projected",
      FOOTER_SECTION_COLUMN_1: "Expectations Based on Data Provided",
      FOOTER_SECTION_COLUMN_2: "Unadjusted Apparent Uplift",
      FOOTER_SECTION_COLUMN_3: "Armatus Optimized Uplift",
      FOOTER_SECTION_COLUMN_4: "Factory Adjusted Uplift",
      FOOTER_SECTION_COLUMN_5: "Net Uplift Year 1",
      FOOTER_SECTION_COLUMN_6: "Payback Period",
    };
    this.REPORT_VERSION_LABEL = "Version: ";
    this.REPORT_VERSION_FORMAT = "nnn-AAA-nAA-neA";
    this.PROJECTION_REPORT_V2_ROW_HEADING = {
      HEAD_0: "Store",
      HEAD_1: "Brand",
      HEAD_2: "State",
      HEAD_3: " Annual Warranty Parts Cost of Goods Sold",
      HEAD_4: " Customer Pay Gross Profit %",
      HEAD_5: " Effective Warranty Gross Profit %",
      HEAD_6: " Customer Pay Gross Profit % as Markup",
      HEAD_7: " Effective Warranty Gross Profit % as Markup",
      HEAD_8: " Cust. Pay vs. Warranty Markup Points Delta",
      HEAD_9: " Annual Uplift (COGS x  Points Delta)",
      HEAD_10: " State Statute  & Armatus Impact Factor (Markup Points)",
      HEAD_11: " Projected Markup",
      HEAD_12: " Add'l Points Above Current Eff. Warranty Markup",
      HEAD_13: " Store Entitled Annual Uplift",
      HEAD_14: " Factory Behavior and/or Store History Factor (Markup Points)",
      HEAD_15: " Markup Increase Net of Factory Behavior",
      HEAD_16: " Net Projected Markup",
      HEAD_17: " Projected Annual Profit Uplift",
      HEAD_18: " Post Approval Submission Fee*",
      HEAD_19: " Monthly Uplift Fee - 12 Months Only",
      HEAD_20: " Total Profit Acquisition Cost",
      HEAD_21: " Net Profit Uplift Year 1",
      HEAD_22: "Payback Period - ALL FEES (months)",
      HEAD_23: "Monthly Uplift",
      HEAD_24: "Projected Monthly Profit Uplift",
    };
    this.PARTS_PSS_REPORT_V2 = {
      EXCEL_PSSHEADING: "Project Stage Summary",
      EXCEL_DESCRIPTION:
        "IMPORTANT: Preliminary results are subject to change once a full audit is completed to determine the final submitted number.  Submitted numbers do not reflect expected or unexpected OEM behavior.  Any expected behavior that may materially impact your submitted numbers has been covered in advance.",
      HEAD_1: "Data Provided",
      HEAD_2: "Projection From Data Provided",
      HEAD_3: "Profit Acquisition Cost",
      HEAD_4: "Initial 12-Month Impact",
      HEAD_5: "Optimized Preliminary Results From DMS Data",
      HEAD_6: "Profit Acquisition Cost",
      HEAD_7: "Initial 12-Month Impact",
      HEAD_8: "Fully Optimized and Audited Final Results",
      HEAD_9: "Prior to Discount Application",
      HEAD_10: "Final Submission Markup (Discounts Applied)",
      HEAD_11: "Profit Acquisition Cost",
      HEAD_12: "Initial 12-Month Impact",
      HEAD_13: "Factory Approved Results",
      HEAD_14: "Profit Acquisition Cost",
      HEAD_15: "Initial 12-Month Impact",
      HEAD_155: " ",
      SUB_HEAD_1: " Average Monthly Warranty Parts Cost of Goods Sold",
      SUB_HEAD_2: "Current Warranty Parts Markup",
      SUB_HEAD_3: "Optimized Markup",
      SUB_HEAD_4: "Add'l Markup Points Over Current",
      SUB_HEAD_5: "Annual Profit Uplift",
      SUB_HEAD_6: "Post Approval Submission Fee",
      SUB_HEAD_7: "Monthly Uplift Fee",
      SUB_HEAD_8: "Total Uplift Fee",
      SUB_HEAD_9: "Total Project Fee",
      SUB_HEAD_10: "Net Uplift",
      SUB_HEAD_11: "Payback ALL Fees (Months)",
      SUB_HEAD_12: "Best Possible Markup From Identified Optimal Range",
      SUB_HEAD_13: "Add'l Markup Points Over Current",
      SUB_HEAD_14: "Annual Profit Uplift",
      SUB_HEAD_15: "Post Approval Submission Fee",
      SUB_HEAD_16: "Monthly Uplift Fee ",
      SUB_HEAD_17: "Total Uplift Fee",
      SUB_HEAD_18: "Total Project Fee",
      SUB_HEAD_19: "Net Uplift",
      SUB_HEAD_20: "Payback ALL Fees (Months)",
      SUB_HEAD_21: "Markup ",
      SUB_HEAD_22: "Add'l Markup Points Over Current",
      SUB_HEAD_23: "Annual Profit Uplift",
      SUB_HEAD_24: " Markup",
      SUB_HEAD_25: "Add'l Markup Points Over Current",
      SUB_HEAD_26: " Annual Profit Uplift",
      SUB_HEAD_27: "OEM Approval Deadline",
      SUB_HEAD_28: "Post Approval Submission Fee",
      SUB_HEAD_29: "Monthly Uplift Fee ",
      SUB_HEAD_30: "Total Uplift Fee",
      SUB_HEAD_31: "Total Project Fee",
      SUB_HEAD_32: "Net Uplift",
      SUB_HEAD_33: "Payback ALL Fees (Months)",
      SUB_HEAD_34: "Markup",
      SUB_HEAD_35: "Factory Adjustment",
      SUB_HEAD_36: "Markup Points Impact",
      SUB_HEAD_37: "Annual $ Impact",
      SUB_HEAD_38: " Adjusted Additional Monthly Profit",
      SUB_HEAD_39: "Adjusted Additional Annual Profit",
      SUB_HEAD_40: "Post Approval Submission Fee",
      SUB_HEAD_41: "Monthly Uplift Fee",
      SUB_HEAD_42: "Total Uplift Fee",
      SUB_HEAD_43: "Total Project Fee",
      SUB_HEAD_44: "Net Uplift",
      SUB_HEAD_45: "Payback ALL Fees (Months)",
      SUB_HEAD_46: "Monthly Profit Uplift",
      SUB_HEAD_47: "Add'l Points Above Current Eff.Warr. Markup",
      SUB_HEAD_455: "PSS Comment",
      TOTAL_LABEL_1: "Totals:",
      TOTAL_LABEL_2: "Ongoing Annual Uplift",
      TOTAL_LABEL_3: "Initial12-Month Uplift",
      TOTAL_LABEL_4: "Payback Period (Months)",
      TOTAL_LABEL_5: "Ongoing Annual Uplift",
      TOTAL_LABEL_6: "Net Uplift Year 1",
      TOTAL_LABEL_7: "Payback Period (Months)",
      TOTAL_LABEL_8: "Annual Discount Impact",
      TOTAL_LABEL_9: "Ongoing Annual Uplift",
      TOTAL_LABEL_10: "Net Uplift Year 1",
      TOTAL_LABEL_11: "Payback Period (Months)",
      TOTAL_LABEL_12: "Annual Factory Adjustment Impact",
      TOTAL_LABEL_13: "Net Uplift Year 1",
      TOTAL_LABEL_14: "Payback Period (Months)",
    };
    this.LABOR_PSS_REPORT_V2 = {
      HEAD_CHANGE: "Optimized Preliminary Results From DMS Data",
      EXCEL_PSSHEADING: "Project Stage Summary",
      EXCEL_DESCRIPTION:
        "IMPORTANT: Preliminary results are subject to change once a full audit is completed to determine the final submitted number.  Submitted numbers do not reflect expected or unexpected OEM behavior.  Any expected behavior that may materially impact your submitted numbers has been covered in advance.",
      HEAD_1: "Data Provided",
      HEAD_2: "Factory Approved Results",
      HEAD_3: "Optimized Pre-Discount Labor Results",
      HEAD_3_: "Fully Optimized and Audited Final Results",
      // HEAD_4: 'Fully Optimized and Audited Final Labor Results',
      HEAD_4: "Optimized Post-Discount Labor Results",
      HEAD_5: "Factory Approved Results",
      HEAD_6: "Profit Acquisition Cost",
      HEAD_7: "Initial 12-Month Impact",
      HEAD_8: " ",
      SUB_HEAD_1: "Warranty Labor & PDI Hours per Month",
      SUB_HEAD_2: "Warranty Labor Rate",
      SUB_HEAD_3: "Uplift Viable?",
      SUB_HEAD_4: "Best Warranty Labor Rate Achievable from Store Raw Data",
      SUB_HEAD_5: "Additional Hourly Rate",
      SUB_HEAD_6: "Additional Monthly Profit",
      SUB_HEAD_7: "Additional Annual Profit",
      SUB_HEAD_8: "Post Approval Submission Fee",
      SUB_HEAD_9: "Monthly Uplift Fee",
      SUB_HEAD_10: "Total Uplift Fee",
      SUB_HEAD_11: "Total Project Fee",
      SUB_HEAD_12: "Net Uplift",
      SUB_HEAD_13: "Payback ALL Fees (Months)",
      SUB_HEAD_14: "Best Rate Achieved After Analysis of ROs in Chosen Range",
      SUB_HEAD_15: "Increase Over Current Labor Rate",
      SUB_HEAD_15_1: "Additional Monthly Profit",
      SUB_HEAD_16: "Additional Annual Profit ",
      SUB_HEAD_17: "Best Rate Achieved Including Store Discounts & Final Audit",
      SUB_HEAD_18: "Increase Over Current Labor Rate",
      SUB_HEAD_19: "Additional Monthly Profit",
      SUB_HEAD_20: "Expected Additional Annual Profit",
      SUB_HEAD_21: "OEM Approval Deadline",
      SUB_HEAD_22: "Post Approval Submission Fee",
      SUB_HEAD_23: "Monthly Uplift Fee ",
      SUB_HEAD_24: "Total Uplift Fee",
      SUB_HEAD_25: "Total Project Fee (Year 1 Only)",
      SUB_HEAD_26: "Net Profit Uplift Year 1",
      SUB_HEAD_27: "Payback Period (Months)",
      SUB_HEAD_28: "Approved Labor Rate",
      SUB_HEAD_29: "Factory Adjustment",
      SUB_HEAD_30: "Rate Impact",
      SUB_HEAD_31: "Annual Profit Impact",
      SUB_HEAD_32: "Increase Over Current Rate",
      SUB_HEAD_33: "Factory Adjusted Additional Monthly Profit",
      SUB_HEAD_34: "Adjusted Additional Annual Profit",
      SUB_HEAD_35: "Post Approval Submission Fee",
      SUB_HEAD_36: "Monthly Uplift Fee",
      SUB_HEAD_37: "Total Uplift Fee",
      SUB_HEAD_37_1: "Maintenance Fee",
      SUB_HEAD_37_2: "Total Uplift/ Maint. Fee",
      SUB_HEAD_38: "Total Project Fee",
      SUB_HEAD_39: "Net Uplift",
      SUB_HEAD_40: "Payback ALL Fees (Months)",
      SUB_HEAD_41: "PSS Comment",
      TOTAL_LABEL_1: "Totals:",
      TOTAL_LABEL_2: "Initial12-Month Uplift",
      TOTAL_LABEL_3: "Payback Period (Months)",
      TOTAL_LABEL_4: "Net Uplift Year 1",
      TOTAL_LABEL_5: "Payback Period (Months)",
      TOTAL_LABEL_6: "Net Uplift Year 1",
      TOTAL_LABEL_7: "Payback Period (Months)",
      TOTAL_LABEL_8: "Annual Factory Adjustment Impact",
      TOTAL_LABEL_9: "Ongoing Annual Uplift",
      TOTAL_LABEL_10: "Annual Discount Impact",
      TOTAL_LABEL_77: " ",
    };
    this.SECONDARY_PROJECT_RESOLVE_LABEL = {
      STATUS_IN_SALES: "In Sales",
      STATUS_TAKE_TO_POST: "Take to Post!",
      DEFAULT_STATUS: "In Production",
      RESOLVE_FORM_ACTION_SUCCESS: "Project Status Updated Successfully",
      RESOLVE_FORM_ACTION_FAILURE: "Error occurred while updating Project Status",
    };
    this.RESOLVE_PROJECT_DELAY_COUNTER = 4;
    this.CONVERT_SOLO_STORE_TO_GROUP = "Successfully Convert Solo Store To Group";
    this.CONFIRM_MESSAGE_SOLO_STORE_TO_GROUP = "Convert Solo Store to Store Group";
    this.CONVERT_SOLO_STORE_TO_GROUP_ERROR = "Error while converting Solo Store to Group";
    this.CONVERT_SOLO_STORE_TO_GROUP_NAME_IS_EXIST =
      "Related store {{soloStoreName}} exist in Portal";
    this.CONVERT_SUCCESS = "Success";
    this.CONVERT_ERROR = "Failure";
    this.CONVERT_GROUP_TO_SOLO_STORE = "Group To Solo Store converted successfully ";
    this.CONVERT_GROUP_TO_SOLO_STORE_ERROR = "Error while converting Group to Solo Store";
    this.CONFIRM_MESSAGE_GROUP_TO_SOLO_STORE = "Convert Store Group to Solo Store";
    this.RELATED_STORE_EXIST = "Related Store exist in Portal/Solve360";
    this.RELATED_STORE_ORPHAN_STORE_EXIST = "Related Store/Orphan Store exist in Portal/Solve360";
    this.RELATED_STORE_ORPHAN_META_GROUP_EXIST =
      "Related Store/Orphan/Meta Group exist in Portal/Solve360";
    this.RELATED_STORE_META_GROUP_EXIST = "Related Store/Meta Group exist in Portal/Solve360";
    this.RELATED_ORPHAN_META_GROUP_EXIST = "Related Orphan/Meta Group exist in Portal/Solve360";
    this.RELATED_ORPHAN_STORE_EXIST = "Related Orphan Store exist in Solve360";
    this.RELATED_META_GROUP_EXIST = "Related Meta Group exist in Solve360";
    this.STORE_GROUP_CONVERSION_COUNTER = 6;

    //Impact report table data colors.

    this.IMPACT_TDBG_WHITE = "#FFFFFF";
    this.IMPACT_TDBG_HIGHLIGHT = "#FFFFE0";
    this.DEL_OPERATION = {
      DEL_STORE_GROUP_WARNING: "Delete Group from s360/portal",
      DEL_SOLO_STORE_WARNING: "Delete Solo Store from s360/portal",
      DEL_ERROR_FROM_S360: "Error while deleting Store Group from Solve360",
      DEL_ERROR_FROM_PORTAL: "Error while deleting Store Group from portal",
      DEL_SUCCESS_INFO: "Success",
      DEL_SUCCESS_MESSAGE: "Store Group deleted successfully",
      DEL_DM_EXIST_WARNING: "Related DM exist in the Store",
      DEL_ERROR_SOLO_STORE_S360: "Error while deleting Solo Store from Solve360",
      DEL_ERROR_SOLO_STORE_PORTAL: "Error while deleting Solo Store from portal",
      DEL_SUCCESS_MESSAGE_SOLO_STORE: "Solo Store deleted successfully",
      DEL_STORE_WARNING: "Delete Store from s360/portal",
      DEL_STORE_WARNING_PORTAL_ONLY: "Delete Store from portal",
      DEL_ERROR_STORE_PORTAL: "Error while deleting Store from portal",
      DEL_ERROR_STORE_S360: "Error while deleting Store from Solve360",
      DEL_SUCCESS_MESSAGE_STORE: "Store deleted successfully",
      DEL_SUCCESS_MESSAGE_ORPHAN_STORE: "Orphan Store deleted successfully",
      DEL_ORPHAN_STORE_WARNING: "Delete Orphan Store from s360",
      DELAY_COUNTER: 5,
    };
    this.SWITCH_PARENT_GROUP_WARNING = "Switch Parent Group of Store";
    this.SWITCH_PARENT_GROUP_SUCCESS = "Successfully Switched Parent Group of Store";
    this.SWITCH_OPERATION_ERROR_FROM_PORTAL =
      "Error while switching Parent Group Of Store Store from portal";
    this.SWITCH_OPERATION_ERROR_FROM_S360 = "Error while switching Parent Group Of Store from S360";
    this.CONVERT_META_GROUP_TO_GROUP = {
      CONVERT_META_TO_GROUP_WARNING: "Convert Meta Group To Store Group",
      CONVERT_META_TO_GROUP_ERROR_FROM_PORTAL:
        "Error while converting Meta Group to Group from portal",
      CONVERT_META_TO_GROUP_ERROR_FROM_S360: "Error while converting Meta Group to Group from S360",
      CONVERT_META_TO_GROUP_SUCCESS: "Successfully Convert Meta Group To Store Group",
      RELOAD_VIEW_TYPE: "META-GROUP-TO-GROUP",
    };
    this.CONVERT_GROUP_TO_META_GROUP = {
      STORE_EXIST_WARNING: "Related Store exist in Portal/Solve360",
      CONVERT_GROUP_TO_META_GROUP_WARNING: "Convert Group To Meta Group",
      CONVERT_GROUP_TO_META_GROUP_ERROR_FROM_PORTAL:
        "Error while converting Store Group to Meta Group from portal",
      CONVERT_GROUP_TO_META_GROUP_ERROR_FROM_S360:
        "Error while converting Store Group to Meta Group from S360",
      CONVERT_GROUP_TO_META_SUCCESS: "Successfully Convert Store Group To Meta Group",
      RELOAD_VIEW_TYPE: "GROUP-TO-META-GROUP",
    };
    this.CONVERT_SOLO_STORE_TO_STORE = {
      CONVERT_SOLO_STORE_TO_STORE_WARNING: "Convert Solo Store To Store",
      CONVERT_SOLO_STORE_TO_STORE_ERROR_FROM_PORTAL:
        "Error while converting Solo Store to Store from portal",
      CONVERT_SOLO_STORE_TO_STORE_ERROR_FROM_S360:
        "Error while converting Solo Store to Store from S360",
      CONVERT_SOLO_STORE_TO_STORE_SUCCESS: "Successfully Convert Solo Store To Store",
    };
    this.IMPACT_REPORT_LABEL = {
      LBL_PERIOD: "Period",
      LBL_RATE: "Rate",
      LBL_IC: "RO Count",
      LBL_ID: "RO Days	",
      LBL_PT: "Pay Types",
      LBL_SD: "Start Date",
      LBL_ED: "End Date",
      LBL_UPLIFT: "Uplift",
      LBL_SDD: "Shipping Deadline Date: ",
      LBL_SNRT: "State Name & Review Time: ",
      LBL_SGN: "Store Group Name: ",
      LBL_MNF: "Manufacturer: ",
      LBL_SN: "Store Name: ",
      LBL_FIUO: "For Internal Use Only",
      LBL_BBR: "Basis Rate",
      LBL_BBC: "Basis Cost",
      LBL_BBH: "Basis Hours",
      LBL_CU: "Calculated Uplift: ",
      LBL_MU: "Monthly Uplift: ",
      LBL_TBR: "Target Rate: ",
      LBL_TR: "Rate: ",
      LBL_BBMS: "Basis ",
      LBL_FSDB: "FSD Basis",
      LBL_LWSB: "LWS Basis",
      LBL_LWS_HOURS: "LWS Hours",
      LBL_BBMC: "Basis Monthly Cost: ",
      LBL_TBS: "Target Bundle Sale: ",
      LBL_CS: "Calculated Cost",
      LBL_COST: "Cost: ",
      LBL_PS: "Projection Sale: ",
      LBL_LA: "Labor Alternate: ",
      LBL_CSS: "Customer Stated Sale",
      LBL_HEAD_PARTS: "Parts Impact Report",
      LBL_HEAD_LABOR: "Labor Impact Report",
      LBL_CC: "Calculated Cost",
      LBL_FSD_COST: "FSD Cost",
      LBL_TP: "Tranche Plot",
      LBL_PAUDIT: "Post Audit",
      LBL_CALC_RATE: "Calculated Rate",
      LBL_CALC_HOURS: "Calculated Hours",
      LBL_FSD_RATE: "FSD Rate",
      LBL_LWS_RATE: "LWS Rate",
      LBL_DOOR_RATE: "Door Rate",
      LBL_CURR_WLR_EFF_ON: "Curr WLR Eff On",
      LBL_PRE_DISCOUNT: "Pre Discount",
      LBL_CUST_STATED_RATE: "Customer Stated Rate: ",
      LBL_PRE_AUDIT: "Pre Audit",
      LBL_PROJECTED: "Projected",
      LBL_ANALIZED_UPLIFT: "Annualized Uplift",
      LBL_SUBMISSION_FEE: "Submission Fee",
      LBL_MONTHLY_RECURRING_FEE: "Monthly Recurring Fee",
      LBL_PAYBACK_PERIOD: "Payback Period",
      LBL_MEGE_SCE_NAME: "MAGE Scenario Name",
      LBL_MEGE_SCE_KEY: "Scenario Key",
      LBL_FACT_STAT: "Factory/Statutory: ",
      LBL_BASIS_MONTHLY_HOURS: "Basis Monthly Hours: ",
      LBL_PRE_POST_AUDIT: "Pre/Post Audit",
      LBL_RETAIL_SUMMARY_BOX: "Retail Summary Box: ",
      LBL_HOURS: "Hours",
      LBL_PARTS_COST: "Cost",
      LBL_SALES_PERSON: "SP: ",
      LBL_ADDITIONAL_MU: "Additional MU: ",
      LBL_ADDITIONAL_LR: "Additional Labor Rate: ",
      LBL_MAINTENANCE_FEE: "Maintenance Fee",
      CUST_STATED: "Cust.stated",
      ROUND: "Round",
      YEAR: "Year",
      PREDISC: "Pre.Disc",
      POSTDISC: "Post Disc",
      DISCEFFECT: "Disc Effect",
      WARRANTY_RATE: "Warranty Rate: ",
      PRE_AUDIT_RATE_WITHOUT_DE: "PreAudit Rate (Without Disc Effect): ",
      PRE_AUDIT_RATE_WITH_DE: "PreAudit Rate (With Disc Effect): ",
      PRE_AUDIT_RATE: "PreAudit Rate: ",
      WITHOUT_DE: "(Without Disc Effect): ",
      PROJECTED_RATE: "Projected Rate: ",
      CONFIRMER: "Confirmer: ",
      CONFIRMED_ON: "Confirmed On: ",
      COMMENTS: "Comments: ",
      PREAUDIT_REVIEW: "PreAudit Review",
      PREAUDIT_DISCOUNT: "Pre-Audit Discount: ",
      PREAUDIT_DISCOUNT_MEMO: "Pre-Audit Discount (Memo)",
      DMS: "DMS: ",
    };
    this.AUDITREPORT_HEADERS_PARTS = {
      V: "Q#",
      W: "RO Number",
      X: "Q# Label",
      Y: "Part Number",
      Z: "Part Description",
      AA: "Sale Adjustment",
      AB: "Extended Cost",
      AC: "Extended Sale",
      AD: "Extended Profit",
      AE: "Extended Mark-Up",
      AF: "Final Sale",
      AG: "VIN",
      AH: "Comment",
      AI: "Quantity",
      AJ: "Advisor",
      AK: "Pay Type",
      AL: "Core Related",
      AM: "RO Date",
    };
    this.AUDITREPORT_HEADERS_LABOR = {
      Y: "Q#",
      Z: "RO Number",
      K: "Labor Number",
      AA: "Q# Label",
      AB: "Part Number",
      AC: "Part Description",
      AD: "Sale Adjustment",
      AE: "Extended Cost",
      AF: "Extended Sale",
      AG: "Extended Profit",
      AH: "Extended Mark-Up",
      AI: "Final Sale",
      AJ: "VIN",
      AK: "Comment",
      AL: "Quantity",
      AM: "Advisor",
      AN: "Pay Type",
      AO: "Core Related",
      AP: "RO Date",
    };

    this.AUDITREPORT_HEADERS_PARTS_V2 = {
      G: "RO Number",
      H: "Part Number",
      X: "Q# Label",
      Y: "RO Date",
      Z: "Part Number",
      AA: "Part Description",
      AB: "Sale Adjustment",
      AC: "Extended Cost",
      AD: "Extended Sale",
      AE: "Extended Profit",
      AF: "Extended Mark-Up",
      AG: "Final Sale",
      AH: "VIN",
      AI: "Comment",
      AJ: "Quantity",
      AK: "Advisor",
      AL: "Pay Type",
      AM: "Core Related",
      AN: "Mapper Outcome",
    };
    this.AUDITREPORT_HEADERS_LABOR_V2 = {
      J: "RO Number",
      AA: "Q#",
      AC: "Q# Label",
      AD: "Part Number",
      AE: "Part Description",
      AF: "Sale Adjustment",
      AG: "Extended Cost",
      AH: "Extended Sale",
      AI: "Extended Profit",
      AJ: "Extended Mark-Up",
      AK: "Final Sale",
      AL: "VIN",
      AM: "Outcome Reason",
      AN: "Quantity",
      AO: "Advisor",
      AP: "Pay Type",
      AQ: "Core Related",
      AR: "RO Date",
      AS: "Mapper Outcome",
    };

    this.AUDITREPORT_HEADERS_PARTS_V2_MAGE = {
      G: "RO Number",
      W: "Q# Label",
      X: "RO Date",
      Y: "Part Number",
      Z: "Part Description",
      AA: "Sale Adjustment",
      AB: "Extended Cost",
      AC: "Extended Sale",
      AD: "Extended Profit",
      AE: "Extended Mark-Up",
      AF: "Final Sale",
      AG: "VIN",
      AH: "Comment",
      AI: "Quantity",
      AJ: "Advisor",
      AK: "Pay Type",
      AL: "Core Related",
    };
    this.AUDITREPORT_HEADERS_LABOR_V2_MAGE = {
      J: "RO Number",
      AA: "Q#",
      AC: "Q# Label",
      AD: "Part Number",
      AE: "Part Description",
      AF: "Sale Adjustment",
      AG: "Extended Cost",
      AH: "Extended Sale",
      AI: "Extended Profit",
      AJ: "Extended Mark-Up",
      AK: "Final Sale",
      AL: "VIN",
      AM: "Comment",
      AN: "Quantity",
      AO: "Advisor",
      AP: "Pay Type",
      AQ: "Core Related",
      AR: "RO Date",
    };

    this.AUDITREPORT_HEADERS_PARTS_V2_BETA = {
      G: "RO Number",
      H: "Part Number",
      Y: "Q# Label",
      Z: "RO Date",
      AA: "Part Number",
      AB: "Part Description",
      AC: "Sale Adjustment",
      AD: "Extended Cost",
      AE: "Extended Sale",
      AF: "Extended Profit",
      AG: "Final Mark-Up",
      AH: "Final Sale",
      AI: "VIN",
      AJ: "Comment",
      AK: "Quantity",
      AL: "Advisor",
      AM: "Pay Type",
      AN: "Core Related",
      AO: "Mapper Outcome",
      K: "Outcome Reason",
    };
    this.AUDITREPORT_HEADERS_LABOR_V2_BETA = {
      J: "RO Number",
      K: "Labor Number",
      N: "Outcome Reason",
      AB: "Q#",
      AD: "Q# Label",
      AE: "Labor Number",
      AF: "Labor Description",
      AG: "Sale Adjustment",
      AH: "Hours",
      AI: "Sale",
      AJ: "Extended Profit",
      AK: "Final Labor Rate",
      AL: "Final Sale",
      AM: "VIN",
      AN: "Comment",
      AO: "Quantity",
      AP: "Advisor",
      AQ: "Pay Type",
      AR: "Core Related",
      AS: "RO Date",
      AT: "Mapper Outcome",
    };

    this.AUDITREPORT_TEMPLATE_URL_PARTS =
      "/assets/templates/Pre & Post AS_Parts_Old.xlsx? r = " + new Date().getTime();
    this.AUDITREPORT_TEMPLATE_URL_LABOR =
      "/assets/templates/Pre & Post AS_Labor_Old.xlsx? r = " + new Date().getTime();
    this.AUDITREPORT_TEMPLATE_URL_PARTS_V2 =
      "/assets/templates/Pre & Post AS_Parts.xlsx? r = " + new Date().getTime();
    this.AUDITREPORT_TEMPLATE_URL_LABOR_V2 =
      "/assets/templates/Pre & Post AS_Labor.xlsx? r = " + new Date().getTime();
    this.AUDITREPORT_TEMPLATE_URL_PARTS_V2_MAGE =
      "/assets/templates/Pre & Post AS_Parts_MAGE.xlsx? r = " + new Date().getTime();
    this.AUDITREPORT_TEMPLATE_URL_LABOR_V2_MAGE =
      "/assets/templates/Pre & Post AS_Labor_MAGE.xlsx? r = " + new Date().getTime();
    this.NON_QUALIFIED_REPORT_TEMPLATE_URL =
      "/assets/templates/Non-Qualified-Report_Template.xlsx? r = " + new Date().getTime();
    this.NON_QUALIFIED_REPORT_HEADER = {
      A: "RO Number",
      B: "RO Position",
      C: "Base Sale",
      D: "Base Cost",
      E: "Job Specification",
      F: "Job Parts",
      G: "Part Number",
      H: "Part Description",
      I: "Part Detail ID",
      J: "Part Type",
      K: "Quantity",
      L: "Scenario part index",
      M: "Scenario Reason",
      N: "Scenario Excluded",
      O: "Scenario Key",
      P: "Final Excluded",
      Q: "Final Reason",
      R: "Audit Comment",
    };
    this.NON_QUALIFIED_DATA_DOWNLOAD_FILE_NAME = "Non-Qualified-Report.xlsx";
    this.EXCEL_CREATION_ERROR_MESSAGE = "Error while creating document";

    this.NOT_VIABLE = "Not Viable";
    this.NOT_APPLICABLE = "--NA--";
    this.IS_SECONDARY_RESOLVED = "Res";
    this.DLR_SOURCE_LIST = ["Client", "Re-Use", "Client-Final"];
    this.FACTORY_FORM_LIST = [
      "NA",
      "Requested",
      "Complete",
      "Customer Submission",
      "To Be Determined",
    ];
    this.THIRD_PARTY_FORM_LIST = [
      "NA",
      "AutoSoft",
      "Adam",
      "AutoMate",
      "Dominion",
      "Dealerbuilt",
      "Reynolds",
      "Tekion",
      "PBS",
    ];
    this.RO_PULLED_ASSIGNED_LIST = ["Steve Z.", "Pat", "Gil", "Nick", "Client", "Vendor"];
    this.DEFAULT_PROJECT_NOTE = "-- No Project Notes --";
    this.DEFAULT_DEADLINE_NOTE = "-- No Deadline Notes --";
    this.DEFAULT_INTERNAL_NOTE = "-- No Internal Communication --";
    this.DEFAULT_STARTUP_NOTE = "-- No Start-Up Notes --";

    this.DEFAULT_STAGE_NOTE = "-- No Stage Note --";
    this.DEALER_LTR_IN_HOUSE = "Dlr Ltr In-House";
    this.FORMS_IN_HOUSE = "Forms In-House";
    this.TPA_RECEIVED = "Third Party Form Received";
    this.ENABLE_PROJECTION = "Include projection in the Impact Report";
    this.DISABLE_PROJECTION = "Exclude projection in the Impact Report";
    this.REMOVE_SYNC_FIELDS_PARTS = [
      "partsMappedBy",
      "partsMappedOn",
      "finalizedBy",
      "finalizedOn",
      "partsRangeSelectedBy",
      "partsRangeSelectedOn",
      "impactFactorsNotes",
      "commentLettersComplete",
    ];
    this.REMOVE_SYNC_FIELDS_LABOR = [
      "laborMappedBy",
      "laborMappedOn",
      "finalizedBy",
      "finalizedOn",
      "laborRangeSelectedBy",
      "laborRangeSelectedOn",
      "impactFactorsLaborNotes",
      "commentLettersComplete",
    ];
    this.DEFAULT_MAINTENANCE_FEE = "750";
    this.LABOR_MAINTENANCE_PROJECT = "Labor Maintenance Project";
    this.LABOR_MAINTENANCE_PROJECT_PARTS = "Due to active Labor Maintenance Project";
    this.VERIFIED_REPORT_LABELS = {
      partsTitle: "RETAIL REPAIR ORDER PART MARKUP VERIFICATION REPORT",
      laborTitle: "RETAIL REPAIR ORDER LABOR RATE VERIFICATION REPORT",
      repairOrder: "Repair Order",
      netCustomer: "Net Customer",
      markup: "Markup",
      labor: "Labor",
      dealerName: "Dealer Name",
      dealerCode: "Dealer Code",
    };
    this.ADD_SOLO_GROUP_TO_PORTAL = {
      BRAND_STATE_MISSING: "Brand and State are required for adding Group to Portal",
      BRAND_MISSING: "Brand is required for adding Group to Portal",
      STATE_MISSING: "State is required for adding Group to Portal",
    };
    this.ADD_SOLO_STORE_TO_PORTAL = {
      BRAND_STATE_MISSING: " - Brand and State missing",
      BRAND_MISSING: " - Brand missing",
      STATE_MISSING: " - State missing",
      MESSAGE: "Added {{count}} store successfully.",
      FAILURE_MESSAGE: "{{failedCount}} orphan stores failed to add to portal ({{failedMessage}})",
      DEFAULT_FAILURE_MESSAGE: "Failed to add orphan stores to portal ({{failedMessage}})",
    };
    this.DEFERRED_BECAUSE_SELECT_LIST = [
      "Circumstantial Delay",
      "Factory Behavior",
      "Factory Contract",
      "Future Dated Project",
      "Future Opportunity",
      "Non-Responsive",
      "Statute",
    ];
    this.SOLVE360_REPORT_FILE_KEYS = {
      PRE_AUDIT_SUMMARY: "pre_as",
      PRE_IMPACT_REPORT: "pre_ir",
      POST_AUDIT_SUMMARY: "post_as",
      POST_IMPACT_REPORT: "post_ir",
      VERIFICATION_REPORT: "vr_qual",
      VERIFICATION_REPORT_ALL: "vr_all",
    };
    this.S360EVENT_DESCRIPTION =
      "The approval deadline for {{projectTitle}} is on {{deadlineReminderDate}}. Please send your reminder to the client... Thank you!";
    this.S360SCHEDULED_EMAIL_DESCRIPTION =
      "{{projectName}} is approved and ready for billing{{verbalApproval}}{{nonResponsiveCustomer}}";
    // this.USER_ARRAY_TO_NOTIFY_EVENTS = ['219443012', '47926217', '209540232'];
    this.USER_ARRAY_TO_NOTIFY_EVENTS = ["219443012"];
    this.USER_ARRAY_TO_NOTIFY_EVENTS_BASED_ON_CONDITION = ["33793457", "202806287", "218769788"];
    this.AWS_ALLOWED_FILES = "Only .xlsx file allowed";
    this.BRAND_WITH_DIFFERENT_REVIEW_DATE = ["Ford", "FCA / Chrysler"];
    this.AUDITREPORT_TEMPLATE_URL_CUSTOMER_PARTS =
      "/assets/templates/Parts_AS_template__.xlsx?r=" + new Date().getTime();
    this.AUDITREPORT_TEMPLATE_URL_CUSTOMER_LABOR =
      "/assets/templates/Labor_AS_template__.xlsx?r=" + new Date().getTime();
    this.AUDITREPORT_TEMPLATE_URL_CUSTOMER_PARTS_QA =
      "/assets/templates/Parts_AS_template_QA.xlsx?r=" + new Date().getTime();
    this.AUDITREPORT_TEMPLATE_URL_CUSTOMER_LABOR_QA =
      "/assets/templates/Labor_AS_template_QA.xlsx?r=" + new Date().getTime();
    this.AUDITREPORT_DB_SAVING_FAILED = "DB Saving Failed";
    this.AUDITREPORT_NO_EDIT_PERFORMED = "No edit performed in the data";
    this.AUDITREPORT_DATA_SAVED_SUCCESS = "Data Saved Successfully";
    this.AUDITREPORT_TEMPLATE_URL_CUSTOMER_PARTS_WITOUT_FORMULA =
      "/assets/templates/Parts_AS_template_remove_formula.xlsx?r=" + new Date().getTime();
    this.AUDITREPORT_TEMPLATE_URL_CUSTOMER_LABOR_WITOUT_FORMULA =
      "/assets/templates/Labor_AS_template_remove_formula.xlsx?r=" + new Date().getTime();

    this.AUDITREPORT_TEMPLATE_URL_CUSTOMER_PARTS_WITOUT_FORMULA_DUAUDIT_PARTS =
      "/assets/templates/Parts_AS_template_remove_formula_Du-Audit-Parts.xlsx?r=" +
      new Date().getTime();
    this.AUDITREPORT_TEMPLATE_URL_CUSTOMER_PARTS_WITOUT_FORMULA_DUAUDIT_LABOR =
      "/assets/templates/Labor_AS_template_remove_formula_Du-Audit-Labor.xlsx?r=" +
      new Date().getTime();
    this.LABOR_GRID_TEMPLATE_URL =
      "/assets/templates/SWAT_Labor_Grid.xlsx?r=" + new Date().getTime();
  }
}
