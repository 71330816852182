import { Injectable } from "@angular/core";
// import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { ConstantService } from "../constants/constant.service";
import { environment } from "src/environments/environment";

@Injectable()
export class UploadFileService {

  constructor(private constantService: ConstantService) {

  }

  uploadfile(file: any, callback: any) {

  }

  getFileBlobData(params: any, callback: any) {

  }

  copyObject(paramValue: any, callback: any) {

  }

  deleteObject(params: any, callback: any) {

  }
}
