import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { LoginComponent } from "./modules/login/login.component";
import { AuthGuard } from "./authGuard";
import { AccessDeniedComponent } from "./structure/components/access-denied/access-denied.component";
import { SolveSyncModule } from "./modules/solve-sync/solve-sync.module";
const routes: Routes = [
  // {
  //   path: "profile",
  //   component: DashboardComponent,
  //   canActivate: [AuthGuard, MsalGuard],
  // },
  // {
  //   path: "",
  //   component: HomeComponent,
  // },
  // {
  //   path: "",
  //   redirectTo: "/login",
  //   pathMatch: "full",
  // },
  {
    path: "access-denied",
    component: AccessDeniedComponent,
    data: { title: "Access Denied" },
  },
  {
    path: "login",
    component: LoginComponent,
    data: { title: "Login" },
  },
  // {
  //   path: "login?:sKey",
  //   component: LoginComponent,
  //   data: { title: "Login" },
  // },

  // { path: "", redirectTo: "due-date-report", pathMatch: "full" },
  // {
  //   path: "",
  //   loadChildren: () =>
  //     import("./modules/home/home.module").then((m) => m.HomeModule),
  //   canActivate: [AuthGuard, MsalGuard],
  //   data: { title: "Home" },
  // },

  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "code",
    redirectTo: "login",
  },
  {
    path: "home",
    loadChildren: () =>
      import("./modules/duedate-report/duedate-report.module").then((m) => m.DuedateReportModule),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Home", breadcrumb: [{ label: "Home", url: "" }] },
  },
  // {
  //   path: "home_",
  //   loadChildren: () =>
  //     import("./modules/home/home.module").then((m) => m.HomeModule),
  //   canActivate: [AuthGuard, MsalGuard],
  //   data: { title: "Home" },
  // },
  {
    path: "swat",
    loadChildren: () => import("./modules/swat/swat.module").then((m) => m.SwatModule),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "SWAT" },
  },
  // {
  //   path: "swat/:id",
  //   loadChildren: () =>
  //     import("./modules/swat/swat.module").then((m) => m.SwatModule),
  //   canActivate: [AuthGuard, MsalGuard],
  //   data: { title: "Swat" },
  // },
  {
    path: "future-projects",
    loadChildren: () =>
      import("./modules/future-projects/future-projects.module").then(
        (m) => m.FutureProjectsModule
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Future Projects" },
  },
  {
    path: "billing-process-history",
    loadChildren: () =>
      import("./modules/billing-process-history/billing-process-history.module").then(
        (m) => m.BillingProcessHistoryModule
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Billing Process History" },
  },
  {
    path: "due-date-report",
    loadChildren: () =>
      import("./modules/duedate-report/duedate-report.module").then((m) => m.DuedateReportModule),
    canActivate: [AuthGuard, MsalGuard],
    data: {
      title: "Due Date Module",
      breadcrumb: [{ label: "Due Date Module", url: "" }],
    },
  },
  {
    path: "exception-management",
    loadChildren: () =>
      import("./modules/exception-management/exception-management.module").then(
        (m) => m.ExceptionManagementModule
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Home" },
  },
  {
    path: "dm-summary",
    loadChildren: () =>
      import("./modules/group-summary/group-summary.module").then((m) => m.GroupSummaryModule),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Group Summary" },
  },
  {
    path: "group-overview",
    loadChildren: () =>
      import("./modules/group-overview/group-overview.module").then((m) => m.GroupOverviewModule),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Group Overview" },
  },
  {
    path: "production-snapshot",
    loadChildren: () =>
      import("./modules/production-snapshot/production-snapshot.module").then(
        (m) => m.ProductionSnapshotModule
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Production Snapshot" },
  },
  {
    path: "productivity-dashboard",
    loadChildren: () =>
      import("./modules/productivity-dashboard/productivity-dashboard.module").then(
        (m) => m.ProductivityDashboardModule
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Productivity Dashboard" },
  },
  {
    path: "project-overview",
    loadChildren: () =>
      import("./modules/project-overview/project-overview.module").then(
        (m) => m.ProjectOverviewModule
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Project Overview" },
  },
  {
    path: "store-overview/:id",
    loadChildren: () =>
      import("./modules/store-overview/store-overview.module").then((m) => m.StoreOverviewModule),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Store Overview" },
  },
  {
    path: "secondary-unresolved",
    loadChildren: () =>
      import("./modules/secondary-unresolved/secondary-unresolved.module").then(
        (m) => m.SecondaryUnresolvedModule
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Secondary Unresolved" },
  },
  {
    path: "store-overview",
    loadChildren: () =>
      import("./modules/store-overview/store-overview.module").then((m) => m.StoreOverviewModule),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Store Overview" },
  },
  {
    path: "store-searcher",
    loadChildren: () =>
      import("./modules/store-searcher/store-searcher.module").then((m) => m.StoreSearcherModule),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Store Searcher" },
  },
  {
    path: "user-management",
    loadChildren: () =>
      import("./modules/user-management/user-management.module").then(
        (m) => m.UserManagementModule
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "User Management" },
  },
  {
    path: "solve-update-log",
    loadChildren: () =>
      import("./modules/solve-sync/solve-sync.module").then((m) => SolveSyncModule),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Solve Update Log" },
  },
  // {
  //   path: "id_token",
  //   redirectTo: "home",
  // },
  // {
  //   path: "**",
  //   redirectTo: "/home",
  //   pathMatch: "full",
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
