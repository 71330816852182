import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable } from "rxjs";
@Injectable()
export class ValidationService {
  patternValidator(regex: RegExp): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const valid = regex.test(control.value);
      return Observable.create((observer: any) => {
        if (valid) {
          observer.next(null); // no error if valid
        } else {
          observer.next({ invalidPattern: { value: control.value } }); // emit error if invalid
        }
        observer.complete();
      });
    };
  }
}
