import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFocusOnClick]',
})
export class FocusOnClickDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('click', ['$event.target'])
  onClick(btn:any) {
    console.log('Multi select dropdown onClick', btn, 'span:', btn.querySelector('span'), 'parent span:', btn.parentElement.querySelector('span'));
    // if (btn.classList.contains('dropdown-btn') || 
    // btn.classList.contains('dropdown-multiselect__caret') ||
    // btn.classList.contains('selected-item')) {
    if (btn.querySelector('span') || btn.parentElement.querySelector('span')) {
      //do stuff
      const input = this.elementRef.nativeElement.querySelector(
        '.filter-textbox > input'
      );
      if (input) {
        input.focus();
        console.log('Search focus', btn);
      }
     } else {
      // nothing to do
      console.log('No need of Search focus', btn);
     }
  }
}