import { Component, HostListener } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import moment from "moment-timezone";
import { Subject, takeUntil } from "rxjs";
import { CalanderInterface } from "src/app/structure/interface/interface";
import { CommonService } from "src/app/structure/services/common.service";

declare let $: any;
declare let jQuery: any;
declare let NProgress: any;

const getPortalApiProcessStatus = gql`
  query getPortalApiProcessStatus {
    getPortalApiProcessStatus
  }
`;

@Component({
  selector: "app-solve-sync",
  templateUrl: "./solve-sync.component.html",
  styleUrls: ["./solve-sync.component.css"],
  providers: [CommonService],
})
export class SolveSyncComponent {
  private subscription$ = new Subject();
  public loading: any = false;
  public isAuthenticated = false;
  private gridApiPortal: any;
  private gridColumnApiPortal: any;
  public columnDefsPortal: any;
  public rowDataPortal: any;
  public logDataMain: any = [];
  public overlayLoadingTemplate: any;
  public overlayNoRowsTemplate: any;
  public defaultColDef: any;
  // public inStartDate: any;
  // public inEndDate: any;

  constructor(
    public commonService: CommonService,
    private apollo: Apollo
  ) {
    this.defaultColDef = {
      resizable: true,
      filter: true,
      sortable: true,
      enablePivot: true,
      suppressSizeToFit: false,
      suppressExcelExport: true,
      debounceVerticalScrollbar: true,
      filterParams: { newRowsAction: "keep" },
    };
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Loading <em aria-hidden="true" class="fa fa-spinner fa-pulse"></em></span>';
    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No data found</span>';
  }

  ngOnInit() {
    /**
     *  check user group permissions and get user accessible menu list while loading the component
     */
    this.commonService.getGroups(() => {
      this.commonService.checkGroups((flag) => {
        if (!flag) {
          return;
        }
        this.isAuthenticated = true;
        this.init();
      });
    });
  }
  ngOnDestroy() {
    this.subscription$.next(void 0);
    this.subscription$.complete();
  }
  init() {
    // const objPropertyCalender: CalanderInterface = this.commonService.getCalenderPropertyObject();
    // $(document).ready(function () {
    //   $('[data-toggle="popover"]').popover();
    //   $('[data-toggle="tooltip"]').tooltip({
    //     trigger: "hover",
    //   });
    //   $("#inStartDate").datetimepicker(objPropertyCalender);
    //   $("#inEndDate").datetimepicker(objPropertyCalender);
    // });
    this.refresh();
  }
  @HostListener("mouseover")
  onMouseOver() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover",
    });
  }
  @HostListener("click", ["$event.target"])
  onClick() {
    $('[data-toggle="popover"]').popover({
      trigger: "click",
    });
  }
  getPortalApiProcessStatus(callback: any) {
    this.apollo
      .use("dashboardClient")
      .query({
        query: getPortalApiProcessStatus,
        fetchPolicy: "network-only",
      })
      .pipe(takeUntil(this.subscription$))
      .subscribe({
        next: (listdata: any) => {
          const result = listdata ? listdata["data"]["getPortalApiProcessStatus"] : [];
          this.logDataMain = result ? JSON.parse(result) : [];
          console.log("getPortalApiProcessStatus listdata: ", listdata);
          callback();
        },
        error: (err) => {
          this.logDataMain = [];
          this.commonService.errorCallback(err, this);
          callback();
        },
        complete: () => {
          console.log("Completed");
        },
      });
  }
  onGridReadyPortal(params: any) {
    this.gridApiPortal = params.api;
    this.gridColumnApiPortal = params.columnApi;
    params.api.sizeColumnsToFit();
  }
  setPortalGridData() {
    this.columnDefsPortal = [
      {
        headerName: "Object Type",
        field: "object_type",
        width: 85,
      },
      {
        headerName: "Identifier",
        field: "object_id",
        width: 100,
      },
      {
        headerName: "Transaction Type",
        field: "transaction_type",
        width: 100,
      },
      {
        headerName: "Source",
        field: "source",
        width: 150,
      },
      {
        headerName: "User",
        field: "action_by",
        width: 230,
      },
      {
        headerName: "Created",
        field: "created",
        width: 200,
      },
      {
        headerName: "Is Processed",
        field: "is_processed",
        width: 100,
        cellRenderer: (params: any) => {
          return params.value == true ? "True" : params.value == false ? "False" : "";
        },
      },
      {
        headerName: "Processing Result",
        field: "processing_result",
        width: 110,
      },
      {
        headerName: "Fields",
        field: "fields",
        width: 200,
        cellRenderer: (params: any) => {
          let displayFulltxt = params.value
            ? params.value.toString().replace(/([^ ])\,/g, "$1, ")
            : "";
          let celltxt = displayFulltxt;
          if (displayFulltxt && displayFulltxt.length > 25) {
            celltxt = displayFulltxt.substring(0, 24) + "...";
          }
          // $(".tooltip").tooltip("hide");
          const data = `<span data-placement="top" data-toggle="tooltip"
          data-original-title="${displayFulltxt}" data-html="true" data-animation="false">
          ${celltxt} 
          </span>`;
          return data;
        },
      },
      {
        headerName: "Categories",
        field: "categories",
        width: 200,
        cellRenderer: (params: any) => {
          let displayFulltxt = params.value
            ? params.value.toString().replace(/([^ ])\,/g, "$1, ")
            : "";
          let celltxt = displayFulltxt;
          if (displayFulltxt && displayFulltxt.length > 25) {
            celltxt = displayFulltxt.substring(0, 24) + "...";
          }
          // $(".tooltip").tooltip("hide");
          const data = `<span data-placement="top" data-toggle="tooltip"
          data-original-title="${displayFulltxt}" data-html="true" data-animation="false">
          ${celltxt} 
          </span>`;
          return data;
        },
      },
      {
        headerName: "Related To",
        field: "relations",
        width: 200,
        cellRenderer: (params: any) => {
          let displayFulltxt = params.value
            ? params.value.toString().replace(/([^ ])\,/g, "$1, ")
            : "";
          let celltxt = displayFulltxt;
          if (displayFulltxt && displayFulltxt.length > 25) {
            celltxt = displayFulltxt.substring(0, 24) + "...";
          }
          // $(".tooltip").tooltip("hide");
          const data = `<span data-placement="top" data-toggle="tooltip"
          data-original-title="${displayFulltxt}" data-html="true" data-animation="false">
          ${celltxt} 
          </span>`;
          return data;
        },
      },
    ];
  }
  refresh() {
    $(".tooltip").tooltip("hide");
    this.loading = true;
    this.getPortalApiProcessStatus(() => {
      console.log("rowData: ", this.logDataMain);
      this.rowDataPortal = this.logDataMain;
      // Extract created dates and convert them to moment objects
      // const createdDates = this.logDataMain.map((obj: any) => moment(obj.created));
      // // Find the minimum and maximum dates
      // const minDate = moment.min(createdDates);
      // const maxDate = moment.max(createdDates);
      // // Format the dates as "MM-DD-YYYY"
      // this.inStartDate = minDate.format("MM-DD-YYYY");
      // this.inEndDate = maxDate.format("MM-DD-YYYY");
      this.loading = false;
      this.setPortalGridData();
    });
  }
  // setInStartDate(e: any) {
  //   if (e.value && e.value !== "") {
  //     this.inStartDate = e.value;
  //   } else {
  //     this.inStartDate = null;
  //   }
  // }
  // setinEndDate(e: any) {
  //   if (e.value && e.value !== "") {
  //     this.inEndDate = e.value;
  //   } else {
  //     this.inEndDate = null;
  //   }
  // }
  // filterResponse() {
  //   // Filter the data to get objects within the date range
  //   let filteredData = [];
  //   if (this.inStartDate && this.inEndDate) {
  //     filteredData = this.logDataMain.filter((obj: any) => {
  //       const createdDate = moment(obj.created).format("MM-DD-YYYY");
  //       return createdDate >= this.inStartDate && createdDate <= this.inEndDate;
  //     });
  //   } else if (this.inStartDate) {
  //     filteredData = this.logDataMain.filter((obj: any) => {
  //       return moment(obj.created).format("MM-DD-YYYY") >= this.inStartDate;
  //     });
  //   } else if (this.inEndDate) {
  //     filteredData = this.logDataMain.filter((obj: any) => {
  //       return moment(obj.created).format("MM-DD-YYYY") <= this.inEndDate;
  //     });
  //   }
  //   console.log("filteredData", filteredData);
  //   this.rowDataPortal = filteredData;
  // }
}
