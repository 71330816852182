<app-common-bar> </app-common-bar>
<div class="cat__content">
  <router-outlet></router-outlet>
  <!-- <button mat-menu-item (click)="loginPopup()">Login using Popup</button> -->
  <!-- <div class="container"> -->
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
  <!-- </div> -->
  <app-footer></app-footer>
</div>
