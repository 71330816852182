import { Component, OnInit } from "@angular/core";
import { NgDynamicBreadcrumbService } from "ng-dynamic-breadcrumb";

@Component({
  selector: "app-access-denied",
  templateUrl: "./access-denied.component.html",
  styleUrls: ["./access-denied.component.css"],
})
export class AccessDeniedComponent implements OnInit {
  constructor(private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) {}

  ngOnInit() {
    // this.NgDynamicBreadcrumbService.addFriendlyNameForRouteRegex(
    //   "\\/access-denied(\\?.*)?$",
    //   "Access Denied"
    // );
    const breadcrumb = { customText: "This is the Custom Text" };
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }
}
